import { ROLE_TYPE } from './account';
import { AVATAR_ID } from './avatars';
import { MemberId, PROF_SPECIALITY } from './member';
export type CalendarId = string;
export enum CALENDAR_WORKING_TIME_REPEATER_TYPE {
  UNDEFINED = 0,
  DAYLY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
}
export type CalendarWorkingTimeRepeater = {
  type: CALENDAR_WORKING_TIME_REPEATER_TYPE;
  count: number;
};
export type CalendarWorkingTimeRepeaters = CalendarWorkingTimeRepeater[];
export type CalendarWorkingTimeId = number;
export type CalendarWorkingTimeIds = CalendarWorkingTimeId[];
export type CalendarWorkingTimeRowversion = string;
export type CalendarTimeInterval = {
  beginTime: string;
  endTime: string;
};
export type CalendarTimeIntervals = CalendarTimeInterval[];
export type CalendarWorkingTime = {
  id: CalendarWorkingTimeId;
  timing: {
    beginTime: string;
    endTime: string;
  };
  rowversion: CalendarWorkingTimeRowversion;
  repeaters: null | CalendarWorkingTimeRepeaters;
};
export type CalendarWorkingTimes = CalendarWorkingTime[];
export type CalendarWorkingTimeWithoutRepeaters = Omit<CalendarWorkingTime, 'repeaters'>;
export enum CALENDAR_TIME_SLOT_STATE {
  UNDEFINED = 0,
  AVAILABLE = 1,
  BUSY = 2,
}
export type CalendarTimeSlotId = string;
export type CalendarTimeSlotBase = {
  id: CalendarTimeSlotId;
  timing: {
    beginTime: string;
    endTime: string;
  };
  state: CALENDAR_TIME_SLOT_STATE;
};
export type CalendarWorkingTimeTimeSlot = CalendarTimeSlotBase & {
  state: CALENDAR_TIME_SLOT_STATE.AVAILABLE;
  meta: {
    workingTime: {
      id: CalendarWorkingTimeId;
    };
  };
};
export type CalendarWorkingTimeTimeSlots = Record<CalendarTimeSlotId, CalendarWorkingTimeTimeSlot>;
export type CalendarConsultationTimeSlot = CalendarTimeSlotBase & {
  state: CALENDAR_TIME_SLOT_STATE.BUSY;
  meta: {
    consultation: {
      id: CalendarConsultationId;
    };
  };
};
export type CalendarConsultationTimeSlots = Record<CalendarTimeSlotId, CalendarConsultationTimeSlot>;
export type CalendarAvailableTimeSlot = CalendarTimeSlotBase & {
  state: CALENDAR_TIME_SLOT_STATE.AVAILABLE;
  meta: {
    workingTime: {
      id: CalendarWorkingTimeId;
    };
  };
};
export type CalendarAvailableTimeSlots = Record<CalendarTimeSlotId, CalendarAvailableTimeSlot>;
export type CalendarBusyTimeSlot = CalendarTimeSlotBase & {
  state: CALENDAR_TIME_SLOT_STATE.BUSY;
  meta: {
    workingTime: {
      id: CalendarWorkingTimeId;
    };
    consultation: {
      id: CalendarConsultationId;
    };
  };
};
export type CalendarTimeSlots = CalendarCurrentMemberTimeSlot[];
export type CalendarConsultationActionId = string;
export enum CALENDAR_CONSULTATION_ACTION_PURPOSE {
  UNDEFINED = 0,
  CALL = 1,
}
export enum CALENDAR_CONSULTATION_ACTION_STATE {
  UNDEFINED = 0,
  CREATED = 1,
  IN_PROGRESS = 2,
  DONE = 3,
}
export type CalendarConsultationActionClientParticipant = {
  userId: MemberId;
  roleType: ROLE_TYPE.CLIENT;
  isInitiator: boolean;
  avatarId?: AVATAR_ID;
  photoUrl?: string;
  name: string;
};
export type CalendarConsultationActionSpecialistParticipant = {
  userId: MemberId;
  roleType: ROLE_TYPE.SPECIALIST;
  isInitiator: boolean;
  avatarId?: AVATAR_ID;
  photoUrl?: string;
  isCertified?: boolean;
  profSpeciality?: PROF_SPECIALITY;
  name: string;
};
export type CalendarConsultationActionParticipant = CalendarConsultationActionClientParticipant | CalendarConsultationActionSpecialistParticipant;
export type CalendarConsultationActionParticipants = CalendarConsultationActionParticipant[];
export type CalendarConsultationAction = {
  id: CalendarConsultationActionId;
  creationTime: string;
  purpose: CALENDAR_CONSULTATION_ACTION_PURPOSE;
  state: CALENDAR_CONSULTATION_ACTION_STATE;
  payload?: {
    rejectionComment: string;
  };
  participants: CalendarConsultationActionParticipants;
};
export type CalendarConsultationActions = CalendarConsultationAction[];
export type CalendarConsultationId = string;
export enum CALENDAR_CONSULTATION_STATE {
  UNDEFINED = 0,
  CREATED = 1,
  APPROVED = 2,
  REJECTED = 3,
  CANCELED = 4,
  DONE = 5,
}
export enum CALENDAR_CONSULTATION_INVITATION_STATE {
  UNDEFINED = 0,
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
}
export enum CALENDAR_PRICE_TYPE {
  UNDEFINED = 0,
  CALL = 1,
}
export type CalendarPrice = {
  itemType: CALENDAR_PRICE_TYPE;
  coinAmount: number;
};
export type CalendarPrices = CalendarPrice[];
export type CalendarDefautls = {
  slot: {
    durationMinutes: number;
  };
  prices: CalendarPrices;
  consultation: {
    maxDurationMinutes: number;
  };
};
export enum CALENDAR_ORIENTATION {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

/**
 * Current Member
 */

export type CalendarCurrentMemberWorkingTimes = CalendarWorkingTimes;
export type CalendarCurrentMemberConsultation = {
  id: CalendarConsultationId;
  timing: {
    beginTime: string;
    durationMinutes: number;
  };
  state: CALENDAR_CONSULTATION_STATE;
  invitationState: CALENDAR_CONSULTATION_INVITATION_STATE;
  creationTime: string;
  payload?: {
    rejectionComment: string;
  };
  actions: CalendarConsultationActions;
};
export type CalendarCurrentMemberConsultations = CalendarCurrentMemberConsultation[];
export type CalendarCurrentMemberInfo = {
  visible: boolean;
  startTime: undefined | string;
};
export type CalendarCurrentSpecialistCalendar = {
  id: CalendarId;
  owner: {
    id: MemberId;
    roleType: ROLE_TYPE.SPECIALIST;
  };
  workingTimes: CalendarCurrentMemberWorkingTimes;
  consultations: CalendarCurrentMemberConsultations;
};
export type CalendarCurrentSpecialistTimeSlot = CalendarAvailableTimeSlot /**/ | CalendarBusyTimeSlot;
export type CalendarCurrentSpecialistTimeSlots = Record<CalendarTimeSlotId, CalendarCurrentSpecialistTimeSlot>;
export type CalendarCurrentSpecialist = {
  calendar?: CalendarCurrentSpecialistCalendar;
  info?: CalendarCurrentMemberInfo;
};
export type CalendarCurrentClientTimeSlot = CalendarConsultationTimeSlot;
export type CalendarCurrentClientTimeSlots = Record<CalendarTimeSlotId, CalendarConsultationTimeSlot>;
export type CalendarCurrentClientCalendar = {
  id: CalendarId;
  owner: {
    id: MemberId;
    roleType: ROLE_TYPE.CLIENT;
  };
  consultations: CalendarCurrentMemberConsultations;
};
export type CalendarCurrentClient = {
  calendar?: CalendarCurrentClientCalendar;
  info?: CalendarCurrentMemberInfo;
};
export type CalendarCurrentMemberCalendar = CalendarCurrentSpecialistCalendar | CalendarCurrentClientCalendar;
export type CalendarCurrentMemberTimeSlot = CalendarCurrentSpecialistTimeSlot | CalendarCurrentClientTimeSlot;
export type CalendarCurrentMemberTimeSlots = CalendarCurrentSpecialistTimeSlots | CalendarCurrentClientTimeSlots;
export type CalendarCurrentMember = CalendarCurrentSpecialist | CalendarCurrentClient;

/**
 * Member
 */

export type CalendarMemberConsultation = {
  id: CalendarConsultationId;
  timing: {
    beginTime: string;
    durationMinutes: number;
  };
  state: CALENDAR_CONSULTATION_STATE;
  creationTime: string;
};
export type CalendarMemberConsultations = CalendarMemberConsultation[];
export type CalendarSpecialistCalendar = {
  id: CalendarId;
  owner: {
    id: MemberId;
    roleType: ROLE_TYPE.SPECIALIST;
    photoUrl: string;
  };
  workingTimes: CalendarWorkingTimes;
  consultations: CalendarMemberConsultations;
  payload?: {
    rejectionComment: string;
  };
  actions?: CalendarConsultationActions;
};
export type CalendarSpecialistInfo = {
  visible: boolean;
  startTime: undefined | string;
};
export type CalendarSpecialist = {
  calendar?: CalendarSpecialistCalendar;
  info?: CalendarSpecialistInfo;
};
export type CalendarMemberCalendar = CalendarSpecialistCalendar;
export type CalendarSpecialistTimeSlot = CalendarAvailableTimeSlot /**/ | CalendarBusyTimeSlot;
export type CalendarMemberTimeSlot = CalendarSpecialistTimeSlot;
export type CalendarMemberTimeSlots = Record<CalendarTimeSlotId, CalendarMemberTimeSlot>;
export type CalendarMember = CalendarSpecialist;
export type CalendarMembersById = Record<MemberId, CalendarMember>;