export enum QUERY_PARAM {
  REDIRECT_TO = 'redirectTo',
  BACK_TO = 'backTo',
  SCROLL_Y = 'scrollY',
  CODE = 'code',
  USER_ID = 'userid',
  SOURCE = 'source',
  REALTIME_KEY = 'realTimeKey',
  COINS_AMOUNT = 'coinsAmount',
  PURPOSE = 'purpose',
  ERROR_CODE = 'error_code',
  ERROR_FIELD = 'error_field',
  STEP = 'step',
  PAGE = 'page',
  KEEP_RESULTS = 'keepResults',
  CHATS_FILTER = 'chats_filter',
  CHATS_SEARCH = 'chats_search',
  FRONTEND_APPLICATION_HOST = 'frontendapplicationhost',
  CALENDAR_CURRENT_DATE = 'calendar_current_date',
  // Start: Search params
  SEARCH_FILTER = 'search_filter',
  SEX = 'sex',
  AGE_FROM = 'age_from',
  AGE_TO = 'age_to',
  SUBJECT_ID = 'subject_id',
  IS_CERTIFIED = 'is_certified',
  PROF_SPECIALITY = 'prof_speciality',
  RATING_FROM = 'rating_from',
  RATING_TO = 'rating_to',
  LEVEL = 'level',
  // End: Search params
  EMAIL_NOTIFICATIONS_UNSUBSCRIBE_UID = 'uid',
  EMAIL_NOTIFICATIONS_UNSUBSCRIBE_CODE = 'code',
  // Page params
  PATHNAME_CLIENT_ID = 'clientId',
  PATHNAME_SPECIALIST_ID = 'specialistId',
  PATHNAME_CHAT_ID = 'chatId',
  PATHNAME_CONSULTATION_ID = 'consultationId',
} // End: Page params

export enum NAVIGATION_MODE {
  PUSH = 'PUSH',
  REPLACE = 'REPLACE',
  REDIRECT = 'REDIRECT',
}