import { calendarConsultationInitiator } from '@helpers/calendar';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimeCalendarConsultationDoneEventPayloadData } from '@services/dto/realtime/realtime-calendar-event/realtime-calendar-consultation-done';
import { calendarDecreaseConsultationsCounter, realtimeReceiveCalendarConsultationDone } from '@slices/calendar';
import { accountGetAccountId } from '@selectors/account';
import { RealtimeCalendarConsultationDone } from './types';
export const realtimeCalendarConsultationDone: RealtimeCalendarConsultationDone = event => {
  return (dispatch, getState) => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeCalendarConsultationDoneEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      consultation
    } = data;
    const currentMemberId = accountGetAccountId(getState());
    const initiator = calendarConsultationInitiator(consultation, currentMemberId);
    if (!initiator) {
      return;
    }
    dispatch(realtimeReceiveCalendarConsultationDone({
      consultationId: consultation.id,
      memberId: initiator.userId
    }));
    dispatch(calendarDecreaseConsultationsCounter({
      decrement: 1
    }));
  };
};