import { SpecialistsReceiveSendComplaintReducer } from './types';
export const specialistsReceiveSendComplaintReducer: SpecialistsReceiveSendComplaintReducer = (state, action) => {
  const {
    specialistId
  } = action.payload;
  return {
    ...state,
    specialistsById: {
      ...state.specialistsById,
      [specialistId]: {
        ...state.specialistsById[specialistId],
        hasComplaint: true
      }
    }
  };
};