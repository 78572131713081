import { AccountSetUnauthorizedReducer } from './types';
export const accountSetUnauthorizedReducer: AccountSetUnauthorizedReducer = state => {
  return {
    ...state,
    isAuthorized: false,
    id: undefined,
    prevId: state.id,
    roleType: undefined,
    emailIsConfirmed: undefined,
    associatedSocialMedia: [],
    timezoneId: undefined,
    timezones: undefined
  };
};