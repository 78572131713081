import { useEffect, useRef } from 'react';
import { POPUP, POPUPS_QUERY_BLACKLIST } from '@config/popups';
import { differenceWithCaseInsensitive } from '@helpers/data';
import { isPopupId, parseQuerySource } from '@helpers/popups';
import { AppFC } from '@types';
import { popupsGet } from '@selectors/popups';
import { usePopup } from '@hooks/popup';
import { useNavigator } from '@hooks/routing';
import { useTypedDispatch, useTypedSelector } from '@hooks/store';
import { POPUPS_BY_ID } from '@popups/popups';
import { Popups } from '@kit/popups';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PopupsManager: AppFC = () => {
  const dispatch = useTypedDispatch();
  const popups = useTypedSelector(popupsGet);
  const {
    addPopup,
    removePopup
  } = usePopup();
  const prevQueryPopupIdsRef = useRef(([] as POPUP[]));
  const navigator = useNavigator();
  const query = navigator.getQuery();
  const blacklistPopupIds = POPUPS_QUERY_BLACKLIST;
  useEffect(() => {
    const queryPopupIds = parseQuerySource(query) //
    .filter(isPopupId);
    const toRemove = differenceWithCaseInsensitive(prevQueryPopupIdsRef.current, queryPopupIds);
    const toAdd = differenceWithCaseInsensitive(queryPopupIds, prevQueryPopupIdsRef.current, blacklistPopupIds);
    toRemove.forEach(id => removePopup({
      id
    }));
    toAdd.forEach(id => addPopup({
      id
    }));
    prevQueryPopupIdsRef.current = queryPopupIds;
  }, [query, navigator, dispatch, blacklistPopupIds, removePopup, addPopup]);
  return _jsx(Popups //
  , {
    popupsDict: POPUPS_BY_ID,
    popups: popups
  });
};