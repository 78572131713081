import { CurrentClientReceiveSetAvatarReducer } from './types';
export const currentClientReceiveSetAvatarReducer: CurrentClientReceiveSetAvatarReducer = (state, action) => {
  const me = state.me;
  if (!me) {
    return state;
  }
  const {
    avatarId,
    sex
  } = action.payload;
  return {
    ...state,
    me: {
      ...me,
      sex,
      avatarId,
      defaultPhotoId: undefined
    }
  };
};