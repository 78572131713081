import { MEDIA_CALL_DIRECTION, MEDIA_CALL_STATE, MEDIA_CALL_TYPE, MediaCall, MediaCallMessageCall } from '@types';
export { isSupported as isMediaCallSupported } from 'twilio-video';
export const isAudioCall = (call: undefined | MediaCall | MediaCallMessageCall): boolean => {
  return call?.type === MEDIA_CALL_TYPE.AUDIO;
};
export const isVideoCall = (call: undefined | MediaCall | MediaCallMessageCall): boolean => {
  return call?.type === MEDIA_CALL_TYPE.VIDEO;
};
export const isIncomingCall = (call: undefined | MediaCall): boolean => {
  return call?.direction === MEDIA_CALL_DIRECTION.INCOMING;
};
export const isOutgoingCall = (call: undefined | MediaCall): boolean => {
  return call?.direction === MEDIA_CALL_DIRECTION.OUTGOING;
};
export const isConnectingCall = (call: undefined | MediaCall): boolean => {
  return call?.state === MEDIA_CALL_STATE.CONNECTING;
};
export const isActiveCall = (call: undefined | MediaCall): boolean => {
  return call?.state === MEDIA_CALL_STATE.ACTIVE;
};
export const isRejectedByUserCall = (call: undefined | MediaCall): boolean => {
  return call?.state === MEDIA_CALL_STATE.REJECTED_BY_USER;
};
export const isRejectedByTimeoutCall = (call: undefined | MediaCall): boolean => {
  return call?.state === MEDIA_CALL_STATE.REJECTED_BY_TIMEOUT;
};
export const isRejectedCall = (call: undefined | MediaCall): boolean => {
  return isRejectedByUserCall(call) || isRejectedByTimeoutCall(call);
};
export const isMediaDeviceAvailable = async (args: {
  audio?: boolean;
  video?: boolean;
}): Promise<boolean> => {
  const {
    audio,
    video
  } = args;
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio,
      video
    });

    /**
     * Calling getUserMedia will start the media track selected.
     * This is not desired as the user may get the impression the mic is in use.
     * Therefore, we want to avoid having tracks started when they're not needed.
     * We only wanted to get the input device list so we stop the tracks immediately.
     */
    stream.getTracks().forEach(track => track.stop());
    return true;
  } catch (error) {
    if (error instanceof DOMException) {
      return false;
    }
    throw error;
  }
};