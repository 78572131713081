import { supportGetUnread } from '@services/support/support-get-unread';
import { supportSetUnreadMessagesCounter } from '@slices/support';
import { CountersFetchGetSupportUnread } from './types';
export const countersFetchGetSupportUnread: CountersFetchGetSupportUnread = () => {
  return async dispatch => {
    const data = await supportGetUnread().promise;
    dispatch(supportSetUnreadMessagesCounter({
      unreadMessagesCount: data.messagesCount
    }));
    return data;
  };
};