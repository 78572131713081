import { ViewsIncreaseViewsCounterReducer } from './types';
export const viewsIncreaseViewsCounterReducer: ViewsIncreaseViewsCounterReducer = (state, action) => {
  const {
    increment
  } = action.payload;
  const {
    pendingViewsCount
  } = state;
  return {
    ...state,
    pendingViewsCount: pendingViewsCount + increment
  };
};