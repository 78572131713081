import { ChatsSetPrepaymentBalanceReducer } from './types';

/**
 * @deprecated
 * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
 */
export const chatsSetPrepaymentBalanceReducer: ChatsSetPrepaymentBalanceReducer = (state, action) => {
  const {
    chatId,
    balanceAmount,
    totalAmount
  } = action.payload;
  const {
    chatsById
  } = state;
  const chat = chatsById[chatId];
  if (!chat) {
    return state;
  }
  return {
    ...state,
    chatsById: {
      ...chatsById,
      [chatId]: {
        ...chat,
        prepaymentInfo: {
          ...chat.prepaymentInfo,
          balanceAmount,
          totalAmount
        }
      }
    }
  };
};