import { AVATAR_ID, ONLINE_STATUS, SEX } from '@types';
import { ROLE_TYPE } from './account';
import { MemberId, PROF_SPECIALITY } from './member';
import { LocalMessage, Message } from './message';
export enum CHAT_FILTER {
  ALL = 1,
  UNREAD = 2,
  BLACK_LIST = 3,
  FAVORITE = 4,
  ONLINE = 5,
}
export type ChatId = string;
export type ChatPrepaymentInfo = {
  balanceAmount: number;
  totalAmount: number;
};
export type Chat = {
  chatId: ChatId;
  chatMateUserId: MemberId;
  chatMateRoleType: ROLE_TYPE;
  chatMateUserName: string;
  userIsCertified?: boolean;
  userProfSpeciality?: PROF_SPECIALITY;
  userRating?: number;
  userOnlineStatus: ONLINE_STATUS;
  userHasComplaint?: boolean;
  userHasComplaintAgainstMe?: boolean;
  userIsDeleted?: boolean;
  avatarId?: AVATAR_ID;
  photoUrl?: string;
  sex?: SEX;
  lastVisitTime?: string;
  lastMessage?: LocalMessage | Message;
  unreadedMessagesCount: number;
  /**
   * @deprecated
   * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
   */
  prepaymentInfo?: ChatPrepaymentInfo;
  isFavorite: boolean;
  inBlackList: boolean;
};
export type Chats = Chat[];
export type ChatsList = {
  items: Chats;
  offset: number;
  limit: number;
  hasMore: boolean;
  totalCount: number;
  dataLoaded: boolean;
};