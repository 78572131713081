import { NOTIFICATION_TYPE } from '@config/notifications';
import { photoOrAvatarUrl } from '@helpers/member';
import { messagesIsCalendarMessage, messagesIsCallMessage, messagesIsDynamicMessage, messagesIsSystemMessage, messagesIsTextMessage } from '@helpers/messages';
import { realtimeChatMessageReceivedFromDto } from '@mapping/realtime/realtime-chat-message-received';
import { notificationsUiHandleAdd } from '@actions/notifications-ui/notifications-ui-handle-add';
import { chatsAddChat, chatsIncreaseUnreadMessagesCounter, chatsSetLastMessage } from '@slices/chats';
import { messagesAddMessage } from '@slices/messages';
import { chatsGetChatById } from '@selectors/chats';
import { messagesGetMessageById, messagesGetSelectedChatId } from '@selectors/messages';
import { RealtimeChatMessageReceived } from './types';
export const realtimeChatMessageReceived: RealtimeChatMessageReceived = event => {
  return (dispatch, getState) => {
    const {
      payload
    } = event;
    const data = realtimeChatMessageReceivedFromDto(payload);
    if (!data) {
      return;
    }
    const {
      chat,
      message
    } = data;

    /**
     * The order of currentMessage reading and adding of the new message
     * is important: https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/886
     */
    const currentMessage = messagesGetMessageById(getState(), message.chatId, message.id);
    dispatch(messagesAddMessage({
      message: message
    }));

    /**
     * Increase the counter when:
     */
    if (!currentMessage &&
    // the current message does not exist
    message.isRead === false &&
    // the received message was not read
    messagesIsDynamicMessage(message) &&
    // the received message is dynamic
    message.creationTime === message.lastChangeTime ||
    // the received message was not changed
    // or
    !currentMessage &&
    // the current message does not exist
    message.isRead === false &&
    // the received message was not read
    !messagesIsDynamicMessage(message) // the received message is not dynamic
    ) {
      dispatch(chatsIncreaseUnreadMessagesCounter({
        chatId: chat.chatId,
        increment: 1
      }));
    }
    const currentChat = chatsGetChatById(getState(), chat.chatId);
    if (!currentChat) {
      dispatch(chatsAddChat({
        chat: chat
      }));
    } else {
      /**
       * chat.lastMessage has a higher priority due to logic in
       * chatsReceiveGetList reducer. chatsReceiveGetList reducer compares
       * lastMessage creationTime values to define whether update
       * unreadedMessagesCount or not.
       */
      dispatch(chatsSetLastMessage({
        chatId: chat.chatId,
        lastMessage: chat.lastMessage ?? message
      }));
    }
    const selectedChatId = messagesGetSelectedChatId(getState());
    if ((!selectedChatId || selectedChatId !== chat.chatId) && !messagesIsCallMessage(message) && !messagesIsCalendarMessage(message)) {
      dispatch(notificationsUiHandleAdd({
        type: NOTIFICATION_TYPE.NEW_MESSAGE,
        chatId: chat.chatId,
        message: messagesIsSystemMessage(message) ? message.text : messagesIsTextMessage(message) ? message.text : '',
        avatar: photoOrAvatarUrl(chat.photoUrl, chat.avatarId, chat.sex),
        name: chat.chatMateUserName
      }));
    }
  };
};