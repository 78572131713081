import { SEVERITY } from '@config/logging';
import { IWarningEvent } from '@logging/logging-events';
import { IWarningEventHandler, WarningEventHandlerHandler } from './types';
export class WarningEventHandler implements IWarningEventHandler {
  private readonly _handler?: WarningEventHandlerHandler;
  severities = [SEVERITY.WARNING];
  constructor(handler?: WarningEventHandlerHandler) {
    this._handler = handler;
  }
  handle(event: IWarningEvent): void {
    const {
      payload
    } = event;
    const {
      message
    } = payload;
    console.warn(message);
    if (this._handler) {
      this._handler(payload);
    }
  }
}