import { appCreateSlice } from '@store/rtk';
import { subjectsReceiveGetReducer } from '@slices/subjects/subjects-receive-get';
import { SubjectsState } from './types';
export const subjectsDefaultState: SubjectsState = {
  subjectIds: [],
  subjectsById: {}
};
const subjectsSlice = appCreateSlice({
  name: 'subjects',
  initialState: subjectsDefaultState,
  reducers: {
    subjectsReceiveGet: subjectsReceiveGetReducer
  }
});
export const {
  actions: {
    subjectsReceiveGet
  },
  reducer: subjectsReducer
} = subjectsSlice;