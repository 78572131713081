import { CALENDAR_AVAILABLE_MONTHS } from '@config/calendar';
import { first } from '@helpers/data';
import { addDays, addMonths, endOfDay, endOfMonth, findMaxDate, findMinDate, startOfDay, subDays } from '@helpers/date';
import { CALENDAR_CONSULTATION_INVITATION_STATE, CALENDAR_CONSULTATION_STATE, CALENDAR_TIME_SLOT_STATE, CalendarBusyTimeSlot, CalendarConsultationActionClientParticipant, CalendarConsultationActionParticipant, CalendarConsultationActionSpecialistParticipant, CalendarCurrentClient, CalendarCurrentClientCalendar, CalendarCurrentMember, CalendarCurrentMemberCalendar, CalendarCurrentMemberConsultation, CalendarCurrentMemberTimeSlot, CalendarCurrentSpecialist, CalendarCurrentSpecialistCalendar, ROLE_TYPE } from '@types';
export const calendarConsultationIsCreated = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return consultation?.state === CALENDAR_CONSULTATION_STATE.CREATED;
};
export const calendarConsultationIsApproved = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return consultation?.state === CALENDAR_CONSULTATION_STATE.APPROVED;
};
export const calendarConsultationIsRejected = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return consultation?.state === CALENDAR_CONSULTATION_STATE.REJECTED;
};
export const calendarConsultationIsCanceled = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return consultation?.state === CALENDAR_CONSULTATION_STATE.CANCELED;
};
export const calendarParticipantIsClient = (value: undefined | CalendarConsultationActionParticipant): value is CalendarConsultationActionClientParticipant => {
  return value?.roleType === ROLE_TYPE.CLIENT;
};
export const calendarParticipantIsSpecialist = (value: undefined | CalendarConsultationActionParticipant): value is CalendarConsultationActionSpecialistParticipant => {
  return value?.roleType === ROLE_TYPE.SPECIALIST;
};
export const calendarTimeSlotIsBusy = (value: undefined | CalendarCurrentMemberTimeSlot): value is CalendarBusyTimeSlot => {
  return value?.state === CALENDAR_TIME_SLOT_STATE.BUSY;
};
export const calendarTimeSlotIsAvailable = (value: undefined | CalendarCurrentMemberTimeSlot): value is CalendarBusyTimeSlot => {
  return value?.state === CALENDAR_TIME_SLOT_STATE.AVAILABLE;
};
export const calendarIsCurrentClient = (member: undefined | CalendarCurrentMember): member is CalendarCurrentClient => {
  return member?.calendar?.owner.roleType === ROLE_TYPE.CLIENT;
};
export const calendarIsCurrentSpecialist = (member: undefined | CalendarCurrentMember): member is CalendarCurrentSpecialist => {
  return member?.calendar?.owner.roleType === ROLE_TYPE.SPECIALIST;
};
export const calendarIsCurrentClientCalendar = (calendar: undefined | CalendarCurrentMemberCalendar): calendar is CalendarCurrentClientCalendar => {
  return calendar?.owner.roleType === ROLE_TYPE.CLIENT;
};
export const calendarIsCurrentSpecialistCalendar = (calendar: undefined | CalendarCurrentMemberCalendar): calendar is CalendarCurrentSpecialistCalendar => {
  return calendar?.owner.roleType === ROLE_TYPE.SPECIALIST;
};
export const calendarConsultationIsRefused = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return calendarConsultationIsRejected(consultation) || calendarConsultationIsCanceled(consultation);
};
export const calendarConsultationIsAccepted = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return calendarConsultationIsCreated(consultation) || calendarConsultationIsApproved(consultation);
};
export const calendarConsultationIsDone = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return consultation?.state === CALENDAR_CONSULTATION_STATE.DONE;
};
export const calendarConsultationInvitationIsPending = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return consultation?.invitationState === CALENDAR_CONSULTATION_INVITATION_STATE.PENDING;
};
export const calendarConsultationInvitationIsApproved = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => {
  return consultation?.invitationState === CALENDAR_CONSULTATION_INVITATION_STATE.APPROVED;
};
export const calendarConsultationIsExpired = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => calendarConsultationIsDone(consultation) && calendarConsultationInvitationIsPending(consultation);
export const calendarConsultationIsComplete = (consultation: undefined | CalendarCurrentMemberConsultation): boolean => calendarConsultationIsDone(consultation) && calendarConsultationInvitationIsApproved(consultation);
export const calendarConsultationInitiator = (consultation: CalendarCurrentMemberConsultation, currentMemberId: undefined | string): undefined | CalendarConsultationActionParticipant => {
  const action = first(consultation.actions);
  if (!action) {
    return;
  }
  const initiator = action.participants //
  .find(participant => participant.userId !== currentMemberId);
  return initiator;
};
export const calendarComputeMaxDate = (args: {
  date: Date;
  timezone: undefined | string;
}): Date => {
  const {
    date,
    timezone
  } = args;
  return endOfMonth(addMonths(endOfMonth(date, timezone), CALENDAR_AVAILABLE_MONTHS, timezone), timezone);
};
export const calendarComputeDateFrom = (args: {
  date: Date;
  minDate: Date;
  maxDate: Date;
  timezone: undefined | string;
}): Date => {
  const {
    date,
    minDate,
    maxDate,
    timezone
  } = args;
  return findMaxDate([minDate, findMinDate([startOfDay(date, timezone), startOfDay(subDays(maxDate, 6, timezone), timezone)])]);
};
export const calendarComputeDateTo = (args: {
  date: Date;
  maxDate: Date;
  timezone: undefined | string;
}): Date => {
  const {
    date,
    maxDate,
    timezone
  } = args;
  return findMinDate([maxDate,
  //
  endOfDay(addDays(date, 6, timezone), timezone)]);
};