import { appCreateSlice } from '@store/rtk';
import { notificationsReceiveGetReducer } from '@slices/notifications/notifications-receive-get';
import { notificationsReceivePushIsSubscribedReducer } from '@slices/notifications/notifications-receive-push-is-subscribed';
import { notificationsReceiveUpdateReducer } from '@slices/notifications/notifications-receive-update';
import { NotificationsState } from './types';
export const notificationsDefaultState: NotificationsState = {
  push: undefined,
  email: undefined
};
const notificationsSlice = appCreateSlice({
  name: 'notifications',
  initialState: notificationsDefaultState,
  reducers: {
    notificationsReceiveGet: notificationsReceiveGetReducer,
    notificationsReceivePushIsSubscribed: notificationsReceivePushIsSubscribedReducer,
    notificationsReceiveUpdate: notificationsReceiveUpdateReducer
  }
});
export const {
  actions: {
    notificationsReceiveGet,
    notificationsReceivePushIsSubscribed,
    notificationsReceiveUpdate
  },
  reducer: notificationsReducer
} = notificationsSlice;