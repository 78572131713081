import { notificationsReceivePushIsSubscribed } from '@slices/notifications';
import { NotificationsFetchPushIsSubscribed } from './types';
export const notificationsFetchPushIsSubscribed: NotificationsFetchPushIsSubscribed = payload => {
  return async dispatch => {
    const {
      push
    } = payload;
    const data = await push.getIsSubscribed();
    dispatch(notificationsReceivePushIsSubscribed({
      isSubscribed: data
    }));
    return data;
  };
};