import { appCreateSlice } from '@store/rtk';
import { loadingScreenHideReducer } from '@slices/loading-screen/loading-screen-hide';
import { loadingScreenShowReducer } from '@slices/loading-screen/loading-screen-show';
import { LoadingScreenState } from './types';
export const loadingScreenDefaultState: LoadingScreenState = {
  screens: []
};
const loadingScreenSlice = appCreateSlice({
  name: 'loadingScreen',
  initialState: loadingScreenDefaultState,
  reducers: {
    loadingScreenShow: loadingScreenShowReducer,
    loadingScreenHide: loadingScreenHideReducer
  }
});
export const {
  actions: {
    loadingScreenShow,
    loadingScreenHide
  },
  reducer: loadingScreenReducer
} = loadingScreenSlice;