import { calendarIsCurrentClient, calendarIsCurrentClientCalendar, calendarIsCurrentSpecialist, calendarIsCurrentSpecialistCalendar } from '@helpers/calendar';
import { uniqById } from '@helpers/data';
import { RealtimeReceiveCalendarConsultationCreatedReducer } from './types';
export const realtimeReceiveCalendarConsultationCreatedReducer: RealtimeReceiveCalendarConsultationCreatedReducer = (state, action) => {
  const {
    data
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentSpecialist(currentMember) && calendarIsCurrentSpecialistCalendar(currentMember.calendar)) {
    const currentMemberCalendar = currentMember.calendar;
    return {
      ...state,
      currentMember: {
        ...state.currentMember,
        calendar: {
          ...currentMemberCalendar,
          consultations: uniqById([data.consultation, ...currentMemberCalendar.consultations])
        }
      }
    };
  }
  if (calendarIsCurrentClient(currentMember) && calendarIsCurrentClientCalendar(currentMember.calendar)) {
    const currentMemberCalendar = currentMember.calendar;
    return {
      ...state,
      currentMember: {
        ...state.currentMember,
        calendar: {
          ...currentMemberCalendar,
          consultations: uniqById([data.consultation, ...currentMemberCalendar.consultations])
        }
      }
    };
  }
  return state;
};