import { NODE_ENV } from '@config/env';
import { ROUTES } from '@routing/routes';
import { ROLE_TYPE } from '@types';
import { last } from './data';
import { isSpecialist } from './member';
export { default as debounce } from 'lodash/debounce';
export { default as throttle } from 'lodash/throttle';
export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve: () => void) => setTimeout(resolve, ms));
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = (): void => {};
export const isServer = (): boolean => {
  return typeof window === 'undefined';
};
export const bgImageUrl = (url: string): string => {
  return `url('${url}')`;
};
export const shouldNotForwardProps = (propNames: string[]) => {
  return (propName: string | number | symbol): boolean => {
    return propNames.includes(propName.toString()) //
    ? false : true;
  };
};
export const isPdf = (url: undefined | string): boolean => {
  if (!url) {
    return false;
  }
  const chunks = url.split('.');
  return last(chunks) === 'pdf';
};
export const isProduction = (): boolean => {
  return NODE_ENV === 'production';
};
export const toOption = <T,>(value: T, label: string): {
  value: T;
  label: string;
} => ({
  value,
  label
});
export const calculateUserAgreementUrl = (roleType: undefined | ROLE_TYPE): string => {
  return isSpecialist(roleType) ? ROUTES.specialistUserAgreement.index.getUrl() : ROUTES.clientUserAgreement.index.getUrl();
};
export const isEmail = (value: string): boolean => {
  return value.includes('@');
};