export enum HTTP_STATUS_CODE {
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  GONE = 410,
  UNEXPECTED_ERROR = 500,
}
export enum HTTP_HEADER_NAME {
  ACCEPT = 'Accept',
  CONTENT_TYPE = 'Content-Type',
  CONTENT_RANGE = 'Content-Range',
  AUTHORIZATION = 'Authorization',
  CACHE_CONTROL = 'Cache-Control',
  X_UNIQUE_UPLOAD_ID = 'X-Unique-Upload-Id',
  X_NONCE = 'X-Nonce',
}
export enum HTTP_CONTENT_TYPE {
  JSON = 'application/json',
  X_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded',
  FORM_DATA = 'multipart/form-data',
}
export enum HTTP_CACHE_CONTROL {
  NO_CACHE = 'no-cache',
}