import { AUTH_CLIENT_ID, AUTH_GRANT_TYPE } from '@config/auth';
import { AuthGetTokenRequestData, AuthGetTokenRequestDto, AuthGetTokenResponseData, AuthGetTokenResponseDto } from '@services/dto/auth';
import { authTokenResponseFromDto } from './auth-token';
export const authGetTokenRequestToDto = (data: AuthGetTokenRequestData): AuthGetTokenRequestDto => {
  return {
    client_id: AUTH_CLIENT_ID,
    grant_type: AUTH_GRANT_TYPE.PASSWORD,
    username: data.userName,
    password: data.password,
    deviceType: data.deviceType,
    visitId: data.visitId
  };
};
export const authGetTokenResponseFromDto = (dto: AuthGetTokenResponseDto): AuthGetTokenResponseData => {
  return authTokenResponseFromDto(dto);
};