const UNSUPPORTED_VIDEO_FILE_EXTENSIONS = ['mkv', 'wmv'];
export const fileToUrl = (file: File): string => {
  return URL.createObjectURL(file);
};
export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string));
    reader.onerror = error => reject(error);
  });
};
export const urlToFile = async (url: string, fileName = 'untitled'): Promise<File> => {
  const blob = await fetch(url).then(response => response.blob());
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type
  });
};
export const isVideoFile = (file: File): boolean => {
  return file.type.includes('video') || file.name.toLowerCase().includes('.mkv') || file.name.toLowerCase().includes('.wmv');
};
export const isImageFile = (file: File): boolean => {
  return file.type.includes('image');
};
export const getVideoFileDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onerror = () => {
        reject(new Error(`Cannot get video file duration`));
      };
      video.src = window.URL.createObjectURL(file);
    } catch (error) {
      reject(error);
    }
  });
};
export const getFileExtension = (uri: string): undefined | string => {
  return uri.split('.').pop();
};
export const isUnsupportedVideoFileExtension = (uri: string): boolean => {
  const fileExtension = getFileExtension(uri);
  if (!fileExtension) {
    return true;
  }
  return UNSUPPORTED_VIDEO_FILE_EXTENSIONS.includes(fileExtension.toLowerCase());
};
export const convertIfUnsupportedExtension = (uri: string): string => {
  if (isUnsupportedVideoFileExtension(uri)) {
    const withoutExtension = uri.split('.');
    withoutExtension.pop();
    return `${withoutExtension.join('.')}.mp4`;
  }
  return uri;
};
export const isMkvVideoExtension = (name: string): boolean => {
  return name.toLowerCase().endsWith('mkv');
};
export const isWmvVideoExtension = (name: string): boolean => {
  return name.toLowerCase().endsWith('wmv');
};