import { REALTIME_STATUS } from '@types';
import { appCreateSlice } from '@store/rtk';
import { realtimeSetStatusReducer } from '@slices/realtime/realtime-set-status';
import { RealtimeState } from './types';
export const realtimeDefaultState: RealtimeState = {
  status: REALTIME_STATUS.DISCONNECTED
};
const realtimeSlice = appCreateSlice({
  name: 'realtime',
  initialState: realtimeDefaultState,
  reducers: {
    realtimeSetStatus: realtimeSetStatusReducer
  }
});
export const {
  actions: {
    realtimeSetStatus
  },
  reducer: realtimeReducer
} = realtimeSlice;