import { keyBy } from '@helpers/data';
import { getMessageTextByCode } from '@helpers/errors';
import { BaseError } from '@errors/base-error';
import { IValidationError, IValidationErrorConstructor, ValidationErrorBody, ValidationErrorFieldErrorsByField } from './types';
export const ValidationError: IValidationErrorConstructor = class ValidationError extends BaseError<ValidationErrorBody> implements IValidationError {
  public getFieldErrorsByField(): ValidationErrorFieldErrorsByField {
    const fieldErrors = this.body.errors //
    .map(error => {
      return {
        ...error,
        message: getMessageTextByCode(error.code),
        data: {
          ...error.data,
          message: getMessageTextByCode(error.data.code)
        }
      };
    });
    return keyBy(fieldErrors, error => error.data.field);
  }
  public constructor(message: string, body: ValidationErrorBody) {
    super(message, body);
    this.name = 'ValidationError';
  }
};