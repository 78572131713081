import { MediaCallHandlerLocalAudioTrack, MediaCallHandlerLocalVideoTrack, MediaCallHandlerRemoteAudioTrack, MediaCallHandlerRemoteVideoTrack } from '@media-call-handler';
import { AVATAR_ID, ChatId, MemberId, SEX } from '@types';
export enum MEDIA_CALL_DIRECTION {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}
export enum MEDIA_CALL_TYPE {
  VIDEO = 1,
  AUDIO = 2,
}
export enum MEDIA_CALL_STATE {
  ACTIVE = 'active',
  CONNECTING = 'connecting',
  REJECTED_BY_USER = 'rejectedByUser',
  REJECTED_BY_TIMEOUT = 'rejectedByTimeout',
}
export enum MEDIA_CALL_PROVIDER {
  TWILIO = 'Twilio',
}
export enum MEDIA_CALL_FACING_MODE {
  USER = 'user',
  ENVIRONMENT = 'environment',
}
export enum MEDIA_CALL_PARTICIPANT_TYPE {
  SENDER = 1,
  RECIPIENT = 2,
}
export type MediaCallRoomId = string;
export type MediaCallRoomName = string;
export type MediaCallId = MediaCallRoomId;
export type MediaCallToken = string;
export type MediaCall = {
  chatId: ChatId;
  type: MEDIA_CALL_TYPE;
  direction: MEDIA_CALL_DIRECTION;
  state: MEDIA_CALL_STATE;
  token: MediaCallToken;
  roomId: MediaCallRoomId;
  roomName: MediaCallRoomName;
  chatMateUserId: MemberId;
  chatMateUserName: string;
  sex: SEX;
  chatMateAvatarId?: AVATAR_ID | null;
  chatMatePhotoUrl?: string | null;
  startTime?: string;
  _chatMateImage?: string;
  _remoteVideoTrack?: MediaCallHandlerRemoteVideoTrack;
  _remoteAudioTrack?: MediaCallHandlerRemoteAudioTrack;
  _localVideoTrack?: MediaCallHandlerLocalVideoTrack;
  _localAudioTrack?: MediaCallHandlerLocalAudioTrack;
  _acquiringVideo?: boolean;
  _acquiringAudio?: boolean;
};
export type MediaCalls = MediaCall[];