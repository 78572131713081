import dynamic from 'next/dynamic';
import { POPUP } from '@config/popups';
import { AppFC, AppFCC } from '@types';
import { AccountBannedAlertProps } from '@popups/modals/account/account-banned-alert';
import { AccountDeletedCanBeRestoredAlertProps } from '@popups/modals/account/account-deleted-can-be-restored';
import { AccountDeletedCanNotBeRestoredAlertProps } from '@popups/modals/account/account-deleted-can-not-be-restored';
import { AccountEmailConfirmationModalProps } from '@popups/modals/account/account-email-confirmation';
import { AccountEmailConfirmationChangeEmailModalProps } from '@popups/modals/account/account-email-confirmation-change-email';
import { AccountEmailConfirmationRequiredModalProps } from '@popups/modals/account/account-email-confirmation-required';
import { AccountEmailConfirmationResendCodeModalProps } from '@popups/modals/account/account-email-confirmation-resend-code';
import { AccountEmailDoesNotExistAlertProps } from '@popups/modals/account/account-email-does-not-exist';
import { AccountExternalAuthAlertProps } from '@popups/modals/account/account-external-auth-error';
import { AccountFilledProfileRequiredModalProps } from '@popups/modals/account/account-filled-profile-required';
import { AccountLockedAlertProps } from '@popups/modals/account/account-locked-alert';
import { AccountLockedByManyComplaintsAlertProps } from '@popups/modals/account/account-locked-by-many-complaints-alert';
import { AccountLoginModalProps } from '@popups/modals/account/account-login';
import { AccountMemberBlockedAlertProps } from '@popups/modals/account/account-member-blocked';
import { AccountMemberDeletedAlertProps } from '@popups/modals/account/account-member-deleted';
import { AccountPasswordRecoveryModalProps } from '@popups/modals/account/account-password-recovery';
import { AccountPasswordRecoveryConfirmModalProps } from '@popups/modals/account/account-password-recovery-confirm';
import { AccountRegisterModalProps } from '@popups/modals/account/account-register';
import { AccountRoleRequiredModalProps } from '@popups/modals/account/account-role-required';
import { AlertDialogProps } from '@popups/modals/alert-dialog';
import { CalendarCoinsHoldDialogProps } from '@popups/modals/calendar/calendar-coins-hold-dialog';
import { CalendarConfirmExitDialogProps } from '@popups/modals/calendar/calendar-confirm-exit-dialog';
import { CalendarConsultationRejectDialogProps } from '@popups/modals/calendar/calendar-consultation-reject-dialog';
import { CalendarCurrentMemberConsultationModalProps } from '@popups/modals/calendar/calendar-current-member-consultation/types';
import { CalendarCurrentMemberTimeSlotsModalProps } from '@popups/modals/calendar/calendar-current-member-time-slots';
import { CalendarMemberTimeSlotsModalProps } from '@popups/modals/calendar/calendar-member-time-slots';
import { CalendarNotEnoughCoinsAlertProps } from '@popups/modals/calendar/calendar-not-enough-coins-alert';
import { CalendarSuccessConsultationAppointmentProps } from '@popups/modals/calendar/calendar-success-consultation-appointment-alert';
import { CalendarThisTimeIsUnavailableAlertProps } from '@popups/modals/calendar/calendar-this-time-is-unavailable-alert';
import { CalendarYouHaveOtherConsultationForThisTimeAlertProps } from '@popups/modals/calendar/calendar-you-have-other-consultation-for-this-time';
import { ErrorBoundaryAlertProps } from '@popups/modals/error-boundary-alert';
import { LandingClientRegisterModalProps } from '@popups/modals/landing/landing-client-register';
import { LandingPsychologistRegisterModalProps } from '@popups/modals/landing/landing-psychologist-register';
import { MediaCallBrowserIsNotSupportedAlertProps } from '@popups/modals/media-call/media-call-browser-is-not-supported';
import { MediaCallIncomingModalProps } from '@popups/modals/media-call/media-call-incoming';
import { MediaCallInterfaceModalProps } from '@popups/modals/media-call/media-call-interface';
import { MediaCallOutgoingModalProps } from '@popups/modals/media-call/media-call-outgoing';
import { MediaCallResourceIsNotAvailableAlertProps } from '@popups/modals/media-call/media-call-resource-is-not-available';
import { MediaCallMemberIsOnRunningCallAlertProps } from '@popups/modals/media-call/medial-call-member-is-on-runnnig-call';
import { MemberAssessRatingModalProps } from '@popups/modals/member/member-assess-rating';
import { MemberDocumentGalleryModalProps } from '@popups/modals/member/member-document-gallery';
import { MemberMediaGalleryModalProps } from '@popups/modals/member/member-media-gallery';
import { MemberNotEnoughCoinsAlertProps } from '@popups/modals/member/member-not-enough-coins-alert';
import { MemberNotEnoughCoinsDialogProps } from '@popups/modals/member/member-not-enough-coins-dialog';
import { PrepaymentConfirmationRequiredAlertProps } from '@popups/modals/member/member-prepayment-confirmation-required';
import { MemberSendComplaintModalProps } from '@popups/modals/member/member-send-complaint';
import { MemberSendComplimentModalProps } from '@popups/modals/member/member-send-compliment';
import { MemberSendMessageModalProps } from '@popups/modals/member/member-send-message';
import { MessagesImageGalleryModalProps } from '@popups/modals/messages/messages-image-gallery';
import { MessagesSendImageModalProps } from '@popups/modals/messages/messages-send-image';
import { PaymentsAddCardsAlertProps } from '@popups/modals/payments/payments-add-cards-alert';
import { PaymentsAddFundsModalProps } from '@popups/modals/payments/payments-add-funds/types';
import { PaymentsBuyPremiumModalProps } from '@popups/modals/payments/payments-buy-premium';
import { PaymentsDeleteBankCardDialogProps } from '@popups/modals/payments/payments-delete-bank-card-dialog';
import { PaymentsFailureAlertProps } from '@popups/modals/payments/payments-failure-alert';
import { PaymentsPremiumSuccessAlertProps } from '@popups/modals/payments/payments-premium-success-alert';
import { PaymentsRefillAlertProps } from '@popups/modals/payments/payments-refill-alert';
import { PaymentsSuccessAlertProps } from '@popups/modals/payments/payments-success-alert';
import { PaymentsUpgradeAccountModalProps } from '@popups/modals/payments/payments-upgrade-account';
import { ProfileEditAvatarSexUndefinedAlertProps } from '@popups/modals/profile-edit/profile-edit-avatar-sex-undefined-alert';
import { ProfileEditCropPhotoModalProps } from '@popups/modals/profile-edit/profile-edit-crop-photo';
import { ProfileEditDeleteDocumentDialogProps } from '@popups/modals/profile-edit/profile-edit-delete-document-dialog';
import { ProfileEditDeleteMediaDialogProps } from '@popups/modals/profile-edit/profile-edit-delete-media-dialog';
import { ProfileEditDocumentGalleryModalProps } from '@popups/modals/profile-edit/profile-edit-document-gallery';
import { ProfileEditDocumentUploadLimitAlertProps } from '@popups/modals/profile-edit/profile-edit-document-upload-limit-alert';
import { ProfileEditMediaGalleryModalProps } from '@popups/modals/profile-edit/profile-edit-media-gallery';
import { ProfileEditPhotoUploadLimitAlertProps } from '@popups/modals/profile-edit/profile-edit-photo-upload-limit-alert';
import { ProfileEditSetAvatarModalProps } from '@popups/modals/profile-edit/profile-edit-set-avatar';
import { ProfileEditSetDefaultPhotoDialogProps } from '@popups/modals/profile-edit/profile-edit-set-default-photo-dialog';
import { ProfileEditFormSubjectsLimitAlertProps } from '@popups/modals/profile-edit/profile-edit-subjects-limit-alert';
import { ProfileEditFormSubjectsLimitDialogProps } from '@popups/modals/profile-edit/profile-edit-subjects-limit-dialog';
import { ProfileEditUpdateFailureAlertProps } from '@popups/modals/profile-edit/profile-edit-update-failure-alert';
import { ProfileEditUpdateSuccessAlertProps } from '@popups/modals/profile-edit/profile-edit-update-success-alert';
import { ProfileEditVideoUploadDurationLimitAlert } from '@popups/modals/profile-edit/profile-edit-video-upload-duration-limit-alert';
import { ProfileEditVideoUploadLimitAlertProps } from '@popups/modals/profile-edit/profile-edit-video-upload-limit-alert';
import { TarrifsCheckAlertProps } from '@popups/modals/tariffs-check';
const ErrorBoundaryAlert = (dynamic(() => import('@popups/modals/error-boundary-alert') //
.then(module => module.ErrorBoundaryAlert)) as AppFCC<ErrorBoundaryAlertProps>);
const AlertDialog = (dynamic(() => import('@popups/modals/alert-dialog').then(module => module.AlertDialog)) as AppFCC<AlertDialogProps>);
const SidebarModal = (dynamic(() => import('@popups/modals/sidebar') //
.then(module => module.SidebarModal)) as AppFC);
const HeaderMenuModal = (dynamic(() => import('@popups/modals/header-menu') //
.then(module => module.HeaderMenuModal)) as AppFC);
const AccountLoginModal = (dynamic(() => import('@popups/modals/account/account-login') //
.then(module => module.AccountLoginModal)) as AppFCC<AccountLoginModalProps>);
const AccountRegisterModal = (dynamic(() => import('@popups/modals/account/account-register') //
.then(module => module.AccountRegisterModal)) as AppFCC<AccountRegisterModalProps>);
const AccountExternalAuthAlert = (dynamic(() => import('@popups/modals/account/account-external-auth-error') //
.then(module => module.AccountExternalAuthAlert)) as AppFCC<AccountExternalAuthAlertProps>);
const AccountEmailDoesNotExistAlert = (dynamic(() => import('@popups/modals/account/account-email-does-not-exist') //
.then(module => module.AccountEmailDoesNotExistAlert)) as AppFCC<AccountEmailDoesNotExistAlertProps>);
const AccountEmailConfirmationModal = (dynamic(() => import('@popups/modals/account/account-email-confirmation') //
.then(module => module.AccountEmailConfirmationModal)) as AppFCC<AccountEmailConfirmationModalProps>);
const AccountEmailConfirmationRequiredModal = (dynamic(() => import('@popups/modals/account/account-email-confirmation-required') //
.then(module => module.AccountEmailConfirmationRequiredModal)) as AppFCC<AccountEmailConfirmationRequiredModalProps>);
const AccountEmailConfirmationResendCodeModal = (dynamic(() => import('@popups/modals/account/account-email-confirmation-resend-code') //
.then(module => module.AccountEmailConfirmationResendCodeModal)) as AppFCC<AccountEmailConfirmationResendCodeModalProps>);
const AccountEmailConfirmationChangeEmailModal = (dynamic(() => import('@popups/modals/account/account-email-confirmation-change-email') //
.then(module => module.AccountEmailConfirmationChangeEmailModal)) as AppFCC<AccountEmailConfirmationChangeEmailModalProps>);
const AccountPasswordRecoveryModal = (dynamic(() => import('@popups/modals/account/account-password-recovery') //
.then(module => module.AccountPasswordRecoveryModal)) as AppFCC<AccountPasswordRecoveryModalProps>);
const AccountPasswordRecoveryConfirmModal = (dynamic(() => import('@popups/modals/account/account-password-recovery-confirm') //
.then(module => module.AccountPasswordRecoveryConfirmModal)) as AppFCC<AccountPasswordRecoveryConfirmModalProps>);
const AccountDeleteAccountDialog = (dynamic(() => import('@popups/modals/account/account-delete-account-dialog') //
.then(module => module.AccountDeleteAccountDialog)) as AppFC);
const AccountDeletedCanBeRestoredAlert = (dynamic(() => import('@popups/modals/account/account-deleted-can-be-restored') //
.then(module => module.AccountDeletedCanBeRestoredAlert)) as AppFCC<AccountDeletedCanBeRestoredAlertProps>);
const AccountDeletedCanNotBeRestoredAlert = (dynamic(() => import('@popups/modals/account/account-deleted-can-not-be-restored') //
.then(module => module.AccountDeletedCanNotBeRestoredAlert)) as AppFCC<AccountDeletedCanNotBeRestoredAlertProps>);
const AccountBannedAlert = (dynamic(() => import('@popups/modals/account/account-banned-alert') //
.then(module => module.AccountBannedAlert)) as AppFCC<AccountBannedAlertProps>);
const AccountLockedAlert = (dynamic(() => import('@popups/modals/account/account-locked-alert') //
.then(module => module.AccountLockedAlert)) as AppFCC<AccountLockedAlertProps>);
const AccountLockedByManyComplaintsAlert = (dynamic(() => import('@popups/modals/account/account-locked-by-many-complaints-alert') //
.then(module => module.AccountLockedByManyComplaintsAlert)) as AppFCC<AccountLockedByManyComplaintsAlertProps>);
const AccountRoleRequiredModal = (dynamic(() => import('@popups/modals/account/account-role-required') //
.then(module => module.AccountRoleRequiredModal)) as AppFCC<AccountRoleRequiredModalProps>);
const AccountFilledProfileRequiredModal = (dynamic(() => import('@popups/modals/account/account-filled-profile-required') //
.then(module => module.AccountFilledProfileRequiredModal)) as AppFCC<AccountFilledProfileRequiredModalProps>);
const AccountMemberBlockedAlert = (dynamic(() => import('@popups/modals/account/account-member-blocked') //
.then(module => module.AccountMemberBlockedAlert)) as AppFCC<AccountMemberBlockedAlertProps>);
const AccountMemberDeletedAlert = (dynamic(() => import('@popups/modals/account/account-member-deleted') //
.then(module => module.AccountMemberDeletedAlert)) as AppFCC<AccountMemberDeletedAlertProps>);
const ProfileEditAvatarSexUndefinedAlert = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-avatar-sex-undefined-alert') //
.then(module => module.ProfileEditAvatarSexUndefinedAlert)) as AppFCC<ProfileEditAvatarSexUndefinedAlertProps>);
const ProfileEditSetAvatarModal = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-set-avatar') //
.then(module => module.ProfileEditSetAvatarModal)) as AppFCC<ProfileEditSetAvatarModalProps>);
const ProfileEditCropPhotoModal = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-crop-photo') //
.then(module => module.ProfileEditCropPhotoModal)) as AppFCC<ProfileEditCropPhotoModalProps>);
const ProfileEditPhotoUploadLimitAlert = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-photo-upload-limit-alert') //
.then(module => module.ProfileEditPhotoUploadLimitAlert)) as AppFCC<ProfileEditPhotoUploadLimitAlertProps>);
const ProfileEditVideoUploadLimitAlert = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-video-upload-limit-alert') //
.then(module => module.ProfileEditVideoUploadLimitAlert)) as AppFCC<ProfileEditVideoUploadLimitAlertProps>);
const ProfileEditMediaGalleryModal = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-media-gallery') //
.then(module => module.ProfileEditMediaGalleryModal)) as AppFCC<ProfileEditMediaGalleryModalProps>);
const ProfileEditSetDefaultPhotoDialog = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-set-default-photo-dialog') //
.then(module => module.ProfileEditSetDefaultPhotoDialog)) as AppFCC<ProfileEditSetDefaultPhotoDialogProps>);
const ProfileEditDeletePhotoDialog = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-delete-media-dialog') //
.then(module => module.ProfileEditDeleteMediaDialog)) as AppFCC<ProfileEditDeleteMediaDialogProps>);
const ProfileEditDocumentUploadLimitAlert = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-document-upload-limit-alert') //
.then(module => module.ProfileEditDocumentUploadLimitAlert)) as AppFCC<ProfileEditDocumentUploadLimitAlertProps>);
const ProfileEditDocumentGalleryModal = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-document-gallery') //
.then(module => module.ProfileEditDocumentGalleryModal)) as AppFCC<ProfileEditDocumentGalleryModalProps>);
const ProfileEditDeleteDocumentDialog = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-delete-document-dialog') //
.then(module => module.ProfileEditDeleteDocumentDialog)) as AppFCC<ProfileEditDeleteDocumentDialogProps>);
const ProfileEditFormSubjectsLimitAlert = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-subjects-limit-alert') //
.then(module => module.ProfileEditFormSubjectsLimitAlert)) as AppFCC<ProfileEditFormSubjectsLimitAlertProps>);
const ProfileEditFormSubjectsLimitDialog = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-subjects-limit-dialog') //
.then(module => module.ProfileEditFormSubjectsLimitDialog)) as AppFCC<ProfileEditFormSubjectsLimitDialogProps>);
const ProfileEditUpdateSuccessAlert = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-update-success-alert') //
.then(module => module.ProfileEditUpdateSuccessAlert)) as AppFCC<ProfileEditUpdateSuccessAlertProps>);
const ProfileEditUpdateFailureAlert = (dynamic(() => import('@popups/modals/profile-edit/profile-edit-update-failure-alert') //
.then(module => module.ProfileEditUpdateFailureAlert)) as AppFCC<ProfileEditUpdateFailureAlertProps>);
const MemberMediaGalleryModal = (dynamic(() => import('@popups/modals/member/member-media-gallery') //
.then(module => module.MemberMediaGalleryModal)) as AppFCC<MemberMediaGalleryModalProps>);
const MemberDocumentGalleryModal = (dynamic(() => import('@popups/modals/member/member-document-gallery') //
.then(module => module.MemberDocumentGalleryModal)) as AppFCC<MemberDocumentGalleryModalProps>);
const MemberSendMessageModal = (dynamic(() => import('@popups/modals/member/member-send-message') //
.then(module => module.MemberSendMessageModal)) as AppFCC<MemberSendMessageModalProps>);
const MemberSendComplimentModal = (dynamic(() => import('@popups/modals/member/member-send-compliment') //
.then(module => module.MemberSendComplimentModal)) as AppFCC<MemberSendComplimentModalProps>);
const MemberSendComplaintModal = (dynamic(() => import('@popups/modals/member/member-send-complaint') //
.then(module => module.MemberSendComplaintModal)) as AppFCC<MemberSendComplaintModalProps>);
const MemberAssessRatingModal = (dynamic(() => import('@popups/modals/member/member-assess-rating') //
.then(module => module.MemberAssessRatingModal)) as AppFCC<MemberAssessRatingModalProps>);
const MemberNotEnoughCoinsDialog = (dynamic(() => import('@popups/modals/member/member-not-enough-coins-dialog') //
.then(module => module.MemberNotEnoughCoinsDialog)) as AppFCC<MemberNotEnoughCoinsDialogProps>);
const MemberNotEnoughCoinsAlert = (dynamic(() => import('@popups/modals/member/member-not-enough-coins-alert') //
.then(module => module.MemberNotEnoughCoinsAlert)) as AppFCC<MemberNotEnoughCoinsAlertProps>);
const PrepaymentConfirmationRequiredAlert = (dynamic(() => import('@popups/modals/member/member-prepayment-confirmation-required') //
.then(module => module.PrepaymentConfirmationRequiredAlert)) as AppFCC<PrepaymentConfirmationRequiredAlertProps>);
const TarrifsCheckAlert = (dynamic(() => import('@popups/modals/tariffs-check') //
.then(module => module.TarrifsCheckAlert)) as AppFCC<TarrifsCheckAlertProps>);
const MessagesSendImageModal = (dynamic(() => import('@popups/modals/messages/messages-send-image') //
.then(module => module.MessagesSendImageModal)) as AppFCC<MessagesSendImageModalProps>);
const MessagesImageGalleryModal = (dynamic(() => import('@popups/modals/messages/messages-image-gallery') //
.then(module => module.MessagesImageGalleryModal)) as AppFCC<MessagesImageGalleryModalProps>);
const MediaCallOutgoingModal = (dynamic(() => import('@popups/modals/media-call/media-call-outgoing') //
.then(module => module.MediaCallOutgoingModal)) as AppFCC<MediaCallOutgoingModalProps>);
const MediaCallIncomingModal = (dynamic(() => import('@popups/modals/media-call/media-call-incoming') //
.then(module => module.MediaCallIncomingModal)) as AppFCC<MediaCallIncomingModalProps>);
const MediaCallInterfaceModal = (dynamic(() => import('@popups/modals/media-call/media-call-interface') //
.then(module => module.MediaCallInterfaceModal)) as AppFCC<MediaCallInterfaceModalProps>);
const MediaCallMemberIsOnRunningCallAlert = (dynamic(() => import('@popups/modals/media-call/medial-call-member-is-on-runnnig-call') //
.then(module => module.MediaCallMemberIsOnRunningCallAlert)) as AppFCC<MediaCallMemberIsOnRunningCallAlertProps>);
const MediaCallBrowserIsNotSupportedAlert = (dynamic(() => import('@popups/modals/media-call/media-call-browser-is-not-supported') //
.then(module => module.MediaCallBrowserIsNotSupportedAlert)) as AppFCC<MediaCallBrowserIsNotSupportedAlertProps>);
const MediaCallResourceIsNotAvailableAlert = (dynamic(() => import('@popups/modals/media-call/media-call-resource-is-not-available') //
.then(module => module.MediaCallResourceIsNotAvailableAlert)) as AppFCC<MediaCallResourceIsNotAvailableAlertProps>);
const PaymentsAddFundsModal = (dynamic(() => import('@popups/modals/payments/payments-add-funds') //
.then(module => module.PaymentsAddFundsModal)) as AppFCC<PaymentsAddFundsModalProps>);
const PaymentsAddCardsAlert = (dynamic(() => import('@popups/modals/payments/payments-add-cards-alert') //
.then(module => module.PaymentsAddCardsAlert)) as AppFCC<PaymentsAddCardsAlertProps>);
const PaymentsDeleteBankCardDialog = (dynamic(() => import('@popups/modals/payments/payments-delete-bank-card-dialog') //
.then(module => module.PaymentsDeleteBankCardDialog)) as AppFCC<PaymentsDeleteBankCardDialogProps>);
const PaymentsSuccessAlert = (dynamic(() => import('@popups/modals/payments/payments-success-alert') //
.then(module => module.PaymentsSuccessAlert)) as AppFCC<PaymentsSuccessAlertProps>);
const PaymentsFailureAlert = (dynamic(() => import('@popups/modals/payments/payments-failure-alert') //
.then(module => module.PaymentsFailureAlert)) as AppFCC<PaymentsFailureAlertProps>);
const PaymentsRefillAlert = (dynamic(() => import('@popups/modals/payments/payments-refill-alert') //
.then(module => module.PaymentsRefillAlert)) as AppFCC<PaymentsRefillAlertProps>);
const PaymentsWithdrawFundsModal = (dynamic(() => import('@popups/modals/payments/payments-withdraw-funds') //
.then(module => module.PaymentsWithdrawFundsModal)) as AppFC);
const PaymentsWithdrawFundsSuccessModal = (dynamic(() => import('@popups/modals/payments/payments-withdraw-funds-success') //
.then(module => module.PaymentsWithdrawFundsSuccessModal)) as AppFC);
const PaymentsUpgradeAccountModal = (dynamic(() => import('@popups/modals/payments/payments-upgrade-account') //
.then(module => module.PaymentsUpgradeAccountModal)) as React.FC<PaymentsUpgradeAccountModalProps>);
const PaymentsBuyPremiumModal = (dynamic(() => import('@popups/modals/payments/payments-buy-premium') //
.then(module => module.PaymentsBuyPremiumModal)) as React.FC<PaymentsBuyPremiumModalProps>);
const PaymentsPremiumSuccessAlert = (dynamic(() => import('@popups/modals/payments/payments-premium-success-alert') //
.then(module => module.PaymentsPremiumSuccessAlert)) as AppFCC<PaymentsPremiumSuccessAlertProps>);
const CalendarCurrentMemberTimeSlotsModal = (dynamic(() => import('@popups/modals/calendar/calendar-current-member-time-slots') //
.then(module => module.CalendarCurrentMemberTimeSlotsModal)) as AppFC<CalendarCurrentMemberTimeSlotsModalProps>);
const CalendarMemberTimeSlotsModal = (dynamic(() => import('@popups/modals/calendar/calendar-member-time-slots') //
.then(module => module.CalendarMemberTimeSlotsModal)) as AppFCC<CalendarMemberTimeSlotsModalProps>);
const CalendarConfirmExitDialog = (dynamic(() => import('@popups/modals/calendar/calendar-confirm-exit-dialog') //
.then(module => module.CalendarConfirmExitDialog)) as AppFCC<CalendarConfirmExitDialogProps>);
const CalendarCoinsHoldDialog = (dynamic(() => import('@popups/modals/calendar/calendar-coins-hold-dialog') //
.then(module => module.CalendarCoinsHoldDialog)) as AppFCC<CalendarCoinsHoldDialogProps>);
const CalendarSuccessConsultationAppointment = (dynamic(() => import('@popups/modals/calendar/calendar-success-consultation-appointment-alert').then(module => module.CalendarSuccessConsultationAppointment)) as AppFCC<CalendarSuccessConsultationAppointmentProps>);
const CalendarYouHaveOtherConsultationForThisTimeAlert = (dynamic(() => import('@popups/modals/calendar/calendar-you-have-other-consultation-for-this-time').then(module => module.CalendarYouHaveOtherConsultationForThisTimeAlert)) as AppFC<CalendarYouHaveOtherConsultationForThisTimeAlertProps>);
const CalendarThisTimeIsUnavailableAlert = (dynamic(() => import('@popups/modals/calendar/calendar-this-time-is-unavailable-alert') //
.then(module => module.CalendarThisTimeIsUnavailableAlert)) as AppFC<CalendarThisTimeIsUnavailableAlertProps>);
const CalendarNotEnoughCoinsAlert = (dynamic(() => import('@popups/modals/calendar/calendar-not-enough-coins-alert') //
.then(module => module.CalendarNotEnoughCoinsAlert)) as AppFC<CalendarNotEnoughCoinsAlertProps>);
const CalendarCurrentMemberConsultationModal = (dynamic(() => import('@popups/modals/calendar/calendar-current-member-consultation') //
.then(module => module.CalendarCurrentMemberConsultationModal)) as AppFCC<CalendarCurrentMemberConsultationModalProps>);
const CalendarConsultationRejectDialog = (dynamic(() => import('@popups/modals/calendar/calendar-consultation-reject-dialog') //
.then(module => module.CalendarConsultationRejectDialog)) as AppFC<CalendarConsultationRejectDialogProps>);
const LandingClientRegisterModal = (dynamic(() => import('@popups/modals/landing/landing-client-register') //
.then(module => module.LandingClientRegisterModal)) as AppFCC<LandingClientRegisterModalProps>);
const LandingPsychologistRegisterModal = (dynamic(() => import('@popups/modals/landing/landing-psychologist-register') //
.then(module => module.LandingPsychologistRegisterModal)) as AppFCC<LandingPsychologistRegisterModalProps>);
export const POPUPS_BY_ID = {
  [POPUP.ERROR_BOUNDARY_ALERT]: ErrorBoundaryAlert,
  [POPUP.ALERT_DIALOG]: AlertDialog,
  [POPUP.SIDEBAR]: SidebarModal,
  [POPUP.HEADER_MENU]: HeaderMenuModal,
  [POPUP.ACCOUNT_LOGIN_MODAL]: AccountLoginModal,
  [POPUP.ACCOUNT_REGISTER_MODAL]: AccountRegisterModal,
  [POPUP.ACCOUNT_EXTERNAL_AUTH_ERROR]: AccountExternalAuthAlert,
  [POPUP.ACCOUNT_EMAIL_DOES_NOT_EXIST]: AccountEmailDoesNotExistAlert,
  [POPUP.ACCOUNT_EMAIL_CONFIRMATION]: AccountEmailConfirmationModal,
  [POPUP.ACCOUNT_EMAIL_CONFIRMATION_REQUIRED]: AccountEmailConfirmationRequiredModal,
  [POPUP.ACCOUNT_EMAIL_CONFIRMATION_RESEND_CODE]: AccountEmailConfirmationResendCodeModal,
  [POPUP.ACCOUNT_EMAIL_CONFIRMATION_CHANGE_EMAIL]: AccountEmailConfirmationChangeEmailModal,
  [POPUP.ACCOUNT_PASSWORD_RECOVERY]: AccountPasswordRecoveryModal,
  [POPUP.ACCOUNT_PASSWORD_RECOVERY_CONFIRM]: AccountPasswordRecoveryConfirmModal,
  [POPUP.ACCOUNT_DELETE_ACCOUNT_DIALOG]: AccountDeleteAccountDialog,
  [POPUP.ACCOUNT_DELETED_CAN_BE_RESTORED]: AccountDeletedCanBeRestoredAlert,
  [POPUP.ACCOUNT_DELETED_CAN_NOT_BE_RESTORED]: AccountDeletedCanNotBeRestoredAlert,
  [POPUP.ACCOUNT_BANNED]: AccountBannedAlert,
  [POPUP.ACCOUNT_LOCKED]: AccountLockedAlert,
  [POPUP.ACCOUNT_LOCKED_BY_MANY_COMPLAINTS]: AccountLockedByManyComplaintsAlert,
  [POPUP.ACCOUNT_ROLE_REQUIRED]: AccountRoleRequiredModal,
  [POPUP.ACCOUNT_FILLED_PROFILE_REQUIRED]: AccountFilledProfileRequiredModal,
  [POPUP.PROFILE_EDIT_AVATAR_SEX_UNDEFINED_ALERT]: ProfileEditAvatarSexUndefinedAlert,
  [POPUP.PROFILE_EDIT_SET_AVATAR_MODAL]: ProfileEditSetAvatarModal,
  [POPUP.PROFILE_EDIT_CROP_PHOTO_MODAL]: ProfileEditCropPhotoModal,
  [POPUP.PROFILE_EDIT_PHOTO_UPLOAD_LIMIT_ALERT]: ProfileEditPhotoUploadLimitAlert,
  [POPUP.PROFILE_EDIT_VIDEO_UPLOAD_LIMIT_ALERT]: ProfileEditVideoUploadLimitAlert,
  [POPUP.PROFILE_EDIT_VIDEO_UPLOAD_DURATION_LIMIT_ALERT]: ProfileEditVideoUploadDurationLimitAlert,
  [POPUP.PROFILE_EDIT_MEDIA_GALLERY_MODAL]: ProfileEditMediaGalleryModal,
  [POPUP.PROFILE_EDIT_SET_DEFAULT_PHOTO_DIALOG]: ProfileEditSetDefaultPhotoDialog,
  [POPUP.PROFILE_EDIT_DELETE_MEDIA_DIALOG]: ProfileEditDeletePhotoDialog,
  [POPUP.PROFILE_EDIT_DOCUMENT_UPLOAD_LIMIT_ALERT]: ProfileEditDocumentUploadLimitAlert,
  [POPUP.PROFILE_EDIT_DOCUMENT_GALLERY_MODAL]: ProfileEditDocumentGalleryModal,
  [POPUP.PROFILE_EDIT_DELETE_DOCUMENT_DIALOG]: ProfileEditDeleteDocumentDialog,
  [POPUP.PROFILE_EDIT_FORM_SUBJECTS_LIMIT_ALERT]: ProfileEditFormSubjectsLimitAlert,
  [POPUP.PROFILE_EDIT_FORM_SUBJECTS_LIMIT_DIALOG]: ProfileEditFormSubjectsLimitDialog,
  [POPUP.PROFILE_EDIT_UPDATE_SUCCESS_ALERT]: ProfileEditUpdateSuccessAlert,
  [POPUP.PROFILE_EDIT_UPDATE_FAILURE_ALERT]: ProfileEditUpdateFailureAlert,
  [POPUP.MEMBER_MEDIA_GALLERY_MODAL]: MemberMediaGalleryModal,
  [POPUP.MEMBER_DOCUMENT_GALLERY_MODAL]: MemberDocumentGalleryModal,
  [POPUP.MEMBER_SEND_MESSAGE_MODAL]: MemberSendMessageModal,
  [POPUP.MEMBER_SEND_COMPLIMENT_MODAL]: MemberSendComplimentModal,
  [POPUP.MEMBER_SEND_COMPLAINT_MODAL]: MemberSendComplaintModal,
  [POPUP.MEMBER_ASSESS_RATING_MODAL]: MemberAssessRatingModal,
  [POPUP.MEMBER_NOT_ENOUGH_COINS_DIALOG]: MemberNotEnoughCoinsDialog,
  [POPUP.MEMBER_NOT_ENOUGH_COINS_ALERT]: MemberNotEnoughCoinsAlert,
  [POPUP.MEMBER_PREPAYMENT_CONFIRMATION_REQUIRED_ALERT]: PrepaymentConfirmationRequiredAlert,
  [POPUP.ACCOUNT_MEMBER_BLOCKED]: AccountMemberBlockedAlert,
  [POPUP.ACCOUNT_MEMBER_DELETED]: AccountMemberDeletedAlert,
  [POPUP.TARRIFS_CHECK_ALERT]: TarrifsCheckAlert,
  [POPUP.MESSAGES_SEND_IMAGE_MODAL]: MessagesSendImageModal,
  [POPUP.MESSAGES_IMAGE_GALERY_MODAL]: MessagesImageGalleryModal,
  [POPUP.MEDIA_CALL_OUTGOING]: MediaCallOutgoingModal,
  [POPUP.MEDIA_CALL_INCOMING]: MediaCallIncomingModal,
  [POPUP.MEDIA_CALL_INTERFACE]: MediaCallInterfaceModal,
  [POPUP.MEDIA_CALL_MEMBER_IS_ON_RUNNING_CALL_ALERT]: MediaCallMemberIsOnRunningCallAlert,
  [POPUP.MEDIA_CALL_BROWSER_IS_NOT_SUPPORTED_ALERT]: MediaCallBrowserIsNotSupportedAlert,
  [POPUP.MEDIA_CALL_RESOURCE_IS_NOT_AVAILABLE_ALERT]: MediaCallResourceIsNotAvailableAlert,
  [POPUP.PAYMENTS_ADD_FUNDS]: PaymentsAddFundsModal,
  [POPUP.PAYMENTS_ADD_CARDS]: PaymentsAddCardsAlert,
  [POPUP.PAYMENTS_DELETE_BANK_CARD_DIALOG]: PaymentsDeleteBankCardDialog,
  [POPUP.PAYMENTS_SUCCESS_ALERT]: PaymentsSuccessAlert,
  [POPUP.PAYMENTS_FAILURE_ALERT]: PaymentsFailureAlert,
  [POPUP.PAYMENTS_REFILL_ALERT]: PaymentsRefillAlert,
  [POPUP.PAYMENTS_WITHDRAW_FUNDS]: PaymentsWithdrawFundsModal,
  [POPUP.PAYMENTS_WITHDRAW_FUNDS_SUCCESS]: PaymentsWithdrawFundsSuccessModal,
  [POPUP.PAYMENTS_UPGRADE_ACCOUNT]: PaymentsUpgradeAccountModal,
  [POPUP.PAYMENTS_BUY_PREMIUM]: PaymentsBuyPremiumModal,
  [POPUP.PAYMENTS_PREMIUM_SUCCESS_ALERT]: PaymentsPremiumSuccessAlert,
  [POPUP.CALENDAR_CURRENT_MEMBER_TIME_SLOTS]: CalendarCurrentMemberTimeSlotsModal,
  [POPUP.CALENDAR_MEMBER_TIME_SLOTS]: CalendarMemberTimeSlotsModal,
  [POPUP.CALENDAR_CONFIRM_EXIT_DIALOG]: CalendarConfirmExitDialog,
  [POPUP.CALENDAR_COINS_HOLD_DIALOG]: CalendarCoinsHoldDialog,
  [POPUP.CALENDAR_SUCCESS_CUNSULTATION_APPOINTMENT_ALERT]: CalendarSuccessConsultationAppointment,
  [POPUP.CALENDAR_YOU_HAVE_OTHER_CONSULTATION_FOR_THIS_TIME_ALERT]: CalendarYouHaveOtherConsultationForThisTimeAlert,
  [POPUP.CALENDAR_CALENDAR_THIS_TIME_IS_UNAVAILABLE_ALERT]: CalendarThisTimeIsUnavailableAlert,
  [POPUP.CALENDAR_NOT_ENOUGH_COINS_ALERT]: CalendarNotEnoughCoinsAlert,
  [POPUP.CALENDAR_CURRENT_MEMBER_CONSULTATION]: CalendarCurrentMemberConsultationModal,
  [POPUP.CALENDAR_CONSULTATION_REJECT_DIALOG]: CalendarConsultationRejectDialog,
  [POPUP.LANDING_CLIENT_REGISTER_MODAL]: LandingClientRegisterModal,
  [POPUP.LANDING_PSYCHOLOGIST_REGISTER_MODAL]: LandingPsychologistRegisterModal
};