import { useEffect } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { addEvent, getGlobalScrollbarWidth } from '@helpers/dom';
import { AppFCC } from '@types';
import { PopupsStyled } from './styled';
import { PopupsProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Popups: AppFCC<PopupsProps> = props => {
  const {
    popupsDict,
    popups
  } = props;
  const hasPopups = Boolean(popups.length);
  useEffect(() => {
    if (hasPopups) {
      document.documentElement.style.setProperty('--app-global-scrollbar-width', `${getGlobalScrollbarWidth()}px`);
      document.body.style.width = '100vw';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.documentElement.style.setProperty('--app-global-scrollbar-width', '0px');
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      document.body.style.width = '';
    }
  }, [hasPopups]);
  useEffect(() => {
    /**
     * The scroll event handler resets preserved document.body.style.top value
     * on scrolling to top. E.g. window.scrollTo(0, 0).
     */
    return addEvent(window, EVENT_NAMES.SCROLL, () => {
      if (window.scrollY === 0) {
        document.body.style.top = '';
      }
    }, true);
  }, []);
  return _jsx(PopupsStyled, {
    children: popups.map(({
      id,
      uuid,
      props
    }) => {
      const Popup = (popupsDict[id] as AppFCC);
      const key = uuid //
      ? `${id}-${uuid}` : id;
      return _jsx(Popup //
      , {
        ...props
      }, key);
    })
  });
};