import { openNewWindow } from '@helpers/routing';
import { IPaymentsPayWindow, IPaymentsPayWindowConstructor, IPaymentsPayWindowOpenArgs } from './types';
export const PaymentsPayWindow: IPaymentsPayWindowConstructor = class PaymentsPayWindow implements IPaymentsPayWindow {
  private _openedWindow: undefined | null | Window;
  open(args: IPaymentsPayWindowOpenArgs): undefined | Window {
    const {
      href
    } = args;
    this._openedWindow?.close();
    this._openedWindow = openNewWindow(href,
    //
    'paymentsPayWindow', {
      width: 1024,
      height: 768
    });
    if (!this._openedWindow) {
      return;
    }
    return this._openedWindow;
  }
  close(): void {
    this._openedWindow?.close();
  }
};