module.exports.flipUtilites = ({ addUtilities }) => {
  const newUtilities = {
    '.flip-x': {
      transform: 'scale(-1, 1)',
    },
    '.flip-y': {
      transform: 'scale(1, -1)',
    },
  };
  addUtilities(newUtilities);
};
