import { last } from '@helpers/data';
import { LoadingScreen, LoadingScreenId } from '@types';
import { appCreateSelector, RootState } from '@store';
import { LoadingScreenState } from '@slices/loading-screen';

/**
 * regular selectors
 */

export const loadingScreenGet = (state: RootState): LoadingScreenState => {
  return state.ui.loadingScreen;
};

/**
 * memoized reselect selectors
 */

export const loadingScreenGetScreens: (state: RootState) => LoadingScreenState['screens'] = appCreateSelector([loadingScreenGet], loadingScreen => loadingScreen.screens);
export const loadingScreenGetScreenIsShown: (state: RootState, id: LoadingScreenId) => boolean = appCreateSelector([loadingScreenGetScreens, (_state, id: LoadingScreenId) => id], (screens, id) => {
  return screens.some(screen => screen.id === id);
});
export const loadingScreenGetLastScreen: (state: RootState) => undefined | LoadingScreen = appCreateSelector([loadingScreenGetScreens], screens => {
  return last(screens);
});