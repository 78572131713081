import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SpecialistsWithdrawFundsRequestData, SpecialistsWithdrawFundsRequestDto, SpecialistsWithdrawFundsResponseData, SpecialistsWithdrawFundsResponseDto } from '@services/dto/specialists';
import { specialistsWithdrawFundsRequestToDto, specialistsWithdrawFundsResponseFromDto } from '@mapping/specialists';
type SpecialistsWithdrawFunds = (requestData: SpecialistsWithdrawFundsRequestData) => AuthedJsonFetchResult<SpecialistsWithdrawFundsResponseData>;
export const specialistsWithdrawFunds: SpecialistsWithdrawFunds = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SpecialistsWithdrawFundsResponseDto, SpecialistsWithdrawFundsRequestDto>(ENDPOINTS.specialists.withdrawFunds(), {
    method: 'POST',
    data: specialistsWithdrawFundsRequestToDto(requestData)
  });
  return {
    promise: promise.then(specialistsWithdrawFundsResponseFromDto),
    cancel
  };
};