import { appCreateSlice } from '@store/rtk';
import { invitationsAddInvitationReducer } from './invitations-add-invitation';
import { invitationsDecreaseCounterReducer } from './invitations-decrease-counter';
import { invitationsIncreaseCounterReducer } from './invitations-increase-counter';
import { invitationsReadAllReducer } from './invitations-read-all';
import { invitationsReceiveGetListReducer } from './invitations-receive-get-list';
import { invitationsRemoveInvitationReducer } from './invitations-remove-invitation';
import { invitationsSetCounterReducer } from './invitations-set-counter';
import { InvitationsState } from './types';
export const invitationsDefaultState: InvitationsState = {
  invitationsById: {},
  invitationsIds: [],
  pendingInvitationsCount: 0,
  hasMore: false,
  limit: 10,
  offset: 0,
  totalCount: 0,
  dataLoaded: false
};
const invitationsSlice = appCreateSlice({
  name: 'invitations',
  initialState: invitationsDefaultState,
  reducers: {
    invitationsAddInvitation: invitationsAddInvitationReducer,
    invitationsRemoveInvitation: invitationsRemoveInvitationReducer,
    invitationsDecreaseCounter: invitationsDecreaseCounterReducer,
    invitationsIncreaseCounter: invitationsIncreaseCounterReducer,
    invitationsReadAll: invitationsReadAllReducer,
    invitationsReceiveGetList: invitationsReceiveGetListReducer,
    invitationsSetCounter: invitationsSetCounterReducer
  }
});
export const {
  actions: {
    invitationsAddInvitation,
    invitationsRemoveInvitation,
    invitationsIncreaseCounter,
    invitationsDecreaseCounter,
    invitationsReadAll,
    invitationsReceiveGetList,
    invitationsSetCounter
  },
  reducer: invitationsReducer
} = invitationsSlice;