import { CurrentSpecialistReceiveSetDefaultPhotoReducer } from './types';
export const currentSpecialistReceiveSetDefaultPhotoReducer: CurrentSpecialistReceiveSetDefaultPhotoReducer = (state, action) => {
  if (!state.me) {
    return state;
  }
  const {
    data
  } = action.payload;
  return {
    ...state,
    me: {
      ...state.me,
      defaultPhotoId: data.id,
      state: data.profileState
    }
  };
};