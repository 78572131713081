import { Middleware } from 'redux';
import { DISPATCHER, ReduxPayloadAction, RootDispatch, RootState } from '@store';
type RealtimeMiddleware = Middleware<RootDispatch, RootState, RootDispatch>;

/**
 * @deprecated
 * We don't use the middleware. Consider its deletion.
 */
export const realtimeMiddleware: RealtimeMiddleware = () => {
  return next => {
    return (action: ReduxPayloadAction) => {
      if (action.dispatcher === DISPATCHER.REALTIME) {
        try {
          /**
           * Read store here
           */
          // const state = store.getState()
          // const realtime = initializeRealtime();
          // realtime.invoke(REALTIME_HUB_METHOD_NAME.RECEIVE_EVENT, action.payload);
          return;
        } catch (err) {
          console.error(err);
          return;
        }
      }
      return next(action);
    };
  };
};