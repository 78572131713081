import { TwilioError } from 'twilio-video';
import { ERROR_CODE, ERROR_TEXTS_BY_CODE } from '@config/errors';
import { AppErrorValueUndefined } from '@errors/app-error';
import { BaseNetErrorBodyError, BaseNetErrorBodyErrors } from '@errors/base-net-error';
import { CallError, CallErrorBody, ICallError } from '@errors/call-error';
import { NotImplementedError } from '@errors/not-implemented-error';
import { BaseNetBodyOtherErrorDto, BaseNetErrorAuthBodyDto, BaseNetErrorBodyDto, BaseNetErrorBodyFieldErrorDto } from '@services/dto/errors/base-net-error';
export const isBaseNetErrorAuthBody = (data: unknown): data is BaseNetErrorAuthBodyDto => {
  return Boolean((data as {
    error?: unknown;
  })?.error);
};
export const isBaseNetErrorBody = (data: unknown): data is BaseNetErrorBodyDto => {
  return Boolean((data as {
    errors?: unknown;
  })?.errors);
};
export const isBaseNetErrorBodyFieldError = (error: BaseNetErrorBodyFieldErrorDto | BaseNetBodyOtherErrorDto): error is BaseNetErrorBodyFieldErrorDto => {
  return Boolean(error.data?.field);
};
export const defineAuthErrorCode = (message: string): ERROR_CODE => {
  switch (message) {
    case ERROR_CODE.INCORRECT_USERNAME_OR_PASSWORD:
      return ERROR_CODE.INCORRECT_USERNAME_OR_PASSWORD;
    default:
      return ERROR_CODE.UNEXPECTED_ERROR;
  }
};
export const getMessageTextByCode = (code: ERROR_CODE): string => {
  if (code in ERROR_TEXTS_BY_CODE) {
    return ERROR_TEXTS_BY_CODE[(code as keyof typeof ERROR_TEXTS_BY_CODE)];
  }
  return ERROR_TEXTS_BY_CODE[ERROR_CODE.UNEXPECTED_ERROR];
};
export const isAccountError = (error: BaseNetErrorBodyError): boolean => {
  return [ERROR_CODE.EXTERNAL_SIGN_IN_FAILED, ERROR_CODE.ACCOUNT_DOES_NOT_EXIST_ROLE_REQUIRED, ERROR_CODE.EMAIL_ADDRESS_DOES_NOT_EXISTS, ERROR_CODE.USER_IP_BANNED, ERROR_CODE.ACCOUNT_DELETED_CAN_BE_RESTORED, ERROR_CODE.ACCOUNT_DELETED_CAN_NOT_BE_RESTORED, ERROR_CODE.ACCOUNT_HAS_BEEN_LOCKED_BY_MANY_COMPLAINTS, ERROR_CODE.ACCOUNT_CURRENT_MEMBER_BLOCKED, ERROR_CODE.ACCOUNT_MEMBER_DELETED].includes(error.code);
};
export const hasAccountError = (errors: BaseNetErrorBodyErrors): boolean => {
  return errors.some(isAccountError);
};
export const throwNotYetImplementedError = (message: string): void => {
  try {
    throw new NotImplementedError(`[NYI]: ${message}`);
  } catch (error) {
    if (error instanceof NotImplementedError) {
      console.error(error.message);
      return;
    }
    throw error;
  }
};

/**
 * @deprecated
 * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
 */
export const isNotEnoughBalanceError = (error: BaseNetErrorBodyError): boolean => {
  if (error.data instanceof Object && 'balance' in error.data && 'prepaymentAmount' in error.data) {
    const {
      balance,
      prepaymentAmount
    } = (error.data as {
      balance: number;
      prepaymentAmount: number;
    });
    return balance < prepaymentAmount;
  } else {
    return true;
  }
};
export const twilioErrorToCallError = (error: TwilioError, args: CallErrorBody): ICallError => {
  const {
    tags,
    secondsFromCallStart,
    participant
  } = args;
  return new CallError(error.message, {
    tags,
    secondsFromCallStart,
    participant,
    code: error.code
  }, error.stack);
};
export const ensureIsDefined = (message: string): void => {
  try {
    throw new AppErrorValueUndefined(message);
  } catch (error) {
    if (error instanceof AppErrorValueUndefined) {
      console.error(error.message);
      return;
    }
    throw error;
  }
};