import { SpecialistsReceiveAddToFavoritesReducer } from './types';
export const specialistsReceiveAddToFavoritesReducer: SpecialistsReceiveAddToFavoritesReducer = (state, action) => {
  const {
    specialistId
  } = action.payload;
  return {
    ...state,
    specialistsById: {
      ...state.specialistsById,
      [specialistId]: {
        ...state.specialistsById[specialistId],
        isFavoriteUser: true
      }
    }
  };
};