import { useCallback } from 'react';
import { ReduxAction } from '@store';
import { addPopup as _addPopup, AddPopupPayload } from '@actions/popups/add-popup';
import { removePopup as _removePopup } from '@slices/popups';
import { removePopupByProps as _removePopupByProps } from '@slices/popups';
import { useTypedDispatch } from '@hooks/store';
import { UsePopupHooks, UsePopupRemovePopup, UsePopupRemovePopupByProps } from './types';
export const usePopup = (): UsePopupHooks => {
  const dispatch = useTypedDispatch();
  const addPopup = useCallback(<P = undefined,>(payload: AddPopupPayload<P>): ReduxAction => dispatch(_addPopup<P>(payload)), [dispatch]);
  const removePopup: UsePopupRemovePopup = useCallback(payload => dispatch(_removePopup(payload)), [dispatch]);
  const removePopupByProps: UsePopupRemovePopupByProps = useCallback(payload => dispatch(_removePopupByProps(payload)), [dispatch]);
  return {
    addPopup,
    removePopup,
    removePopupByProps
  };
};