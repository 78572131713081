import { AVATAR_ID, Client, ClientsById, MemberId } from '@types';
import { appCreateSelector, RootState } from '@store';
import { ClientsState } from '@slices/clients';

/**
 * regular selectors
 */
const clientsGet = (state: RootState): ClientsState => {
  return state.app.clients;
};

/**
 * memoized reselect selectors
 */

const clientsGetClientsById: (state: RootState) => ClientsById = appCreateSelector([clientsGet], clients => clients.clientsById);
export const clientsGetClient: (state: RootState, clientId: MemberId) => Client = appCreateSelector([clientsGetClientsById, (_state, clientId: MemberId) => clientId], (clientsById, clientId) => {
  return clientsById[clientId];
});
export const clientsGetClientAvatarId: (state: RootState, clientId: MemberId) => undefined | AVATAR_ID = appCreateSelector([clientsGetClient], client => client?.avatarId);
export const clientsGetClientProblemDescription: (state: RootState, clientId: MemberId) => undefined | string = appCreateSelector(clientsGetClient, client => client?.problemShortDescription);