import { useCallback, useEffect } from 'react';
import { IExternalAuthEventHandlerCallback } from '@auth/external-auth-event-handler';
import { readAccessToken } from '@storage/auth';
import { AppFC } from '@types';
import { accountFetchLogoff } from '@actions/account/account-fetch-logoff';
import { accountHandleErrorBodyErrors } from '@actions/account/account-handle-error-body-errors';
import { accountLogin } from '@actions/account/account-login';
import { notificationsShowPushPrompt } from '@actions/notifications/notifications-show-push-prompt';
import { useExternalAuthEventHandler } from '@hooks/auth/external-auth-event-handler';
import { useExternalAuthWindow } from '@hooks/auth/external-auth-window';
import { usePush } from '@hooks/push';
import { useNavigator } from '@hooks/routing';
import { useStorage } from '@hooks/storage';
import { useTypedDispatch } from '@hooks/store';
export const ExternalAuthManager: AppFC = () => {
  const storage = useStorage();
  const navigator = useNavigator();
  const push = usePush();
  const externalAuthWindow = useExternalAuthWindow();
  const externalAuthEventHandler = useExternalAuthEventHandler();
  const dispatch = useTypedDispatch();
  const handleEvent: IExternalAuthEventHandlerCallback = useCallback(async ({
    data,
    error
  }) => {
    if (data) {
      const authData = data;
      const currentAccessToken = readAccessToken(storage);
      if (!authData || authData.accessToken === currentAccessToken) {
        return;
      }
      await dispatch(accountFetchLogoff({
        storage,
        navigator
      }));
      dispatch(accountLogin(authData));
      externalAuthWindow.close();
      dispatch(notificationsShowPushPrompt({
        storage,
        push
      }));
      return;
    }
    if (error) {
      const provider = externalAuthWindow.getProvider();
      if (!provider) {
        return;
      }
      dispatch(accountHandleErrorBodyErrors({
        errors: error.getOtherErrors(),
        provider
      }));
      externalAuthWindow.close();
      return;
    }
  }, [dispatch, externalAuthWindow, navigator, push, storage]);
  useEffect(() => {
    const unsubscribe = externalAuthEventHandler.subscribe(handleEvent);
    return unsubscribe;
  }, [externalAuthEventHandler, handleEvent]);
  return null;
};