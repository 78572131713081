import { ClientsReceiveGetByIdReducer } from './types';
export const clientsReceiveGetByIdReducer: ClientsReceiveGetByIdReducer = (state, action) => {
  const {
    client,
    clientId
  } = action.payload;
  return {
    ...state,
    clientsById: {
      ...state.clientsById,
      [clientId]: client
    }
  };
};