import { CalendarConsultationId, COMPLIMENT, MEDIA_CALL_TYPE } from '@types';
import { ChatId } from './chat';
import { MemberId } from './member';
export enum MESSAGE_STATUS {
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  BROKEN = 'BROKEN',
}
export const TEMPORARY_MESSAGE_ID_PREFIX = 'temp';
export enum ATTACHMENT_TYPE {
  IMAGE = 1,
}
export enum MESSAGE_TYPE {
  TEXT = 1,
  ATTACHMENT = 2,
  SYSTEM = 3,
  COMPLIMENT = 4,
  MEDIA_CALL = 5,
  CALENDAR = 6,
}
export enum MESSAGE_DYNAMIC {
  STATIC = 1,
  DYNAMIC = 2,
}
type Attachment = {
  src: string;
  size: number;
  type: ATTACHMENT_TYPE;
};
export type Gift = {
  amount: COMPLIMENT;
};
export type MessageId = string;
export type BaseMessage = {
  id: MessageId;
  chatId: ChatId;
  senderUserId?: MemberId;
  isRead: boolean;
  dynamic: MESSAGE_DYNAMIC;
  creationTime: string;
};
export type StaticMessage = BaseMessage & {
  dynamic: MESSAGE_DYNAMIC.STATIC;
};
export type DynamicMessage = BaseMessage & {
  dynamic: MESSAGE_DYNAMIC.DYNAMIC;
  lastChangeTime: string;
};
export type TextMessage = StaticMessage & {
  type: MESSAGE_TYPE.TEXT;
  text: string;
};
export type ImageMessage = StaticMessage & {
  type: MESSAGE_TYPE.ATTACHMENT;
  attachment: Attachment;
};
export type SystemMessage = StaticMessage & {
  type: MESSAGE_TYPE.SYSTEM;
  text: string;
};
export type ComplimentMessage = StaticMessage & {
  type: MESSAGE_TYPE.COMPLIMENT;
  gift: Gift;
};
export enum MEDIA_CALL_MESSAGE_STATE {
  CREATED = 0,
  MISSED_INCOMING = 1,
  REJECTED_INCOMING = 2,
  UNANSWERED_OUTGOING = 3,
  STARTED = 4,
  COMPLETED = 5,
  BUSY_OUTGOING = 6,
}
export type MediaCallMessageCall = {
  duration: number;
  type: MEDIA_CALL_TYPE;
  state: MEDIA_CALL_MESSAGE_STATE;
};
export type MediaCallMessage = StaticMessage & {
  type: MESSAGE_TYPE.MEDIA_CALL;
  call?: MediaCallMessageCall;
};
export enum CALENDAR_MESSAGE_CONSULTATION_STATE {
  UNDEFINED = 0,
  CREATED = 1,
  APPROVED = 2,
  REJECTED_BY_MEMBER = 3,
  REJECTED_BY_SYSTEM = 4,
  CANCELED = 5,
  DONE = 6,
}
export enum CALENDAR_MESSAGE_CONSULTATION_INVITATION_STATE {
  UNDEFINED = 0,
  PENDING = 1,
  APPROVED = 2,
  REJECTED = 3,
}
export type CalendarMessageCalendarConsultation = {
  id: CalendarConsultationId;
  timing: {
    beginTime: string;
    durationMinutes: number;
  };
  state: CALENDAR_MESSAGE_CONSULTATION_STATE;
  invitationState: CALENDAR_MESSAGE_CONSULTATION_INVITATION_STATE;
  payload?: {
    rejectionComment: string;
  };
};
export type CalendarMessageCalendar = {
  consultation: CalendarMessageCalendarConsultation;
};
type CalendarBaseMessage = {
  type: MESSAGE_TYPE.CALENDAR;
  calendar: CalendarMessageCalendar;
};
export type CalendarStaticMessage = StaticMessage & CalendarBaseMessage;
export type CalendarDynamicMessage = DynamicMessage & CalendarBaseMessage;
export type CalendarMessage = CalendarStaticMessage | CalendarDynamicMessage;
export type MessageImageFile = File;
export type Message = TextMessage | ImageMessage | ComplimentMessage | SystemMessage | MediaCallMessage | CalendarMessage;
export type Messages = Message[];
export type BaseLocalMessage = {
  id: MessageId;
  chatId: ChatId;
  isRead: boolean;
  creationTime: string;
  senderUserId: MemberId;
  type: MESSAGE_TYPE;
  dynamic: MESSAGE_DYNAMIC;
  _status: MESSAGE_STATUS;
};
export type LocalStaticMessage = BaseLocalMessage & {
  dynamic: MESSAGE_DYNAMIC.STATIC;
};
export type LocalTextMessage = LocalStaticMessage & {
  type: MESSAGE_TYPE.TEXT;
  text: string;
};
export type LocalImageMessage = LocalStaticMessage & {
  type: MESSAGE_TYPE.ATTACHMENT;
  attachment: Attachment;
};
export type LocalComplimentMessage = LocalStaticMessage & {
  type: MESSAGE_TYPE.COMPLIMENT;
  gift: Gift;
};
export type LocalMessage = LocalTextMessage | LocalImageMessage | LocalComplimentMessage;
export type LocalMessages = LocalMessage[];
export type MessagesList = {
  data: undefined | Messages;
  hasMore: undefined | boolean;
};