import { InvitationsIncreaseCounterReducer } from './types';
export const invitationsIncreaseCounterReducer: InvitationsIncreaseCounterReducer = (state, action) => {
  const {
    increment
  } = action.payload;
  const {
    pendingInvitationsCount
  } = state;
  return {
    ...state,
    pendingInvitationsCount: pendingInvitationsCount + increment
  };
};