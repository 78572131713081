import { decode, encode, ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';
import { DEFAULT_NEW_WINDOW_FEATURES } from '@config/dom';
import { alwaysArray, alwaysFirst, getObjectPropWithCaseInsensitive, isEqualCaseIncensitive, omitNullish, rejectNullable } from '@helpers/data';
import { parseIntOrUndefined } from '@helpers/parse';
import { NavQuery } from '@routing/nav';
export const decodeQuery = (searchString: string): undefined | ParsedUrlQuery => {
  try {
    return decode((searchString || '').replace(/^[?]/, ''));
  } catch (error) {
    if (error instanceof URIError) {
      console.error(error);
      return undefined;
    }
    throw error;
  }
};
export const decodeUrl = (url: string): {
  pathname: string;
  query: ParsedUrlQuery;
  hash: string;
} => {
  const [path, hash] = url.split('#');
  const [pathname, queryString] = path.split('?');
  const query = decodeQuery(queryString) ?? {};
  return {
    pathname,
    query,
    hash
  };
};
const prepend = (char: string, value: string): string => {
  if (!value) {
    return '';
  }
  return value[0] === char ? value : char + value;
};
export const encodeQuery = (query?: null | ParsedUrlQueryInput): string => {
  return encode(query ? omitNullish(query) : undefined);
};
export const encodeUrl = (pathname: string, query?: null | ParsedUrlQueryInput): string => {
  return `${pathname}${prepend('?', encodeQuery(query))}`;
};

/**
 * @todo
 * Move to windows.ts helpers file.
 */
export const openInNewTab = (url: string): undefined | Window => {
  const _window = window.open(url, '_blank');
  if (!_window) {
    return;
  }
  _window.focus();
  return _window;
};

/**
 * @todo
 * Move to windows.ts helpers file.
 * Or create a class-manager that handles browser windows.
 */
export const openNewWindow = (url: string, target?: string, features?: {
  left?: string;
  top?: string;
  width?: number;
  height?: number;
  resizable?: boolean;
}): null | Window => {
  const featuresString = Object.entries({
    ...DEFAULT_NEW_WINDOW_FEATURES,
    ...features
  }).reduce((processedFeatures, entry) => {
    const [key, value] = entry;
    let processedValue;
    switch (typeof value) {
      case 'boolean':
        processedValue = value ? 1 : 0;
        break;
      default:
        processedValue = value;
    }
    return `${processedFeatures},${key}=${processedValue}`;
  }, '');
  return window.open(url, target, featuresString);
};
export const parsePathnameParam = (value: undefined | string | string[]): undefined | string => {
  return alwaysFirst(value);
};
export const parseQueryInt = (query: NavQuery, queryKey: string): undefined | number => {
  const queryValue = getObjectPropWithCaseInsensitive(query, queryKey);
  return parseIntOrUndefined(alwaysFirst(queryValue));
};
export const parseQueryString = (query: NavQuery, queryKey: string): undefined | string => {
  const queryValue = getObjectPropWithCaseInsensitive(query, queryKey);
  return alwaysFirst(queryValue);
};
export const parseQueryBoolean = (query: NavQuery, queryKey: string): undefined | boolean => {
  const queryValue = getObjectPropWithCaseInsensitive(query, queryKey);
  const maybeStringifiedBoolean = alwaysFirst(queryValue);
  if (maybeStringifiedBoolean == null) {
    return;
  }
  return isEqualCaseIncensitive(maybeStringifiedBoolean, 'true');
};
export const parseQueryDate = (query: NavQuery, queryKey: string): undefined | Date => {
  const queryValue = getObjectPropWithCaseInsensitive(query, queryKey);
  if (!queryValue) {
    return;
  }
  return new Date(alwaysFirst(queryValue));
};
export const parseQueryStringList = (query: NavQuery, queryKey: string): string[] => {
  const queryValue = getObjectPropWithCaseInsensitive(query, queryKey);
  return rejectNullable(alwaysArray(queryValue));
};