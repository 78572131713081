import { ERROR_CODE } from '@config/errors';
import { ValidationError } from '@errors/validation-error';
import { IValidator, IValidatorAsyncValidatorOptions, IValidatorConstructor, IValidatorConstructorArgs, IValidatorValidateResult, IValidatorValidator, IValidatorValidatorErrors, IValidatorValidatorOptions, ToValidationErrorEntity, ValidationRules } from './types';

/**
 * @todo
 * Move Validator manager-class, initialization and validators
 * from data to src or src/managers.
 */
export const Validator: IValidatorConstructor = class Validator implements IValidator {
  private _validator: IValidatorValidator;
  private _defaultOptions: IValidatorValidatorOptions = {
    format: 'detailed',
    fullMessages: false
  };
  private _toValidationErrorEntity: ToValidationErrorEntity = error => {
    return {
      code: ERROR_CODE.VALIDATION_ERROR,
      data: {
        field: error.attribute,
        code: error.error
      }
    };
  };
  constructor(args: IValidatorConstructorArgs) {
    /**
     * @todo Replaced assigning by deep clone
     */
    this._validator = args.validator;
  }
  validate<D>(data: D, rules: ValidationRules, options?: IValidatorValidatorOptions): IValidatorValidateResult {
    const validateJsErrors: IValidatorValidatorErrors = this._validator.validate(data, rules, {
      ...this._defaultOptions,
      ...options
    });
    if (!validateJsErrors) {
      return undefined;
    }
    return new ValidationError('Validation error',
    //
    {
      errors: validateJsErrors.map(this._toValidationErrorEntity)
    });
  }
  async validateAsync<D>(data: D, rules: ValidationRules, options?: IValidatorAsyncValidatorOptions): Promise<IValidatorValidateResult> {
    const defaultAsyncOptions = {
      ...this._defaultOptions,
      wrapErrors: (validateJsErrors: IValidatorValidatorErrors) => {
        return validateJsErrors.map(this._toValidationErrorEntity);
      }
    };
    return new ValidationError('Validation error',
    //
    {
      errors: await this._validator.async(data, rules, {
        ...defaultAsyncOptions,
        ...options
      })
    });
  }
};