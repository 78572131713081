import { parse, Token } from 'path-to-regexp';
import { QUERY_PARAM } from '@config/routing';
import { alwaysFirst, findWithCaseInsensitive, getObjectPropWithCaseInsensitive, includesWithCaseInsensitive, isEmpty, isNullable, isString, mapKeys, omit, omitBy, omitNullish, pick, pickBy, rejectBy, rejectNullable } from '@helpers/data';
import { IRouteFactoryConstructorArgs, RouteFactoryQueryKey, RouteFactoryQueryKeys } from '@routing/route-factory';
import { INav } from '../nav';
import { RouteDescriptor, RouteParams } from '../route';
import { IRouteFactory, IRouteFactoryConstructor, RouteFactoryOptions, RouteFactoryQuery } from './types';
export const RouteFactory: IRouteFactoryConstructor = class RouteFactory implements IRouteFactory {
  private readonly _internalQueryKeys: RouteFactoryQueryKeys;
  private readonly _redirectToQueryKey: RouteFactoryQueryKey;
  private _assignQueryParamsWithCaseInsensitive<P extends RouteParams>(routeDescriptor: RouteDescriptor<P>): RouteDescriptor<P> {
    if (!routeDescriptor.params) {
      return routeDescriptor;
    }
    const tokens = parse(routeDescriptor.pattern);
    const nonStringTokens = (rejectBy(tokens,
    //
    isString) as Exclude<Token, string>[]);
    const paramKeys = nonStringTokens.map(token => String(token.name));
    const params = pick(routeDescriptor.params, paramKeys);
    const query = omit(routeDescriptor.params, ...rejectNullable(paramKeys));
    const nextQuery = omitNullish(mapKeys(query,
    //
    (_value, key) => {
      const queryParams = Object.values(QUERY_PARAM);
      const originKey = findWithCaseInsensitive(queryParams, key);
      return originKey ? originKey : key;
    }));
    return {
      ...routeDescriptor,
      params: ({
        ...params,
        ...nextQuery
      } as P)
    };
  }
  private _removeEmptyQuery<P extends RouteParams>(routeDescriptor: RouteDescriptor<P>): RouteDescriptor<P> {
    let nextParams = routeDescriptor.params;
    nextParams = nextParams //
    ? (omitBy(nextParams, value => {
      return isNullable(value) ||
      //
      typeof value === 'object' && isEmpty(value);
    }) as P) : undefined;
    return {
      ...routeDescriptor,
      params: nextParams
    };
  }
  private _hasRedirectQueryKey(nav: INav): boolean {
    const query = nav.getQuery();
    return Boolean(getObjectPropWithCaseInsensitive(query, this._redirectToQueryKey));
  }
  private _isInternalQueryKey(queryKey: string): boolean {
    return includesWithCaseInsensitive(this._internalQueryKeys, queryKey);
  }
  private _extractExternalQuery(query: RouteFactoryQuery): RouteParams {
    return pickBy(query, (_value, key) => !this._isInternalQueryKey(key));
  }
  private _preserveExternalQuery<P extends RouteParams>(nav: INav, routeDescriptor: RouteDescriptor<P>): RouteDescriptor<P> {
    const query = nav.getQuery();
    const externalQuery = this._extractExternalQuery(query);
    return {
      ...routeDescriptor,
      params: ({
        ...routeDescriptor.params,
        ...externalQuery
      } as P)
    };
  }
  private _addBackTo<P extends RouteParams>(nav: INav, routeDescriptor: RouteDescriptor<P>): RouteDescriptor<P | (P & {
    [QUERY_PARAM.BACK_TO]: string;
  })> {
    if (!routeDescriptor.withBackTo) {
      return routeDescriptor;
    }
    return ({
      ...routeDescriptor,
      params: {
        ...routeDescriptor.params,
        [QUERY_PARAM.BACK_TO]: encodeURIComponent(nav.getUrl())
      }
    } as RouteDescriptor<P | (P & {
      [QUERY_PARAM.BACK_TO]: string;
    })>);
  }
  private _addScrollY<P extends RouteParams>(routeDescriptor: RouteDescriptor<P>): RouteDescriptor<P | (P & {
    [QUERY_PARAM.SCROLL_Y]: string;
  })> {
    if (!routeDescriptor.withScrollY) {
      return routeDescriptor;
    }
    return ({
      ...routeDescriptor,
      params: {
        ...routeDescriptor.params,
        [QUERY_PARAM.SCROLL_Y]: String(window.scrollY)
      }
    } as RouteDescriptor<P | (P & {
      [QUERY_PARAM.SCROLL_Y]: string;
    })>);
  }
  private _getRedirectRoute<P extends RouteParams>(nav: INav): null | RouteDescriptor<P> {
    const query = nav.getQuery();
    const redirectUrl = alwaysFirst(getObjectPropWithCaseInsensitive(query, this._redirectToQueryKey));
    if (!redirectUrl) {
      return null;
    }
    const match = nav.findMatch(redirectUrl);
    if (!match) {
      return null;
    }
    const externalQuery = this._extractExternalQuery(query);
    const {
      route,
      params
    } = match;
    return (route.getDescriptor({
      ...params,
      ...externalQuery
    }) as RouteDescriptor<P>);
  }
  constructor(args: IRouteFactoryConstructorArgs) {
    const {
      internalQueryKeys,
      redirectToQueryKey
    } = args;
    this._internalQueryKeys = internalQueryKeys;
    this._redirectToQueryKey = redirectToQueryKey;
  }
  public get<P extends RouteParams>(nav: INav<undefined>, routeDescriptor: RouteDescriptor<P>, options?: RouteFactoryOptions): RouteDescriptor<P> {
    let nextRouteDescriptor: null | RouteDescriptor<P> = routeDescriptor;
    nextRouteDescriptor = this._assignQueryParamsWithCaseInsensitive(nextRouteDescriptor);
    nextRouteDescriptor = this._removeEmptyQuery(nextRouteDescriptor);
    nextRouteDescriptor = this._preserveExternalQuery(nav, nextRouteDescriptor);
    nextRouteDescriptor = this._addBackTo(nav, nextRouteDescriptor);
    nextRouteDescriptor = this._addScrollY(nextRouteDescriptor);
    const hasRedirectSignature = this._hasRedirectQueryKey(nav);
    if (options?.ignoreRedirect || !hasRedirectSignature) {
      return nextRouteDescriptor;
    }
    nextRouteDescriptor = this._getRedirectRoute<P>(nav);
    if (!nextRouteDescriptor) {
      throw new Error('Unable to resolve redirect alternate route');
    }
    return nextRouteDescriptor;
  }
};