import { GEOMETRY_TYPE } from '@config/geometry';
import { ANALYTICS_ACTION } from '@config/logging';
import { UAParser } from '@helpers/ua-parser';
import { getOrigin } from '@helpers/url';
import { INav } from '@routing/nav';
import { IEventBus } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsPageViewEvent } from '@logging/logging-events';
import { ILocalStorage } from '@storage';
import { readVisitId } from '@storage/analytics';
import { AnalyticsPageViewData, AnalyticsPageViewEventRequestData, AnalyticsPageViewRegularPageData, AnalyticsPageViewWithMemberPageData } from '@services/dto/analytics/analytics-send-event/analytics-page-view-event';
import { defineAnalyticsClientTypeByGeometryType } from './common';
export const isPageViewEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsPageViewEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.PAGEVIEW;
};
export const isAnalyticsPageViewRegularPageData = (data: unknown): data is AnalyticsPageViewRegularPageData => {
  return typeof data === 'object' && data != null && 'pageId' in data && !('memberId' in data);
};
export const isAnalyticsPageViewWithMemberPageData = (data: unknown): data is AnalyticsPageViewWithMemberPageData => {
  return typeof data === 'object' && data != null && 'memberId' in data;
};
export const sendPageView = (eventBus: IEventBus, data: AnalyticsPageViewEventRequestData['data']): void => {
  eventBus.handle(new AnalyticsEvent<AnalyticsPageViewEventRequestData['data']>({
    action: ANALYTICS_ACTION.PAGEVIEW,
    data
  }));
};
export const computePageViewData = async (args: {
  storage: ILocalStorage;
  navigator: INav;
  geometryType: GEOMETRY_TYPE;
}): Promise<AnalyticsPageViewData> => {
  const {
    storage,
    geometryType,
    navigator
  } = args;
  return {
    visitId: readVisitId(storage),
    userAgent: window.navigator.userAgent,
    url: `${getOrigin()}${navigator.getUrl()}`,
    clientType: defineAnalyticsClientTypeByGeometryType(geometryType),
    os: await new UAParser(window.navigator).getOS()
  };
};