import { omit, reject } from '@helpers/data';
import { InvitationsRemoveInvitationReducer } from './types';
export const invitationsRemoveInvitationReducer: InvitationsRemoveInvitationReducer = (state, action) => {
  const {
    invitationId
  } = action.payload;
  const {
    invitationsById,
    invitationsIds,
    totalCount
  } = state;
  return {
    ...state,
    invitationsIds: reject(invitationsIds, invitationId),
    invitationsById: omit(invitationsById, invitationId),
    totalCount: totalCount - 1
  };
};