import { MediaCallSetStateReducer } from './types';
export const mediaCallSetStateReducer: MediaCallSetStateReducer = (state, action) => {
  const {
    chatId,
    mediaCallState
  } = action.payload;
  const currentMediaCall = state.mediaCallsById[chatId];
  return {
    ...state,
    mediaCallsById: {
      ...state.mediaCallsById,
      [chatId]: {
        ...currentMediaCall,
        state: mediaCallState
      }
    }
  };
};