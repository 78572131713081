import { OVERLAY_BG_COLOR } from '@kit/overlay';
export enum POPUP_POSITION {
  LEFT = 'LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM = 'BOTTOM',
  CENTER = 'CENTER',
  FULLSCREEN = 'FULLSCREEN',
}
export type PopupOnCloseEvent = React.MouseEvent | KeyboardEvent;
export type PopupOnClose = (event: PopupOnCloseEvent) => void;
export type PopupProps = {
  position?: POPUP_POSITION;
  overlayBgColor?: OVERLAY_BG_COLOR;
  onClose?: PopupOnClose;
};