import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsT5NotificationLinkVisitedEvent } from '@logging/logging-events';
import { AnalyticsT5NotificationLinkVisitedEventRequestData } from '@services/dto/analytics/analytics-support-send-event/analytics-t5-notification-link-visited-event';
export const isT5NotificationLinkVisitedEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsT5NotificationLinkVisitedEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.T5_NOTIFICATION_LINK_VISITED;
};
export const sendT5NotificationLinkVisited = (eventBus: IEventBus, data: AnalyticsT5NotificationLinkVisitedEventRequestData['data']): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsT5NotificationLinkVisitedEventRequestData['data']>({
    action: ANALYTICS_ACTION.T5_NOTIFICATION_LINK_VISITED,
    data
  }));
};