import { Component } from 'react';
import { connect } from 'react-redux';
import { POPUP } from '@config/popups';
import { sendError } from '@helpers/logging/error';
import { LoggingContext } from '@logging';
import { addPopup } from '@actions/popups/add-popup';
import { ErrorBoundaryStyled, ErrorBoundaryTextStyled } from './styled';
import { ErrorBoundaryProps, ErrorBoundaryState } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export class _ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static contextType = LoggingContext;
  declare context: React.ContextType<typeof LoggingContext>;
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(): Partial<ErrorBoundaryState> {
    return {
      hasError: true
    };
  }
  componentDidCatch(error: Error): void {
    const eventBus = this.context;
    sendError(eventBus, error);
    this.props.dispatch(addPopup({
      id: POPUP.ERROR_BOUNDARY_ALERT
    }));
  }
  render(): React.ReactNode {
    if (this.state.hasError) {
      return _jsx(ErrorBoundaryStyled, {
        children: _jsx(ErrorBoundaryTextStyled, {
          children: "\u0427\u0442\u043E-\u0442\u043E \u043F\u043E\u0448\u043B\u043E \u043D\u0435 \u0442\u0430\u043A ;("
        })
      });
    }
    return this.props.children;
  }
}
export const ErrorBoundary = connect()(_ErrorBoundary);