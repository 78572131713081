import { rejectById } from '@helpers/data';
import { LoadingScreenHideReducer } from './types';
export const loadingScreenHideReducer: LoadingScreenHideReducer = (state, action) => {
  const {
    id
  } = action.payload;
  return {
    ...state,
    screens: rejectById(state.screens, id)
  };
};