import { differenceInMinutes, differenceInSeconds, differenceInYears, isValid, max, millisecondsToHours, millisecondsToMinutes, millisecondsToSeconds, min } from 'date-fns';
import { DateTime } from 'luxon';
import { UTC_TIMEZONE } from '@config/date';
import { rejectNullable } from '@helpers/data';
export { clamp, differenceInMinutes, getDaysInMonth, intervalToDuration, isAfter, isBefore, isEqual, isSameDay, isWithinInterval } from 'date-fns';
const fromJSorMillis = (date: Date | number, options?: {
  zone?: string;
}): DateTime => {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date, options);
  }
  return DateTime.fromMillis(date, options);
};
export const addMilliseconds = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).plus({
    milliseconds: amount
  }).toJSDate();
};
export const addMinutes = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).plus({
    minutes: amount
  }).toJSDate();
};
export const addHours = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).plus({
    hours: amount
  }).toJSDate();
};
export const addDays = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).plus({
    days: amount
  }).toJSDate();
};
export const addWeeks = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).plus({
    weeks: amount
  }).toJSDate();
};
export const addMonths = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).plus({
    months: amount
  }).toJSDate();
};
export const subMinutes = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).minus({
    minutes: amount
  }).toJSDate();
};
export const subHours = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).minus({
    hours: amount
  }).toJSDate();
};
export const subDays = (date: Date | number, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).minus({
    days: amount
  }).toJSDate();
};
export const subMonths = (date: Date, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).minus({
    months: amount
  }).toJSDate();
};
export const subYears = (date: Date, amount: number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).minus({
    years: amount
  }).toJSDate();
};
export const startOfDay = (date: Date | number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).startOf('day').toJSDate();
};
export const startOfWeek = (date: Date | number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).startOf('week').toJSDate();
};
export const startOfMonth = (date: Date | number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).startOf('month').toJSDate();
};
export const endOfDay = (date: Date | number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).endOf('day').toJSDate();
};
export const endOfWeek = (date: Date | number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).endOf('week').toJSDate();
};
export const endOfMonth = (date: Date | number, timezone?: string): Date => {
  return fromJSorMillis(date, {
    zone: timezone
  }).endOf('month').toJSDate();
};
export const findMinDate = (dates: (undefined | null | Date | number)[]): Date => {
  return min(rejectNullable(dates));
};
export const findMaxDate = (dates: (undefined | null | Date | number)[]): Date => {
  return max(rejectNullable(dates));
};
export const getTimezoneOffset = (date?: Date | number, timezone?: string): number => {
  return fromJSorMillis(date ?? new Date(), {
    zone: timezone
  }).offset;
};
export const getTimezoneId = (): string => {
  return DateTime.local().zoneName ?? UTC_TIMEZONE;
};
export const calculateElapsedTimeInYears = (startDate: undefined | string): undefined | number => {
  if (!startDate) {
    return undefined;
  }
  return differenceInYears(new Date(), new Date(startDate));
};
export const calculateElapsedTimeInMinutes = (startDate: undefined | string): undefined | number => {
  if (!startDate) {
    return undefined;
  }
  return differenceInMinutes(new Date(), new Date(startDate));
};
export const calculateElapsedTimeInSeconds = (startDate: undefined | string): undefined | number => {
  if (!startDate) {
    return undefined;
  }
  return differenceInSeconds(new Date(), new Date(startDate));
};
export const millisecondsToDuration = (milliseconds: number): {
  hours: number;
  minutes: number;
  seconds: number;
} => {
  return {
    hours: millisecondsToHours(milliseconds),
    minutes: millisecondsToMinutes(milliseconds) % 60,
    seconds: millisecondsToSeconds(milliseconds) % 60
  };
};
export function toValidDate(value: undefined | string): undefined | Date {
  if (!value) {
    return undefined;
  }
  const date = new Date(value);
  if (!isValid(date)) {
    return undefined;
  }
  return date;
}

/**
 * @todo
 * Get rid of checking oz 'Z' at the end of creationTime string.
 * All creationTime values must be with zero timezone offset.
 */
export const enforceDateStringToISO = (date: string): string => {
  return date.includes('Z') //
  ? date : `${date}Z`;
};
export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};
export const isDateEqual = (left: Date | number, right: Date | number, timezone?: string): boolean => {
  const _left = fromJSorMillis(left, {
    zone: timezone
  });
  const _right = fromJSorMillis(right, {
    zone: timezone
  });
  return _left.year === _right.year && _left.month === _right.month && _left.day === _right.day;
};
export const getTimezoneDiff = (dateA: Date | number, dateB: Date | number, timezone?: string): number => {
  return getTimezoneOffset(dateA, timezone) -
  //
  getTimezoneOffset(dateB, timezone);
};
export const fromDST = (dateA: Date | number, dateB: Date | number, timezone?: string): boolean => {
  return getTimezoneDiff(dateA, dateB, timezone) > 0;
};
export const toDST = (dateA: Date | number, dateB: Date | number, timezone?: string): boolean => {
  return getTimezoneDiff(dateA, dateB, timezone) < 0;
};
export const getTimestamp = (): number => {
  return new Date().getTime();
};