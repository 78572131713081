import { omit, reject } from '@helpers/data';
import { MediaCallClearReducer } from './types';
export const mediaCallClearReducer: MediaCallClearReducer = (state, action) => {
  const {
    chatId
  } = action.payload;
  return {
    ...state,
    mediaCallsById: omit(state.mediaCallsById, chatId),
    mediaCallsIds: reject(state.mediaCallsIds, chatId)
  };
};