import { useEffect, useState } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { POPUP } from '@config/popups';
import { addEvent, playMedia, stopMedia } from '@helpers/dom';
import { AppFC } from '@types';
import { mediaCallsGetActiveCallChatId, mediaCallsGetConnectingCallChatId, mediaCallsGetConnectingCallIsIncoming, mediaCallsGetConnectingCallIsOutgoing } from '@selectors/media-call';
import { popupsGetListByIds } from '@selectors/popups';
import { usePopup } from '@hooks/popup';
import { useTypedSelector } from '@hooks/store';
import { MediaCallPopupProps } from '@managers/media-call/types';
import { Audio } from '@kit/audio';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const MediaCallManager: AppFC = () => {
  const {
    addPopup
  } = usePopup();
  const pendingMediaCallChatId = useTypedSelector(mediaCallsGetConnectingCallChatId);
  const pendingMediaCallIsIncoming = useTypedSelector(mediaCallsGetConnectingCallIsIncoming);
  const pendingMediaCallIsOutgoing = useTypedSelector(mediaCallsGetConnectingCallIsOutgoing);
  const pendingMediaCallPopups = useTypedSelector(state => popupsGetListByIds(state, [POPUP.MEDIA_CALL_OUTGOING, POPUP.MEDIA_CALL_INCOMING]));
  const activeMediaCallChatId = useTypedSelector(mediaCallsGetActiveCallChatId);
  const activeMediaCallPopups = useTypedSelector(state => popupsGetListByIds(state, [POPUP.MEDIA_CALL_INTERFACE]));
  const [incomingAudioElement,
  //
  setIncomingAudioElement] = useState<HTMLAudioElement | null>(null);
  const [outgoingAudioElement,
  //
  setOutgoingAudioElement] = useState<HTMLAudioElement | null>(null);
  useEffect(() => {
    if (!pendingMediaCallChatId) {
      return;
    }
    const popupExists = pendingMediaCallPopups //
    .some(({
      props
    }) => (props as MediaCallPopupProps).chatId === pendingMediaCallChatId);
    if (popupExists) {
      return;
    }
    if (pendingMediaCallIsIncoming) {
      addPopup<MediaCallPopupProps>({
        id: POPUP.MEDIA_CALL_INCOMING,
        props: {
          chatId: pendingMediaCallChatId
        },
        keepSameId: true
      });
    }
    if (pendingMediaCallIsOutgoing) {
      addPopup<MediaCallPopupProps>({
        id: POPUP.MEDIA_CALL_OUTGOING,
        props: {
          chatId: pendingMediaCallChatId
        },
        keepSameId: true
      });
    }
  }, [addPopup, pendingMediaCallChatId, pendingMediaCallIsIncoming, pendingMediaCallIsOutgoing, pendingMediaCallPopups]);
  useEffect(() => {
    if (!activeMediaCallChatId) {
      return;
    }
    const popupExists = activeMediaCallPopups //
    .some(({
      props
    }) => (props as MediaCallPopupProps).chatId === activeMediaCallChatId);
    if (popupExists) {
      return;
    }
    addPopup<MediaCallPopupProps>({
      id: POPUP.MEDIA_CALL_INTERFACE,
      props: {
        chatId: activeMediaCallChatId
      }
    });
  }, [activeMediaCallChatId, activeMediaCallPopups, addPopup]);
  useEffect(() => {
    if (!incomingAudioElement || !pendingMediaCallIsIncoming) {
      return;
    }
    playMedia(incomingAudioElement);
    return () => {
      stopMedia(incomingAudioElement);
    };
  }, [incomingAudioElement, pendingMediaCallIsIncoming]);
  useEffect(() => {
    if (!outgoingAudioElement || !pendingMediaCallIsOutgoing) {
      return;
    }
    playMedia(outgoingAudioElement);
    return () => {
      stopMedia(outgoingAudioElement);
    };
  }, [pendingMediaCallIsOutgoing, outgoingAudioElement]);
  useEffect(() => {
    if (!incomingAudioElement || !outgoingAudioElement) {
      return;
    }
    const unsub = addEvent(window,
    //
    EVENT_NAMES.TOUCHSTART, () => {
      incomingAudioElement.muted = false;
      outgoingAudioElement.muted = false;
      unsub();
    });
    return unsub;
  }, [incomingAudioElement, outgoingAudioElement]);
  return _jsxs(_Fragment, {
    children: [_jsxs(Audio //
    , {
      ref: setIncomingAudioElement,
      loop: true,
      defaultLabel: "\u0412\u0445\u043E\u0434\u044F\u0449\u0438\u0439 \u0437\u0432\u043E\u043D\u043E\u043A",
      defaultLang: "ru",
      muted: true,
      children: [_jsx("source", {
        //
        src: "/sounds/incoming-call-short.ogg",
        type: "audio/ogg"
      }), _jsx("source", {
        //
        src: "/sounds/incoming-call-short.aac",
        type: "audio/aac"
      })]
    }), _jsxs(Audio //
    , {
      ref: setOutgoingAudioElement,
      loop: true,
      defaultLabel: "\u0418\u0441\u0445\u043E\u0434\u044F\u0449\u0438\u0439 \u0437\u0432\u043E\u043D\u043E\u043A",
      defaultLang: "ru",
      muted: true,
      children: [_jsx("source", {
        //
        src: "/sounds/outgoing-call-short.ogg",
        type: "audio/ogg"
      }), _jsx("source", {
        //
        src: "/sounds/outgoing-call-short.aac",
        type: "audio/aac"
      })]
    })]
  });
};