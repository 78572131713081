import { calendarIsCurrentClientCalendar, calendarIsCurrentSpecialistCalendar } from '@helpers/calendar';
import { uniqById } from '@helpers/data';
import { calendarGetCurrentMemberCalendarIsClientData, calendarGetCurrentMemberCalendarIsSpecialistData } from '@mapping/calendar/calendar-get-current-member-calendar';
import { calendarRejectConsultationsByPeriod } from '@mapping/calendar/calendar-reject-consultations-by-period';
import { calendarRejectWorkingTimesByPreiod } from '@mapping/calendar/calendar-reject-working-times-by-preiod';
import { CalendarReceiveGetCurrentMemberCalendarReducer } from './types';
export const calendarReceiveGetCurrentMemberCalendarReducer: CalendarReceiveGetCurrentMemberCalendarReducer = (state, action) => {
  const {
    dateFrom,
    dateTo,
    data
  } = action.payload;
  const currentMember = state.currentMember;
  const calendar = currentMember?.calendar;
  if (calendarGetCurrentMemberCalendarIsSpecialistData(data) && (!calendar || calendarIsCurrentSpecialistCalendar(calendar))) {
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...calendar,
          ...data,
          workingTimes: uniqById([...data.workingTimes, ...calendarRejectWorkingTimesByPreiod(calendar?.workingTimes ?? [], dateFrom, dateTo)]),
          consultations: uniqById([...data.consultations, ...calendarRejectConsultationsByPeriod(calendar?.consultations ?? [], dateFrom, dateTo)])
        }
      }
    };
  }
  if (calendarGetCurrentMemberCalendarIsClientData(data) && (!calendar || calendarIsCurrentClientCalendar(calendar))) {
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...calendar,
          ...data,
          consultations: uniqById([...data.consultations, ...calendarRejectConsultationsByPeriod(calendar?.consultations ?? [], dateFrom, dateTo)])
        }
      }
    };
  }
  return state;
};