import { CalendarReceiveGetMemberCalendarInfoReducer } from './types';
export const calendarReceiveGetMemberCalendarInfoReducer: CalendarReceiveGetMemberCalendarInfoReducer = (state, action) => {
  const {
    memberId,
    data
  } = action.payload;
  return {
    ...state,
    membersById: {
      ...state.membersById,
      [memberId]: {
        ...state.membersById?.[memberId],
        info: data
      }
    }
  };
};