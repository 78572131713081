import { appCreateSlice } from '@store/rtk';
import { addPopupReducer } from '@slices/popups/add-popup';
import { removePopupReducer } from '@slices/popups/remove-popup';
import { removePopupByPropsReducer } from '@slices/popups/remove-popup-by-props';
import { PopupsState } from './types';
export const popupsDefaultState: PopupsState = [];
const popupsSlice = appCreateSlice({
  name: 'popups',
  initialState: popupsDefaultState,
  reducers: {
    addPopup: addPopupReducer,
    removePopup: removePopupReducer,
    removePopupByProps: removePopupByPropsReducer
  }
});
export const {
  actions: {
    removePopup,
    addPopup,
    removePopupByProps
  },
  reducer: popupsReducer
} = popupsSlice;