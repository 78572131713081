import { compile, parse } from 'path-to-regexp';
import { includesWithCaseInsensitive, omitBy, omitNullish } from '@helpers/data';
import { encodeUrl } from '@helpers/routing';
import { IRoute, IRouteConstructor, RouteDescriptor, RouteLink, RouteOptions, RoutePage, RouteParams, RoutePattern, RouteUrl, RouteUrlObject } from './types';
export const Route: IRouteConstructor = class Route<P extends RouteParams> implements IRoute<P> {
  private readonly _routeDescriptor: RouteDescriptor<P>;
  private _getQuery(): RouteParams {
    const tokens = parse(this._routeDescriptor.pattern);
    const paramKeys = tokens.map(token => {
      if (typeof token === 'string') {
        return token;
      }
      return String(token.name);
    });
    const query = (omitBy(omitNullish(this._routeDescriptor.params ?? {}), (_, key) => includesWithCaseInsensitive(paramKeys, key)) as RouteParams);
    return query;
  }
  public constructor(routeDescriptor: RouteDescriptor<P>) {
    this._routeDescriptor = routeDescriptor;
  }
  public getPattern(): RoutePattern {
    return this._routeDescriptor.pattern;
  }
  public getPage(): RoutePage {
    return this._routeDescriptor.page;
  }
  public getDescriptor(params?: P, options?: RouteOptions): RouteDescriptor<P> {
    return {
      ...this._routeDescriptor,
      params,
      mode: options?.mode,
      resetScroll: options?.resetScroll,
      withBackTo: options?.withBackTo,
      withScrollY: options?.withScrollY
    };
  }
  public getUrlObject(params?: P): RouteUrlObject {
    const query = this._getQuery();
    const pathname = compile(this._routeDescriptor.pattern,
    //
    {
      encode: encodeURIComponent
    })({
      ...this._routeDescriptor.params,
      ...params
    });
    return {
      pathname,
      query
    };
  }
  public getUrl(params?: P): RouteUrl {
    const {
      pathname,
      query
    } = this.getUrlObject(params);
    return encodeUrl(pathname, query);
  }
  public getLink(params?: P): RouteLink {
    const {
      pathname,
      query
    } = this.getUrlObject(params);
    const href = encodeUrl(this._routeDescriptor.page, query);
    const as = encodeUrl(pathname, query);
    return {
      href,
      as
    };
  }
};