import { AppFCC } from '@types';
import { PopupContentChildrenStyled, PopupContentStyled } from './styled';
import { PopupContentProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PopupContent: AppFCC<PopupContentProps> = props => {
  const {
    position,
    children
  } = props;
  return _jsx(PopupContentStyled, {
    position: position,
    children: _jsx(PopupContentChildrenStyled, {
      position: position,
      children: children
    })
  });
};