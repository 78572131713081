import { GEOMETRY_TYPE } from '@config/geometry';
import { isDesktop, isTablet } from '@helpers/geometry';
import { getOrigin } from '@helpers/url';
import { INav } from '@routing/nav';
import { ANALYTICS_CLIENT_TYPE, AnalyticsBaseData } from '@types';
export const defineAnalyticsClientTypeByGeometryType = (geometryType: GEOMETRY_TYPE): ANALYTICS_CLIENT_TYPE => {
  return isDesktop(geometryType) ? ANALYTICS_CLIENT_TYPE.DESKTOP_BROWSER : isTablet(geometryType) ? ANALYTICS_CLIENT_TYPE.TABLET_BROWSER : ANALYTICS_CLIENT_TYPE.MOBILE_BROWSER;
};
export const computeAnalyticsBaseData = (args: {
  navigator: INav;
  geometryType: GEOMETRY_TYPE;
}): AnalyticsBaseData => {
  const {
    geometryType,
    navigator
  } = args;
  return {
    url: `${getOrigin()}${navigator.getUrl()}`,
    clientType: defineAnalyticsClientTypeByGeometryType(geometryType)
  };
};