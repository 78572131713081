import { useMemo } from 'react';
import { colors } from '@root/tailwind/colors';
import { AppFCC } from '@types';
import { Overlay, OVERLAY_BG_COLOR } from '@kit/overlay';
import { LoadingOverlaySpinnerStyled, LoadingOverlayStyled } from './styled';
import { LoadingOverlayProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const LoadingOverlay: AppFCC<LoadingOverlayProps> = props => {
  const {
    className,
    spinnerColors: originSpinnerColors,
    //
    overlayBgColor = OVERLAY_BG_COLOR.WHITE
  } = props;
  const _spinnerColors = useMemo(() => {
    return originSpinnerColors ?? [colors['blue-90-39-100']];
  }, [originSpinnerColors]);
  return _jsxs(LoadingOverlayStyled, {
    className: className,
    children: [_jsx(Overlay, {
      overlayBgColor: overlayBgColor
    }), _jsx(LoadingOverlaySpinnerStyled, {
      colors: _spinnerColors
    })]
  });
};