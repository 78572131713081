import { Chat } from '@types';
import { ChatsIncreaseUnreadMessagesCounterReducer } from './types';
export const chatsIncreaseUnreadMessagesCounterReducer: ChatsIncreaseUnreadMessagesCounterReducer = (state, action) => {
  const {
    chatId,
    increment
  } = action.payload;
  const chat = (state.chatsById[chatId] as undefined | Chat);
  const chatsById = chat ? {
    ...state.chatsById,
    [chatId]: {
      ...chat,
      unreadedMessagesCount: chat.unreadedMessagesCount + increment
    }
  } : state.chatsById;
  return {
    ...state,
    chatsById,
    unreadMessagesCount: state.unreadMessagesCount + increment
  };
};