import { uniq } from '@helpers/data';
import { ChatsAddChatReducer } from './types';
export const chatsAddChatReducer: ChatsAddChatReducer = (state, action) => {
  const {
    chat
  } = action.payload;
  if (!chat) {
    return state;
  }
  const {
    chatsIds = [],
    chatsById = {}
  } = state;
  return {
    ...state,
    chatsById: {
      ...chatsById,
      [chat.chatId]: chat
    },
    chatsIds: uniq([chat.chatId, ...chatsIds])
  };
};