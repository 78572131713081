import { appCreateSlice } from '@store/rtk';
import { calendarDecreaseConsultationsCounterReducer } from './calendar-decrease-consultations-counter';
import { calendarIncreaseConsultationsCounterReducer } from './calendar-increase-consultations-counter';
import { calendarReceiveApproveCurrentMemberConsultationReducer } from './calendar-receive-approve-current-member-consultation';
import { calendarReceiveCreateMemberConsultationReducer } from './calendar-receive-create-member-consultation';
import { calendarReceiveGetCurrentMemberCalendarReducer } from './calendar-receive-get-current-member-calendar';
import { calendarReceiveGetCurrentMemberCalendarInfoReducer } from './calendar-receive-get-current-member-calendar-info';
import { calendarReceiveGetCurrentMemberConsultationsReducer } from './calendar-receive-get-current-member-consultations';
import { calendarReceiveGetCurrentMemberWorkingTimesReducer } from './calendar-receive-get-current-member-working-times';
import { calendarReceiveGetDefaultsReducer } from './calendar-receive-get-defaults';
import { calendarReceiveGetMemberCalendarReducer } from './calendar-receive-get-member-calendar';
import { calendarReceiveGetMemberCalendarInfoReducer } from './calendar-receive-get-member-calendar-info';
import { calendarReceiveGetMemberConsultationsReducer } from './calendar-receive-get-member-consultations';
import { calendarReceiveGetMemberWorkingTimesReducer } from './calendar-receive-get-member-working-times';
import { calendarReceiveRejectCurrentMemberConsultationReducer } from './calendar-receive-reject-current-member-consultation';
import { calendarReceiveUpdateCurrentMemberWorkingTimesReducer } from './calendar-receive-update-current-member-working-times';
import { calendarRemoveMemberConsultationReducer } from './calendar-remove-member-consultation';
import { calendarSetConsultationsCounterReducer } from './calendar-set-consultations-counter';
import { realtimeReceiveCalendarConsultationCreatedReducer } from './realtime-receive-calendar-consultation-created';
import { realtimeReceiveCalendarConsultationDoneReducer } from './realtime-receive-calendar-consultation-done';
import { realtimeReceiveCalendarConsultationRejectedReducer } from './realtime-receive-calendar-consultation-rejected';
import { CalendarState } from './types';
export const calendarDefaultState: CalendarState = {
  defaults: undefined,
  currentMember: undefined,
  membersById: undefined,
  pendingConsultationsCount: 0
};
const calendarSlice = appCreateSlice({
  name: 'calendar',
  initialState: calendarDefaultState,
  reducers: {
    calendarReceiveGetDefaults: calendarReceiveGetDefaultsReducer,
    calendarReceiveGetMemberCalendar: calendarReceiveGetMemberCalendarReducer,
    calendarReceiveGetMemberCalendarInfo: calendarReceiveGetMemberCalendarInfoReducer,
    calendarReceiveGetMemberWorkingTimes: calendarReceiveGetMemberWorkingTimesReducer,
    calendarReceiveGetMemberConsultations: calendarReceiveGetMemberConsultationsReducer,
    calendarReceiveGetCurrentMemberCalendar: calendarReceiveGetCurrentMemberCalendarReducer,
    calendarReceiveGetCurrentMemberCalendarInfo: calendarReceiveGetCurrentMemberCalendarInfoReducer,
    calendarReceiveGetCurrentMemberWorkingTimes: calendarReceiveGetCurrentMemberWorkingTimesReducer,
    calendarReceiveUpdateCurrentMemberWorkingTimes: calendarReceiveUpdateCurrentMemberWorkingTimesReducer,
    calendarReceiveGetCurrentMemberConsultations: calendarReceiveGetCurrentMemberConsultationsReducer,
    calendarReceiveCreateMemberConsultation: calendarReceiveCreateMemberConsultationReducer,
    calendarReceiveApproveCurrentMemberConsultation: calendarReceiveApproveCurrentMemberConsultationReducer,
    calendarReceiveRejectCurrentMemberConsultation: calendarReceiveRejectCurrentMemberConsultationReducer,
    realtimeReceiveCalendarConsultationCreated: realtimeReceiveCalendarConsultationCreatedReducer,
    realtimeReceiveCalendarConsultationRejected: realtimeReceiveCalendarConsultationRejectedReducer,
    realtimeReceiveCalendarConsultationDone: realtimeReceiveCalendarConsultationDoneReducer,
    calendarRemoveMemberConsultation: calendarRemoveMemberConsultationReducer,
    calendarSetConsultationsCounter: calendarSetConsultationsCounterReducer,
    calendarDecreaseConsultationsCounter: calendarDecreaseConsultationsCounterReducer,
    calendarIncreaseConsultationsCounter: calendarIncreaseConsultationsCounterReducer
  }
});
export const {
  actions: {
    calendarReceiveGetDefaults,
    calendarReceiveGetMemberCalendar,
    calendarReceiveGetMemberCalendarInfo,
    calendarReceiveGetMemberWorkingTimes,
    calendarReceiveGetMemberConsultations,
    calendarReceiveGetCurrentMemberCalendar,
    calendarReceiveGetCurrentMemberCalendarInfo,
    calendarReceiveGetCurrentMemberWorkingTimes,
    calendarReceiveUpdateCurrentMemberWorkingTimes,
    calendarReceiveGetCurrentMemberConsultations,
    calendarReceiveCreateMemberConsultation,
    calendarReceiveApproveCurrentMemberConsultation,
    calendarReceiveRejectCurrentMemberConsultation,
    realtimeReceiveCalendarConsultationCreated,
    realtimeReceiveCalendarConsultationRejected,
    realtimeReceiveCalendarConsultationDone,
    calendarRemoveMemberConsultation,
    calendarSetConsultationsCounter,
    calendarDecreaseConsultationsCounter,
    calendarIncreaseConsultationsCounter
  },
  reducer: calendarReducer
} = calendarSlice;