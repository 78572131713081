import { MediaCallAddLocalVideoTrackReducer } from './types';
export const mediaCallAddLocalVideoTrackReducer: MediaCallAddLocalVideoTrackReducer = (state, action) => {
  const {
    chatId,
    track
  } = action.payload;
  const currentCall = state.mediaCallsById[chatId];
  return {
    ...state,
    mediaCallsById: {
      ...state.mediaCallsById,
      [chatId]: {
        ...currentCall,
        _localVideoTrack: track
      }
    }
  };
};