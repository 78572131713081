import { POPUP } from '@config/popups';
import { STORAGE_KEYS } from '@config/storage';
import { ILocalStorage, initializeStorage } from '@storage';
import { MemberId } from '@types';
export type PopupsData = {
  impressionsNumberByPopupId: { [key in POPUP]?: {
    [key: MemberId]: number;
  } };
};
export const readPopupsData = (storage: ILocalStorage): undefined | PopupsData => {
  return storage.read<PopupsData>(STORAGE_KEYS.POPUPS);
};
export const writePopupsData = (storage: ILocalStorage, data: PopupsData): void => {
  storage.write<PopupsData>(STORAGE_KEYS.POPUPS, data);
};
export const readPopupImpressionsNumber = (storage: ILocalStorage, popupId: POPUP, memberId: MemberId): undefined | number => {
  const popupsData = readPopupsData(storage);
  return popupsData?.impressionsNumberByPopupId[popupId]?.[memberId];
};
export const writePopupImpressionsNumber = (storage: ILocalStorage, popupId: POPUP, memberId: MemberId, impressionsNumber: number): void => {
  const popupData = readPopupsData(storage);
  writePopupsData(storage, {
    ...popupData,
    impressionsNumberByPopupId: {
      ...popupData?.impressionsNumberByPopupId,
      [popupId]: {
        ...popupData?.impressionsNumberByPopupId[popupId],
        [memberId]: impressionsNumber
      }
    }
  });
};
export const resetPopupImpressionsNumber = (storage: undefined | ILocalStorage, popupId: POPUP, memberId: MemberId): void => {
  /**
   * @todo
   * Remove initialization of storage below when all writePopupImpressionsNumber
   * calls will get storage as an argument.
   */
  if (!storage) {
    storage = initializeStorage();
  }
  writePopupImpressionsNumber(storage, popupId, memberId, 0);
};