import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimeCalendarConsultationApprovedEventPayloadData } from '@services/dto/realtime/realtime-calendar-event/realtime-calendar-consultation-approved';
import { calendarReceiveApproveCurrentMemberConsultation } from '@slices/calendar';
import { RealtimeCalendarConsultationApproved } from './types';
export const realtimeCalendarConsultationApproved: RealtimeCalendarConsultationApproved = event => {
  return dispatch => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeCalendarConsultationApprovedEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      consultation
    } = data;
    dispatch(calendarReceiveApproveCurrentMemberConsultation({
      consultationId: consultation.id
    }));
  };
};