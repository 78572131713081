import { realtimeInactive } from '@services/realtime/realtime-inactive';
import { RealtimeFetchInactive } from './types';
export const realtimeFetchInactive: RealtimeFetchInactive = payload => {
  return async () => {
    const {
      sessionId
    } = payload;
    const data = await realtimeInactive({
      sessionId
    }).promise;
    return data;
  };
};