import { rejectById } from '@helpers/data';
import { FILE_MODERATION_STATUS, FILE_TYPE, MEDIA_UPLOAD_TEMPORARY_VIDEO_ID } from '@types';
import { CurrentSpecialistReceiveUploadVideoCompleteReducer } from './types';
export const currentSpecialistReceiveUploadVideoCompleteReducer: CurrentSpecialistReceiveUploadVideoCompleteReducer = (state, action) => {
  const {
    videoId,
    url
  } = action.payload;
  if (!state.me) {
    return state;
  }
  return {
    ...state,
    me: {
      ...state.me,
      media: [{
        id: videoId,
        fileType: FILE_TYPE.VIDEO,
        url,
        status: FILE_MODERATION_STATUS.NEW
      }, ...rejectById(state.me.media, MEDIA_UPLOAD_TEMPORARY_VIDEO_ID)]
    }
  };
};