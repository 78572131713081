import { IPaymentsPayWindow } from '@payments/payments-pay-window';
import { POPUP } from '@config/popups';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimePaymentIsFailureEvent, RealtimePaymentIsFailureEventPayloadData } from '@services/dto/realtime/realtime-payment-event/realtime-payment-is-failure';
import { PAYMENT_PURPOSE } from '@types';
import { addPopup } from '@actions/popups/add-popup';
import { removePopup } from '@slices/popups';
import { PaymentsFailureAlertProps } from '@popups/modals/payments/payments-failure-alert';
import { RealtimePaymentIsFailure } from './types';
export const realtimePaymentIsFailure = (event: RealtimePaymentIsFailureEvent, args: {
  paymentsPayWindow: IPaymentsPayWindow;
}): RealtimePaymentIsFailure => {
  return dispatch => {
    const {
      payload
    } = event;
    const data = readJson<RealtimePaymentIsFailureEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      purpose
    } = data;
    switch (purpose) {
      case PAYMENT_PURPOSE.BUY_COINS:
        {
          dispatch(removePopup({
            id: POPUP.PAYMENTS_ADD_FUNDS
          }));
          break;
        }
      case PAYMENT_PURPOSE.BUY_PREMIUM:
        {
          dispatch(removePopup({
            id: POPUP.PAYMENTS_BUY_PREMIUM
          }));
          break;
        }
    }
    dispatch(addPopup<PaymentsFailureAlertProps>({
      id: POPUP.PAYMENTS_FAILURE_ALERT,
      props: {
        purpose
      }
    }));
    const {
      paymentsPayWindow
    } = args;
    paymentsPayWindow.close();
  };
};