import { SpecialistsReceiveOnlineStatusByIdReducer } from './types';
export const specialistsReceiveOnlineStatusByIdReducer: SpecialistsReceiveOnlineStatusByIdReducer = (state, action) => {
  const {
    specialistId,
    onlineStatus
  } = action.payload;
  return {
    ...state,
    specialistsById: {
      ...state.specialistsById,
      [specialistId]: {
        ...state.specialistsById[specialistId],
        onlineStatus
      }
    }
  };
};