import { MessagesAddTemporaryMessageReducer } from './types';
export const messagesAddTemporaryMessageReducer: MessagesAddTemporaryMessageReducer = (state, action) => {
  const {
    message
  } = action.payload;
  const {
    byChatId
  } = state;
  const messagesByChatId = byChatId[message.chatId] ?? {};
  const {
    messagesById = {},
    messagesIds = []
  } = messagesByChatId;
  return {
    ...state,
    byChatId: {
      ...byChatId,
      [message.chatId]: {
        ...messagesByChatId,
        messagesById: {
          ...messagesById,
          [message.id]: message
        },
        messagesIds: [message.id, ...messagesIds]
      }
    }
  };
};