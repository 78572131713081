import { getTimezoneOffset } from '@helpers/date';
import { AccountAssociatedSocialMedias, AccountTimezones, MemberId, PROVIDER } from '@types';
import { appCreateSelector, RootState } from '@store';
import { AccountState } from '@slices/account';

/**
 * regular selectors
 */

export const accountGet = (state: RootState): AccountState => {
  return state.app.account;
};

/**
 * memoized reselect selectors
 */

export const accountGetIsAuthorized: (state: RootState) => boolean = appCreateSelector([accountGet],
//
account => account.isAuthorized);
export const accountGetAccountEmail: (state: RootState) => undefined | string = appCreateSelector([accountGet],
//
account => account.email);
export const accountGetAccountId: (state: RootState) => undefined | MemberId = appCreateSelector([accountGet],
//
account => account.id);
export const accountGetAccountPrevId: (state: RootState) => undefined | MemberId = appCreateSelector([accountGet],
//
account => account.prevId);
export const accountGetEmailIsConfirmed: (state: RootState) => undefined | boolean = appCreateSelector([accountGet], account => account.emailIsConfirmed);
export const accountGetAssociatedSocialMedia: (state: RootState) => AccountAssociatedSocialMedias = appCreateSelector([accountGet], account => account.associatedSocialMedia);
export const accountGetAssociatedGoogle: (state: RootState) => boolean = appCreateSelector(accountGetAssociatedSocialMedia, associatedSocialMedia => associatedSocialMedia.some(socialMedia => socialMedia.loginProvider === PROVIDER.GOOGLE));
export const accountGetAssociatedFacebook: (state: RootState) => boolean = appCreateSelector(accountGetAssociatedSocialMedia, associatedSocialMedia => associatedSocialMedia.some(socialMedia => socialMedia.loginProvider === PROVIDER.FB));
export const accountGetAssociatedVkontakte: (state: RootState) => boolean = appCreateSelector(accountGetAssociatedSocialMedia, associatedSocialMedia => associatedSocialMedia.some(socialMedia => socialMedia.loginProvider === PROVIDER.VK));
export const accountGetTimezone: (state: RootState) => undefined | string = appCreateSelector([accountGet],
//
account => account.timezoneId);
export const accountGetTimezones: (state: RootState) => undefined | AccountTimezones = appCreateSelector([accountGet],
//
account => account.timezones);
export const accountGetTimezonesSortedByOffset: (state: RootState) => undefined | AccountTimezones = appCreateSelector([accountGetTimezones],
//
timezones => {
  if (!timezones) {
    return undefined;
  }
  const currentDate = new Date();
  return timezones.slice().sort((a, b) => {
    const offsetDiff = getTimezoneOffset(currentDate, a.timezoneId) - getTimezoneOffset(currentDate, b.timezoneId);
    const startsWithEtc = a.timezoneId.startsWith('Etc') && !b.timezoneId.startsWith('Etc') ? -1 : !a.timezoneId.startsWith('Etc') && b.timezoneId.startsWith('Etc') ? 1 : 0;

    /**
     * Sort timezones by offset then by 'Etc' string start.
     */
    return offsetDiff || startsWithEtc;
  });
});