import { useMemo } from 'react';
import { NewCalendarNotificationMessageStyled } from '@notifications/notifications/new-calendar/styled';
import { QUERY_PARAM } from '@config/routing';
import { calendarConsultationIsCreated, calendarConsultationIsRefused } from '@helpers/calendar';
import { formatBeginTimeWithTimezone } from '@helpers/format';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { membersFetchGetMember } from '@actions/members/members-fetch-get-member';
import { notificationsUiRemove } from '@slices/notifications-ui';
import { accountGetTimezone } from '@selectors/account';
import { membersGetMemberChatIdWithCurrentUser } from '@selectors/members';
import { useNavigator } from '@hooks/routing';
import { useTypedDispatch, useTypedSelector, useTypedStore } from '@hooks/store';
import { NotificationUiWidget, NotificationUiWidgetProps } from '@widgets/notifications/notification-ui';
import { NewCalendarNotificationProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const NewCalendarNotification: AppFCC<NewCalendarNotificationProps> = props => {
  const {
    notificationId,
    name,
    avatar,
    consultation,
    initiator
  } = props;
  const store = useTypedStore();
  const dispatch = useTypedDispatch();
  const navigator = useNavigator();
  const timezone = useTypedSelector(accountGetTimezone);
  const consultationTime = useMemo(() => {
    return formatBeginTimeWithTimezone(new Date(consultation.timing.beginTime), timezone);
  }, [consultation.timing.beginTime, timezone]);
  const actionButton: NotificationUiWidgetProps['actionButton'] = useMemo(() => {
    return {
      text: calendarConsultationIsCreated(consultation) //
      ? 'Ответить' : calendarConsultationIsRefused(consultation) ? 'Перейти в календарь' : '',
      onClick: async () => {
        if (calendarConsultationIsCreated(consultation)) {
          const {
            userId,
            roleType
          } = initiator;
          let chatIdWithCurrentUser = membersGetMemberChatIdWithCurrentUser(store.getState(), userId);
          if (!chatIdWithCurrentUser) {
            await dispatch(membersFetchGetMember({
              memberId: userId,
              memberRoleType: roleType
            }));
          }
          chatIdWithCurrentUser = membersGetMemberChatIdWithCurrentUser(store.getState(), userId);
          if (!chatIdWithCurrentUser) {
            return;
          }
          navigator.navigate(ROUTES.chats.id.getDescriptor({
            [QUERY_PARAM.PATHNAME_CHAT_ID]: chatIdWithCurrentUser
          }));
        }
        if (calendarConsultationIsRefused(consultation)) {
          navigator.navigate(ROUTES.calendar.index.getDescriptor());
        }
        dispatch(notificationsUiRemove({
          id: notificationId
        }));
      }
    };
  }, [consultation, dispatch, initiator, navigator, notificationId, store]);
  return _jsx(NotificationUiWidget //
  , {
    notificationId: notificationId,
    title: calendarConsultationIsCreated(consultation) //
    ? 'Запрос на консультацию' : calendarConsultationIsRefused(consultation) ? 'Отмена консультации' : '',
    name: name,
    avatar: avatar,
    message: _jsxs(NewCalendarNotificationMessageStyled, {
      children: [consultationTime, " ", _jsx("br", {}), !consultation.payload?.rejectionComment ? null : consultation.payload.rejectionComment]
    }),
    actionButton: actionButton
  });
};