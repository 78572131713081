import { useCallback, useEffect, useMemo, useRef } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { ONLINE_MANAGER_OFFLINE_TIMEOUT, ONLINE_MANAGER_ONLINE_INTERVAL } from '@config/realtime';
import { addEvent, isEnter } from '@helpers/dom';
import { debounce, throttle } from '@helpers/misc';
import { readStorageAuthData } from '@storage/auth';
import { readRealtimeSessionId } from '@storage/realtime';
import { AppFC } from '@types';
import { realtimeFetchInactive } from '@actions/realtime/realtime-inactive';
import { realtimeFetchOffline } from '@actions/realtime/realtime-offline';
import { realtimeFetchPing } from '@actions/realtime/realtime-ping';
import { accountGetIsAuthorized } from '@selectors/account';
import { mediaCallsGetHasActiveCall } from '@selectors/media-call';
import { useNavigator } from '@hooks/routing';
import { useStorage } from '@hooks/storage';
import { useTypedDispatch, useTypedSelector } from '@hooks/store';
export const OnlineManager: AppFC = () => {
  const storage = useStorage();
  const navigator = useNavigator();
  const dispatch = useTypedDispatch();
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const hasActiveMediaCall = useTypedSelector(mediaCallsGetHasActiveCall);
  const onlineIsInactiveRef = useRef<boolean>(false);
  const handleInactive = useCallback(() => {
    if (onlineIsInactiveRef.current) {
      return;
    }
    onlineIsInactiveRef.current = true;
    const sessionId = readRealtimeSessionId(storage);
    if (sessionId) {
      dispatch(realtimeFetchInactive({
        sessionId
      }));
    }
  }, [dispatch, storage]);
  const handleOnline = useCallback(async () => {
    onlineIsInactiveRef.current = false;
    /**
     * Prevent ping in case of a user is unauthorized.
     */
    if (!readStorageAuthData(storage)) {
      return;
    }
    await dispatch(realtimeFetchPing({
      storage,
      navigator
    }));
  }, [dispatch, navigator, storage]);
  const throttledHandleOnline = useMemo(() => {
    return throttle(handleOnline, ONLINE_MANAGER_ONLINE_INTERVAL, {
      leading: true,
      trailing: false
    });
  }, [handleOnline]);
  const handleOffline = useCallback(async () => {
    await dispatch(realtimeFetchOffline({
      storage,
      navigator
    }));
  }, [dispatch, navigator, storage]);
  const debouncedHandleOffline = useMemo(() => {
    return debounce(handleOffline, ONLINE_MANAGER_OFFLINE_TIMEOUT, {
      leading: false,
      trailing: true
    });
  }, [handleOffline]);
  const handleOnlineState = useCallback(() => {
    throttledHandleOnline();
    debouncedHandleOffline();
  }, [debouncedHandleOffline, throttledHandleOnline]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    handleOnlineState();
    return () => {
      throttledHandleOnline.cancel();
      debouncedHandleOffline.cancel();
    };
  }, [debouncedHandleOffline, handleOnlineState, isAuthorized, throttledHandleOnline]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent(document.body,
    //
    EVENT_NAMES.TOUCHSTART, handleOnlineState);
  }, [handleOnlineState, isAuthorized]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent(document.body,
    //
    EVENT_NAMES.CLICK, handleOnlineState);
  }, [handleOnlineState, isAuthorized]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent<KeyboardEvent>(document.body,
    //
    EVENT_NAMES.KEYUP, event => {
      if (!isEnter(event)) {
        return;
      }
      handleOnlineState();
    });
  }, [handleOnlineState, isAuthorized]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent(document.body,
    //
    EVENT_NAMES.MOUSEMOVE, handleOnlineState);
  }, [handleOnlineState, isAuthorized]);
  useEffect(() => {
    if (!isAuthorized || !hasActiveMediaCall) {
      return;
    }
    const timer = setInterval(handleOnlineState, ONLINE_MANAGER_ONLINE_INTERVAL);
    return () => {
      clearTimeout(timer);
    };
  }, [handleOnlineState, hasActiveMediaCall, isAuthorized]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent(window,
    //
    EVENT_NAMES.VISIBILITYCHANGE, () => {
      if (!document.hidden) {
        handleOnlineState();
        return;
      }
      handleInactive();
      throttledHandleOnline.cancel();
    });
  }, [handleInactive, handleOnlineState, isAuthorized, throttledHandleOnline]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent(window,
    //
    EVENT_NAMES.FOCUS, handleOnlineState);
  }, [handleOnlineState, isAuthorized]);
  useEffect(() => {
    if (!isAuthorized) {
      return;
    }
    return addEvent(window,
    //
    EVENT_NAMES.BLUR, () => {
      handleInactive();
      throttledHandleOnline.cancel();
    });
  }, [handleInactive, isAuthorized, throttledHandleOnline]);
  return null;
};