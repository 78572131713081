import { specialistsGetStatistics } from '@services/specialists';
import { currentSpecialistReceiveGetStatistics } from '@slices/current-specialist';
import { CurrentSpecialistFetchGetStatistics } from './types';
export const currentSpecialistFetchGetStatistics: CurrentSpecialistFetchGetStatistics = payload => {
  return async dispatch => {
    const data = await specialistsGetStatistics(payload).promise;
    dispatch(currentSpecialistReceiveGetStatistics({
      statisticPeriodType: data.statisticPeriodType,
      amount: data.amount
    }));
    return data;
  };
};