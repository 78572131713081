import { useEffect, useState } from 'react';
import { UseIntersectionObserver } from './types';
export const useIntersectionObserver: UseIntersectionObserver = ({
  root,
  target,
  callback,
  options
}) => {
  const [observer, setObserver] = useState<IntersectionObserver>();
  useEffect(() => {
    if (!target) {
      return;
    }
    const _observer = new IntersectionObserver(callback,
    //
    {
      ...options,
      root
    });
    _observer.observe(target);
    setObserver(_observer);
    return () => {
      _observer.unobserve(target);
    };
  }, [callback, options, root, target]);
  return {
    observer
  };
};