import { mapValues } from '@helpers/data';
import { SupportReceiveSetMessagesReadReducer } from './types';
export const supportReceiveSetMessagesReadReducer: SupportReceiveSetMessagesReadReducer = state => {
  const {
    messagesById = {}
  } = state;
  return {
    ...state,
    messagesById: mapValues(messagesById,
    //
    message => ({
      ...message,
      isRead: true
    }))
  };
};