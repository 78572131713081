import { ERROR_CODE } from '@config/errors';
import { REGEX } from '@config/regex';
import { EmailIsNotAllowedValidator } from './types';
export const emailIsNotAllowedValidator: EmailIsNotAllowedValidator = (value, options) => {
  if (value == null) {
    return;
  }
  if (value.match(REGEX.CONTAINS_EMAIL)) {
    return options?.message ?? ERROR_CODE.FIELD_IS_INVALID;
  }
};