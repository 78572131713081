import { useEffect, useMemo, useState } from 'react';
import { useIntersectionObserver, UseIntersectionObserverArgs } from '@hooks/intersection-observer';
import { UseVisibility } from './types';

/**
 * @todo Add tests
 */
export const useVisibility: UseVisibility = args => {
  const {
    root,
    target,
    options
  } = args;
  const [isVisible, setIsVisible] = useState(false);
  const useIntersectionObserverArgs: UseIntersectionObserverArgs = useMemo(() => ({
    root,
    target,
    callback: entries => setIsVisible(entries.some(entry => entry.isIntersecting)),
    options
  }), [options, root, target]);
  const {
    observer
  } = useIntersectionObserver(useIntersectionObserverArgs);
  useEffect(() => {
    if (!options?.once || !target || !isVisible) {
      return;
    }
    observer?.unobserve(target);
  }, [isVisible, observer, options?.once, target]);
  return {
    isVisible
  };
};