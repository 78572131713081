import { NOTIFICATION_TYPE } from '@config/notifications';
import { calendarConsultationInitiator } from '@helpers/calendar';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { photoOrAvatarUrl } from '@helpers/member';
import { RealtimeCalendarConsultationRejectedEventPayloadData } from '@services/dto/realtime/realtime-calendar-event/realtime-calendar-consultation-rejected';
import { notificationsUiHandleAdd } from '@actions/notifications-ui/notifications-ui-handle-add';
import { calendarDecreaseConsultationsCounter, realtimeReceiveCalendarConsultationRejected } from '@slices/calendar';
import { accountGetAccountId } from '@selectors/account';
import { RealtimeCalendarConsultationRejected } from './types';
export const realtimeCalendarConsultationRejected: RealtimeCalendarConsultationRejected = event => {
  return (dispatch, getState) => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeCalendarConsultationRejectedEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      consultation
    } = data;
    const currentMemberId = accountGetAccountId(getState());
    const initiator = calendarConsultationInitiator(consultation, currentMemberId);
    if (!initiator) {
      return;
    }
    dispatch(realtimeReceiveCalendarConsultationRejected({
      consultationId: consultation.id,
      memberId: initiator.userId
    }));
    dispatch(notificationsUiHandleAdd({
      type: NOTIFICATION_TYPE.NEW_CALENDAR,
      avatar: photoOrAvatarUrl(initiator.photoUrl, initiator.avatarId, undefined),
      name: initiator.name,
      consultation,
      initiator
    }));
    dispatch(calendarDecreaseConsultationsCounter({
      decrement: 1
    }));
  };
};