import { isVideo } from '@helpers/media-files';
import { toUserAchievements } from '@helpers/member';
import { Documents, MediaFiles, MemberId, PROF_SPECIALITY, Specialist, SpecialistsById, USER_ACHIEVEMENT } from '@types';
import { appCreateSelector, RootState } from '@store';
import { SpecialistsState } from '@slices/specialists';

/**
 * regular selectors
 */

export const specialistsGet = (state: RootState): SpecialistsState => {
  return state.app.specialists;
};

/**
 * memoized reselect selectors
 */

export const specialistsGetSpecialistsById: (state: RootState) => SpecialistsById = appCreateSelector([specialistsGet], specialists => specialists.specialistsById);
export const specialistsGetSpecialist: (state: RootState, specialistId: MemberId) => Specialist = appCreateSelector([specialistsGetSpecialistsById, (_state, specialistId: MemberId) => specialistId], (specialistsById, specialistId) => specialistsById[specialistId]);
export const specialistsGetSpecialistIsCertified: (state: RootState, specialistId: MemberId) => undefined | boolean = appCreateSelector(specialistsGetSpecialist, specialist => specialist?.isCertified);
export const specialistsGetSpecialistProfSpeciality: (state: RootState, specialistId: MemberId) => undefined | PROF_SPECIALITY = appCreateSelector(specialistsGetSpecialist, specialist => specialist?.profSpeciality);
export const specialistsGetSpecialistAchievements: (state: RootState, specialistId: MemberId) => USER_ACHIEVEMENT[] = appCreateSelector([specialistsGetSpecialistIsCertified, specialistsGetSpecialistProfSpeciality], (isCertified, profSpeciality) => {
  return toUserAchievements(isCertified, profSpeciality);
});
export const specialistsGetSpecialistMethodicDescription: (state: RootState, specialistId: MemberId) => undefined | string = appCreateSelector(specialistsGetSpecialist, specialist => specialist?.methodicDescription);
export const specialistsGetSpecialistDocuments: (state: RootState, specialistId: MemberId) => undefined | Documents = appCreateSelector(specialistsGetSpecialist, specialist => specialist?.documents);
export const specialistsGetSpecialistMedia: (state: RootState, specialistId: MemberId) => undefined | MediaFiles = appCreateSelector(specialistsGetSpecialist, specialist => specialist?.media);
export const specialistsGetSpecialistHasVideo: (state: RootState, specialistId: MemberId) => undefined | boolean = appCreateSelector(specialistsGetSpecialistMedia, media => media?.some(isVideo));