import { omit, reject } from '@helpers/data';
import { MessagesRemoveBrokenMessageReducer } from './types';
export const messagesRemoveBrokenMessageReducer: MessagesRemoveBrokenMessageReducer = (state, action) => {
  const {
    id,
    chatId
  } = action.payload;
  const {
    byChatId
  } = state;
  const messagesByChatId = byChatId[chatId] ?? {};
  const {
    messagesIds = [],
    messagesById = {}
  } = messagesByChatId;
  return {
    ...state,
    byChatId: {
      ...byChatId,
      [chatId]: {
        ...messagesByChatId,
        messagesById: omit(messagesById, id),
        messagesIds: reject(messagesIds, id)
      }
    }
  };
};