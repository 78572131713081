import { camelKeysRecursive, readJson } from '@helpers/data';
import { isClient, isSpecialist } from '@helpers/member';
import { priceFromDto } from '@helpers/payments';
import { RealtimeBalanceChangedEventPayloadData } from '@services/dto/realtime/realtime-balance-event/realtime-balance-changed-event';
import { STATISTICS_PERIOD_TYPE } from '@types';
import { currentClientSetBalance } from '@slices/current-client';
import { currentSpecialistReceiveGetStatistics, currentSpecialistSetBalance } from '@slices/current-specialist';
import { RealtimeBalanceChanged } from './types';
export const realtimeBalanceChanged: RealtimeBalanceChanged = event => {
  return dispatch => {
    const data = readJson<RealtimeBalanceChangedEventPayloadData>(event.payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      amount,
      roleType,
      specialistCoinsStatistics
    } = data;
    if (isClient(roleType)) {
      dispatch(currentClientSetBalance({
        coinAmount: amount
      }));
      return;
    }
    if (isSpecialist(roleType)) {
      const {
        todayAmount,
        currentMonthAmount
      } = specialistCoinsStatistics;
      dispatch(currentSpecialistSetBalance({
        amount: priceFromDto(amount)
      }));
      dispatch(currentSpecialistReceiveGetStatistics({
        amount: todayAmount,
        statisticPeriodType: STATISTICS_PERIOD_TYPE.TODAY
      }));
      dispatch(currentSpecialistReceiveGetStatistics({
        amount: currentMonthAmount,
        statisticPeriodType: STATISTICS_PERIOD_TYPE.CURRENT_MONTH
      }));
      return;
    }
  };
};