import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { RealtimeGetQueueUpdatesRequestData, RealtimeGetQueueUpdatesRequestParams, RealtimeGetQueueUpdatesResponseData, RealtimeGetQueueUpdatesResponseDto } from '@services/dto/realtime/realtime-get-queue-updates';
import { realtimeGetQueueUpdatesRequestToParams, realtimeGetQueueUpdatesResponseFromDto } from '@mapping/realtime/realtime-get-queue-updates';
type RealtimeGetQueueUpdates = (requestData: RealtimeGetQueueUpdatesRequestData) => AuthedJsonFetchResult<RealtimeGetQueueUpdatesResponseData>;
export const realtimeGetQueueUpdates: RealtimeGetQueueUpdates = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<RealtimeGetQueueUpdatesResponseDto, undefined, RealtimeGetQueueUpdatesRequestParams>(ENDPOINTS.realtime.getQueueUpdates(),
  //
  {
    method: 'GET',
    params: realtimeGetQueueUpdatesRequestToParams(requestData)
  });
  return {
    promise: promise.then(realtimeGetQueueUpdatesResponseFromDto),
    cancel
  };
};