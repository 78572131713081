import { ClientsReceiveSendComplaintReducer } from './types';
export const clientsReceiveSendComplaintReducer: ClientsReceiveSendComplaintReducer = (state, action) => {
  const {
    clientId
  } = action.payload;
  return {
    ...state,
    clientsById: {
      ...state.clientsById,
      [clientId]: {
        ...state.clientsById[clientId],
        hasComplaint: true
      }
    }
  };
};