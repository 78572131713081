import { camelKeysRecursive, readJson } from '@helpers/data';
import { convertIfUnsupportedExtension } from '@helpers/files';
import { currentSpecialistReceiveUploadVideoComplete } from '@slices/current-specialist';
import { CurrentSpecialistReceiveUploadVideoCompletePayload } from '@slices/current-specialist/current-specialist-receive-upload-video-complete';
import { RealtimeVideoUploadComplete } from './types';
export const realtimeVideoUpload: RealtimeVideoUploadComplete = event => {
  return dispatch => {
    const data = readJson<CurrentSpecialistReceiveUploadVideoCompletePayload>(event.payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      videoId,
      videoPublicId,
      url
    } = data;
    dispatch(currentSpecialistReceiveUploadVideoComplete({
      videoId,
      videoPublicId,
      url: convertIfUnsupportedExtension(url)
    }));
  };
};