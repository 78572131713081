import { Dexie } from 'dexie';
import { LocalMessage } from '@types';
import { IDb, IDbConstructor, IDbConstructorArgs } from './types';
export const Db: IDbConstructor = class AppDatabase extends Dexie implements IDb {
  public messages!: Dexie.Table<LocalMessage>;
  constructor(args: IDbConstructorArgs) {
    const {
      name,
      options
    } = args;
    super(name, options);
    this.version(1).stores({
      messages: `
        ++id,
        chatId,
        isRead,
        creationTime,
        senderUserId,
        type,
        text,
        attachment,
        gift,
        _status
      `
    });
  }
};