import { omit } from '@helpers/data';
import { ChatsRemoveFromListReducer } from './types';
export const chatsRemoveFromListReducer: ChatsRemoveFromListReducer = (state, action) => {
  const {
    chatId
  } = action.payload;
  const {
    chatsById,
    chatsIds
  } = state;
  const existingChat = chatsById[chatId];
  if (!existingChat) {
    return state;
  }
  return {
    ...state,
    chatsById: omit(chatsById, chatId),
    chatsIds: chatsIds.filter(id => id !== chatId)
  };
};