import { isClient, isSpecialist } from '@helpers/member';
import { clientsGetMe } from '@services/clients';
import { specialistsGetMe } from '@services/specialists';
import { currentClientReceiveGet, currentClientSetFetched } from '@slices/current-client';
import { currentSpecialistReceiveGet, currentSpecialistSetFetchedMe } from '@slices/current-specialist';
import { currentMemberGetRoleType } from '@selectors/current-member';
import { CurrentMemberFetchGetMe } from './types';
export const currentMemberFetchGetMe: CurrentMemberFetchGetMe = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const roleType = currentMemberGetRoleType(state);
    let data;
    if (isClient(roleType)) {
      try {
        data = await clientsGetMe().promise;
        dispatch(currentClientReceiveGet({
          client: data
        }));
      } finally {
        dispatch(currentClientSetFetched());
      }
    }
    if (isSpecialist(roleType)) {
      try {
        data = await specialistsGetMe().promise;
        dispatch(currentSpecialistReceiveGet({
          specialist: data
        }));
      } finally {
        dispatch(currentSpecialistSetFetchedMe());
      }
    }
    return data;
  };
};