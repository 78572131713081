import { ENDPOINTS } from '@config/endpoints';
import { authedHttpFetch, AuthedHttpFetchResult } from '@services/base/authed-http-fetch';
import { AccountLogoffMeRequestData, AccountLogoffMeResponseData, AccountLogoffMeResponseDto } from '@services/dto/account/account-logoff';
type AccountLogoffMe = (requestData: AccountLogoffMeRequestData) => AuthedHttpFetchResult<AccountLogoffMeResponseData>;
export const accountLogoffMe: AccountLogoffMe = requestData => {
  const {
    refreshToken
  } = requestData;
  return authedHttpFetch<AccountLogoffMeResponseDto>(ENDPOINTS.account.logoff(refreshToken),
  //
  {
    method: 'DELETE'
  });
};