import { first } from '@helpers/data';
import { isSupportT1Message, isSupportT5Message } from '@helpers/support';
import { ONLINE_STATUS, SupportMessage, SupportMessageId, SupportMessageIds, SupportMessagesById } from '@types';
import { appCreateSelector, RootState } from '@store';
import { SupportState } from '@slices/support';

/**
 * regular selectors
 */

export const supportGet = (state: RootState): SupportState => {
  return state.app.support;
};

/**
 * memoized reselect selectors
 */

export const supportGetMessageIds: (state: RootState) => undefined | SupportMessageIds = appCreateSelector([supportGet], support => support.messageIds);
export const supportGetMessagesById: (state: RootState) => undefined | SupportMessagesById = appCreateSelector([supportGet], support => support.messagesById);
export const supportGetMessageListHasMore: (state: RootState) => boolean = appCreateSelector([supportGet],
//
support => support.hasMore);
export const supportGetMessageListLimit: (state: RootState) => number = appCreateSelector([supportGet],
//
support => support.limit);
export const supportGetMessageListOffset: (state: RootState) => number = appCreateSelector([supportGet],
//
support => support.offset);
export const supportGetMessageListTotal: (state: RootState) => number = appCreateSelector([supportGet],
//
support => support.total);
export const supportGetMessageListDataLoaded: (state: RootState) => boolean = appCreateSelector([supportGet],
//
support => support.dataLoaded);
export const supportGetHasMessages: (state: RootState) => undefined | boolean = appCreateSelector([supportGetMessageIds], messageIds => {
  if (!messageIds) {
    return undefined;
  }
  return Boolean(messageIds);
});
export const supportGetMessageById: (state: RootState, messageId: SupportMessageId) => undefined | SupportMessage = appCreateSelector([supportGetMessagesById, (_state, messageId: SupportMessageId) => messageId], (messagesById, messageId) => {
  if (!messagesById) {
    return;
  }
  return messagesById[messageId];
});
export const supportGetLastMessageId: (state: RootState) => undefined | SupportMessageId = appCreateSelector([supportGetMessageIds], messageIds => {
  if (!messageIds) {
    return;
  }
  return first(messageIds);
});
export const supportGetLastMessage: (state: RootState) => undefined | SupportMessage = appCreateSelector([supportGetLastMessageId, (state: RootState) => state], (lastMessageId, state) => {
  if (!lastMessageId) {
    return;
  }
  return supportGetMessageById(state, lastMessageId);
});
export const supportGetLastMessageCreationTime: (state: RootState) => undefined | string = appCreateSelector([supportGetLastMessage], lastMessage => lastMessage?.creationTime);
export const supportGetLastMessageText: (state: RootState) => undefined | string = appCreateSelector([supportGetLastMessage], lastMessage => {
  if (!lastMessage) {
    return;
  }
  if (isSupportT1Message(lastMessage)) {
    return `🤗 Привет, и добро пожаловать на “Фрейдерс”!`;
  }
  if (isSupportT5Message(lastMessage)) {
    return `🤔 Зачем тратить время на поиски?`;
  }
});
export const supportGetLasMessageIsRead: (state: RootState) => undefined | boolean = appCreateSelector([supportGetLastMessage], lastMessage => lastMessage?.isRead);
export const supportGetUnreadMessagesCount: (state: RootState) => undefined | number = appCreateSelector([supportGet], support => support.unreadMessagesCount);
export const supportGetHasUnreadMessages: (state: RootState) => undefined | boolean = appCreateSelector([supportGetUnreadMessagesCount], unreadMessagesCount => {
  if (unreadMessagesCount == null) {
    return;
  }
  return Boolean(unreadMessagesCount);
});

/**
 * hardcoded "selectors" according to [FR-074]
 */

export const supportGetOnlineStatus = (): ONLINE_STATUS => {
  /**
   * Must be online according to [FR-074]
   */
  return ONLINE_STATUS.ONLINE;
};
export const supportGetChatImageUrl = (): string => {
  /**
   * Must be hardcoded according to [FR-074]
   */
  return '/images/support/support-avatar.png';
};
export const supportGetChatMateUserName = (): string => {
  /**
   * Must be hardcoded according to [FR-074]
   */
  return 'Наша команда';
};