import { GEOMETRY_TYPE } from '@config/geometry';
import { ANALYTICS_ACTION } from '@config/logging';
import { generateUuid } from '@helpers/data';
import { getOrigin } from '@helpers/url';
import { INav } from '@routing/nav';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsVisitEvent } from '@logging/logging-events';
import { ILocalStorage } from '@storage';
import { readVisitId, writeVisitId } from '@storage/analytics';
import { AnalyticsSendVisitRequestData } from '@services/dto/analytics/analytics-send-visit';
import { defineAnalyticsClientTypeByGeometryType } from './common';
export const isVisitEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsVisitEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.VISIT;
};
export const sendVisit = (eventBus: IEventBus, data: AnalyticsSendVisitRequestData): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsSendVisitRequestData>({
    action: ANALYTICS_ACTION.VISIT,
    data
  }));
};
export const computeAuthVisitData = (args: {
  storage: ILocalStorage;
  navigator: INav;
  geometryType: GEOMETRY_TYPE;
}): AnalyticsSendVisitRequestData => {
  const {
    storage,
    geometryType,
    navigator
  } = args;
  let visitId = readVisitId(storage);
  if (!visitId) {
    visitId = generateUuid();
    writeVisitId(storage, visitId);
  }
  return {
    visitId,
    userAgent: window.navigator.userAgent,
    url: `${getOrigin()}${navigator.getUrl()}`,
    clientType: defineAnalyticsClientTypeByGeometryType(geometryType)
  };
};