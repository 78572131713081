module.exports.groupHoverVariant = ({ addVariant, e, postcss }) => {
  addVariant('group-hover', ({ container, separator }) => {
    const hoverRule = postcss.atRule({
      name: 'media',
      params: '(hover: hover)',
    });
    hoverRule.append(container.nodes);
    container.append(hoverRule);
    hoverRule.walkRules((rule) => {
      rule.selector = `.group:hover .${e(
        `group-hover${separator}${rule.selector.slice(1)}`
      )}`;
    });
  });
};
