import { appCreateSlice } from '@store/rtk';
import { messagesAddBrokenMessageReducer } from '@slices/messages/messages-add-broken-message';
import { messagesAddMessageReducer } from '@slices/messages/messages-add-message';
import { messagesAddTemporaryMessageReducer } from '@slices/messages/messages-add-temporary-message';
import { messagesApproveConsultationReducer } from '@slices/messages/messages-approve-consultation';
import { messagesClearSelectedChatReducer } from '@slices/messages/messages-clear-selected-chat';
import { messagesReceiveAddMessageReducer } from '@slices/messages/messages-receive-add-message';
import { messagesReceiveGetListReducer } from '@slices/messages/messages-receive-get-list';
import { messagesRemoveBrokenMessageReducer } from '@slices/messages/messages-remove-broken-message';
import { messagesSetReadByChatIdReducer } from '@slices/messages/messages-set-read-by-chat-id';
import { messagesSetSelectedChatReducer } from '@slices/messages/messages-set-selected-chat';
import { MessagesState } from './types';
export const messagesDefaultState: MessagesState = {
  byChatId: {},
  selectedChatId: undefined
};
const messagesSlice = appCreateSlice({
  name: 'messages',
  initialState: messagesDefaultState,
  reducers: {
    messagesAddBrokenMessage: messagesAddBrokenMessageReducer,
    messagesAddMessage: messagesAddMessageReducer,
    messagesAddTemporaryMessage: messagesAddTemporaryMessageReducer,
    messagesClearSelectedChat: messagesClearSelectedChatReducer,
    messagesReceiveAddMessage: messagesReceiveAddMessageReducer,
    messagesReceiveGetList: messagesReceiveGetListReducer,
    messagesRemoveBrokenMessage: messagesRemoveBrokenMessageReducer,
    messagesSetReadByChatId: messagesSetReadByChatIdReducer,
    messagesSetSelectedChat: messagesSetSelectedChatReducer,
    messagesApproveConsultation: messagesApproveConsultationReducer
  }
});
export const {
  actions: {
    messagesAddBrokenMessage,
    messagesAddMessage,
    messagesAddTemporaryMessage,
    messagesClearSelectedChat,
    messagesReceiveAddMessage,
    messagesReceiveGetList,
    messagesRemoveBrokenMessage,
    messagesSetReadByChatId,
    messagesSetSelectedChat,
    messagesApproveConsultation
  },
  reducer: messagesReducer
} = messagesSlice;