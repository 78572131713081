import { enforceDateStringToISO } from '@helpers/date';
import { SpecialistsGetTopRequestData, SpecialistsGetTopRequestParams, SpecialistsGetTopResponseData, SpecialistsGetTopResponseDto } from '@services/dto/specialists';
export const specialistsGetTopRequestToParams = (data: SpecialistsGetTopRequestData): SpecialistsGetTopRequestParams => {
  return {
    limit: data.limit,
    offset: data.offset
  };
};
export const specialistsGetTopResponseFromDto = (dto: SpecialistsGetTopResponseDto): SpecialistsGetTopResponseData => {
  return {
    ...dto.data,
    items: dto.data.items.map(item => {
      return {
        ...item,
        lastActivityTime: item.lastActivityTime ? enforceDateStringToISO(item.lastActivityTime) : undefined
      };
    })
  };
};