import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { ClientsPostMeRequestData, ClientsPostMeRequestDto, ClientsPostMeResponseData, ClientsPostMeResponseDto } from '@services/dto/clients';
import { clientsPostMeRequestToDto, clientsPostMeResponseFromDto } from '@mapping/clients';
type PostClientsMe = (requestData: ClientsPostMeRequestData) => AuthedJsonFetchResult<ClientsPostMeResponseData>;
export const clientsUpdateMe: PostClientsMe = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<ClientsPostMeResponseDto, ClientsPostMeRequestDto>(ENDPOINTS.clients.me(), {
    method: 'POST',
    data: clientsPostMeRequestToDto(requestData)
  });
  return {
    promise: promise.then(clientsPostMeResponseFromDto),
    cancel
  };
};