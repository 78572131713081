import { POPUP } from '@config/popups';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { isMediaCallSupported, isRejectedCall } from '@helpers/media-call';
import { RealtimeChatIncomingMediaCallReceivedEventPayloadData } from '@services/dto/realtime/realtime-chat-event/realtime-chat-incoming-video-call-received';
import { MEDIA_CALL_DIRECTION, MEDIA_CALL_TYPE, SEX } from '@types';
import { addPopup } from '@actions/popups/add-popup';
import { removePopupByProps } from '@actions/popups/remove-popup-by-props';
import { mediaCallReceiveCreate } from '@slices/media-call';
import { mediaCallsGetCallByChatId } from '@selectors/media-call';
import { popupsGetListByIds } from '@selectors/popups';
import { MediaCallPopupProps } from '@managers/media-call/types';
import { MediaCallBrowserIsNotSupportedAlertProps } from '@popups/modals/media-call/media-call-browser-is-not-supported';
import { MediaCallOutgoingModalProps } from '@popups/modals/media-call/media-call-outgoing';
import { RealtimeChatIncomingMediaCallReceived } from './types';
export const realtimeChatIncomingMediaCallReceived: RealtimeChatIncomingMediaCallReceived = event => {
  return async (dispatch, getState) => {
    const data = readJson<RealtimeChatIncomingMediaCallReceivedEventPayloadData>(event.payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      chat,
      payload,
      callType
    } = data;
    const {
      chatId,
      chatMateUserId,
      chatMateUserName,
      avatarId,
      photoUrl,
      sex
    } = chat;
    if (!isMediaCallSupported) {
      dispatch(addPopup<MediaCallBrowserIsNotSupportedAlertProps>({
        id: POPUP.MEDIA_CALL_BROWSER_IS_NOT_SUPPORTED_ALERT
      }));
      return;
    }
    const state = getState();
    const mediaCallOutgoingPopups = popupsGetListByIds(state,
    //
    [POPUP.MEDIA_CALL_OUTGOING]);
    const currentMediaCallPopup = mediaCallOutgoingPopups.find(popup => (popup.props as MediaCallPopupProps).chatId === chatId);
    const currentMediaCall = mediaCallsGetCallByChatId(state, chatId);
    const hasRejectedCall = isRejectedCall(currentMediaCall);
    if (hasRejectedCall && currentMediaCallPopup) {
      dispatch(removePopupByProps<MediaCallOutgoingModalProps>({
        id: currentMediaCallPopup.id,
        props: {
          chatId
        }
      }));
    }
    const {
      token,
      roomSid,
      roomName
    } = payload;
    const callParams = {
      token,
      roomId: roomSid,
      roomName,
      chatId,
      chatMateUserId: chatMateUserId,
      chatMateUserName: chatMateUserName,
      chatMateAvatarId: avatarId,
      chatMatePhotoUrl: photoUrl,
      sex: sex ?? SEX.MALE,
      direction: MEDIA_CALL_DIRECTION.INCOMING,
      type: callType ?? MEDIA_CALL_TYPE.AUDIO
    };
    await dispatch(mediaCallReceiveCreate(callParams));
  };
};