import { uniq } from '@helpers/data';
import { SupportAddMessageReducer } from './types';
export const supportAddMessageReducer: SupportAddMessageReducer = (state, action) => {
  const {
    message
  } = action.payload;
  const {
    messageIds = [],
    messagesById = {}
  } = state;
  return {
    ...state,
    messagesById: {
      ...messagesById,
      [message.id]: message
    },
    messageIds: uniq([message.id, ...messageIds])
  };
};