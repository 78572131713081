import { REALTIME_STATUS } from '@types';
import { appCreateSelector, RootState } from '@store';
import { RealtimeState } from '@slices/realtime';

/**
 * regular reducers
 */

export const realtimeGet = (state: RootState): RealtimeState => {
  return state.app.realtime;
};

/**
 * memoized reselect reducers
 */

export const realtimeGetStatus: (state: RootState) => REALTIME_STATUS = appCreateSelector([realtimeGet], realtime => realtime.status);