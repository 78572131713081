import { MediaCallsById } from '@slices/media-call';
import { MediaCallAcceptedReducer } from './types';
export const mediaCallAcceptedReducer: MediaCallAcceptedReducer = (state, action) => {
  const {
    chatId,
    startTime
  } = action.payload;
  const currentMediaCall = state.mediaCallsById[chatId];
  const mediaCallsById: MediaCallsById = {
    ...state.mediaCallsById,
    [chatId]: {
      ...currentMediaCall,
      startTime
    }
  };
  return {
    ...state,
    mediaCallsById
  };
};