import { appCreateSlice } from '@store/rtk';
import { notificationsUiAddReducer } from '@slices/notifications-ui/notifications-ui-add';
import { notificationsUiRemoveReducer } from '@slices/notifications-ui/notifications-ui-remove';
import { NotificationsUiState } from './types';
export const notificationsUiDefaultState: NotificationsUiState = {
  ids: [],
  byId: {}
};
const notificationsUiSlice = appCreateSlice({
  name: 'notificationsUi',
  initialState: notificationsUiDefaultState,
  reducers: {
    notificationsUiAdd: notificationsUiAddReducer,
    notificationsUiRemove: notificationsUiRemoveReducer
  }
});
export const {
  actions: {
    notificationsUiAdd,
    notificationsUiRemove
  },
  reducer: notificationsUiReducer
} = notificationsUiSlice;