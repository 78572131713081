import { CurrentSpecialistReceiveUploadPhotoReducer } from './types';
export const currentSpecialistReceiveUploadPhotoReducer: CurrentSpecialistReceiveUploadPhotoReducer = (state, action) => {
  if (!state.me) {
    return state;
  }
  const {
    data
  } = action.payload;
  return {
    ...state,
    me: {
      ...state.me,
      media: [...state.me.media, data],
      state: data.profileState
    }
  };
};