import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { AccountGetTimezoneResponseData, AccountGetTimezoneResponseDto } from '@services/dto/account/account-get-timezone';
import { accountGetTimezoneResponseFromDto } from '@mapping/account/account-get-timezone';
type AccountGetTimezone = () => AuthedJsonFetchResult<AccountGetTimezoneResponseData>;
export const accountGetTimezone: AccountGetTimezone = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<AccountGetTimezoneResponseDto>(ENDPOINTS.account.getTimezone(),
  //
  {
    method: 'GET'
  });
  return {
    promise: promise.then(accountGetTimezoneResponseFromDto),
    cancel
  };
};