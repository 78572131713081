import { rejectById } from '@helpers/data';
import { CurrentClientReceiveDeletePhotoReducer } from './types';
export const currentClientReceiveDeletePhotoReducer: CurrentClientReceiveDeletePhotoReducer = (state, action) => {
  if (!state.me) {
    return state;
  }
  const {
    data
  } = action.payload;
  const {
    id,
    defaultPhotoId,
    avatarId,
    profileState
  } = data;
  const media = rejectById(state.me.media, id);
  return {
    ...state,
    me: {
      ...state.me,
      defaultPhotoId,
      media,
      avatarId,
      state: profileState
    }
  };
};