import { calendarIsCurrentClient, calendarIsCurrentClientCalendar, calendarIsCurrentSpecialist, calendarIsCurrentSpecialistCalendar } from '@helpers/calendar';
import { calendarSetConsultationsState } from '@mapping/calendar/calendar-set-consultations-state';
import { CALENDAR_CONSULTATION_STATE } from '@types';
import { CalendarReceiveApproveCurrentMemberConsultationReducer } from './types';
export const calendarReceiveApproveCurrentMemberConsultationReducer: CalendarReceiveApproveCurrentMemberConsultationReducer = (state, action) => {
  const {
    consultationId
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentSpecialist(currentMember) && calendarIsCurrentSpecialistCalendar(currentMember.calendar)) {
    const currentMemberCalendar = currentMember.calendar;
    const consultations = calendarSetConsultationsState(currentMemberCalendar.consultations, consultationId, CALENDAR_CONSULTATION_STATE.APPROVED);
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...currentMemberCalendar,
          consultations
        }
      }
    };
  }
  if (calendarIsCurrentClient(currentMember) && calendarIsCurrentClientCalendar(currentMember.calendar)) {
    const currentMemberCalendar = currentMember.calendar;
    const consultations = calendarSetConsultationsState(currentMemberCalendar.consultations, consultationId, CALENDAR_CONSULTATION_STATE.APPROVED);
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...currentMemberCalendar,
          consultations
        }
      }
    };
  }
  return state;
};