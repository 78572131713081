import { isEqualCaseIncensitive, rejectBy } from '@helpers/data';
import { Popup } from '@types';
import { PopupsState } from '@slices/popups';
import { RemovePopupByProps } from './types';
export const removePopupByPropsReducer = <P,>(state: Popup<P>[], action: RemovePopupByProps<P>): PopupsState => {
  const {
    id,
    props
  } = action.payload;
  return rejectBy(state, (popup: Popup<P>) => {
    return isEqualCaseIncensitive(popup.id, id) && Object.entries(props ?? {}).every(entry => {
      const [key, value] = entry;
      return popup?.props?.[(key as keyof P)] === value;
    });
  });
};