import { ENDPOINTS } from '@config/endpoints';
import { HTTP_CONTENT_TYPE, HTTP_HEADER_NAME } from '@config/net';
import { encodeQuery } from '@helpers/routing';
import { authedJsonFetch } from '@services/base/authed-json-fetch';
import { JsonFetchResult } from '@services/base/json-fetch';
import { AnalyticsSupportSendEventRequestData, AnalyticsSupportSendEventResponseData, AnalyticsSupportSendEventResponseDto } from '@services/dto/analytics/analytics-support-send-event';
import { analyticsSupportSendEventRequestToDto } from '@mapping/analytics/analytics-support-send-event';
type AnalyticsSupportSendEvent = (requestData: AnalyticsSupportSendEventRequestData) => JsonFetchResult<AnalyticsSupportSendEventResponseData>;
const headers = {
  [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE.X_WWW_FORM_URLENCODED
};
type AnalyticsSupportSendEventHeaders = {
  [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE;
};
export const analyticsSupportSendEvent: AnalyticsSupportSendEvent = requestData => {
  return authedJsonFetch<AnalyticsSupportSendEventResponseDto, string, undefined, AnalyticsSupportSendEventHeaders>(ENDPOINTS.analytics.supportSendEvent(requestData.eventType), {
    method: 'POST',
    data: encodeQuery(analyticsSupportSendEventRequestToDto(requestData)),
    headers
  });
};