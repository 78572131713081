import { keyBy, uniq } from '@helpers/data';
import { TopSpecialistsReceiveGetListReducer } from './types';
export const topSpecialistsReceiveGetListReducer: TopSpecialistsReceiveGetListReducer = (state, action) => {
  const {
    specialistList
  } = action.payload;
  const {
    limit,
    offset,
    items,
    totalCount,
    keepResults
  } = specialistList;
  const {
    listItemIds,
    listItemsById
  } = state;
  const nextListItemsById = {
    ...(keepResults ? listItemsById : undefined),
    ...keyBy(items, item => item.userId)
  };
  const nextListItemIds = uniq([...(keepResults ? listItemIds : []), ...items.map(item => item.userId)]);
  return {
    ...state,
    listItemsById: nextListItemsById,
    listItemIds: nextListItemIds,
    hasMore: offset + limit < totalCount,
    limit,
    offset,
    totalCount
  };
};