import { uniq } from '@helpers/data';
import { ViewsAddViewReducer } from './types';
export const viewsAddViewReducer: ViewsAddViewReducer = (state, action) => {
  const {
    view
  } = action.payload;
  const {
    viewsById,
    viewsIds,
    totalCount
  } = state;
  return {
    ...state,
    viewsById: {
      ...viewsById,
      [view.userId]: view
    },
    viewsIds: uniq([view.userId, ...viewsIds]),
    totalCount: !totalCount //
    ? totalCount + 1 : totalCount
  };
};