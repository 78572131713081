import { FB_PIXEL_ID } from '@config/env';
import { SEVERITY } from '@config/logging';
import { isFillProfileEvent } from '@helpers/logging/analytics/fill-profile-event';
import { isPageViewEvent } from '@helpers/logging/analytics/page-view-event';

// import { isServer } from '@helpers/misc';
import { IAnalyticsEvent } from '@logging/logging-events/analytics-event';

// import { initFBQScript } from './fbq-scripts.js';
import { IFBQEventHandler } from './types';
export class FBQEventHandler<D> implements IFBQEventHandler<D> {
  private trackingId = FB_PIXEL_ID;
  public severities = [SEVERITY.ANALYTICS];
  public constructor() {
    if (!this.trackingId) {
      console.warn("FBQ wasn't initialized");
      return;
    }

    // if (isServer()) {
    //   return;
    // }

    /**
     * Temporarily disabled initialization of script.
     * It was replaced by the inlined one in _document.tsx.
     */
    // initFBQScript(this.trackingId);
  }

  public handle<D>(event: IAnalyticsEvent<D>): void {
    if (!window.fbq || !this.trackingId) {
      return;
    }
    if (isPageViewEvent(event)) {
      window.fbq('track', 'PageView');
    }
    if (isFillProfileEvent(event)) {
      window.fbq('track', 'Lead');
    }
  }
}