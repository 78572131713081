import { AppFCC } from '@types';
import { BUTTON_BASE_COLORED_COLOR } from '@kit/buttons/button-base-colored';
import { AlertDialogBodyStyled, AlertDialogButtonStyled, AlertDialogCloseButtonIconStyled, AlertDialogCloseButtonStyled, AlertDialogStyled, AlertDialogTextStyled } from './styled';
import { AlertDialogProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AlertDialog: AppFCC<AlertDialogProps> = props => {
  const {
    className,
    textClassName,
    text,
    submitButtonClassName,
    submitButtonColor,
    submitButtonText,
    submitButtonOnClick,
    closeButtonOnClick,
    disabled
  } = props;
  return _jsxs(AlertDialogStyled, {
    className: className,
    children: [_jsx(AlertDialogCloseButtonStyled, {
      disabled: disabled,
      onClick: closeButtonOnClick,
      children: _jsx(AlertDialogCloseButtonIconStyled, {})
    }), _jsxs(AlertDialogBodyStyled, {
      children: [_jsx(AlertDialogTextStyled, {
        className: textClassName,
        children: text
      }), !submitButtonText ? null : _jsx(AlertDialogButtonStyled, {
        className: submitButtonClassName,
        color: submitButtonColor ?? BUTTON_BASE_COLORED_COLOR.BLUE_BORDERS,
        onClick: submitButtonOnClick
        // eslint-disable-next-line jsx-a11y/no-autofocus
        ,
        autoFocus: true,
        disabled: disabled,
        children: submitButtonText
      })]
    })]
  });
};