import { findById } from '@helpers/data';
import { LoadingScreenShowReducer } from './types';
export const loadingScreenShowReducer: LoadingScreenShowReducer = (state, action) => {
  const {
    id,
    props
  } = action.payload;
  return {
    ...state,
    screens: findById(state.screens, id) ? state.screens : [...state.screens, {
      id,
      props
    }]
  };
};