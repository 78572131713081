import { ERROR_CODE } from '@config/errors';
import { POPUP } from '@config/popups';
import { NAVIGATION_MODE } from '@config/routing';
import { findByCode, findByCodes } from '@helpers/data';
import { initializeNavigator } from '@routing';
import { ROUTES } from '@routing/routes';
import { addPopup } from '@actions/popups/add-popup';
import { chatsRemoveFromList } from '@slices/chats';
import { AccountBannedAlertProps } from '@popups/modals/account/account-banned-alert';
import { AccountDeletedCanBeRestoredAlertProps } from '@popups/modals/account/account-deleted-can-be-restored';
import { AccountDeletedCanNotBeRestoredAlertProps } from '@popups/modals/account/account-deleted-can-not-be-restored';
import { AccountEmailDoesNotExistAlertProps } from '@popups/modals/account/account-email-does-not-exist';
import { AccountExternalAuthAlertProps } from '@popups/modals/account/account-external-auth-error';
import { AccountLockedAlertProps } from '@popups/modals/account/account-locked-alert';
import { AccountLockedByManyComplaintsAlertProps } from '@popups/modals/account/account-locked-by-many-complaints-alert';
import { AccountMemberBlockedAlertProps } from '@popups/modals/account/account-member-blocked';
import { AccountMemberDeletedAlertProps } from '@popups/modals/account/account-member-deleted';
import { AccountRoleRequiredModalProps } from '@popups/modals/account/account-role-required';
import { AlertDialogProps } from '@kit/popups/alert-dialog';
import { AccountHandleErrorBodyErrors } from './types';

/**
 * @todo
 * Dispatch accountHandleError inside fetch actions that require such
 * error handling instead of dispatching accountHandleErrorBodyErrors inside
 * components.
 */
export const accountHandleErrorBodyErrors: AccountHandleErrorBodyErrors = ({
  errors,
  provider,
  email,
  password,
  code,
  chatId
}) => dispatch => {
  const authError = findByCode(errors, ERROR_CODE.EXTERNAL_SIGN_IN_FAILED);
  if (authError) {
    dispatch(addPopup<AccountExternalAuthAlertProps>({
      id: POPUP.ACCOUNT_EXTERNAL_AUTH_ERROR,
      props: {
        text: authError.message
      }
    }));
    return;
  }
  if (findByCode(errors, ERROR_CODE.ACCOUNT_DOES_NOT_EXIST_ROLE_REQUIRED)) {
    if (!provider) {
      return;
    }
    dispatch(addPopup<AccountRoleRequiredModalProps>({
      id: POPUP.ACCOUNT_ROLE_REQUIRED,
      props: {
        provider
      }
    }));
    return;
  }
  if (findByCode(errors, ERROR_CODE.EMAIL_ADDRESS_DOES_NOT_EXISTS)) {
    if (!email) {
      return;
    }
    dispatch(addPopup<AccountEmailDoesNotExistAlertProps>({
      id: POPUP.ACCOUNT_EMAIL_DOES_NOT_EXIST,
      props: {
        email
      }
    }));
    return;
  }
  if (findByCode(errors, ERROR_CODE.USER_IP_BANNED)) {
    dispatch(addPopup<AccountBannedAlertProps>({
      id: POPUP.ACCOUNT_BANNED
    }));
    return;
  }
  const accountDeletedCanBeRestoredError = findByCode(errors, ERROR_CODE.ACCOUNT_DELETED_CAN_BE_RESTORED);
  if (accountDeletedCanBeRestoredError) {
    dispatch(addPopup<AccountDeletedCanBeRestoredAlertProps>({
      id: POPUP.ACCOUNT_DELETED_CAN_BE_RESTORED,
      props: {
        provider,
        userName: email,
        password,
        code: accountDeletedCanBeRestoredError.data || code
      }
    }));
    return;
  }
  if (findByCode(errors, ERROR_CODE.ACCOUNT_DELETED_CAN_NOT_BE_RESTORED)) {
    dispatch(addPopup<AccountDeletedCanNotBeRestoredAlertProps>({
      id: POPUP.ACCOUNT_DELETED_CAN_NOT_BE_RESTORED
    }));
    return;
  }
  if (findByCode(errors, ERROR_CODE.ACCOUNT_HAS_BEEN_LOCKED_BY_MANY_COMPLAINTS)) {
    dispatch(addPopup<AccountLockedByManyComplaintsAlertProps>({
      id: POPUP.ACCOUNT_LOCKED_BY_MANY_COMPLAINTS
    }));
    return;
  }
  if (findByCode(errors, ERROR_CODE.ACCOUNT_CURRENT_MEMBER_BLOCKED)) {
    dispatch(addPopup<AccountLockedAlertProps>({
      id: POPUP.ACCOUNT_LOCKED
    }));
    return;
  }
  if (findByCode(errors, ERROR_CODE.ACCOUNT_MEMBER_BLOCKED)) {
    dispatch(addPopup<AccountMemberBlockedAlertProps>({
      id: POPUP.ACCOUNT_MEMBER_BLOCKED
    }));
    return;
  }
  if (findByCode(errors, ERROR_CODE.ACCOUNT_MEMBER_DELETED)) {
    dispatch(addPopup<AccountMemberDeletedAlertProps>({
      id: POPUP.ACCOUNT_MEMBER_DELETED
    }));
    return;
  }
  const lockedByAdminError = findByCodes(errors, [ERROR_CODE.USER_HAS_NO_ACCESS_TO_CHAT, ERROR_CODE.ACCOUNT_CURRENT_MEMBER_BLOCKED, ERROR_CODE.USER_LOCKED_FOR_MESSAGE_SEND]);

  /**
   * @todo
   * Get rid of calling of initializeNavigator inside
   * accountHandleErrorBodyErrors. We must read it with useNavigator and pass as
   * arguments.
   */
  if (lockedByAdminError) {
    const navigator = initializeNavigator();
    navigator.navigate(ROUTES.chats.index.getDescriptor(), {
      mode: NAVIGATION_MODE.REPLACE
    });
    if (chatId) {
      dispatch(chatsRemoveFromList({
        chatId
      }));
    }
    dispatch(addPopup<AlertDialogProps>({
      id: POPUP.ALERT_DIALOG,
      props: {
        text: lockedByAdminError.message
      }
    }));
  }
  return errors;
};