import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { ASSETS_HOST } from '@config/images';
import { addEvent } from '@helpers/dom';
import { ensureUrlIsExternal, mergeUrlParts } from '@helpers/url';
import { UseImageLoaded, UseImageLoadedResult, UseImageSource } from './types';
export const useImageLoaded: UseImageLoaded = ({
  src,
  srcSet
}) => {
  const [loaded, setLoaded] = useState<UseImageLoadedResult>(false);
  const image = useRef<HTMLImageElement>();
  useEffect(() => {
    setLoaded(false);
    image.current = new Image();
    image.current.src = src;
    image.current.srcset = srcSet || src;
    const unOnLoad = addEvent(image.current, EVENT_NAMES.LOAD,
    //
    () => setLoaded('loaded'));
    const unOnError = addEvent(image.current, EVENT_NAMES.ERROR,
    //
    () => setLoaded('error'));
    return () => {
      unOnLoad();
      unOnError();
      image.current = undefined;
    };
  }, [src, srcSet]);
  return loaded;
};
export const useImageSource: UseImageSource = ({
  src,
  srcSet
}) => {
  const [pending, setPending] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [image, setImage] = useState<HTMLImageElement>();
  const [attemptsToLoad, setAttemptsToLoad] = useState<number>(0);
  const currentHost = useMemo(() => attemptsToLoad > 0 ? '/' : ASSETS_HOST,
  //
  [attemptsToLoad]);
  const ensureSrcIsExternal = useCallback((src: undefined | string): boolean => {
    if (!src) {
      return false;
    }
    return ensureUrlIsExternal(src);
  }, []);
  const ensureSrcSetIsExternal = useCallback((srcSet: undefined | string): boolean => {
    if (!srcSet) {
      return false;
    }
    return srcSet.split(',').some(src => {
      const trimmedSrc = src.trim();
      return ensureUrlIsExternal(trimmedSrc);
    });
  }, []);
  const originalSrcIsExternal = useMemo(() => ensureSrcIsExternal(src),
  //
  [ensureSrcIsExternal, src]);
  const originalSrcSetIsExternal = useMemo(() => ensureSrcSetIsExternal(srcSet), [ensureSrcSetIsExternal, srcSet]);
  const getProcessedSrc = useCallback((src: undefined | string) => {
    if (!src) {
      return;
    }
    return ensureUrlIsExternal(src) //
    ? src : mergeUrlParts(currentHost, src);
  }, [currentHost]);
  const getProcessedSrcSet = useCallback((srcSet: undefined | string) => {
    if (!srcSet) {
      return;
    }
    return srcSet.split(',').map(src => {
      const trimmedSrc = src.trim();
      return ensureUrlIsExternal(trimmedSrc) //
      ? trimmedSrc : mergeUrlParts(currentHost, src);
    }).join(', ');
  }, [currentHost]);
  const processedSrc = useMemo(() => {
    if (originalSrcIsExternal) {
      return src;
    }
    if (isError) {
      return;
    }
    return getProcessedSrc(src);
  }, [getProcessedSrc, isError, originalSrcIsExternal, src]);
  const processedSrcSet = useMemo(() => {
    if (originalSrcSetIsExternal) {
      return srcSet;
    }
    if (isError) {
      return;
    }
    return getProcessedSrcSet(srcSet);
  }, [getProcessedSrcSet, isError, originalSrcSetIsExternal, srcSet]);
  const setCurrentImage = useCallback(() => {
    const currentImage = new Image();
    currentImage.src = getProcessedSrc(src) ?? '';
    if (srcSet) {
      currentImage.srcset = getProcessedSrcSet(srcSet) ?? '';
    }
    setImage(currentImage);
  }, [getProcessedSrc, getProcessedSrcSet, src, srcSet]);
  const handleSuccessLoad = useCallback(() => {
    setIsError(false);
    setPending(false);
  }, []);
  const handleErrorOnLoad = useCallback(() => {
    setIsError(true);
    switch (attemptsToLoad) {
      case 2:
        setIsError(true);
        break;
      case 1:
        setIsError(false);
        setAttemptsToLoad(prev => prev + 1);
        setCurrentImage();
        break;
      default:
        setAttemptsToLoad(prev => prev + 1);
        setCurrentImage();
    }
  }, [attemptsToLoad, setCurrentImage]);
  useEffect(() => {
    if (!image) {
      setCurrentImage();
      return;
    }
    if (!src && !srcSet) {
      setIsError(true);
      return;
    }
    if (originalSrcIsExternal ?? originalSrcSetIsExternal) {
      setPending(false);
      return;
    }
    if (image.complete) {
      return handleSuccessLoad();
    }
    const unOnLoad = addEvent(image, EVENT_NAMES.LOAD,
    //
    handleSuccessLoad);
    const unOnError = addEvent(image, EVENT_NAMES.ERROR,
    //
    handleErrorOnLoad);
    return () => {
      unOnLoad();
      unOnError();
    };
  }, [handleErrorOnLoad, handleSuccessLoad, image, originalSrcIsExternal, originalSrcSetIsExternal, setCurrentImage, src, srcSet]);
  return {
    src: processedSrc,
    srcSet: processedSrcSet,
    pending
  };
};