module.exports.filterUtilites = ({ addUtilities }) => {
  const newUtilities = {
    '.filter-none': {
      filter: 'none',
    },
    '.filter-grayscale': {
      filter: 'grayscale(1)',
    },
    '.filter-blur-6px': {
      filter: 'blur(6px)',
    },
    '.filter-blur-12px': {
      filter: 'blur(10px)',
    },
    '.filter-blur-30px': {
      filter: 'blur(30px)',
    },
    '.filter-brightness-60': {
      filter: 'brightness(0.6)',
    },
    '.filter-brightness-140': {
      filter: 'brightness(1.4)',
    },
  };
  addUtilities(newUtilities);
};
