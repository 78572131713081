import { appCreateSlice } from '@store/rtk';
import { mediaCallAcceptedReducer } from '@slices/media-call/media-call-accepted';
import { mediaCallAddLocalAudioTrackReducer } from '@slices/media-call/media-call-add-local-audio-track';
import { mediaCallAddLocalVideoTrackReducer } from '@slices/media-call/media-call-add-local-video-track';
import { mediaCallAddRemoteAudioTrackReducer } from '@slices/media-call/media-call-add-remote-audio-track';
import { mediaCallAddRemoteVideoTrackReducer } from '@slices/media-call/media-call-add-remote-video-track';
import { mediaCallClearReducer } from '@slices/media-call/media-call-clear';
import { mediaCallDisableAcquiringAudioReducer } from '@slices/media-call/media-call-disable-acquiring-audio';
import { mediaCallDisableAcquiringVideoReducer } from '@slices/media-call/media-call-disable-acquiring-video';
import { mediaCallEnableAcquiringAudioReducer } from '@slices/media-call/media-call-enable-acquiring-audio';
import { mediaCallEnableAcquiringVideoReducer } from '@slices/media-call/media-call-enable-acquiring-video';
import { mediaCallReceiveCreateReducer } from '@slices/media-call/media-call-receive-create';
import { mediaCallRemoveLocalAudioTrackReducer } from '@slices/media-call/media-call-remove-local-audio-track';
import { mediaCallRemoveLocalVideoTrackReducer } from '@slices/media-call/media-call-remove-local-video-track';
import { mediaCallRemoveRemoteAudioTrackReducer } from '@slices/media-call/media-call-remove-remote-audio-track';
import { mediaCallRemoveRemoteVideoTrackReducer } from '@slices/media-call/media-call-remove-remote-video-track';
import { mediaCallSetStateReducer } from '@slices/media-call/media-call-set-state';
import { MediaCallState } from './types';
export const mediaCallDefaultState: MediaCallState = {
  mediaCallsById: {},
  mediaCallsIds: []
};
const mediaCallSlice = appCreateSlice({
  name: 'mediaCall',
  initialState: mediaCallDefaultState,
  reducers: {
    mediaCallAccepted: mediaCallAcceptedReducer,
    mediaCallAddLocalAudioTrack: mediaCallAddLocalAudioTrackReducer,
    mediaCallAddLocalVideoTrack: mediaCallAddLocalVideoTrackReducer,
    mediaCallAddRemoteAudioTrack: mediaCallAddRemoteAudioTrackReducer,
    mediaCallAddRemoteVideoTrack: mediaCallAddRemoteVideoTrackReducer,
    mediaCallClear: mediaCallClearReducer,
    mediaCallDisableAcquiringAudio: mediaCallDisableAcquiringAudioReducer,
    mediaCallDisableAcquiringVideo: mediaCallDisableAcquiringVideoReducer,
    mediaCallEnableAcquiringAudio: mediaCallEnableAcquiringAudioReducer,
    mediaCallEnableAcquiringVideo: mediaCallEnableAcquiringVideoReducer,
    mediaCallReceiveCreate: mediaCallReceiveCreateReducer,
    mediaCallRemoveLocalAudioTrack: mediaCallRemoveLocalAudioTrackReducer,
    mediaCallRemoveLocalVideoTrack: mediaCallRemoveLocalVideoTrackReducer,
    mediaCallRemoveRemoteAudioTrack: mediaCallRemoveRemoteAudioTrackReducer,
    mediaCallRemoveRemoteVideoTrack: mediaCallRemoveRemoteVideoTrackReducer,
    mediaCallSetState: mediaCallSetStateReducer
  }
});
export const {
  actions: {
    mediaCallAccepted,
    mediaCallAddLocalAudioTrack,
    mediaCallAddLocalVideoTrack,
    mediaCallAddRemoteAudioTrack,
    mediaCallAddRemoteVideoTrack,
    mediaCallClear,
    mediaCallDisableAcquiringAudio,
    mediaCallDisableAcquiringVideo,
    mediaCallEnableAcquiringAudio,
    mediaCallEnableAcquiringVideo,
    mediaCallReceiveCreate,
    mediaCallRemoveLocalAudioTrack,
    mediaCallRemoveLocalVideoTrack,
    mediaCallRemoveRemoteAudioTrack,
    mediaCallRemoveRemoteVideoTrack,
    mediaCallSetState
  },
  reducer: mediaCallReducer
} = mediaCallSlice;