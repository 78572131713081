import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch } from '@services/base/authed-json-fetch';
import { AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { AnalyticsSendAmplitudeSessionRequestData, AnalyticsSendAmplitudeSessionRequestDto, AnalyticsSendAmplitudeSessionResponseData, AnalyticsSendAmplitudeSessionResponseDto } from '@services/dto/analytics/analytics-send-amplitude-session';
import { analyticsSendAmplitudeSessionRequestToDto } from '@mapping/analytics/analytics-send-amplitude-session';
type AnalyticsSendAmplitudeSession = (requestData: AnalyticsSendAmplitudeSessionRequestData) => AuthedJsonFetchResult<AnalyticsSendAmplitudeSessionResponseData>;
export const analyticsSendAmplitudeSession: AnalyticsSendAmplitudeSession = requestData => {
  return authedJsonFetch<AnalyticsSendAmplitudeSessionResponseDto, AnalyticsSendAmplitudeSessionRequestDto>(ENDPOINTS.analytics.sendAmplitudeSession(), {
    method: 'POST',
    data: analyticsSendAmplitudeSessionRequestToDto(requestData)
  });
};