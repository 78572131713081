import { AUTH_TOKEN_TYPE } from '@config/auth';
import { EVENT_NAMES } from '@config/dom';
import { HTTP_HEADER_NAME, HTTP_STATUS_CODE } from '@config/net';
import { dispatchCustomEvent } from '@helpers/dom';
import { initializeStorage } from '@storage';
import { readAccessToken, readRefreshToken, removeStorageAuthData, writeStorageAuthData } from '@storage/auth';
import { authRefreshToken } from '@services/auth';
import { initializeRefreshHelper } from '@services/base/auth-refresh-state';
import { httpFetch, HttpFetchResponse } from '@services/base/http-fetch';
import { AuthedHttpFetchConfig, AuthedHttpFetchHeaders, AuthedHttpFetchOwnHeaders, AuthedHttpFetchResult } from './types';
const _authedHttpFetch = <R, D = undefined, P = undefined, H extends AuthedHttpFetchHeaders = undefined>(url: string, config?: AuthedHttpFetchConfig<D, P, H>): AuthedHttpFetchResult<R> => {
  const storage = initializeStorage();
  const accessToken = readAccessToken(storage);
  const authedHttpOwnHeaders = {
    [HTTP_HEADER_NAME.AUTHORIZATION]: `${AUTH_TOKEN_TYPE} ${accessToken}`
  };
  const nextConfig = {
    ...config,
    headers: {
      ...authedHttpOwnHeaders,
      ...config?.headers
    }
  };
  return httpFetch<R, D, P, AuthedHttpFetchOwnHeaders | (AuthedHttpFetchOwnHeaders & H)>(url, nextConfig);
};
export const authedHttpFetch = <R, D = undefined, P = undefined, H extends AuthedHttpFetchHeaders = undefined>(url: string, config?: AuthedHttpFetchConfig<D, P, H>): AuthedHttpFetchResult<R> => {
  const {
    promise,
    cancel
  } = _authedHttpFetch<R, D, P, H>(url, config);
  const authRefreshState = initializeRefreshHelper();
  return {
    promise: promise.catch(async (response: HttpFetchResponse<R>) => {
      if (response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
        if (authRefreshState.getPending()) {
          await authRefreshState.addPendingPromise();
          return _authedHttpFetch<R, D, P, H>(url, config).promise;
        }
        const storage = initializeStorage();
        const refreshToken = readRefreshToken(storage);
        if (!refreshToken) {
          throw response;
        }
        authRefreshState.addPendingPromise();
        return authRefreshToken({
          refreshToken
        }).promise //
        .catch((response: HttpFetchResponse<R>) => {
          removeStorageAuthData(storage);
          dispatchCustomEvent(window, EVENT_NAMES.AUTH_REFRESH, undefined);
          authRefreshState.rejectPendingPromises();
          throw response;
        }).then(({
          data
        }) => {
          writeStorageAuthData(storage, data);
          dispatchCustomEvent(window, EVENT_NAMES.AUTH_REFRESH, data);
          authRefreshState.resolvePendingPromises();
          return _authedHttpFetch<R, D, P, H>(url, config).promise;
        });
      }
      throw response;
    }),
    cancel
  };
};