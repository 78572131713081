import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsFillProfileEvent } from '@logging/logging-events';
import { AnalyticsFillProfileEventRequestData } from '@services/dto/analytics/analytics-send-event/analytics-fill-profile-event';
export const isFillProfileEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsFillProfileEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.FILL_PROFILE;
};
export const sendFillProfile = (eventBus: IEventBus): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsFillProfileEventRequestData['data']>({
    action: ANALYTICS_ACTION.FILL_PROFILE,
    data: undefined
  }));
};