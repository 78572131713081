import { useCallback, useEffect } from 'react';
import { isClient, isOnline, isSpecialist } from '@helpers/member';
import { AppFC, STATISTICS_PERIOD_TYPE } from '@types';
import { countersFetchCounters } from '@actions/counters/counters-fetch-counters';
import { currentClientFetchGetBalance } from '@actions/current-client/current-client-fetch-get-balance';
import { currentSpecialistFetchGetBalance } from '@actions/current-specialist/current-specialist-fetch-get-balance';
import { currentSpecialistFetchGetStatistics } from '@actions/current-specialist/current-specialist-fetch-get-statistics';
import { accountGetAccountId } from '@selectors/account';
import { currentMemberGetRoleType } from '@selectors/current-member';
import { onlineGetStatus } from '@selectors/online';
import { useFetchCommonData } from '@hooks/data';
import { useTypedDispatch, useTypedSelector } from '@hooks/store';
export const CommonDataManager: AppFC = () => {
  const dispatch = useTypedDispatch();
  const currentMemberId = useTypedSelector(accountGetAccountId);
  const currentMemberRoleType = useTypedSelector(currentMemberGetRoleType);
  const currentMemberOnlineStatus = useTypedSelector(onlineGetStatus);
  const _currentMemberIsOnline = isOnline(currentMemberOnlineStatus);
  useFetchCommonData(dispatch);
  const fetchCounters = useCallback(() => {
    dispatch(countersFetchCounters());
  }, [dispatch]);
  const fetchBalance = useCallback(() => {
    if (isClient(currentMemberRoleType)) {
      dispatch(currentClientFetchGetBalance());
      return;
    }
    if (isSpecialist(currentMemberRoleType)) {
      dispatch(currentSpecialistFetchGetBalance());
      return;
    }
  }, [currentMemberRoleType, dispatch]);
  const fetchStatistics = useCallback(() => {
    dispatch(currentSpecialistFetchGetStatistics({
      statisticsPeriodType: STATISTICS_PERIOD_TYPE.TODAY
    }));
    dispatch(currentSpecialistFetchGetStatistics({
      statisticsPeriodType: STATISTICS_PERIOD_TYPE.CURRENT_MONTH
    }));
  }, [dispatch]);
  useEffect(() => {
    if (!currentMemberId || !_currentMemberIsOnline) {
      return;
    }
    fetchCounters();
  }, [_currentMemberIsOnline, fetchCounters, currentMemberId]);
  useEffect(() => {
    if (!currentMemberId || !_currentMemberIsOnline) {
      return;
    }
    fetchBalance();
  }, [fetchBalance, _currentMemberIsOnline, currentMemberId]);
  useEffect(() => {
    if (!currentMemberId || !_currentMemberIsOnline) {
      return;
    }
    fetchStatistics();
  }, [_currentMemberIsOnline, currentMemberId, fetchStatistics]);
  return null;
};