import { generateUuid } from '@helpers/data';
import { notificationsUiAdd } from '@slices/notifications-ui';
import { NotificationsUiAdd } from './types';
export const notificationsUiHandleAdd: NotificationsUiAdd = payload => {
  return dispatch => {
    const id = generateUuid();
    dispatch(notificationsUiAdd({
      id,
      ...payload
    }));
  };
};