export { flatten } from 'flatten-anything';
export { default as chunk } from 'lodash/chunk';
export { default as difference } from 'lodash/difference';
export { default as differenceBy } from 'lodash/differenceBy';
export { default as findIndex } from 'lodash/findIndex';
export { default as first } from 'lodash/first';
export { default as groupBy } from 'lodash/groupBy';
export { default as intersection } from 'lodash/intersection';
export { default as isEmpty } from 'lodash/isEmpty';
export { default as isEqual } from 'lodash/isEqual';
export { default as isNullable } from 'lodash/isNil';
export { default as isNumber } from 'lodash/isNumber';
export { default as isString } from 'lodash/isString';
export { default as keyBy } from 'lodash/keyBy';
export { default as last } from 'lodash/last';
export { default as mapKeys } from 'lodash/mapKeys';
export { default as mapValues } from 'lodash/mapValues';
export { default as mean } from 'lodash/mean';
export { default as merge } from 'lodash/merge';
export { default as omit } from 'lodash/omit';
export { default as omitBy } from 'lodash/omitBy';
export { default as pick } from 'lodash/pick';
export { default as pickBy } from 'lodash/pickBy';
export { default as range } from 'lodash/range';
export { default as sortBy } from 'lodash/sortBy';
export { default as trim } from 'lodash/trim';
export { default as uniq } from 'lodash/uniq';
export { default as uniqBy } from 'lodash/uniqBy';
export { default as zip } from 'lodash/zip';
export { v4 as generateUuid } from 'uuid';
import { camelKeys } from 'js-convert-case';
import { default as differenceWith } from 'lodash/differenceWith';
import { default as findIndex } from 'lodash/findIndex';
import { default as isNil } from 'lodash/isNil';
import { default as keyBy } from 'lodash/keyBy';
import { default as omitBy } from 'lodash/omitBy';
import { default as sortBy } from 'lodash/sortBy';
import { default as uniqBy } from 'lodash/uniqBy';
import { Md5 } from 'ts-md5';
export const isTruthy = (value: unknown): boolean => {
  return Boolean(value);
};
export const nonUndefinedOr = <A, B>(a: A, b: B): A | B => {
  return a !== undefined ? a : b;
};
export const keyById = <T extends {
  id: number | string;
},>(array: T[]): {
  [key: string]: T;
} => {
  return keyBy<T>(array, ({
    id
  }) => id);
};
export const mapById = <T extends {
  id: ID;
}, ID>(array: T[]): ID[] => {
  return array.map(({
    id
  }) => id);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const omitNullish = <T extends object,>(object: T): Partial<T> => {
  return omitBy<T>(object, isNil);
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const omitFalsy = <T extends object,>(object: T): Partial<T> => {
  return omitBy<T>(object, value => !value);
};
export const rejectBy = <T,>(array: T[], fn: (val: T, index: number) => boolean): T[] => {
  return array.filter((value, index) => !fn(value, index));
};
export const reject = <T,>(array: T[], value: T): T[] => {
  return rejectBy(array, item => item === value);
};
export const rejectById = <T extends {
  id: ID;
}, ID>(array: T[], id: ID): T[] => {
  return rejectBy(array, item => item.id === id);
};
export const rejectByIndex = <T,>(array: T[], index: number): T[] => {
  return rejectBy(array, (_, _index) => _index === index);
};
export const rejectNullable = <T,>(array: T[]): NonNullable<T>[] => {
  return (rejectBy(array, isNil) as NonNullable<T>[]);
};
export const uniqById = <I extends {
  id: ID;
}, ID>(array: I[]): I[] => {
  return uniqBy(array, item => item.id);
};
export const findById = <I extends {
  id: ID;
}, ID>(array: I[], id: ID): undefined | I => {
  return array.find(item => item.id === id);
};
export const findByKey = <I extends {
  key: K;
}, K>(array: I[], key: K): undefined | I => {
  return array.find(item => item.key === key);
};
export const findByCode = <I extends {
  code: C;
}, C>(array: I[], code: C): undefined | I => {
  return array.find(item => item.code === code);
};
export const findByCodes = <I extends {
  code: C;
}, C>(array: I[], codes: C[]): undefined | I => {
  return array.find(item => codes.includes(item.code));
};
export const findByType = <I extends {
  type: T;
}, T>(array: I[], type: T): undefined | I => {
  return array.find(item => item.type === type);
};
export const findByCategory = <I extends {
  category: T;
}, T>(array: I[], category: T): undefined | I => {
  return array.find(item => item.category === category);
};
export const findDefault = <I extends {
  isDefault: boolean;
},>(array: I[]): undefined | I => {
  return array.find(item => item.isDefault);
};
export const findIndexById = <T extends {
  id: ID;
}, ID>(array: T[], id: ID): undefined | number => {
  return findIndex(array, item => item.id === id);
};
export const sortById = <T extends {
  id: ID;
}, ID>(array: T[]): T[] => {
  return sortBy(array, item => item.id);
};
export const differenceWithCaseInsensitive = <T extends string,>(a: T[], b: T[], c?: T[]): T[] => {
  return differenceWith(a, b, c ?? [], isEqualCaseIncensitive);
};
export const alwaysArray = <T,>(value: T | ConcatArray<T>): T[] => {
  return ([] as T[]).concat(value);
};
export const alwaysFirst = <T,>(val: T | T[]): T => {
  return alwaysArray(val)[0];
};
export const numberOrNothing = (value: unknown): undefined | number => {
  if (value == null || isNaN(Number(value))) {
    return undefined;
  }
  return Number(value);
};
export const readJson = <T extends unknown,>(jsonString: string, converter?: (object: unknown) => object | null): undefined | T => {
  try {
    const result: T = JSON.parse(jsonString);
    if (!converter) {
      return result;
    }
    return (converter(result) as T);
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * @todo
 * Add typing with ts string literals.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const camelKeysRecursive = (object: unknown): null | object => {
  return camelKeys(object, {
    recursive: true,
    recursiveInArray: true
  });
};
export const isEqualCaseIncensitive = <T extends string,>(a: T, b: T): boolean => {
  return a.localeCompare(b, undefined, {
    sensitivity: 'accent'
  }) === 0;
};
export const includesWithCaseInsensitive = <T extends string,>(array: T[], value: T): boolean => {
  return array.some(_value => isEqualCaseIncensitive(_value, value));
};
export const rejectByIdWithCaseInsensitive = <T extends {
  id: ID;
}, ID extends string>(array: T[], id: ID): T[] => {
  return rejectBy(array, item => isEqualCaseIncensitive(item.id, id));
};
export const findKeyWithCaseInsensitive = <
// eslint-disable-next-line @typescript-eslint/ban-types
O extends object, K extends keyof O>(object: O, key: K): undefined | K => {
  return (Object.keys(object) //
  .find(_key => isEqualCaseIncensitive(_key, String(key))) as K);
};
export const getObjectPropWithCaseInsensitive = <
// eslint-disable-next-line @typescript-eslint/ban-types
O extends object, K extends keyof O>(object: O, key: K): undefined | O[K] => {
  const _key = findKeyWithCaseInsensitive(object, key);
  if (!_key) {
    return undefined;
  }
  return object[_key];
};
export const findWithCaseInsensitive = <A extends string[], K extends string>(array: A, key: K): undefined | K => {
  return (array //
  .find(_key => _key.toLowerCase() === key.toLowerCase()) as K);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonToBase64 = <O extends Record<string, any>,>(object: O): string => {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString('base64');
};
export const base64ToJson = <O,>(base64String: string): O => {
  const json = Buffer.from(base64String, 'base64').toString();
  return JSON.parse(json);
};
export const hashStringMd5 = (string: string): string => {
  return Md5.hashStr(string);
};