import { AppFCC } from '@types';
import { BUTTON_BASE_COLORED_COLOR } from '@kit/buttons/button-base-colored';
import { NotificationActionButtonIconStyled, NotificationActionButtonStyled, NotificationActionButtonTextStyled, NotificationAvatarRoundedStyled, NotificationBodyStyled, NotificationCloseButtonStyled, NotificationCloseIconStyled, NotificationContainerStyled, NotificationMessageStyled, NotificationNameStyled, NotificationStyled, NotificationTitleStyled } from './styled';
import { NotificationProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const Notification: AppFCC<NotificationProps> = props => {
  const {
    className,
    title,
    name,
    avatar,
    message,
    children,
    actionButton,
    onClose
  } = props;
  return _jsxs(NotificationStyled, {
    className: className,
    children: [_jsx(NotificationTitleStyled, {
      children: title
    }), _jsxs(NotificationBodyStyled, {
      children: [_jsx(NotificationAvatarRoundedStyled, {
        src: avatar
      }), _jsxs(NotificationContainerStyled, {
        children: [!name ? null : _jsx(NotificationNameStyled, {
          children: name
        }), !message ? null : _jsx(NotificationMessageStyled, {
          children: message
        }), children]
      })]
    }), !actionButton ? null : _jsxs(NotificationActionButtonStyled, {
      color: BUTTON_BASE_COLORED_COLOR.DARK_BLUE_BORDERS,
      onClick: actionButton.onClick,
      children: [!actionButton.icon ? null : _jsx(NotificationActionButtonIconStyled, {
        children: actionButton.icon
      }), _jsx(NotificationActionButtonTextStyled, {
        children: actionButton.text
      })]
    }), _jsx(NotificationCloseButtonStyled, {
      onClick: onClose,
      children: _jsx(NotificationCloseIconStyled, {})
    })]
  });
};