import { CHAT_FILTER } from '@types';
import { appCreateSlice } from '@store/rtk';
import { chatsAddChatReducer } from '@slices/chats/chats-add-chat';
import { chatsAddToBlackListReducer } from '@slices/chats/chats-add-to-black-list';
import { chatsAddToFavoriteReducer } from '@slices/chats/chats-add-to-favorite';
import { chatsDecreaseUnreadMessagesCounterReducer } from '@slices/chats/chats-decrease-unread-messages-counter';
import { chatsIncreaseUnreadMessagesCounterReducer } from '@slices/chats/chats-increase-unread-messages-counter';
import { chatsReceiveGetByIdReducer } from '@slices/chats/chats-receive-get-by-id';
import { chatsReceiveGetLastChatReducer } from '@slices/chats/chats-receive-get-last-chat';
import { chatsReceiveGetListReducer } from '@slices/chats/chats-receive-get-list';
import { chatsRemoveFromBlackListReducer } from '@slices/chats/chats-remove-from-black-list';
import { chatsRemoveFromFavoriteReducer } from '@slices/chats/chats-remove-from-favorite';
import { chatsRemoveFromListReducer } from '@slices/chats/chats-remove-from-list';
import { chatsSetLastMessageReducer } from '@slices/chats/chats-set-last-message';
import { chatsSetPrepaymentBalanceReducer } from '@slices/chats/chats-set-prepayment-balance';
import { chatsSetUnreadMessagesCounterReducer } from '@slices/chats/chats-set-unread-messages-counter';
import { ChatsState } from './types';
export const chatsDefaultState: ChatsState = {
  chatsById: {},
  chatsIds: [],
  unreadMessagesCount: 0,
  recipientName: undefined,
  filter: CHAT_FILTER.ALL,
  limit: 20,
  offset: 0,
  hasMore: false,
  totalCount: 0,
  dataLoaded: false
};
const chatsSlice = appCreateSlice({
  name: 'chats',
  initialState: chatsDefaultState,
  reducers: {
    chatsAddChat: chatsAddChatReducer,
    chatsAddToBlackList: chatsAddToBlackListReducer,
    chatsAddToFavorite: chatsAddToFavoriteReducer,
    chatsDecreaseUnreadMessagesCounter: chatsDecreaseUnreadMessagesCounterReducer,
    chatsIncreaseUnreadMessagesCounter: chatsIncreaseUnreadMessagesCounterReducer,
    chatsReceiveGetById: chatsReceiveGetByIdReducer,
    chatsReceiveGetLastChat: chatsReceiveGetLastChatReducer,
    chatsReceiveGetList: chatsReceiveGetListReducer,
    chatsRemoveFromBlackList: chatsRemoveFromBlackListReducer,
    chatsRemoveFromFavorite: chatsRemoveFromFavoriteReducer,
    chatsRemoveFromList: chatsRemoveFromListReducer,
    chatsSetLastMessage: chatsSetLastMessageReducer,
    chatsSetPrepaymentBalance: chatsSetPrepaymentBalanceReducer,
    chatsSetUnreadMessagesCounter: chatsSetUnreadMessagesCounterReducer
  }
});
export const {
  actions: {
    chatsAddChat,
    chatsAddToBlackList,
    chatsAddToFavorite,
    chatsDecreaseUnreadMessagesCounter,
    chatsIncreaseUnreadMessagesCounter,
    chatsReceiveGetById,
    chatsReceiveGetLastChat,
    chatsReceiveGetList,
    chatsRemoveFromBlackList,
    chatsRemoveFromFavorite,
    chatsRemoveFromList,
    chatsSetLastMessage,
    chatsSetPrepaymentBalance,
    chatsSetUnreadMessagesCounter
  },
  reducer: chatsReducer
} = chatsSlice;