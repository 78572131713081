import { differenceBy } from '@helpers/data';
import { calendarToWorkingTimesWithoutRepeaters } from '@mapping/calendar/calendar-to-working-times-without-repeaters';
import { CalendarWorkingTimes } from '@types';
export const calendarRejectWorkingTimesByPreiod = (workingTimes: CalendarWorkingTimes, dateFrom?: string, dateTo?: string): CalendarWorkingTimes => {
  if (!dateFrom || !dateTo) {
    return workingTimes;
  }
  const workingTimesToReject = calendarToWorkingTimesWithoutRepeaters({
    workingTimes,
    dateFrom,
    dateTo
  });
  return differenceBy(workingTimes, workingTimesToReject, workingTime => workingTime.id);
};