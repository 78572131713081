import { InvitationsDecreaseCounterReducer } from './types';
export const invitationsDecreaseCounterReducer: InvitationsDecreaseCounterReducer = (state, action) => {
  const {
    decrement
  } = action.payload;
  const {
    pendingInvitationsCount
  } = state;
  return {
    ...state,
    pendingInvitationsCount: Math.max(0, pendingInvitationsCount - decrement)
  };
};