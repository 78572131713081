import { UAParser } from 'ua-parser-js';
import tailwindConfig from '@root/tailwind.config';
import { GEOMETRY_TYPE } from '@config/geometry';
export const defineGeometryType = (): GEOMETRY_TYPE => {
  const {
    screens
  } = tailwindConfig.theme;
  return window.matchMedia(screens['desktop-lg'].raw).matches ? GEOMETRY_TYPE['DESKTOP-LG'] : window.matchMedia(screens['desktop'].raw).matches ? GEOMETRY_TYPE['DESKTOP-XL'] : window.matchMedia(screens['tablet-h'].raw).matches ? GEOMETRY_TYPE['TABLET-H'] : window.matchMedia(screens['tablet'].raw).matches ? GEOMETRY_TYPE['TABLET-V'] : window.matchMedia(screens['mobile-h'].raw).matches ? GEOMETRY_TYPE['MOBILE-H'] : GEOMETRY_TYPE['MOBILE-V'];
};
export const defineGeometryTypeByUserAgent = (userAgent?: string): GEOMETRY_TYPE => {
  const parser = new UAParser(userAgent);
  const {
    type
  } = parser.getDevice();
  switch (type) {
    case 'mobile':
      return GEOMETRY_TYPE['MOBILE-V'];
    case 'tablet':
      return GEOMETRY_TYPE['TABLET-V'];
    default:
      return GEOMETRY_TYPE['DESKTOP-LG'];
  }
};
export const defineBrowserName = (userAgent: string): undefined | string => {
  const parser = new UAParser(userAgent);
  const {
    name
  } = parser.getBrowser();
  return name;
};
export const defineOS = (userAgent: string): undefined | string => {
  const parser = new UAParser(userAgent);
  const {
    name
  } = parser.getOS();
  return name;
};
export const isDesktop = (geometryType: GEOMETRY_TYPE): boolean => {
  return [GEOMETRY_TYPE['DESKTOP-XL'],
  //
  GEOMETRY_TYPE['DESKTOP-LG']].includes(geometryType);
};
export const isDesktopLg = (geometryType: GEOMETRY_TYPE): boolean => {
  return geometryType === GEOMETRY_TYPE['DESKTOP-LG'];
};
export const isDesktopXl = (geometryType: GEOMETRY_TYPE): boolean => {
  return geometryType === GEOMETRY_TYPE['DESKTOP-XL'];
};
export const isTablet = (geometryType: GEOMETRY_TYPE): boolean => {
  return [GEOMETRY_TYPE['TABLET-V'],
  //
  GEOMETRY_TYPE['TABLET-H']].includes(geometryType);
};
export const isTabletH = (geometryType: GEOMETRY_TYPE): boolean => {
  return geometryType === GEOMETRY_TYPE['TABLET-H'];
};
export const isMobile = (geometryType: GEOMETRY_TYPE): boolean => {
  return [GEOMETRY_TYPE['MOBILE-V'],
  //
  GEOMETRY_TYPE['MOBILE-H']].includes(geometryType);
};
export const isMobileH = (geometryType: GEOMETRY_TYPE): boolean => {
  return geometryType === GEOMETRY_TYPE['MOBILE-H'];
};
export const isMobileV = (geometryType: GEOMETRY_TYPE): boolean => {
  return geometryType === GEOMETRY_TYPE['MOBILE-V'];
};