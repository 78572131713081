import { calendarIsCurrentClient, calendarIsCurrentClientCalendar, calendarIsCurrentSpecialist, calendarIsCurrentSpecialistCalendar } from '@helpers/calendar';
import { uniqById } from '@helpers/data';
import { calendarRejectConsultationsByPeriod } from '@mapping/calendar/calendar-reject-consultations-by-period';
import { CalendarReceiveGetCurrentMemberConsultationsReducer } from './types';
export const calendarReceiveGetCurrentMemberConsultationsReducer: CalendarReceiveGetCurrentMemberConsultationsReducer = (state, action) => {
  const {
    data,
    dateFrom,
    dateTo
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentSpecialist(currentMember) && calendarIsCurrentSpecialistCalendar(currentMember.calendar)) {
    const calendar = currentMember?.calendar;
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...calendar,
          consultations: uniqById([...data, ...calendarRejectConsultationsByPeriod(calendar.consultations, dateFrom, dateTo)])
        }
      }
    };
  }
  if (calendarIsCurrentClient(currentMember) && calendarIsCurrentClientCalendar(currentMember.calendar)) {
    const calendar = currentMember?.calendar;
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...calendar,
          consultations: uniqById([...data, ...calendarRejectConsultationsByPeriod(calendar.consultations, dateFrom, dateTo)])
        }
      }
    };
  }
  return state;
};