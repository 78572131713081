import { omit } from '@helpers/data';
import { enforceDateStringToISO } from '@helpers/date';
import { ClientsGetMeResponseData, ClientsGetMeResponseDto } from '@services/dto/clients';
import { FILE_TYPE } from '@types';
export const clientsGetMeResponseFromDto = (dto: ClientsGetMeResponseDto): ClientsGetMeResponseData => {
  return omit({
    ...dto.data,
    birthDate: dto.data.birthDate ? enforceDateStringToISO(dto.data.birthDate) : undefined,
    media: [...dto.data.photos.map(file => ({
      ...file,
      fileType: FILE_TYPE.PHOTO
    }))]
  }, 'photos');
};