import { appCreateSlice } from '@store/rtk';
import { pricesReceiveGetCoinPricesByCategoryReducer } from '@slices/prices/prices-receive-get-coin-prices-by-category';
import { pricesReceiveGetPremiumPriceReducer } from '@slices/prices/prices-receive-get-premium-price';
import { PricesState } from './types';
export const pricesDefaultState: PricesState = {
  coinPrices: undefined,
  premiumPrice: undefined
};
const pricesSlice = appCreateSlice({
  name: 'prices',
  initialState: pricesDefaultState,
  reducers: {
    pricesReceiveGetCoinPricesByCategory: pricesReceiveGetCoinPricesByCategoryReducer,
    pricesReceiveGetPremiumPrice: pricesReceiveGetPremiumPriceReducer
  }
});
export const {
  actions: {
    pricesReceiveGetCoinPricesByCategory,
    pricesReceiveGetPremiumPrice
  },
  reducer: pricesReducer
} = pricesSlice;