import { NOTIFICATION_TYPE } from '@config/notifications';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { photoOrAvatarUrl } from '@helpers/member';
import { RealtimeInvitationReceivedEventPayloadData } from '@services/dto/realtime/realtime-invitation-event/realtime-invitation-received';
import { notificationsUiHandleAdd } from '@actions/notifications-ui/notifications-ui-handle-add';
import { invitationsAddInvitation, invitationsIncreaseCounter } from '@slices/invitations';
import { RealtimeInvitationReceived } from './types';
export const realtimeInvitationReceived: RealtimeInvitationReceived = event => {
  return dispatch => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeInvitationReceivedEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      invitation
    } = data;
    dispatch(invitationsAddInvitation({
      invitation
    }));
    dispatch(invitationsIncreaseCounter({
      increment: 1
    }));
    dispatch(notificationsUiHandleAdd({
      type: NOTIFICATION_TYPE.NEW_INVITATION,
      avatar: photoOrAvatarUrl(invitation.profile.photoUrl, invitation.profile.avatarId, invitation.profile.sex),
      name: invitation.profile.name,
      invitationId: invitation.id
    }));
  };
};