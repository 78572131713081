import { VK_OPEN_API_ID } from '@config/env';
import { SEVERITY } from '@config/logging';
import { isFillProfileEvent } from '@helpers/logging/analytics/fill-profile-event';
import { isServer } from '@helpers/misc';
import { IAnalyticsEvent } from '@logging/logging-events';
import { IVkEventHandler } from './types';
import { initVkScript } from './vk-scripts.js';
export class VkEventHandler<D> implements IVkEventHandler<D> {
  private trackingId = VK_OPEN_API_ID;
  public severities = [SEVERITY.ANALYTICS];
  public constructor() {
    if (!this.trackingId) {
      console.warn("VK pixel wasn't initialized");
      return;
    }
    if (isServer()) {
      return;
    }
    initVkScript(this.trackingId);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handle<D>(event: IAnalyticsEvent<D>): void {
    if (!window.VK || !this.trackingId) {
      return;
    }
    if (isFillProfileEvent(event)) {
      window.VK.Goal('complete_registration');
    }
  }
}