import { CalendarReceiveGetCurrentMemberCalendarInfoReducer } from './types';
export const calendarReceiveGetCurrentMemberCalendarInfoReducer: CalendarReceiveGetCurrentMemberCalendarInfoReducer = (state, action) => {
  const {
    data
  } = action.payload;
  return {
    ...state,
    currentMember: {
      ...state.currentMember,
      info: data
    }
  };
};