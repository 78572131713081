import { isActiveCall } from '@helpers/media-call';
import { MediaCallEnableAcquiringAudioReducer } from './types';
export const mediaCallEnableAcquiringAudioReducer: MediaCallEnableAcquiringAudioReducer = state => {
  const activeCall = Object.values(state.mediaCallsById).find(isActiveCall);
  if (!activeCall) {
    return state;
  }
  return {
    ...state,
    mediaCallsById: {
      ...state.mediaCallsById,
      [activeCall.chatId]: {
        ...activeCall,
        _acquiringAudio: true
      }
    }
  };
};