import { CALENDAR_MESSAGE_CONSULTATION_STATE, CalendarMessage } from '@types';
import { MessagesApproveConsultationReducer } from './types';
export const messagesApproveConsultationReducer: MessagesApproveConsultationReducer = (state, action) => {
  const {
    id,
    chatId
  } = action.payload;
  const {
    byChatId
  } = state;
  const messagesByChatId = byChatId[chatId];
  const {
    messagesById
  } = messagesByChatId;
  const prevMessage = (messagesById[id] as CalendarMessage);
  return {
    ...state,
    byChatId: {
      ...byChatId,
      [chatId]: {
        ...messagesByChatId,
        messagesById: {
          ...messagesById,
          [id]: {
            ...prevMessage,
            calendar: {
              consultation: {
                ...prevMessage.calendar.consultation,
                state: CALENDAR_MESSAGE_CONSULTATION_STATE.APPROVED
              }
            }
          }
        }
      }
    }
  };
};