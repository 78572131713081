import { AuthTokenResponseDto } from '@services/dto/auth';
import { AuthToken } from '@types';
export const authTokenResponseFromDto = (dto: AuthTokenResponseDto): AuthToken => {
  return {
    accessToken: dto.access_token,
    expiresIn: dto.expires_in,
    tokenType: dto.token_type,
    refreshToken: dto.refresh_token,
    scope: dto.scope
  };
};