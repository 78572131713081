import { PhotosUploadMeRequestData, PhotosUploadMeRequestDto, PhotosUploadMeResponseData, PhotosUploadMeResponseDto } from '@services/dto/photos';
import { FILE_TYPE } from '@types';
export const photosUploadMeRequestToDto = (data: PhotosUploadMeRequestData): PhotosUploadMeRequestDto => {
  const formData = new FormData();
  formData.append('photo', data.file, data.file.name);
  formData.append('visitId', data.visitId ?? '');
  formData.append('url', data.url);
  formData.append('clientType', String(data.clientType));
  formData.append('userAgent', data.userAgent);
  return formData;
};
export const photosUploadMeResponseFromDto = (dto: PhotosUploadMeResponseDto): PhotosUploadMeResponseData => {
  return {
    ...dto.data,
    fileType: FILE_TYPE.PHOTO
  };
};