module.exports.backgroundImageUtilites = ({ addUtilities }) => {
  const newUtilities = {
    '.bg-landing-main-women-problems': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/women-problems-bg.svg')",
      backgroundSize: '18.25rem 41.625rem',
      backgroundPosition: '2.875rem 1.5rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-women-problems-mobile-h': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/women-problems-bg-mobile-h.svg')",
      backgroundSize: '30.25rem 35.375rem',
      backgroundPosition: '0.125rem 2rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-women-problems-tablet': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/women-problems-bg-tablet.svg')",
      backgroundSize: '37.5rem 43.75rem',
      backgroundPosition: '0.125rem 0rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-women-problems-desktop': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/women-problems-bg-tablet.svg')",
      backgroundSize: '50.25rem 58.5rem',
      backgroundPosition: '0rem 0rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-women-problems-desktop-lg': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/women-problems-bg-tablet.svg')",
      backgroundSize: '50.25rem 56rem',
      backgroundPosition: '0.125rem -0.25rem',
      backgroundRepeat: 'no-repeat',
    },

    '.bg-landing-main-men-problems': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/men-problems-bg.svg')",
      backgroundSize: '23.875rem 40.5rem',
      backgroundPosition: '0rem 4.4rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-men-problems-mobile-h': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/men-problems-bg-mobile-h.svg')",
      backgroundSize: '32.125rem 37.5rem',
      backgroundPosition: '0.125rem 2.5rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-men-problems-tablet': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/men-problems-bg-tablet.svg')",
      backgroundSize: '37.625rem 43.75rem',
      backgroundPosition: '-0.125rem 0.75rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-men-problems-desktop': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/men-problems-bg-tablet.svg')",
      backgroundSize: '49.875rem 58.652rem',
      backgroundPosition: '0rem 0rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-men-problems-desktop-lg': {
      backgroundImage:
        "url('/images/landing/main/start-conversation/men-problems-bg-tablet.svg')",
      backgroundSize: '44rem 56.375rem',
      backgroundPosition: '0rem 0.75rem',
      backgroundRepeat: 'no-repeat',
    },

    '.bg-landing-main-choice': {
      backgroundImage: "url('/images/landing/main/choice/specialists-bg.svg')",
      backgroundSize: 'auto 121%',
      backgroundPosition: '50% -1rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-choice-mobile-h': {
      backgroundImage: "url('/images/landing/main/choice/specialists-bg.svg')",
      backgroundSize: 'auto 120%',
      backgroundPosition: '50% -0.25rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-choice-tablet': {
      backgroundImage: "url('/images/landing/main/choice/specialists-bg.svg')",
      backgroundSize: 'auto 119%',
      backgroundPosition: '50% -0.125rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-choice-tablet-h': {
      backgroundImage: "url('/images/landing/main/choice/specialists-bg.svg')",
      backgroundSize: 'auto 104%',
      backgroundPosition: '50% 1rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-choice-desktop': {
      backgroundImage: "url('/images/landing/main/choice/specialists-bg.svg')",
      backgroundSize: 'auto 103%',
      backgroundPosition: '50% -0.25rem',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-choice-desktop-lg': {
      backgroundImage: "url('/images/landing/main/choice/specialists-bg.svg')",
      backgroundSize: '115.5rem 57.75rem',
      backgroundPosition: '50% -1.25rem',
      backgroundRepeat: 'no-repeat',
    },

    '.bg-landing-main-specialists-description-woman': {
      backgroundImage:
        "url('/images/landing/main/specialists-description/specialists-description-woman-bg.svg')",
      backgroundSize: 'auto 88%',
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-specialists-description-woman-mobile-h': {
      backgroundImage:
        "url('/images/landing/main/specialists-description/specialists-description-woman-bg.svg')",
      backgroundSize: 'auto 80%',
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-specialists-description-woman-tablet': {
      backgroundImage:
        "url('/images/landing/main/specialists-description/specialists-description-woman-bg.svg')",
      backgroundSize: 'auto 80%',
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-specialists-description-woman-tablet-h': {
      backgroundImage:
        "url('/images/landing/main/specialists-description/specialists-description-woman-bg.svg')",
      backgroundSize: 'auto 80%',
      backgroundPosition: 'center bottom',
      backgroundRepeat: 'no-repeat',
    },
    '.bg-landing-main-intrigue': {
      backgroundImage: "url('/images/landing/main/intrigue/intrigue-bg.svg')",
      backgroundSize: 'auto 100%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  };
  addUtilities(newUtilities);
};
