import { Match, match, MatchResult } from 'path-to-regexp';
import { decodeUrl } from '@helpers/routing';
import { IRoute, Route, RouteDescriptor, RouteParams, RouteUrl } from '../route';
import { INav, INavConstructor, INavigator, INavNavigateOptions, NavArgs, NavMatch, NavPathname, NavQuery, NavRouteDict, NavRouteFactory, NavRouteList, NavUrl } from './types';
export const Nav: INavConstructor = class Nav<C = undefined> implements INav<C> {
  private readonly _navigator: INavigator<C>;
  private readonly _routes: NavRouteList = [];
  private readonly _routeFactory?: NavRouteFactory;

  /**
   * @todo
   * Extract all methods related to managing of routes to _routesDictToList,
   * addRoute, findRoute, findMatch, _match in new AppRoutes class.
   */
  private _routesDictToList = (routes: NavRouteDict): NavRouteList => {
    return Object.values(routes).reduce((acc: NavRouteList, val) => {
      if (val instanceof Route) {
        return [...acc, val];
      }
      return [...acc, ...this._routesDictToList((val as NavRouteDict))];
    }, []);
  };
  private _match = <P extends RouteParams,>(routeDescriptor: RouteDescriptor<P>, pathname: string): Match<P> => {
    return match<P>(routeDescriptor.pattern,
    //
    {
      decode: decodeURIComponent
    })(pathname);
  };
  public constructor(args: NavArgs<C>) {
    this._navigator = args.navigator;
    this._routes = this._routesDictToList(args.routes);
    this._routeFactory = args.routeFactory;
  }
  public addRoute<P extends RouteParams>(route: IRoute<P>): void {
    this._routes.push(route);
  }
  public findRoute(pathname: NavPathname): undefined | IRoute<NavQuery> {
    return this._routes.find(route => {
      return this._match(route.getDescriptor(), pathname);
    });
  }
  public findMatch<P extends RouteParams>(url: RouteUrl): null | NavMatch<P> {
    const {
      pathname,
      query
    } = decodeUrl(url);
    const route = this.findRoute(pathname);
    if (!route) {
      return null;
    }
    const _match = (this._match(route.getDescriptor(), pathname) as MatchResult<P>);
    return {
      route: (route as IRoute<P>),
      params: {
        ...query,
        ..._match.params
      }
    };
  }
  public getUrl(): NavUrl {
    return this._navigator.getUrl();
  }
  public getPathname(): NavPathname {
    return this._navigator.getPathname();
  }
  public getQuery(): NavQuery {
    return this._navigator.getQuery();
  }
  public isActive<P extends RouteParams>(routeDescriptor: RouteDescriptor<P>): boolean {
    return Boolean(this._match(routeDescriptor, this.getPathname()));
  }
  public async navigate<P extends RouteParams>(routeDescriptor: RouteDescriptor<P>, options?: INavNavigateOptions<C>): Promise<void> {
    let nextRouteDescriptor = routeDescriptor;
    if (this._routeFactory) {
      nextRouteDescriptor = this._routeFactory.get(this, nextRouteDescriptor, options?.routeFactoryOptions);
    }
    if (options?.mode) {
      nextRouteDescriptor = {
        ...nextRouteDescriptor,
        mode: options?.mode
      };
    }
    await this._navigator.navigate<P>(nextRouteDescriptor, options?.context);
  }
  public async navigateQuery(query: NavQuery, options?: INavNavigateOptions<C>): Promise<void> {
    const match = this.findMatch(this.getPathname());
    if (!match) {
      return;
    }
    await this.navigate(match.route.getDescriptor({
      ...match.params,
      ...this.getQuery(),
      ...query
    }), options);
  }
};