import { findById, rejectById } from '@helpers/data';
import { calculateElapsedTimeInYears } from '@helpers/date';
import { isPhoto } from '@helpers/media-files';
import { isClient, isSpecialist, photoOrAvatarUrl } from '@helpers/member';
import { CurrentClient, CurrentSpecialist, MediaFile, MediaFiles, MemberProfileState, Photo, PhotoId, Photos, ROLE_TYPE, SEX } from '@types';
import { appCreateSelector, RootState } from '@store';
import { currentClientGetAvatarId, currentClientGetMe } from '@selectors/current-client';
import { currentSpecialistGetMe } from '@selectors/current-specialist';

/**
 * regular selectors
 */

export const currentMemberGetRoleType = (state: RootState): undefined | ROLE_TYPE => {
  return state.app?.account.roleType;
};

/**
 * memoized reselect selectors
 */

export const currentMemberGetMember: (state: RootState) => undefined | CurrentClient | CurrentSpecialist = appCreateSelector([currentMemberGetRoleType, (state: RootState) => state], (roleType, state) => {
  if (isClient(roleType)) {
    return currentClientGetMe(state);
  }
  if (isSpecialist(roleType)) {
    return currentSpecialistGetMe(state);
  }
});
export const currentMemberGetExists: (state: RootState) => boolean = appCreateSelector(currentMemberGetMember,
//
currentMember => {
  return Boolean(currentMember);
});
export const currentMemberGetName: (state: RootState) => undefined | string = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.name);
export const currentMemberGetSex: (state: RootState) => undefined | SEX = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.sex);
export const currentMemberGetBirthDate: (state: RootState) => undefined | string = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.birthDate);
export const currentMemberGetAge: (state: RootState) => undefined | number = appCreateSelector(currentMemberGetBirthDate, birthDate => {
  return calculateElapsedTimeInYears(birthDate);
});
export const currentMemberGetPhotos: (state: RootState) => undefined | Photos = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.media?.filter(isPhoto));
export const currentMemberGetMedia: (state: RootState) => undefined | MediaFiles = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.media);
export const currentMemberGetDefaultPhotoId: (state: RootState) => undefined | number = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.defaultPhotoId);
export const currentMemberGetDefaultPhoto: (state: RootState) => undefined | Photo = appCreateSelector([currentMemberGetDefaultPhotoId, currentMemberGetPhotos], (defaultPhotoId, photos) => {
  if (!defaultPhotoId || !photos) {
    return undefined;
  }
  return findById(photos, defaultPhotoId);
});

/**
 * @deprecated
 * Candidate to be deleted in case of doing cropping on the client rather
 * than on the server.
 */
export const currentMemberGetPhotosWithoutDefault: (state: RootState) => undefined | MediaFiles = appCreateSelector([currentMemberGetPhotos, currentMemberGetDefaultPhotoId], (photos, defaultPhotoId) => {
  if (!photos) {
    return undefined;
  }
  return rejectById(photos, defaultPhotoId);
});
export const currentMemberGetPhotoById: (state: RootState, photoId: PhotoId) => undefined | MediaFile = appCreateSelector([currentMemberGetPhotos, (_state, photoId: PhotoId) => photoId], (photos, photoId) => {
  if (!photos) {
    return undefined;
  }
  return findById(photos, photoId);
});

/**
 * @deprecated
 * Candidate to be deleted in case of doing cropping on the client rather
 * than on the server.
 */
export const currentMemberGetDefaultPhotoOriginPhotoId: (state: RootState) => undefined | number = appCreateSelector(currentMemberGetDefaultPhoto, defaultPhoto => defaultPhoto?.originPhotoId);
export const currentMemberGetDefaultPhotoOrAvatarUrl: (state: RootState) => undefined | string = appCreateSelector([currentMemberGetMember, (state: RootState) => state], (currentMember, state) => {
  if (!currentMember) {
    return undefined;
  }
  const defaultPhoto = currentMemberGetDefaultPhoto(state);
  const roleType = currentMemberGetRoleType(state);
  const sex = currentMemberGetSex(state);
  if (isClient(roleType)) {
    const avatarId = currentClientGetAvatarId(state);
    return photoOrAvatarUrl(defaultPhoto?.url, avatarId, sex);
  }
  if (isSpecialist(roleType)) {
    return photoOrAvatarUrl(defaultPhoto?.url, undefined, sex);
  }
  return undefined;
});
export const currentMemberGetRating: (state: RootState) => undefined | number = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.rating);
export const currentMemberGetDefaultSubjectId: (state: RootState) => undefined | number = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.defaultSubjectId);
export const currentMemberGetState: (state: RootState) => undefined | MemberProfileState = appCreateSelector(currentMemberGetMember, currentMember => currentMember?.state);
export const currentMemberGetHasPhotos: (state: RootState) => undefined | boolean = appCreateSelector(currentMemberGetState, profileState => profileState?.hasPhotos);
export const currentMemberGetHasDefaultPhoto: (state: RootState) => undefined | boolean = appCreateSelector(currentMemberGetState, profileState => profileState?.hasDefaultPhoto);
export const currentMemberGetIsInfoFilled: (state: RootState) => undefined | boolean = appCreateSelector(currentMemberGetState, profileState => profileState?.infoFilled);
export const currentMemberGetIsOnModeration: (state: RootState) => undefined | boolean = appCreateSelector(currentMemberGetState, profileState => profileState?.onModeration);
export const currentMemberGetIsEverApproved: (state: RootState) => undefined | boolean = appCreateSelector(currentMemberGetState, profileState => profileState?.isEverApproved);
export const currentMemberGetProfileIsFilled: (state: RootState) => undefined | boolean = appCreateSelector([currentMemberGetRoleType, (state: RootState) => state], (roleType, state) => {
  const hasPhotos = currentMemberGetHasPhotos(state);
  const isInfoFilled = currentMemberGetIsInfoFilled(state);
  if (isClient(roleType)) {
    return isInfoFilled;
  }
  if (isSpecialist(roleType)) {
    return hasPhotos && isInfoFilled;
  }
});
export const currentMemberGetProfileIsFilledAndApproved: (state: RootState) => undefined | boolean = appCreateSelector([currentMemberGetRoleType, (state: RootState) => state], (roleType, state) => {
  const profileIsFilled = currentMemberGetProfileIsFilled(state);
  const profileIsApproved = currentMemberGetIsEverApproved(state);
  if (isClient(roleType)) {
    return profileIsFilled;
  }
  if (isSpecialist(roleType)) {
    return profileIsFilled && profileIsApproved;
  }
});