import { defineGeometryType } from '@helpers/geometry';
import { GeometryResizeReducer } from './types';
export const geometryResizeReducer: GeometryResizeReducer = (state, action) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    width
  } = action.payload;
  const nextGeometryType = defineGeometryType();
  if (state.type === nextGeometryType) {
    return state;
  }
  return {
    ...state,
    type: defineGeometryType()
  };
};