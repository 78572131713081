import { FILE_TYPE } from '@types';
import { CurrentClientReceiveUploadPhotoReducer } from './types';
export const currentClientReceiveUploadPhotoReducer: CurrentClientReceiveUploadPhotoReducer = (state, action) => {
  if (!state.me) {
    return state;
  }
  const {
    data
  } = action.payload;
  const photo = {
    id: data.id,
    fileType: FILE_TYPE.PHOTO,
    url: data.url,
    width: data.width,
    height: data.height,
    status: data.status
  };
  return {
    ...state,
    me: {
      ...state.me,
      media: [...state.me.media, photo],
      state: data.profileState
    }
  };
};