import { calendarIsCurrentClient, calendarIsCurrentClientCalendar, calendarIsCurrentSpecialist, calendarIsCurrentSpecialistCalendar } from '@helpers/calendar';
import { rejectById } from '@helpers/data';
import { CalendarReceiveRejectCurrentMemberConsultationReducer } from './types';
export const calendarReceiveRejectCurrentMemberConsultationReducer: CalendarReceiveRejectCurrentMemberConsultationReducer = (state, action) => {
  const {
    consultationId
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentSpecialist(currentMember) && calendarIsCurrentSpecialistCalendar(currentMember.calendar)) {
    const calendar = currentMember.calendar;
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...calendar,
          consultations: rejectById(calendar.consultations, consultationId)
        }
      }
    };
  }
  if (calendarIsCurrentClient(currentMember) && calendarIsCurrentClientCalendar(currentMember.calendar)) {
    const calendar = currentMember.calendar;
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...calendar,
          consultations: rejectById(calendar.consultations, consultationId)
        }
      }
    };
  }
  return state;
};