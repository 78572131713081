import { NOTIFICATION_TYPE } from '@config/notifications';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { photoOrAvatarUrl } from '@helpers/member';
import { RealtimeViewReceivedEventPayloadData } from '@services/dto/realtime/realtime-view-event/realtime-view-received';
import { notificationsUiHandleAdd } from '@actions/notifications-ui/notifications-ui-handle-add';
import { viewsAddView, viewsIncreaseViewsCounter } from '@slices/views';
import { RealtimeViewReceived } from './types';
export const realtimeViewReceived: RealtimeViewReceived = event => {
  return dispatch => {
    const data = readJson<RealtimeViewReceivedEventPayloadData>(event.payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      view
    } = data;
    dispatch(viewsAddView({
      view
    }));
    dispatch(viewsIncreaseViewsCounter({
      increment: 1
    }));
    dispatch(notificationsUiHandleAdd({
      type: NOTIFICATION_TYPE.NEW_VIEW,
      avatar: photoOrAvatarUrl(view.photoUrl, view.avatarId, view.sex),
      name: view.name ?? ''
    }));
  };
};