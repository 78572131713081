export const CHAT_LIST_ITEM_MAX_MESSAGES_NUMBER = 30;
export const SUPPORT_CHAT_ID = 'support';

/**
 * @todo
 * Reconsider the const below. Probably we don't need store the config
 * as config constant.
 */
export const CHATS_GET_LIST_DEFAULT_REQUEST_PARAMS = {
  limit: 20,
  offset: 0
};