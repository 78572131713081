// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initVkScript = (vkOpenApiId) => {
  !(function () {
    var t = document.createElement('script');
    (t.type = 'text/javascript'),
      (t.async = !0),
      (t.src = 'https://vk.com/js/api/openapi.js?169'),
      (t.onload = function () {
        // eslint-disable-next-line no-undef
        VK.Retargeting.Init(vkOpenApiId), VK.Retargeting.Hit();
        // eslint-disable-next-line no-undef
      }),
      document.head.appendChild(t);
  })();
};
