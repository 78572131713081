import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SpecialistsPostMeRequestData, SpecialistsPostMeRequestDto, SpecialistsPostMeResponseData, SpecialistsPostMeResponseDto } from '@services/dto/specialists';
import { specialistsPostMeRequestToDto, specialistsPostMeResponseFromDto } from '@mapping/specialists';
type PostSpecialistsMe = (requestData: SpecialistsPostMeRequestData) => AuthedJsonFetchResult<SpecialistsPostMeResponseData>;
export const specialistsUpdateMe: PostSpecialistsMe = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SpecialistsPostMeResponseDto, SpecialistsPostMeRequestDto>(ENDPOINTS.specialists.me(), {
    method: 'POST',
    data: specialistsPostMeRequestToDto(requestData)
  });
  return {
    promise: promise.then(specialistsPostMeResponseFromDto),
    cancel
  };
};