import { uniq } from '@helpers/data';
import { InvitationsAddInvitationReducer } from './types';
export const invitationsAddInvitationReducer: InvitationsAddInvitationReducer = (state, action) => {
  const {
    invitation
  } = action.payload;
  const {
    invitationsById,
    invitationsIds,
    totalCount
  } = state;
  return {
    ...state,
    invitationsById: {
      ...invitationsById,
      [invitation.id]: invitation
    },
    invitationsIds: uniq([invitation.id, ...invitationsIds]),
    totalCount: !totalCount //
    ? totalCount + 1 : totalCount
  };
};