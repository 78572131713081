import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SpecialistsGetStatisticsRequestData, SpecialistsGetStatisticsRequestParams, SpecialistsGetStatisticsResponseData, SpecialistsGetStatisticsResponseDto } from '@services/dto/specialists';
import { specialistsGetStatisticsRequestToParams, specialistsGetStatisticsResponseFromDto } from '@mapping/specialists';
type SpecialistsGetStatistics = (requestData: SpecialistsGetStatisticsRequestData) => AuthedJsonFetchResult<SpecialistsGetStatisticsResponseData>;
export const specialistsGetStatistics: SpecialistsGetStatistics = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SpecialistsGetStatisticsResponseDto, undefined, SpecialistsGetStatisticsRequestParams>(ENDPOINTS.specialists.getStatistics(), {
    method: 'GET',
    params: specialistsGetStatisticsRequestToParams(requestData)
  });
  return {
    promise: promise.then(specialistsGetStatisticsResponseFromDto),
    cancel
  };
};