import { ACCOUNT_WITHDRAW_FUNDS_MAX_AMOUNT, ACCOUNT_WITHDRAW_FUNDS_MIN_AMOUNT, ACCOUNT_WITHDRAW_FUNDS_QIWI_MIN_AMOUNT, ACCOUNT_WITHDRAW_FUNDS_RUS_BANK_CARD_MIN_AMOUNT, ACCOUNT_WITHDRAW_FUNDS_UKR_BANK_CARD_MIN_AMOUNT } from './account';
export enum ERROR_CODE {
  VALIDATION_ERROR = 10,
  INVALID_FRONTEND_HOST = 20,
  ACCESS_DENIED = 30,
  FIELD_REQUIRED = 40,
  PHOTO_REQUIRED = 'PHOTO_REQUIRED',
  INVALID_PASSWORD_LENGTH = 50,
  INVALID_EMAIL = 60,
  INVALID_USER_NAME = 40160,
  INVALID_USER_NAME_LENGTH = 40150,
  INVALID_CARDHOLDER_NAME = 'Invalid cardholder name',
  PASSWORD_REPEATED_NOT_EQUAL_PASSWORD = 70,
  FIELD_IS_INVALID = 80,
  /**
   * @todo replace codes with real API when it's ready
   */
  INVALID_CREDIT_CARD_NUMBER = 310,
  INVALID_WEBMONEY_WALLET = 320,
  INVALID_QIWI_WALLET = 330,
  USER_BY_SPAM_LOCKED = 190,
  MESSAGE_HAS_SPAM = 200,
  USER_LOCKED_FOR_MESSAGE_SEND = 210,
  PROFILE_NOT_APPROVED_YET = 10040,
  INTERNAL_ACCOUNT_EXCEPTION = 20010,
  USER_NOT_FOUND = 20020,
  INVALID_OLD_PASSWORD = 20030,
  INVALID_TEXTAREA_LENGTH = 'Invalid about self length',
  INVALID_ACCOUNT_ROLE = 20040,
  USER_PROFILE_CREATE_FAILED = 20050,
  USER_WITH_SAME_NAME_ALREADY_EXISTS = 20060,
  PASSWORD_CHANGED_BUT_GET_ACCESS_TOKEN_FAILED = 20070,
  CHANGE_EMAIL_FAILED = 20080,
  EMAIL_ALREADY_REGISTERED = 20120,
  EXTERNAL_SIGN_IN_FAILED = 20130,
  EMAIL_NOT_CONFIRMED = 20135,
  INCORRECT_USERNAME_OR_PASSWORD = 'Incorrect username or password',
  ACCOUNT_DELETED_CAN_BE_RESTORED = 20140,
  ACCOUNT_DELETED_CAN_NOT_BE_RESTORED = 20150,
  ACCOUNT_DOES_NOT_EXIST_ROLE_REQUIRED = 20180,
  ACCOUNT_EXTERNAL_ACCOUNT_ALREADY_LINKED_TO_ANOTHER_ACCOUNT = 20190,
  ACCOUNT_INVALID_PROTECTED_USER_ID = 20200,
  EMAIL_REGISTRATION_CODE_ALREADY_SENT = 20210,
  INVALID_EMAIL_REGISTRATION_CODE = 20220,
  INVALID_PASSWORD = 20230,
  INVALID_LONG_TERM_TOKEN = 20240,
  EMAIL_ADDRESS_DOES_NOT_EXISTS = 20260,
  USER_IP_BANNED = 20270,
  USER_IS_OFFLINE = 20290,
  ACCOUNT_HAS_BEEN_LOCKED_BY_MANY_COMPLAINTS = 20300,
  ACCOUNT_CURRENT_MEMBER_BLOCKED = 20310,
  ACCOUNT_MEMBER_BLOCKED = 20320,
  ACCOUNT_DENIED_INVITATION = 20330,
  ACCOUNT_MEMBER_DELETED = 20350,
  CHAT_MESSAGE_INVALID_CHARACTERS = 100,
  CHAT_MESSAGE_INVALID_LENGTH = 'CHAT_MESSAGE_INVALID_LENGTH',
  USER_HAS_NO_ACCESS_TO_CHAT = 30110,
  CHAT_MESSAGE_PHONE_NOT_ALLOWED = 30120,
  CHAT_MESSAGE_URL_NOT_ALLOWED = 30130,
  CHAT_MESSAGE_EMAIL_NOT_ALLOWED = 30140,
  CHAT_NOT_FOUND = 30150,
  CHAT_MESSAGE_ATTACHMENT_SAVE_ERROR = 30160,
  CHAT_MESSAGE_NOT_ALLOW_BY_LOW_RATING = 30170,
  CHAT_NOT_ALLOW_BY_ROLE_TYPES = 30180,
  CHAT_MESSAGE_INVALID_RECIPIENT = 30200,
  CHAT_MESSAGE_RECIPIENT_NOT_ENOUGH_MONEY = 30215,
  CHAT_MESSAGE_COMPLIMENT_REQUIRED = 'CHAT_MESSAGE_COMPLIMENT_REQUIRED',
  CHAT_ALREADY_RUNNING_CALL = 30230,
  CHAT_ALREADY_RUNNING_CALL_BY_RECEPIENT_USER = 30280,
  CLIENT_NOT_ENOUGH_COINS = 30210,
  /**
   * @deprecated
   * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
   */
  CLIENT_PREPAYMENT_REQUIRED = 40300,
  /**
   * @deprecated
   * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
   */
  CLIENT_PREPAYMENT_NOT_ENABLED = 40280,
  /**
   * @deprecated
   * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
   */
  CLIENT_ALREADY_CONFIRM_PREPAYMENT = 40290,
  CLIENT_NOT_ENOUGH_COINS_CALL_END = 'CLIENT_NOT_ENOUGH_COINS_CALL_END',
  MEMBER_COMPLAINT_REQUIRED = 'MEMBER_COMPLAINT_REQUIRED',
  MEMBER_RATING_REQUIRED = 'MEMBER_RATING_REQUIRED',
  PAYMENT_AGGREGATOR_NOT_FOUND = 40000,
  PAYMENT_AGGREGATOR_ERROR = 40030,
  PAYMENT_AGGREGATOR_MIR_ERROR = 'PAYMENT_AGGREGATOR_MIR_ERROR',
  WITHDRAW_ENTER_AMOUNT = 'WITHDRAW_ENTER_AMOUNT',
  WITHDRAW_MONEY_MIN_LIMIT_EXCEEDED = 340,
  WITHDRAW_MONEY_UKR_BANK_CARD_MIN_LIMIT_EXCEEDED = 'WITHDRAW_MONEY_UKR_BANK_CARD_MIN_LIMIT_EXCEEDED',
  WITHDRAW_MONEY_RUS_BANK_CARD_MIN_LIMIT_EXCEEDED = 'WITHDRAW_MONEY_RUS_BANK_CARD_MIN_LIMIT_EXCEEDED',
  WITHDRAW_MONEY_QIWI_MIN_LIMIT_EXCEEDED = 'WITHDRAW_MONEY_QIWI_MIN_LIMIT_EXCEEDED',
  WITHDRAW_MONEY_BALANCE_AMOUNT_EXCEEDED = 'WITHDRAW_MONEY_BALANCE_AMOUNT_EXCEEDED',
  WITHDRAW_MONEY_MAX_LIMIT_EXCEEDED = 40060,
  WITHDRAW_DO_NOT_ALLOW_HAS_OPEN_ORDER = 40080,
  USER_ALREADY_HAS_PREMIUM = 40190,
  BLOB_FILE_REMOVE_ERROR = 110,
  UPLOAD_DOCUMENTS_LIMIT_EXCEEDED = 120,
  PHOTO_NOT_APPROVED = 130,
  APPROVED_DEFAULT_PROFILE_PHOTO_NOT_EXISTS = 220,
  PHOTO_NOT_FOUND = 140,
  EXISTS_APPROVED_DEFAULT_PHOTO = 150,
  EXISTS_APPROVED_PHOTO = 160,
  AVATAR_DOES_NOT_MATCH_GENDER = 170,
  INVALID_AVATAR_ID = 180,
  UNABLE_CROP_IMAGE = 221,
  PHOTO_CANNOT_BE_DELETED = 222,
  INVALID_PHOTO_FILE_TYPE = 'INVALID_PHOTO_FILE_TYPE',
  INVALID_VIDEO_FILE_TYPE = 'INVALID_VIDEO_FILE_TYPE',
  EXCEEDED_PHOTO_FILE_SIZE = 'EXCEEDED_PHOTO_FILE_SIZE',
  EXCEEDED_VIDEO_FILE_SIZE = 'EXCEEDED_VIDEO_FILE_SIZE',
  INVALID_DOCUMENT_FILE_TYPE = 'INVALID_DOCUMENT_FILE_TYPE',
  EXCEEDED_DOCUMENT_FILE_SIZE = 'EXCEEDED_DOCUMENT_FILE_SIZE',
  INVALID_CONFIRMATION_CODE_LENGTH = 'INVALID_CONFIRMATION_CODE_LENGTH',
  CALENDAR_NONCE_IS_INCORRECT = 60020,
  CALENDAR_THIS_TIME_IS_UNAVAILABLE = 60050,
  CALENDAR_YOU_HAVE_OTHER_APPOINTMENT_FOR_THIS_TIME = 60060,
  CALENDAR_NOT_ENOUGH_COINS = 70010,
  CALENDAR_CONSULTATION_REJECT_MESSAGE_INVALID_LENGTH = 'CALENDAR_CONSULTATION_REJECT_MESSAGE_INVALID_LENGTH',
  /** Twilio error codes **/
  INVALID_MEDIA_ACCESS_TOKEN = 20101,
  MEDIA_RESOURCE_IS_NOT_AVAILABLE = 5300,
  // 5300 is virtual code. There is no such code in docs
  WEB_RTC_IS_NOT_SUPPORTED = 5301,
  // 5301 is virtual code. There is no such code in docs
  SIGNALING_CONNECTION_ERROR = 53000,
  SIGNALING_CONNECTION_DISCONNECTED_ERROR = 53001,
  SIGNALING_CONNECTION_TIMEOUT_ERROR = 53002,
  SIGNALING_SERVER_BUSY_ERROR = 53006,
  ROOM_MAX_PARTICIPANTS_EXCEEDED_ERROR = 53105,
  ROOM_NOT_FOUND_ERROR = 53106,
  ROOM_IS_COMPLETED = 53118,
  PARTICIPANT_DUPLICATE_IDENTITY_ERROR = 53205,
  MEDIA_CONNECTION_ERROR = 53405,
  /** End of Twilio error codes **/

  UNEXPECTED_ERROR = 500,
}
export const ERROR_TEXTS_BY_CODE = {
  [ERROR_CODE.VALIDATION_ERROR]: 'Ошибка валидации',
  [ERROR_CODE.INVALID_FRONTEND_HOST]: 'Неверный хост',
  [ERROR_CODE.ACCESS_DENIED]: 'Доступ запрещен',
  [ERROR_CODE.FIELD_REQUIRED]: 'Поле обязательно для заполнения',
  [ERROR_CODE.PHOTO_REQUIRED]: 'Необходимо загрузить фото',
  [ERROR_CODE.INVALID_PASSWORD_LENGTH]: 'Длина пароля должна быть минимум 6 символов',
  [ERROR_CODE.INVALID_EMAIL]: `Неправильный формат e-mail`,
  [ERROR_CODE.INVALID_USER_NAME]: `Введено некорректное имя`,
  [ERROR_CODE.INVALID_USER_NAME_LENGTH]: `Имя может содержать от 2 до 20 символов`,
  [ERROR_CODE.INVALID_CARDHOLDER_NAME]: `Введено некорректное имя держателя`,
  [ERROR_CODE.PASSWORD_REPEATED_NOT_EQUAL_PASSWORD]: 'Подтверждение пароля не совпадает с паролем',
  [ERROR_CODE.FIELD_IS_INVALID]: 'Введено неверное значение',
  [ERROR_CODE.INVALID_CREDIT_CARD_NUMBER]: 'Некорректный номер карты',
  [ERROR_CODE.INVALID_WEBMONEY_WALLET]: 'Некорректный номер кошелька Webmoney',
  [ERROR_CODE.INVALID_QIWI_WALLET]: 'Некорректный номер кошелька Qiwi',
  [ERROR_CODE.USER_BY_SPAM_LOCKED]: 'Отправка сообщений временно недоступна! Обратитесь к администрации сайта.',
  [ERROR_CODE.MESSAGE_HAS_SPAM]: 'Отправка сообщений временно недоступна! Обратитесь к администрации сайта.',
  [ERROR_CODE.USER_LOCKED_FOR_MESSAGE_SEND]: 'Пользователь заблокирован',
  [ERROR_CODE.CHAT_MESSAGE_INVALID_CHARACTERS]: 'Сообщение содержит некорректные символы',
  [ERROR_CODE.CHAT_MESSAGE_INVALID_LENGTH]: 'Сообщение может содержать от 1 до 1000 символов',
  [ERROR_CODE.CHAT_MESSAGE_EMAIL_NOT_ALLOWED]: 'Ваш текущий рейтинг не позволяет отправлять email',
  [ERROR_CODE.USER_HAS_NO_ACCESS_TO_CHAT]: 'Нет доступа к чату',
  [ERROR_CODE.CHAT_MESSAGE_PHONE_NOT_ALLOWED]: 'Ваш текущий рейтинг не позволяет отправлять телефонные номера',
  [ERROR_CODE.CHAT_MESSAGE_URL_NOT_ALLOWED]: 'Ваш текущий рейтинг не позволяет отправлять ссылки',
  [ERROR_CODE.CHAT_NOT_FOUND]: 'Указанный чат не найден',
  [ERROR_CODE.CHAT_MESSAGE_ATTACHMENT_SAVE_ERROR]: 'При сохранении файла произошла ошибка',
  [ERROR_CODE.CHAT_MESSAGE_NOT_ALLOW_BY_LOW_RATING]: 'Ваш текущий рейтинг не позволяет отправлять изображения',
  [ERROR_CODE.CHAT_NOT_ALLOW_BY_ROLE_TYPES]: 'Вы не можете отправить сообщение выбранному пользователю',
  [ERROR_CODE.CLIENT_NOT_ENOUGH_COINS]: 'Недостаточно монет на счете',
  [ERROR_CODE.CHAT_MESSAGE_COMPLIMENT_REQUIRED]: 'Выберите комплимент для отправки',
  [ERROR_CODE.CHAT_MESSAGE_INVALID_RECIPIENT]: 'Недостаточно прав для отправки сообщения выбранному пользователю',
  [ERROR_CODE.CHAT_ALREADY_RUNNING_CALL]: 'У вас уже есть активный звонок.\nЗавершите его, чтобы начать новый',
  [ERROR_CODE.CLIENT_NOT_ENOUGH_COINS_CALL_END]: 'Недостаточно средств для продолжения звонка',
  [ERROR_CODE.MEMBER_COMPLAINT_REQUIRED]: 'Выберите жалобу для отправки',
  [ERROR_CODE.MEMBER_RATING_REQUIRED]: 'Выберите оценку для пользователя',
  [ERROR_CODE.PAYMENT_AGGREGATOR_NOT_FOUND]: 'Выбранный способ оплаты не доступен. Попробуйте выбрать другой или оплатить позднее',
  [ERROR_CODE.PAYMENT_AGGREGATOR_ERROR]: 'Выбранный способ оплаты не доступен. Попробуйте выбрать другой или оплатить позднее',
  [ERROR_CODE.WITHDRAW_ENTER_AMOUNT]: 'Введите сумму для вывода',
  [ERROR_CODE.WITHDRAW_MONEY_MIN_LIMIT_EXCEEDED]: `Минимальная сумма для вывода – ${ACCOUNT_WITHDRAW_FUNDS_MIN_AMOUNT} руб`,
  [ERROR_CODE.WITHDRAW_MONEY_UKR_BANK_CARD_MIN_LIMIT_EXCEEDED]: `Минимальная сумма для вывода через банковские карты Украины – ${ACCOUNT_WITHDRAW_FUNDS_UKR_BANK_CARD_MIN_AMOUNT} руб`,
  [ERROR_CODE.WITHDRAW_MONEY_RUS_BANK_CARD_MIN_LIMIT_EXCEEDED]: `Минимальная сумма для вывода через банковские карты России – ${ACCOUNT_WITHDRAW_FUNDS_RUS_BANK_CARD_MIN_AMOUNT} руб`,
  [ERROR_CODE.WITHDRAW_MONEY_QIWI_MIN_LIMIT_EXCEEDED]: `Минимальная сумма для вывода через QiWi – ${ACCOUNT_WITHDRAW_FUNDS_QIWI_MIN_AMOUNT} руб`,
  [ERROR_CODE.WITHDRAW_MONEY_BALANCE_AMOUNT_EXCEEDED]: 'Данная сумма больше количества средств на вашем балансе',
  [ERROR_CODE.WITHDRAW_MONEY_MAX_LIMIT_EXCEEDED]: `Вы превысили cуточный лимит на вывод в размере ${ACCOUNT_WITHDRAW_FUNDS_MAX_AMOUNT} руб`,
  [ERROR_CODE.WITHDRAW_DO_NOT_ALLOW_HAS_OPEN_ORDER]: 'Запрос на вывод средств уже создан',
  [ERROR_CODE.USER_ALREADY_HAS_PREMIUM]: 'У вас уже подключен Premium',
  [ERROR_CODE.INTERNAL_ACCOUNT_EXCEPTION]: 'Ссылка уже недействительна',
  [ERROR_CODE.USER_NOT_FOUND]: 'Ссылка восстановления отправлена на указанный email',
  [ERROR_CODE.INVALID_OLD_PASSWORD]: 'Введен неверный пароль',
  [ERROR_CODE.INVALID_PASSWORD]: 'Введен неверный пароль',
  [ERROR_CODE.INVALID_TEXTAREA_LENGTH]: 'Длина текста не более 500 символов',
  [ERROR_CODE.INVALID_ACCOUNT_ROLE]: 'Неверная роль участника',
  [ERROR_CODE.USER_PROFILE_CREATE_FAILED]: 'Ошибка сохранения профиля',
  [ERROR_CODE.PASSWORD_CHANGED_BUT_GET_ACCESS_TOKEN_FAILED]: 'Пароль изменен, но возникла ошибка доступа',
  [ERROR_CODE.USER_WITH_SAME_NAME_ALREADY_EXISTS]: 'Неверный логин/пароль',
  [ERROR_CODE.CHANGE_EMAIL_FAILED]: 'Пользователь с таким email уже существует',
  [ERROR_CODE.EMAIL_ALREADY_REGISTERED]: 'Пользователь с таким email уже существует',
  [ERROR_CODE.EXTERNAL_SIGN_IN_FAILED]: 'Ошибка входа в приложение',
  [ERROR_CODE.EMAIL_NOT_CONFIRMED]: 'Сначала необходимо подтвердить email',
  [ERROR_CODE.INCORRECT_USERNAME_OR_PASSWORD]: 'Неверный email или пароль',
  [ERROR_CODE.ACCOUNT_DELETED_CAN_BE_RESTORED]: 'Аккаунт удален, но может быть восстановлен',
  [ERROR_CODE.ACCOUNT_DELETED_CAN_NOT_BE_RESTORED]: 'Аккаунт удален, и не может быть восстановлен',
  [ERROR_CODE.ACCOUNT_EXTERNAL_ACCOUNT_ALREADY_LINKED_TO_ANOTHER_ACCOUNT]: 'Выбранный аккаунт уже привязан к другому пользователю',
  [ERROR_CODE.ACCOUNT_INVALID_PROTECTED_USER_ID]: 'Невалидный код пользователя',
  [ERROR_CODE.INVALID_EMAIL_REGISTRATION_CODE]: 'Введен неверный код подтверждения',
  [ERROR_CODE.EMAIL_ADDRESS_DOES_NOT_EXISTS]: 'Email не существует. Пожалуйста, введите действительный email.',
  [ERROR_CODE.USER_IP_BANNED]: 'Доступ запрещён. Свяжитесь с администрацией сайта.',
  [ERROR_CODE.USER_IS_OFFLINE]: 'Пользователь оффлайн',
  [ERROR_CODE.ACCOUNT_CURRENT_MEMBER_BLOCKED]: 'Аккаунт заблокирован администратором',
  [ERROR_CODE.ACCOUNT_MEMBER_BLOCKED]: 'Данная анкета заблокирована администратором сайта',
  [ERROR_CODE.ACCOUNT_MEMBER_DELETED]: 'Данная анкета удалена пользователем',
  [ERROR_CODE.UPLOAD_DOCUMENTS_LIMIT_EXCEEDED]: 'В вашем профиле может быть максимум 5 документов. Удалите старый документ, чтобы загрузить новый.',
  [ERROR_CODE.PHOTO_NOT_APPROVED]: 'Фото должно быть одобрено модератором, преде чем вы сможете сделать его главным',
  [ERROR_CODE.PHOTO_NOT_FOUND]: 'Фото не найдено ;( Возможно фото было удалено вами ранее',
  [ERROR_CODE.EXISTS_APPROVED_DEFAULT_PHOTO]: 'Вы не можете установить аватар, если выбрано фото по умолчанию',
  [ERROR_CODE.EXISTS_APPROVED_PHOTO]: 'Вы не можете установить аватар, если выбрано фото по умолчанию',
  [ERROR_CODE.AVATAR_DOES_NOT_MATCH_GENDER]: 'Выбранный аватар не соответствует полу',
  [ERROR_CODE.INVALID_AVATAR_ID]: 'Выберите аватар',
  [ERROR_CODE.INVALID_PHOTO_FILE_TYPE]: 'Загружать можно только jpeg и png файлы',
  [ERROR_CODE.INVALID_VIDEO_FILE_TYPE]: 'Видео в таком формате не поддерживатся',
  [ERROR_CODE.EXCEEDED_PHOTO_FILE_SIZE]: 'Фото не может быть больше 10 Мб',
  [ERROR_CODE.EXCEEDED_VIDEO_FILE_SIZE]: 'Видео не может быть больше 500 МБ',
  [ERROR_CODE.INVALID_DOCUMENT_FILE_TYPE]: 'Загружать можно только jpeg, png и pdf файлы',
  [ERROR_CODE.EXCEEDED_DOCUMENT_FILE_SIZE]: 'Загружать можно только файлы до 10 Мб',
  [ERROR_CODE.INVALID_CONFIRMATION_CODE_LENGTH]: 'Код должен содержать 3 цифры',
  [ERROR_CODE.CALENDAR_CONSULTATION_REJECT_MESSAGE_INVALID_LENGTH]: 'Сообщение может содержать до 150 символов',
  /** Twilio error messages **/
  [ERROR_CODE.INVALID_MEDIA_ACCESS_TOKEN]: 'Ошибка: Токен доступа к звонку невалиден',
  [ERROR_CODE.MEDIA_RESOURCE_IS_NOT_AVAILABLE]: 'Ошибка получения доступа к камере/микрофону',
  [ERROR_CODE.WEB_RTC_IS_NOT_SUPPORTED]: 'Ошибка: Невозможно совершить звонок. Пожалуйста, используйте браузер с поддержкой технологии WebRTC',
  [ERROR_CODE.SIGNALING_CONNECTION_ERROR]: 'Ошибка соединения: пожалуйста, проверьте интернет-соединение',
  [ERROR_CODE.SIGNALING_CONNECTION_DISCONNECTED_ERROR]: 'Ошибка соединения: пожалуйста, проверьте интернет-соединение',
  [ERROR_CODE.SIGNALING_CONNECTION_TIMEOUT_ERROR]: 'Ошибка соединения: пожалуйста, попробуйте немного позже',
  [ERROR_CODE.SIGNALING_SERVER_BUSY_ERROR]: 'Ошибка соединения: пожалуйста, попробуйте немного позже',
  [ERROR_CODE.ROOM_MAX_PARTICIPANTS_EXCEEDED_ERROR]: 'Ошибка соединения: достигнуто максимальное число участников',
  [ERROR_CODE.ROOM_NOT_FOUND_ERROR]: 'Ошибка соединения: невозможно подключиться к звонку',
  [ERROR_CODE.ROOM_IS_COMPLETED]: 'Ошибка соединения: звонок завершен',
  [ERROR_CODE.PARTICIPANT_DUPLICATE_IDENTITY_ERROR]: 'Ошибка соединения: участник с указанными данными уже участвует в звонке',
  [ERROR_CODE.MEDIA_CONNECTION_ERROR]: 'Ошибка соединения: ошибка подключения к медиаустройствам',
  /** End of Twilio error messages **/

  [ERROR_CODE.UNEXPECTED_ERROR]: 'Что-то пошло не так ;('
};
export const ERROR_NAME = {
  NOT_ALLOWED_ERROR: 'NotAllowedError',
  NOT_FOUND_ERROR: 'NotFoundError',
  NOT_READABLE_ERROR: 'NotReadableError',
  OVERCONSTRAINED_ERROR: 'OverconstrainedError',
  TYPE_ERROR: 'TypeError'
};
export const TWILIO_MEDIA_ERRORS = [ERROR_NAME.NOT_ALLOWED_ERROR, ERROR_NAME.NOT_FOUND_ERROR, ERROR_NAME.NOT_READABLE_ERROR, ERROR_NAME.OVERCONSTRAINED_ERROR, ERROR_NAME.TYPE_ERROR];
export const CALL_ERROR_TAGS = {
  CONNECTION: {
    call: 'connection'
  },
  DISCONNECT: {
    call: 'disconnection'
  },
  MEDIA: {
    call: 'media'
  },
  RECONNECT: {
    'app_exception.call': 'reconnect'
  }
};