import { CurrentSpecialistReceiveDeleteDocumentReducer } from './types';
export const currentSpecialistReceiveDeleteDocumentReducer: CurrentSpecialistReceiveDeleteDocumentReducer = (state, action) => {
  if (!state.me) {
    return state;
  }
  const {
    documentId
  } = action.payload;
  const filteredDocuments = state.me.documents.filter(document => document.id !== documentId);
  return {
    ...state,
    me: {
      ...state.me,
      documents: filteredDocuments
    }
  };
};