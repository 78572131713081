import { onlineSetStatus } from 'data/slices/online';
import { generateUuid } from '@helpers/data';
import { computeAnalyticsBaseData } from '@helpers/logging/analytics/common';
import { readRealtimeSessionId, writeRealtimeSessionId } from '@storage/realtime';
import { realtimePing } from '@services/realtime/realtime-ping';
import { ONLINE_STATUS } from '@types';
import { geometryGetType } from '@selectors/geometry';
import { RealtimeFetchPing } from './types';
export const realtimeFetchPing: RealtimeFetchPing = payload => {
  return async (dispatch, getState) => {
    const {
      storage,
      navigator
    } = payload;
    let realtimeSessionId = readRealtimeSessionId(storage);
    if (!realtimeSessionId) {
      realtimeSessionId = generateUuid();
      writeRealtimeSessionId(storage, realtimeSessionId);
    }
    const state = getState();
    const geometryType = geometryGetType(state);
    const data = await realtimePing({
      sessionId: realtimeSessionId,
      ...computeAnalyticsBaseData({
        navigator,
        geometryType
      })
    }).promise;
    dispatch(onlineSetStatus({
      status: ONLINE_STATUS.ONLINE
    }));
    return data;
  };
};