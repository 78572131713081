import { FRONTEND_APPLICATION_HOST } from '@config/env';
import { enforceDateStringToISO } from '@helpers/date';
import { ClientsPostMeRequestData, ClientsPostMeRequestDto, ClientsPostMeResponseData, ClientsPostMeResponseDto } from '@services/dto/clients';
export const clientsPostMeRequestToDto = (data: ClientsPostMeRequestData): ClientsPostMeRequestDto => {
  return {
    ...data,
    frontEndApplicationHost: FRONTEND_APPLICATION_HOST
  };
};
export const clientsPostMeResponseFromDto = (dto: ClientsPostMeResponseDto): ClientsPostMeResponseData => {
  return {
    ...dto.data,
    birthDate: dto.data.birthDate ? enforceDateStringToISO(dto.data.birthDate) : undefined
  };
};