export const THRESHOLD_USER_RATING = 80;
export const NEW_USER_RATING = 10;
export const LEGAL_AGE = 18;

/**
 * @todo Move enums below to config/forms
 */
export enum ACCOUNT_PASSWORD_RECOVERY_STEPS {
  EMAIL_INPUT = 0,
  CODE_SENT = 1,
}
export enum ACCOUNT_PASSWORD_RECOVERY_CONFIRM_STEPS {
  NEW_PASSWORD = 0,
  PASSWORD_SENT = 1,
}
export enum ACCOUNT_TWO_STEP_REGISTER_STEPS {
  START = 0,
}
export const ACCOUNT_WITHDRAW_FUNDS_MIN_AMOUNT = 50;
export const ACCOUNT_WITHDRAW_FUNDS_MAX_AMOUNT = 10000;
export const ACCOUNT_WITHDRAW_FUNDS_UKR_BANK_CARD_MIN_AMOUNT = 5000;
export const ACCOUNT_WITHDRAW_FUNDS_RUS_BANK_CARD_MIN_AMOUNT = 1500;
export const ACCOUNT_WITHDRAW_FUNDS_QIWI_MIN_AMOUNT = 50;