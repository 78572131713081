import { ENDPOINTS } from '@config/endpoints';
import { jsonFetch, JsonFetchResult } from '@services/base/json-fetch';
import { AnalyticsSendVisitRequestData, AnalyticsSendVisitRequestDto, AnalyticsSendVisitResponseData, AnalyticsSendVisitResponseDto } from '@services/dto/analytics/analytics-send-visit';
import { analyticsSendVisitRequestToDto } from '@mapping/analytics';
type AnalyticsSendVisit = (requestData: AnalyticsSendVisitRequestData) => JsonFetchResult<AnalyticsSendVisitResponseData>;
export const analyticsSendVisit: AnalyticsSendVisit = requestData => {
  return jsonFetch<AnalyticsSendVisitResponseDto, AnalyticsSendVisitRequestDto>(ENDPOINTS.analytics.sendVisit(), {
    method: 'POST',
    data: analyticsSendVisitRequestToDto(requestData)
  });
};