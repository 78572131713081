import { GA_TRACKING_ID } from '@config/env';
import { SEVERITY } from '@config/logging';
import { isFillProfileEvent } from '@helpers/logging/analytics/fill-profile-event';
import { isPageViewEvent } from '@helpers/logging/analytics/page-view-event';

// import { isServer } from '@helpers/misc';
import { IAnalyticsEvent } from '@logging/logging-events/analytics-event';

// import { initGaScript } from './ga-scripts';
import { IGAEventHandler } from './types';
export class GAEventHandler<D> implements IGAEventHandler<D> {
  private trackingId = GA_TRACKING_ID;
  public severities = [SEVERITY.ANALYTICS];
  public constructor() {
    if (!this.trackingId) {
      console.warn("GA wasn't initialized");
      return;
    }

    // if (isServer()) {
    //   return;
    // }

    /**
     * Temporarily disabled initialization of script.
     * It was replaced by the inlined one in _document.tsx.
     */
    // initGaScript(this.trackingId);
  }

  public handle<D>(event: IAnalyticsEvent<D>): void {
    if (!window.gtag || !this.trackingId) {
      return;
    }
    if (isPageViewEvent(event)) {
      const {
        data
      } = event.payload;
      window.gtag('config', this.trackingId, {
        page_path: data.url
      });
    }
    if (isFillProfileEvent(event)) {
      window.gtag('event',
      //
      'conversion', {
        send_to: `${this.trackingId}/0HLeCMCjgJYBEL3dqfYC`
      });
    }
  }
}