import { useLayoutEffect } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { addEvent } from '@helpers/dom';
import { sendError } from '@helpers/logging/error';
import { IEventBus } from '@logging/event-bus';
export const useCatchErrors = (eventBus: IEventBus): void => {
  useLayoutEffect(() => {
    const unsub = addEvent<ErrorEvent>(window,
    //
    EVENT_NAMES.ERROR, event => sendError(eventBus, event.error));
    return unsub;
  }, [eventBus]);
};