import { appCreateSlice } from '@store/rtk';
import { viewsAddViewReducer } from '@slices/views/views-add-view';
import { viewsIncreaseViewsCounterReducer } from '@slices/views/views-increase-views-counter';
import { viewsReceiveGetListReducer } from '@slices/views/views-receive-get-list';
import { viewsSetViewsCounterReducer } from '@slices/views/views-set-views-counter';
import { ViewsState } from './types';
export const viewsDefaultState: ViewsState = {
  viewsById: {},
  viewsIds: [],
  pendingViewsCount: 0,
  hasMore: false,
  limit: 10,
  offset: 0,
  totalCount: 0,
  dataLoaded: false
};
const viewsSlice = appCreateSlice({
  name: 'views',
  initialState: viewsDefaultState,
  reducers: {
    viewsAddView: viewsAddViewReducer,
    viewsIncreaseViewsCounter: viewsIncreaseViewsCounterReducer,
    viewsReceiveGetList: viewsReceiveGetListReducer,
    viewsSetViewsCounter: viewsSetViewsCounterReducer
  }
});
export const {
  actions: {
    viewsAddView,
    viewsIncreaseViewsCounter,
    viewsReceiveGetList,
    viewsSetViewsCounter
  },
  reducer: viewsReducer
} = viewsSlice;