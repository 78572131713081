import { ENDPOINTS } from '@config/endpoints';
import { OAUTH_VK_APP_ID, OAUTH_VK_URL } from '@config/env';
import { jsonToBase64 } from '@helpers/data';
import { encodeUrl } from '@helpers/routing';
import { AccountAssociatingVkRequestData, AccountAssociatingVkRequestDto, AccountAuthVkRequestData, AccountAuthVkRequestDto } from '@services/dto/account/account-auth-vk';
import { accountAssociatingVkRequestToDto, accountAuthVkRequestToDto } from '@mapping/account/account-auth-vk';
export abstract class VkAuth {
  private static encodeAuthUrl(dto: AccountAuthVkRequestDto | AccountAssociatingVkRequestDto): string {
    return encodeUrl(dto.appUrl, {
      client_id: dto.appId,
      display: dto.display,
      redirect_uri: dto.redirectUri,
      response_type: dto.responseType,
      scope: dto.scope.join(' '),
      state: jsonToBase64(dto.state)
    });
  }
  static createAuthUrl(data: Omit<AccountAuthVkRequestData,
  //
  'appUrl' | 'appId' | 'redirectUri'>): string {
    return VkAuth.encodeAuthUrl(accountAuthVkRequestToDto({
      appUrl: OAUTH_VK_URL,
      appId: OAUTH_VK_APP_ID,
      redirectUri: ENDPOINTS.auth.externalAuthCallback(),
      ...data
    }));
  }
  static createAssociatingUrl(data: Omit<AccountAssociatingVkRequestData, 'appUrl' | 'appId' | 'redirectUri'>): string {
    return VkAuth.encodeAuthUrl(accountAssociatingVkRequestToDto({
      appUrl: OAUTH_VK_URL,
      appId: OAUTH_VK_APP_ID,
      redirectUri: ENDPOINTS.account.externalLinkCallback(),
      ...data
    }));
  }
}