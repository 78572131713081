import { MESSAGES_GET_LIST_DEFAULT_REQUEST_PARAMS } from '@config/messages';
import { first, mapById, rejectNullable, sortBy } from '@helpers/data';
import { ChatId, LocalMessage, Message, MessageId } from '@types';
import { appCreateSelector, RootState } from '@store';
import { MessagesByChatId, MessagesById, MessagesIds, MessagesState } from '@slices/messages';

/**
 * regular selectors
 */

export const messagesGet = (state: RootState): MessagesState => {
  return state.app.messages;
};

/**
 * memoized reselect selectors
 */

export const messagesGetByChatId: (state: RootState) => Record<ChatId, MessagesByChatId> = appCreateSelector([messagesGet], messages => messages.byChatId);
export const messagesGetSelectedChatId: (state: RootState) => undefined | ChatId = appCreateSelector([messagesGet],
//
messages => messages.selectedChatId);
export const messagesGetMessagesByChatId: (state: RootState, chatId: ChatId) => undefined | MessagesByChatId = appCreateSelector([messagesGetByChatId, (_state, chatId: ChatId) => chatId], (byChatId, chatId) => byChatId[chatId]);
export const messagesGetIds: (state: RootState, chatId: ChatId) => undefined | MessagesIds = appCreateSelector([messagesGetMessagesByChatId], messagesByChatId => messagesByChatId?.messagesIds);
export const messagesGetById: (state: RootState, chatId: ChatId) => undefined | MessagesById = appCreateSelector([messagesGetMessagesByChatId], messagesByChatId => messagesByChatId?.messagesById);
export const messagesGetMessages: (state: RootState, chatId: ChatId) => undefined | (Message | LocalMessage)[] = appCreateSelector([messagesGetIds, messagesGetById], (messagesIds, messagesById) => {
  if (!messagesIds) {
    return undefined;
  }
  return rejectNullable(messagesIds.map(messageId => messagesById?.[messageId]));
});
export const messagesGetIdsSorted: (state: RootState, chatId: ChatId) => undefined | MessagesIds = appCreateSelector([messagesGetMessages, messagesGetIds], messages => {
  if (!messages) {
    return undefined;
  }
  const sortedMessages = sortBy(messages,
  //
  message => -new Date(message.creationTime).getTime());
  return mapById(sortedMessages);
});
export const messagesGetLastMessageId: (state: RootState, chatId: ChatId) => undefined | MessageId = appCreateSelector([messagesGetIdsSorted],
//
messagesIds => {
  if (!messagesIds) {
    return undefined;
  }
  return first(messagesIds);
});
export const messagesGetLastMessage: (state: RootState, chatId: ChatId) => undefined | LocalMessage | Message = appCreateSelector([messagesGetLastMessageId, messagesGetById], (lastMessageId, messagesById) => {
  if (!lastMessageId || !messagesById) {
    return undefined;
  }
  return messagesById[lastMessageId];
});
export const messagesGetMessageById: (state: RootState, chatId: ChatId, messageId: MessageId) => undefined | Message = appCreateSelector([messagesGetById, (_state, _chatId, messageId: MessageId) => messageId], (messagesById, messageId) => {
  if (!messagesById) {
    return undefined;
  }
  return messagesById[messageId];
});
export const messagesGetListHasMore: (state: RootState, chatId: ChatId) => undefined | boolean = appCreateSelector([messagesGetMessagesByChatId], messagesByChatId => messagesByChatId?.hasMore);
export const messagesGetCurrentLimit: (state: RootState, chatId: ChatId) => number = appCreateSelector([messagesGetMessagesByChatId], messagesByChatId => {
  return messagesByChatId?.limit ??
  //
  MESSAGES_GET_LIST_DEFAULT_REQUEST_PARAMS.limit;
});
export const messagesGetCurrentOffset: (state: RootState, chatId: ChatId) => number = appCreateSelector([messagesGetMessagesByChatId], messagesByChatId => {
  return messagesByChatId?.offset ?? MESSAGES_GET_LIST_DEFAULT_REQUEST_PARAMS.offset;
});