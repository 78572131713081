import { POPUP } from '@config/popups';
import { isRejectedCall } from '@helpers/media-call';
import { BaseNetError } from '@errors/base-net-error';
import { mediaCallStop } from '@services/media-call/media-call-stop';
import { addPopup } from '@actions/popups/add-popup';
import { mediaCallClear } from '@slices/media-call';
import { mediaCallsGetCallByChatId } from '@selectors/media-call';
import { AlertDialogProps } from '@popups/modals/alert-dialog';
import { MediaCallFetchStop } from './types';
export const mediaCallFetchStop: MediaCallFetchStop = payload => {
  return async (dispatch, getState) => {
    const {
      chatId,
      roomId
    } = payload;
    try {
      const data = await mediaCallStop({
        chatId,
        interactiveKey: roomId
      }).promise;
      const currentMediaCall = mediaCallsGetCallByChatId(getState(), chatId);
      if (!isRejectedCall(currentMediaCall)) {
        dispatch(mediaCallClear({
          chatId
        }));
      }
      return data;
    } catch (error) {
      if (error instanceof BaseNetError) {
        const errors = error.getOtherErrors();
        errors.forEach(_error => {
          dispatch(addPopup<AlertDialogProps>({
            id: POPUP.ALERT_DIALOG,
            props: {
              text: _error.message
            }
          }));
        });
      }
    }
  };
};