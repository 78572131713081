import { FILE_MODERATION_STATUS, FILE_TYPE, MediaFile, Photo, Video } from '@types';
export const isFileApproved = (file: MediaFile): boolean => {
  return file.status === FILE_MODERATION_STATUS.APPROVED;
};
export const isFileOnModeration = (file: MediaFile): boolean => {
  return file.status === FILE_MODERATION_STATUS.NEW;
};
export const isPhoto = (file: MediaFile): file is Photo => {
  return file.fileType === FILE_TYPE.PHOTO;
};
export const isVideo = (file: MediaFile): file is Video => {
  return file.fileType === FILE_TYPE.VIDEO;
};
export const isFileRejected = (photo: MediaFile): boolean => {
  return photo.status === FILE_MODERATION_STATUS.REJECTED;
};