import { rejectBy } from '@helpers/data';
import { IEvent, IEventBus, IEventBusHandleResult, IEventHandler, IEventHandlers } from './types';
export class EventBus implements IEventBus {
  private _eventHandlers: IEventHandlers;
  constructor(eventHandlers: IEventHandlers) {
    this._eventHandlers = eventHandlers;
  }
  private _rejectEventHandler<P>(eventHandler: IEventHandler<P>) {
    this._eventHandlers = rejectBy(this._eventHandlers, handler => {
      return handler === eventHandler;
    });
  }
  private _addEventHandler<P>(eventHandler: IEventHandler<P>) {
    this._eventHandlers = [...this._eventHandlers, eventHandler];
  }
  subscribe<P>(eventHandler: IEventHandler<P>): void {
    this._rejectEventHandler(eventHandler);
    this._addEventHandler(eventHandler);
  }
  unsubscribe<P>(eventHandler: IEventHandler<P>): void {
    this._rejectEventHandler(eventHandler);
  }
  handle<P>(event: IEvent<P>): IEventBusHandleResult {
    return this._eventHandlers //
    .filter(({
      severities
    }) => severities.includes(event.severity)).map(eventHandler => eventHandler.handle(event));
  }
}