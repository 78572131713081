import { ERROR_CODE } from '@config/errors';
import { FileSizeValidator } from './types';
export const fileSizeValidator: FileSizeValidator = (value, options) => {
  if (value == null || !value.length) {
    return;
  }
  const fileSizes = value.map(file => file.size);
  const sizeExceeded = fileSizes //
  .some(fileSize => fileSize > options.fileSize);
  if (sizeExceeded) {
    return options.message ?? ERROR_CODE.FIELD_IS_INVALID;
  }
};