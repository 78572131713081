import { CurrentClientSetLevelReducer } from './types';
export const currentClientSetLevelReducer: CurrentClientSetLevelReducer = (state, action) => {
  const {
    level
  } = action.payload;
  const {
    me
  } = state;
  if (!me) {
    return state;
  }
  return {
    ...state,
    me: {
      ...me,
      level
    }
  };
};