import { ONLINE_STATUS } from '@types';
import { appCreateSlice } from '@store/rtk';
import { onlineSetStatusReducer } from '@slices/online/online-set-status';
import { OnlineState } from './types';
export const onlineDefaultState: OnlineState = {
  status: ONLINE_STATUS.OFFLINE
};
const onlineSlice = appCreateSlice({
  name: 'online',
  initialState: onlineDefaultState,
  reducers: {
    onlineSetStatus: onlineSetStatusReducer
  }
});
export const {
  actions: {
    onlineSetStatus
  },
  reducer: onlineReducer
} = onlineSlice;