export const TARRIFS_CHECK_ALERT_IMPRESSIONS_MAX_NUMBER = 1;
export const PUSH_PROMPT_IMPRESSIONS_MAX_NUMBER = 1;
export const ACCOUNT_EMAIL_CONFIRMATION_REQUIRED_POPUP_IMPRESSIONS_MAX_NUMBER = 1;
export const ACCOUNT_EMAIL_CONFIRMATION_REQUIRED_POPUP_DELAY = 5000;
export enum POPUP {
  /**
   * PUSH_PROMPT is used to count number of impressions of OneSignal push prompt.
   */
  PUSH_PROMPT = 'push_prompt',
  ERROR_BOUNDARY_ALERT = 'error_boundary_alert',
  ALERT_DIALOG = 'alert_dialog',
  SIDEBAR = 'sidebar',
  HEADER_MENU = 'header_menu',
  ACCOUNT_LOGIN_MODAL = 'account_login',
  ACCOUNT_REGISTER_MODAL = 'account_register',
  ACCOUNT_EXTERNAL_AUTH_ERROR = 'landing_external_auth_error',
  ACCOUNT_EMAIL_DOES_NOT_EXIST = 'account_email_does_not_exist',
  ACCOUNT_EMAIL_CONFIRMATION = 'email_confirmation',
  ACCOUNT_EMAIL_CONFIRMATION_REQUIRED = 'email_confirmation_required',
  ACCOUNT_EMAIL_CONFIRMATION_RESEND_CODE = 'email_confirmation_resend_code',
  ACCOUNT_EMAIL_CONFIRMATION_CHANGE_EMAIL = 'email_confirmation_change_email',
  ACCOUNT_PASSWORD_RECOVERY = 'password_recovery',
  ACCOUNT_PASSWORD_RECOVERY_CONFIRM = 'password_recovery_confirm',
  ACCOUNT_DELETE_ACCOUNT_DIALOG = 'account_delete_account_dialog',
  ACCOUNT_DELETED_CAN_BE_RESTORED = 'account_deleted_can_be_restored',
  ACCOUNT_DELETED_CAN_NOT_BE_RESTORED = 'account_deleted_can_not_be_restored',
  ACCOUNT_BANNED = 'account_banned',
  ACCOUNT_LOCKED = 'account_locked',
  ACCOUNT_LOCKED_BY_MANY_COMPLAINTS = 'account_locked_by_many_complaints',
  ACCOUNT_ROLE_REQUIRED = 'account_role_required',
  ACCOUNT_FILLED_PROFILE_REQUIRED = 'account_filled_profile_required',
  PROFILE_EDIT_AVATAR_SEX_UNDEFINED_ALERT = 'profile_edit_avatar_sex_undefined_alert',
  PROFILE_EDIT_SET_AVATAR_MODAL = 'profile_edit_set_avatar',
  PROFILE_EDIT_CROP_PHOTO_MODAL = 'profile_edit_crop_photo',
  PROFILE_EDIT_PHOTO_UPLOAD_LIMIT_ALERT = 'profile_edit_photo_upload_limit_alert',
  PROFILE_EDIT_VIDEO_UPLOAD_LIMIT_ALERT = 'profile_edit_video_upload_limit_alert',
  PROFILE_EDIT_VIDEO_UPLOAD_DURATION_LIMIT_ALERT = 'profile_edit_video_upload_duration_limit_alert',
  PROFILE_EDIT_MEDIA_GALLERY_MODAL = 'profile_edit_media_gallery',
  PROFILE_EDIT_SET_DEFAULT_PHOTO_DIALOG = 'profile_edit_set_default_photo_dialog',
  PROFILE_EDIT_DELETE_MEDIA_DIALOG = 'profile_edit_delete_media_dialog',
  PROFILE_EDIT_DOCUMENT_UPLOAD_LIMIT_ALERT = 'profile_edit_document_upload_limit_alert',
  PROFILE_EDIT_DOCUMENT_GALLERY_MODAL = 'profile_edit_document_gallery',
  PROFILE_EDIT_DELETE_DOCUMENT_DIALOG = 'profile_edit_delete_document_dialog',
  PROFILE_EDIT_FORM_SUBJECTS_LIMIT_ALERT = 'profile_edit_form_subjects_limit_alert',
  PROFILE_EDIT_FORM_SUBJECTS_LIMIT_DIALOG = 'profile_edit_form_subjects_limit_dialog',
  PROFILE_EDIT_UPDATE_SUCCESS_ALERT = 'profile_edit_update_success',
  PROFILE_EDIT_UPDATE_FAILURE_ALERT = 'profile_edit_update_failure',
  MEMBER_MEDIA_GALLERY_MODAL = 'member_media_gallery',
  MEMBER_DOCUMENT_GALLERY_MODAL = 'member_document_gallery',
  MEMBER_SEND_MESSAGE_MODAL = 'member_send_message_modal',
  MEMBER_SEND_COMPLIMENT_MODAL = 'member_send_compliment_modal',
  MEMBER_SEND_COMPLAINT_MODAL = 'member_send_complaint_modal',
  MEMBER_ASSESS_RATING_MODAL = 'member_assess_rating_modal',
  MEMBER_NOT_ENOUGH_COINS_DIALOG = 'member_not_enough_coins_dialog',
  MEMBER_NOT_ENOUGH_COINS_ALERT = 'member_not_enough_coins_alert',
  /**
   * @deprecated
   * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
   */
  MEMBER_PREPAYMENT_CONFIRMATION_REQUIRED_ALERT = 'member_prepayment_confirmation_required_alert',
  ACCOUNT_MEMBER_BLOCKED = 'account_member_blocked',
  ACCOUNT_MEMBER_DELETED = 'account_member_deleted',
  TARRIFS_CHECK_ALERT = 'tarrifs_check_alert',
  MESSAGES_SEND_IMAGE_MODAL = 'messages_send_image_modal',
  MESSAGES_IMAGE_GALERY_MODAL = 'messages_image_galery_modal',
  MEDIA_CALL_OUTGOING = 'media_outgoing_call',
  MEDIA_CALL_INCOMING = 'media_incoming_call',
  MEDIA_CALL_INTERFACE = 'media_call_interface',
  MEDIA_CALL_MEMBER_IS_ON_RUNNING_CALL_ALERT = 'media_call_member_is_on_running_call_alert',
  MEDIA_CALL_BROWSER_IS_NOT_SUPPORTED_ALERT = 'media_call_browser_is_not_supported_alert',
  MEDIA_CALL_RESOURCE_IS_NOT_AVAILABLE_ALERT = 'media_call_resource_is_not_available_alert',
  PAYMENTS_ADD_FUNDS = 'payments_add_funds',
  PAYMENTS_ADD_CARDS = 'payments_add_cards',
  PAYMENTS_DELETE_BANK_CARD_DIALOG = 'payments_delete_bank_card',
  PAYMENTS_SUCCESS_ALERT = 'payments_success',
  PAYMENTS_FAILURE_ALERT = 'payments_failure',
  PAYMENTS_REFILL_ALERT = 'payments_refill',
  PAYMENTS_WITHDRAW_FUNDS = 'payments_withdraw_funds',
  PAYMENTS_WITHDRAW_FUNDS_SUCCESS = 'payments_withdraw_funds_success',
  PAYMENTS_UPGRADE_ACCOUNT = 'payments_upgrade_account',
  PAYMENTS_BUY_PREMIUM = 'payments_buy_premium',
  PAYMENTS_PREMIUM_SUCCESS_ALERT = 'payments_premium_success',
  CALENDAR_CURRENT_MEMBER_TIME_SLOTS = 'calendar_current_member_time_slots',
  CALENDAR_MEMBER_TIME_SLOTS = 'calendar_member_time_slots',
  CALENDAR_CONFIRM_EXIT_DIALOG = 'calendar_confirm_exit_dialog',
  CALENDAR_COINS_HOLD_DIALOG = 'calendar_coins_hold_dialog',
  CALENDAR_SUCCESS_CUNSULTATION_APPOINTMENT_ALERT = 'calendar_success_cunsultation_appointment_alert',
  CALENDAR_YOU_HAVE_OTHER_CONSULTATION_FOR_THIS_TIME_ALERT = 'calendar_you_have_other_consultation_for_this_time_alert',
  CALENDAR_CALENDAR_THIS_TIME_IS_UNAVAILABLE_ALERT = 'calendar_calendar_this_time_is_unavailable_alert',
  CALENDAR_NOT_ENOUGH_COINS_ALERT = 'calendar_not_enough_coins_alert',
  CALENDAR_CURRENT_MEMBER_CONSULTATION = 'calendar_current_member_consultation',
  CALENDAR_CONSULTATION_REJECT_DIALOG = 'calendar_consultation_reject_dialog',
  LANDING_CLIENT_REGISTER_MODAL = 'landing_client_register_modal',
  LANDING_PSYCHOLOGIST_REGISTER_MODAL = 'landing_psychologist_register_modal',
}
export const POPUPS_QUERY_BLACKLIST = [POPUP.ACCOUNT_EMAIL_CONFIRMATION_REQUIRED, POPUP.ACCOUNT_EMAIL_CONFIRMATION_CHANGE_EMAIL];