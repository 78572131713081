import { ERROR_CODE } from '@config/errors';
import { defineAuthErrorCode, isBaseNetErrorAuthBody, isBaseNetErrorBody, isBaseNetErrorBodyFieldError } from '@helpers/errors';
import { lowercaseFirstLetter } from '@helpers/format';
import { BaseNetErrorBodyData } from '@services/dto/errors/base-net-error';
export const baseNetErrorBodyFromDto = (dto: unknown): BaseNetErrorBodyData => {
  /**
   * isNetErrorBody condition check must be placed as first because server
   * is able to return structure:
   * {
   *   error: "invalid_grant",
   *   error_description: "Account is deleted",
   *   message: "Account is deleted",
   *   errors: [
   *     {
   *       code: 20140
   *     }
   *   ],
   *   data: null
   * }
   */
  if (isBaseNetErrorBody(dto)) {
    return {
      data: dto.data,
      errors: dto.errors.map(error => {
        if (isBaseNetErrorBodyFieldError(error)) {
          return {
            ...error,
            data: {
              ...error.data,
              field: lowercaseFirstLetter(error.data.field)
            }
          };
        }
        return error;
      })
    };
  }
  if (isBaseNetErrorAuthBody(dto)) {
    return {
      data: null,
      errors: [{
        code: defineAuthErrorCode(dto.message),
        data: undefined
      }]
    };
  }
  return {
    data: null,
    errors: [{
      code: ERROR_CODE.UNEXPECTED_ERROR,
      data: undefined
    }]
  };
};