module.exports.backgroundImage = {
  'messages-default': "url('/images/bg/messages-default-bg.svg')",
  'landing-main-listener-320w':
    "url('/images/landing/main/listener/listener-bg-320w.svg')",
  'landing-main-listener-768w':
    "url('/images/landing/main/listener/listener-bg-768w.svg')",
  'landing-main-listener':
    "url('/images/landing/main/listener/listener-bg.svg')",
  'landing-main-specialists-description-bg':
    "url('/images/landing/main/specialists-description/specialists-description-bg.svg')",
  'landing-main-specialists-description-bg-w320':
    "url('/images/landing/main/specialists-description/specialists-description-bg-w320.svg')",
  'landing-main-specialists-description-bg-w540':
    "url('/images/landing/main/specialists-description/specialists-description-bg-w540.svg')",
  'landing-main-inspiration-bg':
    "url('/images/landing/main/inspiration/inspiration-bg.svg')",
};
