import { isServer } from '@helpers/misc';
import { createRootStore } from './store';
import { RootState, RootStore } from './types';
let store: undefined | RootStore;
export const initializeStore = (preloadedState?: RootState): RootStore => {
  if (isServer()) {
    return createRootStore(preloadedState);
  }
  if (store) {
    return store;
  }
  store = createRootStore(preloadedState);
  return store;
};