import { Subject, SubjectId, SubjectIds, Subjects, SubjectsById } from '@types';
import { appCreateSelector, RootState } from '@store';
import { SubjectsState } from '@slices/subjects';

/**
 * regular selectors
 */

export const subjectsGet = (state: RootState): SubjectsState => {
  return state.app.subjects;
};

/**
 * memoized reselect selectors
 */

export const subjectsGetSubjectIds: (state: RootState) => SubjectIds = appCreateSelector([subjectsGet], subjects => subjects.subjectIds);
export const subjectsGetSubjectsById: (state: RootState) => SubjectsById = appCreateSelector([subjectsGet], subjects => subjects.subjectsById);
export const subjectsGetSubjectById: (state: RootState, subjectId: SubjectId) => undefined | Subject = appCreateSelector([subjectsGetSubjectsById, (_state, subjectId: SubjectId) => subjectId], (subjectsById, subjectId) => subjectsById[subjectId]);
export const subjectsGetSubjects: (state: RootState) => Subjects = appCreateSelector([subjectsGetSubjectIds, subjectsGetSubjectsById], (subjectIds, subjectsById) => {
  return subjectIds.map(id => subjectsById[id]);
});
export const subjectsGetTitlesByIds: (state: RootState, subjectIds: SubjectIds) => (undefined | string)[] = appCreateSelector([(_state, subjectIds: SubjectIds) => subjectIds, subjectsGetSubjectsById], (subjectIds, subjectsById) => subjectIds.map(subjectId => subjectsById[subjectId]?.name));
export const subjectsGetTitleById: (state: RootState, subjectId: SubjectId) => undefined | string = appCreateSelector([subjectsGetSubjectById], subject => subject?.name);