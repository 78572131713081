import { CURRENCY, PROMOTION_TYPE } from '@types';
export enum COIN {
  COINS_1 = 1,
  COINS_3 = 3,
  COINS_5 = 5,
  COINS_10 = 10,
  COINS_20 = 20,
  COINS_30 = 30,
}
export const COINS = {
  [COIN.COINS_1]: {
    id: COIN.COINS_1,
    imageSrc: '/images/coins/coins-1.png'
  },
  [COIN.COINS_3]: {
    id: COIN.COINS_3,
    imageSrc: '/images/coins/coins-3.png'
  },
  [COIN.COINS_5]: {
    id: COIN.COINS_5,
    imageSrc: '/images/coins/coins-5.png'
  },
  [COIN.COINS_10]: {
    id: COIN.COINS_10,
    imageSrc: '/images/coins/coins-10.png'
  },
  [COIN.COINS_20]: {
    id: COIN.COINS_20,
    imageSrc: '/images/coins/coins-20.png'
  },
  [COIN.COINS_30]: {
    id: COIN.COINS_30,
    imageSrc: '/images/coins/coins-30.png'
  }
};
export type PriceId = number;
export enum PRICE_CATEGORY {
  BASIC = 1,
  PREMIUM = 2,
}
export type Price = {
  id: PriceId;
  category: PRICE_CATEGORY;
  realAmount: number;
  currency: CURRENCY;
  discount: number;
  promotionTypes: PROMOTION_TYPE[];
  isDefault: boolean;
};
export type Prices = Price[];
export type CoinPrice = {
  coinsAmount: number;
  prices: Prices;
};
export type CoinPrices = CoinPrice[];
export type PremiumPrice = {
  realAmount: number;
  currency: CURRENCY;
  coinsDiscount: number;
};