import { AUTH_CLIENT_ID, AUTH_GRANT_TYPE, FACEBOOK_AUTH_DISPLAY, FACEBOOK_AUTH_RESPONSE_TYPE, FACEBOOK_AUTH_SCOPE } from '@config/auth';
import { FRONTEND_APPLICATION_HOST } from '@config/env';
import { AccountAssociatingFacebookRequestData, AccountAssociatingFacebookRequestDto, AccountAuthFacebookRequestData, AccountAuthFacebookRequestDto } from '@services/dto/account/account-auth-facebook';
import { PROVIDER } from '@types';
export const accountAuthFacebookRequestToDto = (data: AccountAuthFacebookRequestData): AccountAuthFacebookRequestDto => {
  return {
    appUrl: data.appUrl,
    appId: data.appId,
    redirectUri: data.redirectUri,
    responseType: FACEBOOK_AUTH_RESPONSE_TYPE.CODE,
    display: data.isDesktop //
    ? FACEBOOK_AUTH_DISPLAY.PAGE : FACEBOOK_AUTH_DISPLAY.TOUCH,
    scope: [FACEBOOK_AUTH_SCOPE.EMAIL, FACEBOOK_AUTH_SCOPE.USER_BIRTHDAY, FACEBOOK_AUTH_SCOPE.USER_GENDER],
    state: {
      roleType: data.roleType,
      timezoneId: data.timezoneId,
      timezoneOffset: data.timezoneOffset,
      culture: data.culture,
      allowRestoreAccount: data.allowRestoreAccount,
      appId: data.appId,
      visitId: data.visitId,
      url: data.url,
      clientType: data.clientType,
      userAgent: data.userAgent,
      clientId: AUTH_CLIENT_ID,
      grantType: AUTH_GRANT_TYPE.PASSWORD,
      frontEndApplicationHost: FRONTEND_APPLICATION_HOST,
      provider: PROVIDER.FB
    }
  };
};
export const accountAssociatingFacebookRequestToDto = (data: AccountAssociatingFacebookRequestData): AccountAssociatingFacebookRequestDto => {
  return {
    appUrl: data.appUrl,
    appId: data.appId,
    redirectUri: data.redirectUri,
    responseType: FACEBOOK_AUTH_RESPONSE_TYPE.CODE,
    display: data.isDesktop //
    ? FACEBOOK_AUTH_DISPLAY.PAGE : FACEBOOK_AUTH_DISPLAY.TOUCH,
    scope: [FACEBOOK_AUTH_SCOPE.EMAIL, FACEBOOK_AUTH_SCOPE.USER_BIRTHDAY, FACEBOOK_AUTH_SCOPE.USER_GENDER],
    state: {
      roleType: data.roleType,
      timezoneId: data.timezoneId,
      timezoneOffset: data.timezoneOffset,
      culture: data.culture,
      allowRestoreAccount: data.allowRestoreAccount,
      appId: data.appId,
      visitId: data.visitId,
      url: data.url,
      clientType: data.clientType,
      userAgent: data.userAgent,
      clientId: AUTH_CLIENT_ID,
      grantType: AUTH_GRANT_TYPE.PASSWORD,
      frontEndApplicationHost: FRONTEND_APPLICATION_HOST,
      provider: PROVIDER.FB,
      protectedUserId: data.protectedUserId
    }
  };
};