import { STORAGE_KEYS } from '@config/storage';
import { ILocalStorage } from '@storage';
import { RealtimeSessionId } from '@types';
export type RealtimeData = {
  sessionId: undefined | RealtimeSessionId;
};
export const readRealtimeData = (storage: ILocalStorage): undefined | RealtimeData => {
  return storage.read<RealtimeData>(STORAGE_KEYS.REALTIME);
};
export const writeRealtimeData = (storage: ILocalStorage, data: RealtimeData): void => {
  storage.write<RealtimeData>(STORAGE_KEYS.REALTIME, data);
};
export const readRealtimeSessionId = (storage: ILocalStorage): undefined | RealtimeSessionId => {
  const realtimeData = readRealtimeData(storage);
  return realtimeData?.sessionId;
};
export const writeRealtimeSessionId = (storage: ILocalStorage, sessionId: undefined | RealtimeSessionId): void => {
  const realtimeData = readRealtimeData(storage);
  writeRealtimeData(storage, {
    ...realtimeData,
    sessionId
  });
};