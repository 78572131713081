import { Chat } from '@types';
import { ChatsAddToFavoriteReducer } from './types';
export const chatsAddToFavoriteReducer: ChatsAddToFavoriteReducer = (state, action) => {
  const {
    chatId
  } = action.payload;
  const {
    chatsById
  } = state;
  const existingChat = chatsById[chatId];
  if (!existingChat) {
    return state;
  }
  const updatedChat: Chat = {
    ...existingChat,
    isFavorite: true
  };
  return {
    ...state,
    chatsById: {
      ...chatsById,
      [chatId]: updatedChat
    }
  };
};