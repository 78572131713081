import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { RealtimePingRequestData, RealtimePingRequestDto, RealtimePingResponseData, RealtimePingResponseDto } from '@services/dto/realtime/realtime-ping';
import { realtimePingRequestToDto } from '@mapping/realtime/realtime-ping';
type RealtimePing = (requestData: RealtimePingRequestData) => AuthedJsonFetchResult<RealtimePingResponseData>;
export const realtimePing: RealtimePing = requestData => {
  return authedJsonFetch<RealtimePingResponseDto, RealtimePingRequestDto>(ENDPOINTS.realtime.ping(requestData.sessionId),
  //
  {
    method: 'POST',
    data: realtimePingRequestToDto(requestData)
  });
};