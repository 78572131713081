import { uniqById } from '@helpers/data';
import { calendarRejectConsultationsByPeriod } from '@mapping/calendar/calendar-reject-consultations-by-period';
import { calendarRejectWorkingTimesByPreiod } from '@mapping/calendar/calendar-reject-working-times-by-preiod';
import { CalendarReceiveGetMemberCalendarReducer } from './types';
export const calendarReceiveGetMemberCalendarReducer: CalendarReceiveGetMemberCalendarReducer = (state, action) => {
  const {
    memberId,
    dateFrom,
    dateTo,
    data
  } = action.payload;
  const member = state.membersById?.[memberId];
  const calendar = member?.calendar;
  return {
    ...state,
    membersById: {
      ...state.membersById,
      [memberId]: {
        ...member,
        calendar: {
          ...calendar,
          ...data,
          workingTimes: uniqById([...data.workingTimes, ...calendarRejectWorkingTimesByPreiod(calendar?.workingTimes ?? [], dateFrom, dateTo)]),
          consultations: uniqById([...data.consultations, ...calendarRejectConsultationsByPeriod(calendar?.consultations ?? [], dateFrom, dateTo)])
        }
      }
    }
  };
};