import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsPremiumShowInfoEvent } from '@logging/logging-events';
import { AnalyticsPremiumShowInfoEventRequestData } from '@services/dto/analytics/analytics-send-event/analytics-premium-show-info';
export const isPremiumShowInfoEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsPremiumShowInfoEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.PREMIUM_SHOW_INFO;
};
export const sendPremiumShowInfo = (eventBus: IEventBus, data: AnalyticsPremiumShowInfoEventRequestData['data']): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsPremiumShowInfoEventRequestData['data']>({
    action: ANALYTICS_ACTION.PREMIUM_SHOW_INFO,
    data
  }));
};