import { keyBy, uniq } from '@helpers/data';
import { SearchListItems } from '@types';
import { SearchReceiveGetListReducer } from './types';
export const searchReceiveGetListReducer: SearchReceiveGetListReducer = (state, action) => {
  const {
    searchList
  } = action.payload;
  const {
    limit,
    offset,
    items,
    totalCount,
    keepResults,
    lastSearchTime,
    lastSearchFilters
  } = searchList;
  const {
    listItemIds,
    listItemsById
  } = state;
  const nextListItemsById = {
    ...(keepResults ? listItemsById : undefined),
    ...keyBy((items as SearchListItems), item => item.userId)
  };
  const nextListItemIds = uniq([...(keepResults ? listItemIds : []), ...items.map(item => item.userId)]);
  return {
    ...state,
    listItemsById: nextListItemsById,
    listItemIds: nextListItemIds,
    hasMore: offset + limit < totalCount,
    limit,
    offset,
    totalCount,
    dataLoaded: true,
    hasError: false,
    lastSearchTime,
    lastSearchFilters: lastSearchFilters ? lastSearchFilters : {}
  };
};