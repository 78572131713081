export type OverlayProps = {
  className?: string;
  overlayBgColor?: OVERLAY_BG_COLOR;
  onClick?: React.MouseEventHandler;
  onKeyPress?: React.KeyboardEventHandler;
};
export enum OVERLAY_BG_COLOR {
  WHITE = 'WHITE',
  WHITE_NON_TRANSPARENT = 'WHITE_NON_TRANSPARENT',
  BLACK = 'BLACK',
  BLUE = 'BLUE',
  TRANSPARENT = 'TRANSPARENT',
}
export type OverlayStyledProps = {
  overlayBgColor?: OVERLAY_BG_COLOR;
};