import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SpecialistsGetMeResponseData, SpecialistsGetMeResponseDto } from '@services/dto/specialists';
import { specialistsGetMeResponseFromDto } from '@mapping/specialists';
type SpecialistsGetMe = () => AuthedJsonFetchResult<SpecialistsGetMeResponseData>;
export const specialistsGetMe: SpecialistsGetMe = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SpecialistsGetMeResponseDto>(ENDPOINTS.specialists.me(), {
    method: 'GET'
  });
  return {
    promise: promise.then(specialistsGetMeResponseFromDto),
    cancel
  };
};