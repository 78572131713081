export enum IMAGE_LOADING_STATE {
  PENDING = 'pending',
  LOADED = 'loaded',
  ERROR = 'error',
}
export type ImageLoaded = 'loaded' | 'error' | boolean;
export type UseImageLoadedInput = {
  src: string;
  srcSet?: string;
};
export type UseImageLoadedResult = ImageLoaded;
export type UseImageLoaded = (input: UseImageLoadedInput) => UseImageLoadedResult;
export type UseImageSourceInput = {
  src: undefined | string;
  srcSet?: string;
};
export type UseImageSourceResult = {
  src: undefined | string;
  srcSet: undefined | string;
  pending: boolean;
};
export type UseImageSource = (input: UseImageSourceInput) => UseImageSourceResult;