import { uniq } from '@helpers/data';
import { photoOrAvatarUrl } from '@helpers/member';
import { MEDIA_CALL_STATE } from '@types';
import { MediaCallsById, MediaCallsIds } from '@slices/media-call';
import { MediaCallReceiveCreateReducer } from './types';
export const mediaCallReceiveCreateReducer: MediaCallReceiveCreateReducer = (state, action) => {
  const {
    token,
    direction,
    chatId,
    type,
    roomId,
    roomName,
    chatMateAvatarId,
    chatMateUserId,
    chatMateUserName,
    chatMatePhotoUrl,
    sex
  } = action.payload;
  const mediaCallsById: MediaCallsById = {
    ...state.mediaCallsById,
    [chatId]: {
      token,
      direction,
      chatId,
      type,
      roomId,
      roomName,
      chatMateUserId,
      chatMateUserName,
      chatMateAvatarId,
      chatMatePhotoUrl,
      sex,
      _chatMateImage: photoOrAvatarUrl(chatMatePhotoUrl, chatMateAvatarId, sex),
      state: MEDIA_CALL_STATE.CONNECTING
    }
  };
  const mediaCallsIds: MediaCallsIds = uniq([...state.mediaCallsIds, chatId]);
  return {
    ...state,
    mediaCallsById,
    mediaCallsIds
  };
};