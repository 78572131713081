import { useMemo } from 'react';
import { NewRefundNotificationMessageStyled } from '@notifications/notifications/new-refund/styled';
import { formatCoinsParental } from '@helpers/format';
import { AppFCC } from '@types';
import { notificationsUiRemove } from '@slices/notifications-ui';
import { useTypedDispatch } from '@hooks/store';
import { NotificationUiWidget, NotificationUiWidgetProps } from '@widgets/notifications/notification-ui';
import { NewRefundNotificationProps } from './types';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NewRefundNotification: AppFCC<NewRefundNotificationProps> = props => {
  const {
    notificationId,
    name,
    avatar,
    coinsAmount
  } = props;
  const dispatch = useTypedDispatch();
  const actionButton: NotificationUiWidgetProps['actionButton'] = useMemo(() => {
    return {
      text: 'Ок',
      onClick: () => {
        dispatch(notificationsUiRemove({
          id: notificationId
        }));
      }
    };
  }, [dispatch, notificationId]);
  return _jsx(NotificationUiWidget //
  , {
    notificationId: notificationId,
    title: "\u0412\u043E\u0437\u0432\u0440\u0430\u0442 \u0441\u0440\u0435\u0434\u0441\u0442\u0432",
    avatar: avatar,
    actionButton: actionButton,
    children: _jsxs(NewRefundNotificationMessageStyled, {
      children: ["\u0421\u0440\u0435\u0434\u0441\u0442\u0432\u0430 \u0432 \u0440\u0430\u0437\u043C\u0435\u0440\u0435 ", coinsAmount, " ", formatCoinsParental(coinsAmount), ' ', "\u0440\u0435\u0437\u0435\u0440\u0432\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u0435 \u0434\u043B\u044F \u043A\u043E\u043D\u0441\u0443\u043B\u044C\u0442\u0430\u0446\u0438\u0438 \u0441\u043E \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u043E\u043C ", name, " \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0435\u043D\u044B \u043D\u0430 \u0432\u0430\u0448 \u0441\u0447\u0435\u0442"]
    })
  });
};