import { AppFCC } from '@types';
import { SpinnerCircleStyled, SpinnerStyled, SpinnerSvgStyled } from './styled';
import { SpinnerProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Spinner: AppFCC<SpinnerProps> = props => {
  const {
    className,
    colors = ['blue'],
    dash = 63,
    duration = 1
  } = props;
  const _duration = colors.length * duration;
  const durationAlt = _duration / colors.length;
  const keyframe = 1 / (colors.length * 2) * 100;
  return _jsx(SpinnerStyled, {
    className: className,
    children: _jsx(SpinnerSvgStyled //
    , {
      duration: _duration,
      durationAlt: durationAlt,
      dash: dash,
      keyframe: keyframe,
      viewBox: "0 0 24 24",
      children: colors.map((color, index) => {
        return _jsx(SpinnerCircleStyled //
        , {
          index: index,
          durationAlt: durationAlt,
          color: color,
          cx: "12",
          cy: "12",
          r: "10"
        }, color);
      })
    })
  });
};