import { POPUP } from '@config/popups';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimeMemberAssessRatingEventPayloadData } from '@services/dto/realtime/realtime-member-event/realtime-member-access-rating';
import { addPopup } from '@actions/popups/add-popup';
import { MemberAssessRatingModalProps } from '@popups/modals/member/member-assess-rating';
import { RealtimeMemberAssessRating } from './types';
export const realtimeMemberAssessRating: RealtimeMemberAssessRating = event => {
  return dispatch => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeMemberAssessRatingEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      userId,
      userName,
      rateTimeStamp
    } = data;
    dispatch(addPopup<MemberAssessRatingModalProps>({
      id: POPUP.MEMBER_ASSESS_RATING_MODAL,
      props: {
        memberId: userId,
        memberName: userName,
        rateTimeStamp
      }
    }));
  };
};