import { AppFCC } from '@types';
require('intersection-observer');
import App, { AppProps } from 'next/app';
import { LayoutProps } from '@config/page';
import { defineGeometryTypeByUserAgent } from '@helpers/geometry';
import { isServer } from '@helpers/misc';
import { initializeNavigator } from '@routing';
import { initializeEventBus } from '@logging';
import { wrapper } from '@store';
import { geometrySetType } from '@slices/geometry';
import { useCatchErrors } from '@hooks/catch-errors';
import { FilledProfileRequiredErrorManager } from '@managers/access-control/filled-profile-required-error';
import { AnalyticsVisitManager } from '@managers/analytics/analytics-visit';
import { AuthByAdminManager } from '@managers/auth/auth-by-admin';
import { AuthRefreshManager } from '@managers/auth/auth-refresh';
import { ExternalAuthManager } from '@managers/auth/external-auth';
import { CommonDataManager } from '@managers/common-data';
import { GeometryOnResizeManager } from '@managers/geometry/geometry-on-resize';
import { ErrorBoundary } from '@managers/logging/error-boundary';
import { SentryManager } from '@managers/logging/sentry';
import { MediaCallManager } from '@managers/media-call';
import { OnlineManager } from '@managers/online';
import { PopupsManager } from '@managers/popups/popups-manager';
import { ProvidersManager } from '@managers/providers';
// import { PushManager } from '@managers/push';
import { RealtimeManager } from '@managers/realtime-manager';
import { SEOTagsManager } from '@managers/seo/seo-tags';
import { LoadingScreenWidget } from '@widgets/loading-screen/loading-screen';
import { NotificationsUiContainerWidget } from '@widgets/notifications/notifications-ui-container';
import '../styles/base.css';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
type PageProps = {
  err: undefined | Error;
};
type CustomComponent = AppProps['Component'] & {
  layout: AppFCC;
  layoutProps?: LayoutProps;
};
type MyAppProps = Omit<AppProps<PageProps>, 'pageProps'> & {
  Component: CustomComponent;
  pageProps: PageProps;
  err: Error;
};
const MyApp = (props: MyAppProps): React.ReactElement => {
  const {
    Component,
    pageProps,
    ...rest
  } = props;
  const {
    store
  } = wrapper.useWrappedStore(rest);
  const eventBus = initializeEventBus();
  useCatchErrors(eventBus);
  const navigator = initializeNavigator();
  return _jsxs(ProvidersManager //
  , {
    eventBus: eventBus,
    navigator: navigator,
    store: store,
    children: [_jsx(SEOTagsManager, {}), _jsx(GeometryOnResizeManager, {}), _jsx(AnalyticsVisitManager, {}), _jsx(SentryManager, {}), _jsx(FilledProfileRequiredErrorManager, {}), _jsx(AuthRefreshManager, {
      children: _jsx(ErrorBoundary, {
        children: Component.layout ? _jsx(Component.layout, {
          ...Component.layoutProps,
          children: _jsx(Component //
          , {
            ...pageProps
          })
        }) : _jsx(Component //
        , {
          ...pageProps
        })
      })
    }), _jsx(PopupsManager, {}), _jsx(NotificationsUiContainerWidget, {}), _jsx(LoadingScreenWidget, {}), _jsx(CommonDataManager, {}), _jsx(MediaCallManager, {}), _jsx(ExternalAuthManager, {}), _jsx(AuthByAdminManager, {}), _jsx(OnlineManager, {}), _jsx(RealtimeManager, {})]
  });
};
MyApp.getInitialProps = wrapper.getInitialAppProps(store => {
  return async appContext => {
    const appInitialProps = await App.getInitialProps(appContext);
    const {
      req
    } = appContext.ctx;
    if (isServer()) {
      const {
        dispatch
      } = store;
      const userAgent = req?.headers['user-agent'];
      dispatch(geometrySetType({
        type: defineGeometryTypeByUserAgent(userAgent)
      }));
    }
    return {
      pageProps: {
        ...appInitialProps.pageProps
      }
    };
  };
});
export default MyApp;