import { keyById, mapById, uniq } from '@helpers/data';
import { SupportReceiveGetMessageListReducer } from './types';
export const supportReceiveGetMessageListReducer: SupportReceiveGetMessageListReducer = (state, action) => {
  const {
    limit,
    offset,
    items,
    total
  } = action.payload;
  return {
    ...state,
    messagesById: keyById(items),
    messageIds: uniq(mapById(items)),
    limit,
    offset,
    total,
    hasMore: offset + limit < total,
    dataLoaded: true
  };
};