import { ERROR_CODE } from '@config/errors';
import { findByCode } from '@helpers/data';
import { BaseNetError } from '@errors/base-net-error';
import { countersGetUnread } from '@services/counters';
import { calendarSetConsultationsCounter } from '@slices/calendar';
import { chatsSetUnreadMessagesCounter } from '@slices/chats';
import { invitationsSetCounter } from '@slices/invitations';
import { viewsSetViewsCounter } from '@slices/views';
import { CountersFetchGetUnread } from './types';
export const countersFetchGetUnread: CountersFetchGetUnread = () => {
  return async dispatch => {
    try {
      const data = await countersGetUnread().promise;
      dispatch(chatsSetUnreadMessagesCounter({
        unreadMessagesCount: data.messagesCount
      }));
      dispatch(invitationsSetCounter({
        pendingInvitationsCount: data.invitationsCount
      }));
      dispatch(viewsSetViewsCounter({
        pendingViewsCount: data.visitsCount
      }));
      dispatch(calendarSetConsultationsCounter({
        pendingConsultationsCount: data.consultationsCount
      }));
    } catch (error) {
      if (error instanceof BaseNetError) {
        if (findByCode(error.body.errors, ERROR_CODE.EMAIL_NOT_CONFIRMED)) {
          return undefined;
        }
        throw error;
      }
      throw error;
    }
  };
};