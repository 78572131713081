import { MemberId } from '@types';
export enum REALTIME_STATUS {
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  DISCONNECTED = 'DISCONNECTED',
}
export enum REALTIME_EVENT_TYPE {
  CHAT_MESSAGE_RECEIVED = 1,
  CHAT_HAS_BEEN_READ = 2,
  CHAT_COMPLIMENT_RECEIVED = 3,
  CHAT_INCOMING_MEDIA_CALL_RECEIVED = 5,
  CHAT_MEDIA_CALL_HAS_BEEN_REJECTED = 6,
  CHAT_MEDIA_CALL_ENDED = 15,
  CALL_ACCEPTED_ALREADY = 7,
  CLIENT_NOT_ENOUGH_MONEY_CALL_END = 8,
  /**
   * @deprecated
   * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
   */
  CHAT_PREPAYMENT_BALANCE_CHANGED = 20,
  VIDEO_UPLOAD_COMPLETED = 21,
  INVITATIONS_READ_ALL = 22,
  VIEWS_READ_ALL = 23,
  SUPPORT_MESSAGE_RECEIVED = 19,
  PAYMENT_IS_SUCCESSFUL = 9,
  PAYMENT_IS_FAILURE = 10,
  BALANCE_CHANGED = 14,
  MEMBER_ASSESS_RATING = 12,
  INVITATION_RECEIVED = 4,
  VIEW_RECEIVED = 17,
  CALENDAR_CONSULTATION_CREATED = 25,
  CALENDAR_CONSULTATION_APPROVED = 26,
  CALENDAR_CONSULTATION_REJECTED = 27,
  CALENDAR_COINS_REFUND = 28,
  CALENDAR_CONSULTATION_DONE = 29,
  USER_LOGOUT = 18,
}
export enum REALTIME_HUB_METHOD_NAME {
  RECEIVE_EVENT = 'ReceiveEvent',
}
export type RealtimeEventId = string;
export type RealtimeEventKey = number;
export type RealtimeEvent<T extends REALTIME_EVENT_TYPE, P = unknown> = {
  userId: MemberId;
  type: T;
  payload: P;
  key: RealtimeEventKey;
  prevKey: RealtimeEventKey;
};
export type RealtimeSessionId = string;