import { useEffect } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { ERROR_CODE } from '@config/errors';
import { findByCode } from '@helpers/data';
import { addEvent } from '@helpers/dom';
import { BaseNetError } from '@errors/base-net-error';
import { ROUTES } from '@routing/routes';
import { useNavigator } from '@hooks/routing';
export const FilledProfileRequiredErrorManager: React.FC = () => {
  const navigator = useNavigator();
  useEffect(() => {
    return addEvent<PromiseRejectionEvent>(window, EVENT_NAMES.UNHANDLEDREJECTION, event => {
      event.promise.catch(error => {
        if (error instanceof BaseNetError) {
          const errors = error.getOtherErrors();
          if (findByCode(errors, ERROR_CODE.PROFILE_NOT_APPROVED_YET)) {
            navigator.navigate(ROUTES.profile.edit.index.getDescriptor());
          }
        }
      });
    });
  });
  return null;
};