module.exports.activeVariant = ({ addVariant, e, postcss }) => {
  addVariant('active', ({ container, separator }) => {
    const allRule = postcss.atRule({
      name: 'media',
      params: 'all',
    });
    allRule.append(container.nodes);
    container.append(allRule);
    allRule.walkRules((rule) => {
      rule.selector = `.${e(
        `active${separator}${rule.selector.slice(1)}`
      )}:active`;
    });
  });
};
