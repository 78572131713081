import { enforceDateStringToISO } from '@helpers/date';
import { CalendarGetCurrentClientCalendarResponseData, CalendarGetCurrentClientCalendarResponseDto, CalendarGetCurrentMemberCalendarRequestData, CalendarGetCurrentMemberCalendarRequestParams, CalendarGetCurrentMemberCalendarResponseData, CalendarGetCurrentMemberCalendarResponseDto, CalendarGetCurrentSpecialistCalendarResponseData, CalendarGetCurrentSpecialistCalendarResponseDto } from '@services/dto/calendar/calendar-get-current-member-calendar';
import { ROLE_TYPE } from '@types';
export const calendarGetCurrentMemberCalendarIsClientDto = (dto: CalendarGetCurrentMemberCalendarResponseDto): dto is CalendarGetCurrentClientCalendarResponseDto => {
  return dto.data.owner.roleType === ROLE_TYPE.CLIENT;
};
export const calendarGetCurrentMemberCalendarIsClientData = (data: CalendarGetCurrentMemberCalendarResponseData): data is CalendarGetCurrentClientCalendarResponseData => {
  return data.owner.roleType === ROLE_TYPE.CLIENT;
};
export const calendarGetCurrentMemberCalendarIsSpecialistDto = (dto: CalendarGetCurrentMemberCalendarResponseDto): dto is CalendarGetCurrentSpecialistCalendarResponseDto => {
  return dto.data.owner.roleType === ROLE_TYPE.SPECIALIST;
};
export const calendarGetCurrentMemberCalendarIsSpecialistData = (data: CalendarGetCurrentMemberCalendarResponseData): data is CalendarGetCurrentSpecialistCalendarResponseData => {
  return data.owner.roleType === ROLE_TYPE.SPECIALIST;
};
export const calendarGetCurrentMemberCalendarRequestToParams = (data: CalendarGetCurrentMemberCalendarRequestData): CalendarGetCurrentMemberCalendarRequestParams => {
  return {
    dateFrom: data.dateFrom,
    dateTo: data.dateTo
  };
};
export const calendarGetCurrentMemberCalendarResponseFromDto = (dto: CalendarGetCurrentMemberCalendarResponseDto): CalendarGetCurrentMemberCalendarResponseData => {
  if (calendarGetCurrentMemberCalendarIsSpecialistDto(dto)) {
    return {
      ...dto.data,
      workingTimes: dto.data.workingTimes.map(workingTime => ({
        ...workingTime,
        timing: {
          ...workingTime.timing,
          beginTime: enforceDateStringToISO(workingTime.timing.beginTime),
          endTime: enforceDateStringToISO(workingTime.timing.endTime)
        }
      })),
      consultations: dto.data.consultations.map(consultation => ({
        ...consultation,
        timing: {
          ...consultation.timing,
          beginTime: enforceDateStringToISO(consultation.timing.beginTime)
        },
        creationTime: enforceDateStringToISO(consultation.creationTime),
        actions: consultation.actions.map(action => ({
          ...action,
          creationTime: enforceDateStringToISO(action.creationTime)
        }))
      }))
    };
  }
  return {
    ...dto.data,
    consultations: dto.data.consultations.map(consultation => ({
      ...consultation,
      timing: {
        ...consultation.timing,
        beginTime: enforceDateStringToISO(consultation.timing.beginTime)
      },
      creationTime: enforceDateStringToISO(consultation.creationTime),
      actions: consultation.actions.map(action => ({
        ...action,
        creationTime: enforceDateStringToISO(action.creationTime)
      }))
    }))
  };
};