import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { RealtimeRuntimeRequestDto, RealtimeRuntimeResponseData, RealtimeRuntimeResponseDto } from '@services/dto/realtime/realtime-runtime';
type RealtimeRuntime = () => AuthedJsonFetchResult<RealtimeRuntimeResponseData>;
export const realtimeRuntime: RealtimeRuntime = () => {
  return authedJsonFetch<RealtimeRuntimeResponseDto, RealtimeRuntimeRequestDto>(ENDPOINTS.realtime.runtime(),
  //
  {
    method: 'POST'
  });
};