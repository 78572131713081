import { Message } from '@types';
import { MessagesAddMessageReducer } from './types';
export const messagesAddMessageReducer: MessagesAddMessageReducer = (state, action) => {
  const {
    message
  } = action.payload;
  const {
    byChatId
  } = state;
  const messagesByChatId = byChatId[message.chatId] ?? {};
  const {
    messagesIds = [],
    messagesById = {}
  } = messagesByChatId;
  const prevMessage = (messagesById[message.id] as undefined | Message);
  return {
    ...state,
    byChatId: {
      ...byChatId,
      [message.chatId]: {
        ...messagesByChatId,
        messagesById: {
          ...messagesById,
          [message.id]: {
            ...prevMessage,
            ...message,
            /**
             * Message's isRead can be changed while the app is waiting for
             * response data, so we have to preserve the old value in case
             * it exists.
             */
            isRead: prevMessage?.isRead ?? message.isRead
          }
        },
        messagesIds: messagesIds.includes(message.id) ? messagesIds : [message.id, ...messagesIds]
      }
    }
  };
};