import { NotificationsUiAddReducer } from './types';
export const notificationsUiAddReducer: NotificationsUiAddReducer = (state, action) => {
  const {
    ids,
    byId
  } = state;
  const notification = action.payload;
  return {
    ...state,
    ids: [...ids, notification.id],
    byId: {
      ...byId,
      [notification.id]: notification
    }
  };
};