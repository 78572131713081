import { AppFCC } from '@types';
import { AspectRatioBoxInnerBoxStyled, AspectRatioBoxStyled } from './styled';
import { AspectRatioBoxProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspectRatioBox: AppFCC<AspectRatioBoxProps> = props => {
  const {
    className,
    children,
    ratio,
    onClick
  } = props;
  return _jsx(AspectRatioBoxStyled //
  , {
    className: className,
    ratio: ratio,
    onClick: onClick,
    children: _jsx(AspectRatioBoxInnerBoxStyled, {
      children: children
    })
  });
};