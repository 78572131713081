import { POPUP } from '@config/popups';
import { QUERY_PARAM } from '@config/routing';
import { includesWithCaseInsensitive, isString, rejectByIdWithCaseInsensitive } from '@helpers/data';
import { parseQueryStringList } from '@helpers/routing';
import { Popup, Popups } from '@types';
import { ParseQuerySource } from './types';
export const isPopupId = (value: unknown): value is POPUP => {
  if (!isString(value)) {
    return false;
  }
  return includesWithCaseInsensitive(Object.values(POPUP), value);
};
export const rejectPopup = <P,>(popups: Popups<P>, popup: Popup<P>): Popups<P> => {
  return rejectByIdWithCaseInsensitive(popups, popup.id);
};
export const parseQuerySource: ParseQuerySource = query => {
  return parseQueryStringList(query, QUERY_PARAM.SOURCE);
};