import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SupportGetUnreadResponseData, SupportGetUnreadResponseDto } from '@services/dto/support/support-get-unread';
import { supportGetUnreadResponseFromDto } from '@mapping/support/support-get-unread';
type SupportGetUnread = () => AuthedJsonFetchResult<SupportGetUnreadResponseData>;
export const supportGetUnread: SupportGetUnread = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SupportGetUnreadResponseDto>(ENDPOINTS.support.getUnread(), {
    method: 'GET'
  });
  return {
    promise: promise.then(supportGetUnreadResponseFromDto),
    cancel
  };
};