import { ACCOUNT_PASSWORD_RECOVERY_CONFIRM_STEPS, ACCOUNT_PASSWORD_RECOVERY_STEPS, ACCOUNT_TWO_STEP_REGISTER_STEPS } from '@config/account';
import { LANDING_CLIENT_REGISTER_STEPS } from '@config/landing';
import { QUERY_PARAM } from '@config/routing';
import { parseQueryInt } from '@helpers/routing';
import { AuthDecodedToken } from '@types';
import { ParseQueryAccountPasswordRecoveryConfirmStep, ParseQueryAccountPasswordRecoveryStep, ParseQueryAccountTwoStepsRegisterStep, ParseQueryLandingClientRegisterStep, ParseQueryStep } from './types';
export const isTwoStepRegisterStep = (mayBeStep: unknown): mayBeStep is ACCOUNT_TWO_STEP_REGISTER_STEPS => {
  return Boolean(typeof mayBeStep === 'number' && mayBeStep in ACCOUNT_TWO_STEP_REGISTER_STEPS);
};
export const twoStepRegisterStepOrStart = (mayBeStep: unknown): ACCOUNT_TWO_STEP_REGISTER_STEPS => {
  return isTwoStepRegisterStep(mayBeStep) //
  ? mayBeStep : ACCOUNT_TWO_STEP_REGISTER_STEPS.START;
};
export const isClientRegisterStep = (mayBeStep: unknown): mayBeStep is LANDING_CLIENT_REGISTER_STEPS => {
  return Boolean(typeof mayBeStep === 'number' && mayBeStep in LANDING_CLIENT_REGISTER_STEPS);
};
export const clientRegisterStepOrStart = (mayBeStep: unknown): LANDING_CLIENT_REGISTER_STEPS => {
  return isClientRegisterStep(mayBeStep) //
  ? mayBeStep : LANDING_CLIENT_REGISTER_STEPS.START;
};
export const calculateTokenTimeDifference = (decodedToken: undefined | AuthDecodedToken): number => {
  return (decodedToken?.exp ?? 0) * 1000 - Date.now();
};
export const parseQueryStep: ParseQueryStep = query => {
  return parseQueryInt(query, QUERY_PARAM.STEP);
};
export const parseQueryLandingClientRegisterStep: ParseQueryLandingClientRegisterStep = query => {
  const queryStep = parseQueryStep(query);
  return queryStep !== undefined && queryStep in LANDING_CLIENT_REGISTER_STEPS //
  ? (queryStep as LANDING_CLIENT_REGISTER_STEPS) : undefined;
};
export const parseQueryAccountTwoStepsRegisterStep: ParseQueryAccountTwoStepsRegisterStep = query => {
  const queryStep = parseQueryStep(query);
  return queryStep !== undefined && queryStep in ACCOUNT_TWO_STEP_REGISTER_STEPS //
  ? (queryStep as ACCOUNT_TWO_STEP_REGISTER_STEPS) : undefined;
};
export const parseQueryAccountPasswordRecoveryStep: ParseQueryAccountPasswordRecoveryStep = query => {
  const queryStep = parseQueryStep(query);
  return queryStep !== undefined && queryStep in ACCOUNT_PASSWORD_RECOVERY_STEPS //
  ? (queryStep as ACCOUNT_PASSWORD_RECOVERY_STEPS) : undefined;
};
export const parseQueryAccountPasswordRecoveryConfirmStep: ParseQueryAccountPasswordRecoveryConfirmStep = query => {
  const queryStep = parseQueryStep(query);
  return queryStep !== undefined && queryStep in ACCOUNT_PASSWORD_RECOVERY_CONFIRM_STEPS //
  ? (queryStep as ACCOUNT_PASSWORD_RECOVERY_CONFIRM_STEPS) : undefined;
};