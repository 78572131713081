import { IPaymentsPayWindow } from '@payments/payments-pay-window';
import { POPUP } from '@config/popups';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimePaymentIsSuccessfulEvent, RealtimePaymentIsSuccessfulEventPayloadData } from '@services/dto/realtime/realtime-payment-event/realtime-payment-is-successful';
import { LEVEL, PAYMENT_PURPOSE } from '@types';
import { addPopup } from '@actions/popups/add-popup';
import { currentClientSetBalance, currentClientSetLevel } from '@slices/current-client';
import { removePopup } from '@slices/popups';
import { PaymentsPremiumSuccessAlertProps } from '@popups/modals/payments/payments-premium-success-alert';
import { PaymentsRefillAlertProps } from '@popups/modals/payments/payments-refill-alert';
import { RealtimePaymentIsSuccessful } from './types';
export const realtimePaymentIsSuccessful = (event: RealtimePaymentIsSuccessfulEvent, args: {
  paymentsPayWindow: IPaymentsPayWindow;
}): RealtimePaymentIsSuccessful => {
  return dispatch => {
    const {
      payload
    } = event;
    const data = readJson<RealtimePaymentIsSuccessfulEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      coinsAmount,
      paymentPurpose,
      coinsBalance
    } = data;
    switch (paymentPurpose) {
      case PAYMENT_PURPOSE.BUY_COINS:
        {
          dispatch(currentClientSetBalance({
            coinAmount: coinsBalance
          }));
          dispatch(removePopup({
            id: POPUP.PAYMENTS_ADD_FUNDS
          }));
          dispatch(addPopup<PaymentsRefillAlertProps>({
            id: POPUP.PAYMENTS_REFILL_ALERT,
            props: {
              coinsAmount
            }
          }));
          break;
        }
      case PAYMENT_PURPOSE.BUY_PREMIUM:
        {
          dispatch(currentClientSetLevel({
            level: LEVEL.PREMIUM
          }));
          dispatch(removePopup({
            id: POPUP.PAYMENTS_BUY_PREMIUM
          }));
          dispatch(addPopup<PaymentsPremiumSuccessAlertProps>({
            id: POPUP.PAYMENTS_PREMIUM_SUCCESS_ALERT
          }));
          break;
        }
    }
    const {
      paymentsPayWindow
    } = args;
    paymentsPayWindow.close();
  };
};