import { camelKeysRecursive, readJson } from '@helpers/data';
import { isConnectingCall, isOutgoingCall, isRejectedCall } from '@helpers/media-call';
import { RealtimeChatMediaCallEndedEventPayloadData } from '@services/dto/realtime/realtime-chat-event/realtime-chat-media-call-ended';
import { MEDIA_CALL_STATE } from '@types';
import { mediaCallClear, mediaCallSetState } from '@slices/media-call';
import { mediaCallsGetCallByChatId } from '@selectors/media-call';
import { RealtimeChatMediaCallEnded } from './types';
export const realtimeChatMediaCallEnded: RealtimeChatMediaCallEnded = event => {
  return (dispatch, getState) => {
    const data = readJson<RealtimeChatMediaCallEndedEventPayloadData>(event.payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      chatId
    } = data;
    let currentMediaCall = mediaCallsGetCallByChatId(getState(), chatId);
    if (isConnectingCall(currentMediaCall) && isOutgoingCall(currentMediaCall)) {
      dispatch(mediaCallSetState({
        chatId,
        mediaCallState: MEDIA_CALL_STATE.REJECTED_BY_TIMEOUT
      }));
    }
    currentMediaCall = mediaCallsGetCallByChatId(getState(), chatId);
    if (!isRejectedCall(currentMediaCall)) {
      dispatch(mediaCallClear({
        chatId
      }));
    }
  };
};