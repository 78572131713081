import UAParserJS from 'ua-parser-js';
import { IUAParser, IUAParserConstructorArgs } from './types';

/**
 * The implementation of UIParser relies on the new userAgentData experimental
 * feature that is available in limited amount of browsers.
 * Types for the userAgentData feature are located in user-agent-data.d.ts
 * user-agent-data.d.ts must be removed when the userAgentData feature support
 * will be added in all browsers.
 */
export class UAParser implements IUAParser {
  private readonly _userAgent: string;
  private readonly _userAgentData: Navigator['userAgentData'];
  private readonly _parser: UAParserJS;
  constructor(args: IUAParserConstructorArgs) {
    const {
      userAgent,
      userAgentData
    } = args;
    this._userAgent = userAgent;
    this._userAgentData = userAgentData;
    this._parser = new UAParserJS(this._userAgent);
  }
  private _getOSFromUserAgent(): string {
    const os = this._parser.getOS();
    return `${os.name} ${os.version}`;
  }
  public async getOS(): Promise<string> {
    if (!this._userAgentData) {
      return Promise.resolve(this._getOSFromUserAgent());
    }

    /**
     * Due to stopping support of OS version exporting in userAgent we have
     * to get it via the new userAgentData api:
     * https://learn.microsoft.com/en-us/microsoft-edge/web-platform/how-to-detect-win11
     */
    const ua = await this._userAgentData //
    .getHighEntropyValues(['platformVersion']);
    if (!ua.platform || !ua.platformVersion) {
      return this._getOSFromUserAgent();
    }
    if (ua.platform === 'Windows') {
      const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
      if (majorPlatformVersion >= 13) {
        return 'Windows 11';
      }
      if (majorPlatformVersion > 0) {
        return 'Windows 10';
      }
    }
    if (ua.platform === 'macOS') {
      return `${ua.platform} ${ua.platformVersion}`;
    }
    return this._getOSFromUserAgent();
  }
}