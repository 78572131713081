import { authDecodeToken } from '@helpers/auth';
import { readStorageAuthData } from '@storage/auth';
import { initializeStorage } from '@storage/initialize';
import { appCreateSlice } from '@store/rtk';
import { accountReceiveGetAssociatedSocialMediaReducer } from './account-recieve-get-associated-social-media';
import { accountReceiveGetEmailIsConfirmedReducer } from './account-recieve-get-email-is-confrmed';
import { accountReceiveGetTimezonesReducer } from './account-recieve-get-timezones';
import { accountSetAuthorizedReducer } from './account-set-authorized';
import { accountSetTimezoneReducer } from './account-set-timezone';
import { accountSetUnauthorizedReducer } from './account-set-unauthorized';
import { AccountState } from './types';
export const accountDefaultState: AccountState = {
  isAuthorized: false,
  id: undefined,
  prevId: undefined,
  roleType: undefined,
  email: undefined,
  emailIsConfirmed: undefined,
  associatedSocialMedia: [],
  timezoneId: undefined,
  timezones: undefined
};
export const accountGetDefaultState = (): AccountState => {
  const storage = initializeStorage();
  const authData = readStorageAuthData(storage);
  const decodedToken = authDecodeToken(authData?.accessToken);
  return {
    ...accountDefaultState,
    isAuthorized: Boolean(authData),
    id: decodedToken?.user_id,
    prevId: decodedToken?.user_id,
    roleType: decodedToken?.role_type ? Number(decodedToken?.role_type) : undefined,
    email: decodedToken?.email,
    timezoneId: decodedToken?.timezone_id
  };
};
const accountSlice = appCreateSlice({
  name: 'account',
  initialState: accountGetDefaultState,
  reducers: {
    accountSetAuthorized: accountSetAuthorizedReducer,
    accountSetUnauthorized: accountSetUnauthorizedReducer,
    accountReceiveGetAssociatedSocialMedia: accountReceiveGetAssociatedSocialMediaReducer,
    accountReceiveGetEmailIsConfirmed: accountReceiveGetEmailIsConfirmedReducer,
    accountSetTimezone: accountSetTimezoneReducer,
    accountReceiveGetTimezones: accountReceiveGetTimezonesReducer
  }
});
export const {
  actions: {
    accountSetAuthorized,
    accountSetUnauthorized,
    accountReceiveGetAssociatedSocialMedia,
    accountReceiveGetEmailIsConfirmed,
    accountSetTimezone,
    accountReceiveGetTimezones
  },
  reducer: accountReducer
} = accountSlice;