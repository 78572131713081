import { ClientsSetChatIdWithCurrentUserReducer } from './types';
export const clientsSetChatIdWithCurrentUserReducer: ClientsSetChatIdWithCurrentUserReducer = (state, action) => {
  const {
    clientId,
    chatIdWithCurrentUser
  } = action.payload;
  return {
    ...state,
    clientsById: {
      ...state.clientsById,
      [clientId]: {
        ...state.clientsById[clientId],
        chatIdWithCurrentUser
      }
    }
  };
};