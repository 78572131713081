import { PaymentsGetBankCardsResponseData, PaymentsGetBankCardsResponseDto, PaymentsGetCoinPricesResponseData, PaymentsGetCoinPricesResponseDto } from '@services/dto/payments';
import { COIN, COINS, CURRENCY, CURRENCY_CODE, CURRENCY_SYM } from '@types';
import { enforceDateStringToISO } from './date';
export const amountToCoins = (amount: number): COIN => {
  return amount > 0 && amount <= 1 ? COIN.COINS_1 : amount > 1 && amount <= 3 ? COIN.COINS_3 : amount > 3 && amount <= 5 ? COIN.COINS_5 : amount > 5 && amount <= 10 ? COIN.COINS_10 : amount > 10 && amount <= 50 ? COIN.COINS_20 : amount > 50 ? COIN.COINS_30 : COIN.COINS_1;
};
export const coinsImageSrcByAmount = (amount: number): string => {
  return COINS[amountToCoins(amount)].imageSrc;
};
export const priceFromDto = (value: number): number => {
  return value / 100;
};
export const priceToDto = (value: number): number => {
  return value * 100;
};
export const currencyToCurrencySym = (currency: CURRENCY): CURRENCY_SYM => {
  return {
    [CURRENCY.RUB]: CURRENCY_SYM.RUB,
    [CURRENCY.EUR]: CURRENCY_SYM.EUR
  }[currency];
};
export const currencyToCurrencyCode = (currency: CURRENCY): CURRENCY_CODE => {
  return {
    [CURRENCY.RUB]: CURRENCY_CODE.RUB,
    [CURRENCY.EUR]: CURRENCY_CODE.EUR
  }[currency];
};
export const bankCardsFromDto = (dto: PaymentsGetBankCardsResponseDto['data']): PaymentsGetBankCardsResponseData => {
  return dto.map(item => {
    return {
      ...item,
      creationTime: enforceDateStringToISO(item.creationTime)
    };
  });
};
export const coinPricesFromDto = (dto: PaymentsGetCoinPricesResponseDto['data']): PaymentsGetCoinPricesResponseData => {
  return dto.map(item => {
    return {
      ...item,
      prices: item.prices.map(price => {
        return {
          ...price,
          realAmount: priceFromDto(price.realAmount),
          discount: priceFromDto(price.discount)
        };
      })
    };
  });
};
export const isPlatronIframe = (url: undefined | string): undefined | boolean => {
  if (!url) {
    return undefined;
  }
  return /\.*platron.*/.test(url);
};
export const isPayablIframe = (url: undefined | string): undefined | boolean => {
  if (!url) {
    return undefined;
  }
  return /\.*[^sandbox]\.payabl\.*/.test(url);
};
export const isPayablSandboxIframe = (url: undefined | string): undefined | boolean => {
  if (!url) {
    return undefined;
  }
  return /\.*sandbox.payabl\.*/.test(url);
};
export const isSoftPayIframe = (url: undefined | string): undefined | boolean => {
  if (!url) {
    return undefined;
  }
  return /\.*softpaymoney.*/.test(url);
};