import { uniq } from '@helpers/data';
import { SupportReceiveGetLastMessageReducer } from './types';
export const supportReceiveGetLastMessageReducer: SupportReceiveGetLastMessageReducer = (state, action) => {
  const {
    message
  } = action.payload;
  if (!message) {
    return state;
  }
  const {
    messageIds: messagesIds,
    messagesById
  } = state;
  return {
    ...state,
    messagesById: {
      ...messagesById,
      [message.id]: message
    },
    messageIds: uniq([message.id, ...(messagesIds ?? [])])
  };
};