import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SpecialistsSaveWalletsRequestData, SpecialistsSaveWalletsRequestDto, SpecialistsSaveWalletsResponseData, SpecialistsSaveWalletsResponseDto } from '@services/dto/specialists';
import { specialistsSaveWalletsRequestToDto, specialistsSaveWalletsResponseFromDto } from '@mapping/specialists';
type SpecialistsSaveWallets = (requestData: SpecialistsSaveWalletsRequestData) => AuthedJsonFetchResult<SpecialistsSaveWalletsResponseData>;
export const specialistsSaveWallets: SpecialistsSaveWallets = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SpecialistsSaveWalletsResponseDto, SpecialistsSaveWalletsRequestDto>(ENDPOINTS.specialists.saveWallets(), {
    method: 'POST',
    data: specialistsSaveWalletsRequestToDto(requestData)
  });
  return {
    promise: promise.then(specialistsSaveWalletsResponseFromDto),
    cancel
  };
};