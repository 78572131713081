import { AppFCC } from '@types';
import { OverlayStyled } from './styled';
import { OverlayProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Overlay: AppFCC<OverlayProps> = props => {
  const {
    className,
    overlayBgColor,
    onClick,
    onKeyPress
  } = props;
  return _jsx(OverlayStyled //
  , {
    className: className,
    overlayBgColor: overlayBgColor,
    role: "banner",
    onClick: onClick,
    onKeyPress: onKeyPress
  });
};