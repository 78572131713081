import { SpecialistsReceiveInviteMemberReducer } from './types';
export const specialistsReceiveInviteMemberReducer: SpecialistsReceiveInviteMemberReducer = (state, action) => {
  const {
    specialistId
  } = action.payload;
  return {
    ...state,
    specialistsById: {
      ...state.specialistsById,
      [specialistId]: {
        ...state.specialistsById[specialistId],
        invitationRequested: true
      }
    }
  };
};