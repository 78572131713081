import { POPUP } from '@config/popups';
import { authDecodeToken } from '@helpers/auth';
import { resetPopupImpressionsNumber } from '@storage/popups';
import { accountLogin } from '@actions/account/account-login';
import { appResetStore } from '@actions/app';
import { accountGetAccountId, accountGetIsAuthorized } from '@selectors/account';
import { AccountRelogin } from './types';
export const accountRelogin: AccountRelogin = authData => (dispatch, getState) => {
  const state = getState();
  const isAuthorized = accountGetIsAuthorized(state);
  const currentMemberId = accountGetAccountId(state);
  const decodedToken = authDecodeToken(authData.accessToken);
  if (isAuthorized && currentMemberId !== decodedToken?.user_id) {
    dispatch(accountLogin(authData));
    dispatch(appResetStore());

    /**
     * We don't rely on currentMemberId here because
     * ACCOUNT_EMAIL_CONFIRMATION_REQUIRED popup impressions number is reset
     * on unauthorization.
     */
    const currentMemberId = '';
    resetPopupImpressionsNumber(undefined, POPUP.ACCOUNT_EMAIL_CONFIRMATION_REQUIRED, currentMemberId);
    return;
  }
  dispatch(accountLogin(authData));
};