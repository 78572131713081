import { STORAGE_KEYS } from '@config/storage';
import { ILocalStorage } from '@storage';
import { AuthToken } from '@types';
import { initializeStorage } from './initialize';
export type AuthData = AuthToken;

/**
 * @deprecated
 * Must take storage: ILocalStorage as an argument to ensure
 * that they read/write from/to the right storage object on server.
 * Some helpers like authedHttpFetch doesn't support such implementation.
 * Think, how to implement it in the future.
 */
export const writeAuthData = (data: AuthData): void => {
  const storage = initializeStorage();
  storage.write<AuthData>(STORAGE_KEYS.AUTH, data);
};

/**
 * @deprecated
 * Must take storage: ILocalStorage as an argument to ensure
 * that they read/write from/to the right storage object on server.
 * Some helpers like authedHttpFetch doesn't support such implementation.
 * Think, how to implement it in the future.
 */
export const removeAuthData = (): void => {
  const storage = initializeStorage();
  storage.remove(STORAGE_KEYS.AUTH);
};
export const readStorageAuthData = (storage: ILocalStorage): undefined | AuthData => {
  return storage.read<AuthData>(STORAGE_KEYS.AUTH);
};
export const readAccessToken = (storage: ILocalStorage): undefined | string => {
  const authData = readStorageAuthData(storage);
  return authData?.accessToken;
};
export const readRefreshToken = (storage: ILocalStorage): undefined | string => {
  const authData = readStorageAuthData(storage);
  return authData?.refreshToken;
};
export const writeStorageAuthData = (storage: ILocalStorage, data: AuthData): void => {
  storage.write<AuthData>(STORAGE_KEYS.AUTH, data);
};
export const removeStorageAuthData = (storage: ILocalStorage): void => {
  storage.remove(STORAGE_KEYS.AUTH);
};