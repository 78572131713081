import { uniq } from '@helpers/data';
import { ChatsReceiveGetLastChatReducer } from './types';

//@todo check if needed and remove or remove this todo
export const chatsReceiveGetLastChatReducer: ChatsReceiveGetLastChatReducer = (state, action) => {
  const {
    chat
  } = action.payload;
  if (!chat) {
    return state;
  }
  const {
    chatsIds,
    chatsById
  } = state;
  return {
    ...state,
    chatsById: {
      ...chatsById,
      [chat.chatId]: chat
    },
    chatsIds: uniq([chat.chatId, ...(chatsIds ?? [])])
  };
};