import { EVENT_NAMES, KEY_NAMES } from '@config/dom';
import { ERROR_NAME } from '@config/errors';
type AddScriptArgs = {
  src: string;
  onload?: () => void;
  async?: boolean;
};
export const addScript = async (args: AddScriptArgs): Promise<void> => {
  const {
    src,
    async
  } = args;
  await new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = async ?? false;
    script.onload = resolve;
    script.onabort = reject;
    document.body.appendChild(script);
  });
};
export type AddEventResult = () => void;
export const addEvent = <E extends Event,>(target: EventTarget, name: string, fn: (event: E) => void, useCapture = false): AddEventResult => {
  target.addEventListener(name, (fn as EventListener), useCapture);
  return () => {
    target.removeEventListener(name, (fn as EventListener), useCapture);
  };
};
export const dispatchEvent = (target: EventTarget, name: string): void => {
  target.dispatchEvent(new Event(name));
};
export const dispatchCustomEvent = (target: EventTarget, name: string, detail: undefined | Record<string, unknown>): void => {
  target.dispatchEvent(new CustomEvent(name, {
    detail
  }));
};
export const formDispatchSubmitEvent = (formElement: null | HTMLFormElement, options?: EventInit): void => {
  if (!formElement) {
    return;
  }
  if (typeof formElement.requestSubmit === 'function') {
    formElement.requestSubmit();
  } else {
    formElement.dispatchEvent(new Event(EVENT_NAMES.SUBMIT, options));
  }
};
export const emulateTouchEnter = <E extends Element,>(onTouchEnter: (event: React.TouchEvent<E>) => void): ((event: React.TouchEvent<E>) => void) => {
  let lastInteractedElement: undefined | E;
  const touchMove = (event: React.TouchEvent<E>) => {
    const {
      touches
    } = event;
    if (touches.length > 1) {
      return;
    }
    const element = (document.elementFromPoint(touches[0].clientX, touches[0].clientY) as E);
    if (!element) {
      return;
    }
    if (!lastInteractedElement) {
      lastInteractedElement = element;
      return;
    }
    if (lastInteractedElement === element) {
      return;
    }
    lastInteractedElement = element;
    event.target = element;
    onTouchEnter(event);
  };
  return touchMove;
};
export const isShift = (event: KeyboardEvent | React.KeyboardEvent): boolean => {
  return event.shiftKey;
};
export const isEscape = (event: KeyboardEvent | React.KeyboardEvent): boolean => {
  const {
    key
  } = event;
  return key === KEY_NAMES.ESCAPE;
};
export const isEnter = (event: KeyboardEvent | React.KeyboardEvent): boolean => {
  const {
    key
  } = event;
  return key === KEY_NAMES.ENTER;
};
export const isSpace = (event: KeyboardEvent | React.KeyboardEvent): boolean => {
  const {
    key
  } = event;
  return key === KEY_NAMES.SPACE;
};
export const isTab = (event: KeyboardEvent | React.KeyboardEvent): boolean => {
  const {
    key
  } = event;
  return key === KEY_NAMES.TAB;
};
export const isArrowUp = (event: KeyboardEvent | React.KeyboardEvent): boolean => {
  const {
    key
  } = event;
  return key === KEY_NAMES.ARROW_UP;
};
export const isArrowDown = (event: KeyboardEvent | React.KeyboardEvent): boolean => {
  const {
    key
  } = event;
  return key === KEY_NAMES.ARROW_DOWN;
};
export const isSingleTouch = (event: TouchEvent): boolean => {
  return event.targetTouches.length === 1;
};
export const isDescendantOf = (descendant: undefined | null | Node, ancestor: null | Node): boolean => {
  return Boolean(ancestor) && Boolean(descendant) && (ancestor === descendant || isDescendantOf(descendant?.parentNode, ancestor));
};

/**
 * Measures the CURRENT width of the body scrollbar. Returns ZERO if the body
 * doesn't currently have a scrollbar. This relies on the fact that in our CSS,
 * we always set `overflow-y: scroll` for the body, which allows to avoid layout
 * shifting when navigating between pages that overflow and ones that don't.
 */

export const getGlobalScrollbarWidth = (): number => {
  return window.innerWidth - document.documentElement.clientWidth;
};
export const scrollIntoView = (element: Element, options?: ScrollIntoViewOptions): void => {
  element.scrollIntoView(options);
};
export const isElementVisible = (element: Element, partial?: boolean): boolean => {
  const {
    top,
    right,
    bottom,
    left,
    width,
    height
  } = element.getBoundingClientRect();
  if (top + right + bottom + left === 0) {
    return false;
  }
  const topCheck = partial ? top + height : top;
  const bottomCheck = partial ? bottom - height : bottom;
  const rightCheck = partial ? right - width : right;
  const leftCheck = partial ? left + width : left;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  return topCheck >= 0 && leftCheck >= 0 && bottomCheck <= windowHeight && rightCheck <= windowWidth;
};
export const playMedia = async (element: HTMLAudioElement | HTMLVideoElement): Promise<void> => {
  try {
    await element.play();
    element.muted = false;
  } catch (error) {
    if (error instanceof DOMException) {
      if (error.name === ERROR_NAME.NOT_ALLOWED_ERROR) {
        console.error(error);
        return;
      }
      throw error;
    }
    throw error;
  }
};
export const stopMedia = (element: HTMLAudioElement | HTMLVideoElement): void => {
  element.pause();
  element.currentTime = 0;
};
export const ensureIsFramed = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  return window !== top || document !== top.document || self.location !== top.location;
};