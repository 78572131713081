import { useCallback, useEffect } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { FRONTEND_ADMIN_APPLICATION_HOST } from '@config/env';
import { addEvent } from '@helpers/dom';
import { ROUTES } from '@routing/routes';
import { readAccessToken } from '@storage/auth';
import { AuthByAdminResponseDto } from '@services/dto/auth/auth-by-admin';
import { AppFC } from '@types';
import { accountFetchLogoff } from '@actions/account/account-fetch-logoff';
import { accountLogin } from '@actions/account/account-login';
import { useNavigator } from '@hooks/routing';
import { useStorage } from '@hooks/storage';
import { useTypedDispatch } from '@hooks/store';
export const AuthByAdminManager: AppFC = () => {
  const storage = useStorage();
  const navigator = useNavigator();
  const dispatch = useTypedDispatch();
  const handleMessageEvent = useCallback(async (event: MessageEvent<AuthByAdminResponseDto>) => {
    const {
      origin,
      data: {
        authData
      }
    } = event;
    const currentAccessToken = readAccessToken(storage);
    if (
    /**
     * Be careful with setting of FRONTEND_ADMIN_APPLICATION_HOST env value!
     * It must be strictly equal to the origin of the event.
     * Trailing '/' in FRONTEND_ADMIN_APPLICATION_HOST can be a reason that
     * breaks the functionality.
     */
    origin !== FRONTEND_ADMIN_APPLICATION_HOST || !authData || authData.accessToken === currentAccessToken || !window.opener) {
      return;
    }
    await dispatch(accountFetchLogoff({
      storage,
      navigator
    }));
    dispatch(accountLogin(authData));
    window.opener.postMessage({
      isAuthorized: true
    }, FRONTEND_ADMIN_APPLICATION_HOST);
    navigator.navigate(ROUTES.search.index.getDescriptor());
  }, [dispatch, navigator, storage]);
  useEffect(() => {
    return addEvent(window,
    //
    EVENT_NAMES.MESSAGE, handleMessageEvent);
  }, [handleMessageEvent]);
  return null;
};