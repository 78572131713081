import { FRONTEND_APPLICATION_HOST } from '@config/env';
import { REGEX } from '@config/regex';
export const getOrigin = (): string => {
  return typeof origin === 'undefined' //
  ? FRONTEND_APPLICATION_HOST : origin;
};
export const getOriginDomain = (): string => {
  return getOrigin().replace(REGEX.PROTOCOL, '');
};
export const ensureUrlIsExternal = (url: string): boolean => {
  return REGEX.PROTOCOL.test(url);
};
export const mergeUrlParts = (host: string, ...urlParts: string[]): string => {
  const separator = '/';
  const processedHost = host.replace(/\/$/, '');
  const processedUrlParts = Array.from(urlParts) //
  .map(part => part.replace(/(^\s*\/)|(\s*$)/gm, ''));
  return `${processedHost}${separator}${processedUrlParts.join(separator)}`;
};
export const getNewWishesEmail = (): string => {
  return `new-wishes@${getOriginDomain()}`;
};
export const getSupportEmail = (): string => {
  return `support@${getOriginDomain()}`;
};