import { IAuthRefreshState, IAuthRefreshStateConstructor } from './types';
export const AuthRefreshState: IAuthRefreshStateConstructor = class RefreshHelper implements IAuthRefreshState {
  /**
   * keep in mind that this is not a promises,
   * but an executor functions
   */
  private pendingPromises: {
    resolve: () => void;
    reject: () => void;
  }[];
  constructor() {
    this.pendingPromises = [];
  }
  public getPending(): boolean {
    return Boolean(this.pendingPromises.length);
  }
  public rejectPendingPromises(): void {
    while (this.pendingPromises.length) {
      this.pendingPromises.shift()?.reject();
    }
  }
  public resolvePendingPromises(): void {
    while (this.pendingPromises.length) {
      this.pendingPromises.shift()?.resolve();
    }
  }
  public addPendingPromise(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.pendingPromises.push({
        resolve,
        reject
      });
    });
  }
};