import { addDays, addMilliseconds, addMonths, addWeeks } from '@helpers/date';
import { CALENDAR_WORKING_TIME_REPEATER_TYPE, CalendarWorkingTimes, CalendarWorkingTimeWithoutRepeaters } from '@types';
export const repeatWorkingTimes = (args: {
  workingTimes: CalendarWorkingTimeWithoutRepeaters[];
  count: number;
  repeatFunction: (date: Date | number, amount: number) => Date;
  dateFrom?: string;
  dateTo?: string;
}): CalendarWorkingTimeWithoutRepeaters[] => {
  const {
    workingTimes,
    count,
    repeatFunction,
    dateFrom,
    dateTo
  } = args;
  const _dateFrom = !dateFrom //
  ? undefined : new Date(dateFrom);
  const _dateTo = !dateTo //
  ? undefined : new Date(dateTo);
  return workingTimes.reduce((acc, curr) => {
    const temp: CalendarWorkingTimeWithoutRepeaters[] = [];
    for (let r = 0; r < count; r++) {
      const beginTime = new Date(curr.timing.beginTime);
      const endTime = new Date(curr.timing.endTime);
      const repeatedBeginTime = repeatFunction(beginTime, r);
      const diff = endTime.getTime() - beginTime.getTime();
      const repeatedEndTime = addMilliseconds(repeatedBeginTime, diff);
      if (_dateFrom && repeatedEndTime <= _dateFrom ||
      //
      _dateTo && repeatedBeginTime >= _dateTo) {
        continue;
      }
      temp.push({
        id: curr.id,
        timing: {
          beginTime: repeatedBeginTime.toISOString(),
          endTime: repeatedEndTime.toISOString()
        },
        rowversion: curr.rowversion
      });
    }
    return [...acc, ...temp];
  }, ([] as CalendarWorkingTimeWithoutRepeaters[]));
};
export const calendarToWorkingTimesWithoutRepeaters = (args: {
  workingTimes: CalendarWorkingTimes;
  dateFrom?: string;
  dateTo?: string;
}): CalendarWorkingTimeWithoutRepeaters[] => {
  const {
    workingTimes,
    dateFrom,
    dateTo
  } = args;
  const workingTimesWithoutRepeaters = workingTimes //
  .reduce((acc, curr) => {
    let temp: CalendarWorkingTimeWithoutRepeaters[] = [{
      id: curr.id,
      timing: {
        beginTime: curr.timing.beginTime,
        endTime: curr.timing.endTime
      },
      rowversion: curr.rowversion
    }];
    const repeaters = !curr.repeaters ? [{
      type: CALENDAR_WORKING_TIME_REPEATER_TYPE.DAYLY,
      count: 1
    }] : curr.repeaters.map(repeater => ({
      ...repeater,
      count: repeater.count + 1
    }));
    repeaters.forEach(repeater => {
      if (repeater.type === CALENDAR_WORKING_TIME_REPEATER_TYPE.DAYLY) {
        temp = repeatWorkingTimes({
          workingTimes: temp,
          count: repeater.count,
          repeatFunction: addDays,
          dateFrom,
          dateTo
        });
      }
      if (repeater.type === CALENDAR_WORKING_TIME_REPEATER_TYPE.WEEKLY) {
        temp = repeatWorkingTimes({
          workingTimes: temp,
          count: repeater.count,
          repeatFunction: addWeeks,
          dateFrom,
          dateTo
        });
      }
      if (repeater.type === CALENDAR_WORKING_TIME_REPEATER_TYPE.MONTHLY) {
        temp = repeatWorkingTimes({
          workingTimes: temp,
          count: repeater.count,
          repeatFunction: addMonths,
          dateFrom,
          dateTo
        });
      }
    });
    return [...acc, ...temp];
  }, ([] as CalendarWorkingTimeWithoutRepeaters[]));
  return workingTimesWithoutRepeaters;
};