import { useMemo, useState } from 'react';
import { cx } from '@emotion/css';
import { AppFCC } from '@types';
import { useImageSource, UseImageSourceInput } from '@hooks/image';
import { useVisibility, UseVisibilityArgs } from '@hooks/visibility';
import { imageFadeInLoadedClassName, imageFadeInPendingClassName, ImageSpinnerStyled, ImageSpinnerWrapStyled } from './styled';
import { ImageProps } from './types';

/**
 * @todo Add tests
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const Image: AppFCC<ImageProps> = props => {
  const {
    className,
    src,
    srcSet,
    alt,
    fadeInEnabled,
    loaderDisabled,
    ...rest
  } = props;
  const [imgElement, setImgElement] = useState<null | HTMLImageElement>();
  const useVisibilityOptions: UseVisibilityArgs = useMemo(() => ({
    target: imgElement,
    options: {
      once: true
    }
  }), [imgElement]);
  const {
    isVisible
  } = useVisibility(useVisibilityOptions);
  const useImageSourceInput: UseImageSourceInput = useMemo(() => ({
    src,
    srcSet
  }), [src, srcSet]);
  const {
    pending,
    src: processedSrc,
    srcSet: processedSrcSet
  } = useImageSource(useImageSourceInput);
  const fadeInClassName = fadeInEnabled ? !pending && isVisible //
  ? imageFadeInLoadedClassName : imageFadeInPendingClassName : '';
  return !loaderDisabled && !fadeInEnabled && pending ? _jsxs(ImageSpinnerWrapStyled, {
    children: [_jsx("img", {
      //
      className: className,
      src: "/images/no-photo-bg.svg",
      alt: alt
    }), _jsx(ImageSpinnerStyled, {
      colors: ['white']
    })]
  }) : _jsx("img", {
    //
    ref: setImgElement,
    className: cx(fadeInClassName, className),
    src: processedSrc,
    srcSet: processedSrcSet,
    alt: alt,
    ...rest
  });
};