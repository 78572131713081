import { AVATAR_ID, BankCardId, CalendarConsultationId, ChatId, DocumentId, InvitationId, MemberId, PaymentMethodId, PhotoId, RealtimeSessionId, VideoId } from '@types';
import { AUTH_API_URL, CALENDAR_API_URL, CLOUDINARY_API_URL, CLOUDINARY_CLOUD_ID, REALTIME_API_URL, SUPPORT_API_URL, UPLOADS_API_URL, WEB_API_URL } from './env';
import { ANALYTICS_EVENT_TYPE, ANALYTICS_SUPPORT_EVENT_TYPE } from './logging';
const authApiUrl = AUTH_API_URL;
const webApiUrl = WEB_API_URL;
const calendarApiUrl = CALENDAR_API_URL;
const supportApiUrl = SUPPORT_API_URL;
const uploadApiUrl = UPLOADS_API_URL;
const cloudinaryApiUrl = CLOUDINARY_API_URL;
const realtimeApiUrl = REALTIME_API_URL;
export const ENDPOINTS = {
  auth: {
    token: (): string => `${authApiUrl}/connect/token`,
    externalAuthCallback: (): string => `${authApiUrl}/api/accounts/external-signin-callback`
  },
  account: {
    loginByCode: (): string => `${authApiUrl}/api/accounts/login/code`,
    exists: (): string => `${authApiUrl}/api/accounts/exists`,
    register: (): string => `${authApiUrl}/api/accounts/register`,
    extendedRegister: (): string => `${authApiUrl}/api/accounts/register/extend`,
    sendConfirmationCode: (): string => `${authApiUrl}/api/accounts/confirm/email/code`,
    externalLinkCallback: (): string => `${authApiUrl}/api/accounts/external-link-callback`,
    passwordRecovery: (): string => `${authApiUrl}/api/accounts/password/recover`,
    confirmPasswordRecovery: (userId: string): string => `${authApiUrl}/api/accounts/${userId}/password/recover/confirm`,
    changeEmail: (): string => `${authApiUrl}/api/accounts/me/email`,
    changePassword: (): string => `${authApiUrl}/api/accounts/me/password`,
    confirmEmail: (): string => `${authApiUrl}/api/accounts/confirm/email`,
    emailConfirmed: (): string => `${authApiUrl}/api/accounts/me/confirm/email`,
    restore: (): string => `${authApiUrl}/api/accounts/restore`,
    restoreByCode: (): string => `${authApiUrl}/api/accounts/restore/code`,
    delete: (): string => `${authApiUrl}/api/accounts/me`,
    notifications: (): string => `${authApiUrl}/api/accounts/me/notifications`,
    associatedSocialMedia: (): string => `${authApiUrl}/api/accounts/me/linked-social`,
    protectedUserId: (): string => `${authApiUrl}/api/accounts/me/protected-userid`,
    logoff: (refreshToken: string): string => `${authApiUrl}/api/accounts/me/logoff/${refreshToken}`,
    unsubscribeEmailNotifications: (): string => `${authApiUrl}/api/accounts/notifications/email/unsubscribe`,
    getTimezone: (): string => `${authApiUrl}/api/account-settings/me/timezone`,
    updateTimezone: (): string => `${authApiUrl}/api/account-settings/me/timezone`,
    getTimezones: (): string => `${calendarApiUrl}/api/dictionaries/timezones`
  },
  photos: {
    upload: (): string => `${uploadApiUrl}/api/photos/me`,
    setDefault: (photoId: PhotoId): string => `${uploadApiUrl}/api/photos/me/${photoId}/default`,
    delete: (photoId: PhotoId): string => `${uploadApiUrl}/api/photos/me/${photoId}`
  },
  cloudinary: {
    getSignature: (): string => `${uploadApiUrl}/api/uploads/me/video/cloudinary/signature`,
    upload: (): string => `${cloudinaryApiUrl}/${CLOUDINARY_CLOUD_ID}/auto/upload`
  },
  video: {
    delete: (videoId: VideoId): string => `${uploadApiUrl}/api/uploads/me/video/${videoId}`
  },
  documents: {
    upload: (): string => `${uploadApiUrl}/api/uploads/me`,
    delete: (documentId: DocumentId): string => `${uploadApiUrl}/api/uploads/me/${documentId}`
  },
  avatars: {
    setMe: (avatarId: AVATAR_ID): string => `${uploadApiUrl}/api/avatars/me/${avatarId}`
  },
  clients: {
    me: (): string => `${webApiUrl}/api/clients/me`,
    getById: (clientId: string): string => `${webApiUrl}/api/clients/${clientId}`,
    getBalance: (): string => `${webApiUrl}/api/clients/me/balance`,
    premiumSubscription: (): string => `${webApiUrl}/api/clients/me/premium/subscription`
  },
  specialists: {
    me: (): string => `${webApiUrl}/api/specialists/me`,
    getById: (specialistId: string): string => `${webApiUrl}/api/specialists/${specialistId}`,
    top: (): string => `${webApiUrl}/api/specialists/top`,
    getBalance: (): string => `${webApiUrl}/api/specialists/me/balance`,
    getStatistics: (): string => `${webApiUrl}/api/specialists/me/coins/statistic`,
    saveWallets: (): string => `${webApiUrl}/api/withdraw/me/wallets`,
    getWallets: (): string => `${webApiUrl}/api/withdraw/me/wallets`,
    withdrawFunds: (): string => `${webApiUrl}/api/withdraw/me/money`
  },
  subjects: {
    get: (): string => `${webApiUrl}/api/subjects`
  },
  chats: {
    getList: (): string => `${webApiUrl}/api/me/chats`,
    getById: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}`,
    setRead: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/read`,
    addToBlackList: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/black-list`,
    removeFromBlackList: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/black-list`,
    addToFavorite: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/favorite`,
    removeFromFavorite: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/favorite`
  },
  messages: {
    getList: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/messages`,
    addTextMessage: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/messages/text`,
    sendTextMessageToMember: (memberId: MemberId): string => `${webApiUrl}/api/me/users/${memberId}/messages/text`,
    sendImageMessageToMember: (memberId: MemberId): string => `${webApiUrl}/api/me/users/${memberId}/messages/image`,
    sendComplimentToMember: (memberId: MemberId): string => `${webApiUrl}/api/me/users/${memberId}/messages/gift`
  },
  invitations: {
    getList: (): string => `${webApiUrl}/api/me/invitations`,
    read: (): string => `${webApiUrl}/api/me/invitations/read`,
    accept: (invitationId: InvitationId): string => `${webApiUrl}/api/invitations/${invitationId}`,
    inviteMember: (memberId: MemberId): string => `${webApiUrl}/api/invitations/${memberId}`
  },
  views: {
    getList: (): string => `${webApiUrl}/api/profiles/me/visits`,
    setProfileViewed: (memberId: MemberId): string => `${webApiUrl}/api/profiles/me/visit/${memberId}`,
    read: (): string => `${webApiUrl}/api/me/visits/read`
  },
  counters: {
    getUnread: (): string => `${webApiUrl}/api/me/counters/unread`
  },
  favorites: {
    addMember: (memberId: MemberId): string => `${webApiUrl}/api/favorites/${memberId}`,
    removeMember: (memberId: MemberId): string => `${webApiUrl}/api/favorites/${memberId}`
  },
  complaints: {
    sendComplaint: (memberId: MemberId): string => `${webApiUrl}/api/complaints/${memberId}`
  },
  search: {
    maxAge: (): string => `${webApiUrl}/api/search/max-age`,
    clients: (): string => `${webApiUrl}/api/search/clients`,
    specialists: (): string => `${webApiUrl}/api/search/specialists`
  },
  mediaCalls: {
    createVideo: (userId: MemberId): string => `${webApiUrl}/api/me/users/${userId}/messages/video`,
    createAudio: (userId: MemberId): string => `${webApiUrl}/api/me/users/${userId}/messages/audio`,
    reject: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/messages/call/reject`,
    end: (chatId: ChatId): string => `${webApiUrl}/api/me/chats/${chatId}/messages/call/end`
  },
  payments: {
    settings: (): string => `${webApiUrl}/api/payments/settings`,
    premium: (): string => `${webApiUrl}/api/payments/me/premium`,
    getBankCards: (paymentMethodId: PaymentMethodId): string => `${webApiUrl}/api/payments/me/${paymentMethodId}/bankcards`,
    deleteBankCard: (cardId: BankCardId): string => `${webApiUrl}/api/payments/me/bankcards/${cardId}`,
    /**
     * @deprecated
     * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
     */
    confirmPrepayment: (chatId: ChatId): string => `${webApiUrl}/api/payments/me/prepayment/${chatId}`
  },
  prices: {
    getByCategory: (): string => `${webApiUrl}/api/price`,
    getPremium: (): string => `${webApiUrl}/api/price/premium`
  },
  analytics: {
    sendVisit: (): string => `${webApiUrl}/api/occur/visit`,
    sendAmplitudeSession: (): string => `${webApiUrl}/api/occur/amplitude-session`,
    sendEvent: (eventType: ANALYTICS_EVENT_TYPE): string => `${webApiUrl}/api/occur/${eventType}`,
    supportSendEvent: (eventType: ANALYTICS_SUPPORT_EVENT_TYPE): string => `${supportApiUrl}/api/occur/${eventType}`
  },
  realtime: {
    ping: (sessionId: RealtimeSessionId): string => `${realtimeApiUrl}/api/me/ping/${sessionId}`,
    offline: (sessionId: RealtimeSessionId): string => `${realtimeApiUrl}/api/me/offline/${sessionId}`,
    inactive: (sessionId: RealtimeSessionId): string => `${realtimeApiUrl}/api/me/inactive/${sessionId}`,
    runtime: (): string => `${webApiUrl}/api/me/runtime`,
    getQueueUpdates: (): string => `${realtimeApiUrl}/api/realtime/updates/queue/me`
  },
  userRating: {
    rate: (memberId: MemberId): string => `${webApiUrl}/api/user-rating/me/rate/${memberId}`,
    cancelRate: (memberId: MemberId): string => `${webApiUrl}/api/user-rating/me/rate/${memberId}/cancel`
  },
  calendar: {
    getDefaults: (): string => `${calendarApiUrl}/api/calendars/defaults`,
    getMemberCalendar: (memberId: MemberId): string => `${calendarApiUrl}/api/calendars/${memberId}`,
    getMemberCalendarInfo: (memberId: MemberId): string => `${calendarApiUrl}/api/calendars/${memberId}/info`,
    getMemberWorkingTimes: (memberId: MemberId): string => `${calendarApiUrl}/api/calendars/${memberId}/working-times`,
    getMemberConsultations: (memberId: MemberId): string => `${calendarApiUrl}/api/calendars/${memberId}/consultations`,
    createMemberConsultaion: (memberId: MemberId): string => `${calendarApiUrl}/api/calendars/${memberId}/consultations`,
    getCurrentMemberCalendar: (): string => `${calendarApiUrl}/api/calendars/me`,
    getCurrentMemberCalendarInfo: (): string => `${calendarApiUrl}/api/calendars/me/info`,
    getCurrentMemberWorkingTimes: (): string => `${calendarApiUrl}/api/calendars/me/working-times`,
    updateCurrentMemberWorkingTimes: (): string => `${calendarApiUrl}/api/calendars/me/working-times`,
    getCurrentMemberConsultations: (): string => `${calendarApiUrl}/api/calendars/me/consultations`,
    getCurrentMemberConsultation: (consultationId: CalendarConsultationId): string => `${calendarApiUrl}/api/calendars/me/consultations/${consultationId}`,
    approveCurrentMemberConsultation: (consultationId: CalendarConsultationId): string => `${calendarApiUrl}/api/calendars/consultations/${consultationId}/approve`,
    rejectCurrentMemberConsultation: (consultationId: CalendarConsultationId): string => `${calendarApiUrl}/api/calendars/consultations/${consultationId}/reject`
  },
  support: {
    getMessageList: (): string => `${supportApiUrl}/api/our-team/messages`,
    setMessagesRead: (): string => `${supportApiUrl}/api/me/our-team/read`,
    getUnread: (): string => `${supportApiUrl}/api/me/our-team/unread`
  }
};