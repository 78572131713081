import { GEOMETRY_TYPE } from '@config/geometry';
import { appCreateSlice } from '@store/rtk';
import { geometryResizeReducer } from '@slices/geometry/geometry-resize';
import { geometrySetTypeReducer } from '@slices/geometry/geometry-set-type';
import { GeometryState } from './types';
export const geometryDefaultState: GeometryState = {
  type: GEOMETRY_TYPE['MOBILE-V']
};
const geometrySlice = appCreateSlice({
  name: 'geometry',
  initialState: geometryDefaultState,
  reducers: {
    geometryResize: geometryResizeReducer,
    //
    geometrySetType: geometrySetTypeReducer
  }
});
export const {
  actions: {
    geometryResize,
    geometrySetType
  },
  reducer: geometryReducer
} = geometrySlice;