import { ONLINE_STATUS } from '@types';
import { appCreateSelector, RootState } from '@store';
import { OnlineState } from '@slices/online';

/**
 * regular selectors
 */

export const onlineGet = (state: RootState): OnlineState => {
  return state.app.online;
};

/**
 * memoized reselect selectors
 */

export const onlineGetStatus: (state: RootState) => ONLINE_STATUS = appCreateSelector([onlineGet],
//
online => online.status);