import { AUTH_CLIENT_ID, AUTH_GRANT_TYPE, GOOGLE_ACCESS_TYPE, GOOGLE_AUTH_PROMPT, GOOGLE_AUTH_RESPONSE_TYPE, GOOGLE_AUTH_SCOPE } from '@config/auth';
import { FRONTEND_APPLICATION_HOST } from '@config/env';
import { AccountAssociatingGoogleRequestData, AccountAssociatingGoogleRequestDto, AccountAuthGoogleRequestData, AccountAuthGoogleRequestDto } from '@services/dto/account/account-auth-google';
import { PROVIDER } from '@types';
export const accountAuthGoogleRequestToDto = (data: AccountAuthGoogleRequestData): AccountAuthGoogleRequestDto => {
  return {
    appUrl: data.appUrl,
    appId: data.appId,
    accessType: GOOGLE_ACCESS_TYPE.ONLINE,
    redirectUri: data.redirectUri,
    responseType: GOOGLE_AUTH_RESPONSE_TYPE.CODE,
    scope: [GOOGLE_AUTH_SCOPE.EMAIL, GOOGLE_AUTH_SCOPE.PROFILE],
    includeGrantedScopes: true,
    prompt: GOOGLE_AUTH_PROMPT.SELECT_ACCOUNT,
    state: {
      roleType: data.roleType,
      timezoneId: data.timezoneId,
      timezoneOffset: data.timezoneOffset,
      culture: data.culture,
      allowRestoreAccount: data.allowRestoreAccount,
      appId: data.appId,
      visitId: data.visitId,
      url: data.url,
      clientType: data.clientType,
      userAgent: data.userAgent,
      clientId: AUTH_CLIENT_ID,
      grantType: AUTH_GRANT_TYPE.PASSWORD,
      frontEndApplicationHost: FRONTEND_APPLICATION_HOST,
      provider: PROVIDER.GOOGLE
    }
  };
};
export const accountAssociatingGoogleRequestToDto = (data: AccountAssociatingGoogleRequestData): AccountAssociatingGoogleRequestDto => {
  return {
    appUrl: data.appUrl,
    appId: data.appId,
    accessType: GOOGLE_ACCESS_TYPE.ONLINE,
    redirectUri: data.redirectUri,
    responseType: GOOGLE_AUTH_RESPONSE_TYPE.CODE,
    scope: [GOOGLE_AUTH_SCOPE.EMAIL, GOOGLE_AUTH_SCOPE.PROFILE],
    includeGrantedScopes: true,
    prompt: GOOGLE_AUTH_PROMPT.SELECT_ACCOUNT,
    state: {
      roleType: data.roleType,
      timezoneId: data.timezoneId,
      timezoneOffset: data.timezoneOffset,
      culture: data.culture,
      allowRestoreAccount: data.allowRestoreAccount,
      visitId: data.visitId,
      url: data.url,
      clientType: data.clientType,
      userAgent: data.userAgent,
      appId: data.appId,
      clientId: AUTH_CLIENT_ID,
      grantType: AUTH_GRANT_TYPE.PASSWORD,
      frontEndApplicationHost: FRONTEND_APPLICATION_HOST,
      provider: PROVIDER.GOOGLE,
      protectedUserId: data.protectedUserId
    }
  };
};