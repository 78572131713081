import { GEOMETRY_TYPE } from '@config/geometry';
import { ANALYTICS_ACTION } from '@config/logging';
import { UAParser } from '@helpers/ua-parser';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsUIClickedEvent } from '@logging/logging-events';
import { ILocalStorage } from '@storage';
import { readVisitId } from '@storage/analytics';
import { AnalyticsUIClickBaseRequestData, AnalyticsUIClickEventRequestData } from '@services/dto/analytics/analytics-send-event/analytics-ui-click-event';
import { defineAnalyticsClientTypeByGeometryType } from './common';
export const isSendUIClickEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsUIClickedEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.UI_CLICK;
};
export const sendUIClick = <D extends AnalyticsUIClickEventRequestData['data'],>(eventBus: IEventBus, data: D): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsUIClickEventRequestData['data']>({
    action: ANALYTICS_ACTION.UI_CLICK,
    data
  }));
};
export const computeAnalyticsUIClickData = async (args: {
  storage: ILocalStorage;
  geometryType: GEOMETRY_TYPE;
}): Promise<Omit<AnalyticsUIClickBaseRequestData, 'uiId' | 'pageId'>> => {
  const {
    storage,
    geometryType
  } = args;
  return {
    visitId: readVisitId(storage),
    clientType: defineAnalyticsClientTypeByGeometryType(geometryType),
    os: await new UAParser(window.navigator).getOS(),
    userAgent: window.navigator.userAgent
  };
};