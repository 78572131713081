import { keyBy, uniq } from '@helpers/data';
import { InvitationsReceiveGetListReducer } from './types';
export const invitationsReceiveGetListReducer: InvitationsReceiveGetListReducer = (state, action) => {
  const {
    limit,
    offset,
    items,
    totalCount,
    keepResults
  } = action.payload;
  const {
    invitationsIds,
    invitationsById
  } = state;
  const cleanedInvitationsById = {
    ...(keepResults ? invitationsById : undefined),
    ...keyBy(items, item => item.id)
  };
  const cleanedInvitationIds = uniq([...(keepResults ? invitationsIds : []), ...items.map(invitation => invitation.id)]);
  return {
    ...state,
    invitationsById: cleanedInvitationsById,
    invitationsIds: cleanedInvitationIds,
    hasMore: offset + limit < totalCount,
    limit,
    offset,
    totalCount,
    dataLoaded: true
  };
};