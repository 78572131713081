/**
 * Local storage
 *
 * Accessing localStorage may throw an error depending on browser / device /
 * browsing mode. For instance, writing to LS throws in Safari (iOS / OS X) in
 * private mode. We ignore all storage errors.
 */

import { ILocalStorage, ILocalStorageConstructor, ILocalStorageCustomStorage, Key } from './types';

/**
 * @todo
 * Move LocalStorage manager-class and initialization
 * from data to src or src/managers.
 */
export const LocalStorage: ILocalStorageConstructor = class LocalStorage implements ILocalStorage {
  private readonly _storage: ILocalStorageCustomStorage;
  constructor() {
    try {
      this._storage = localStorage;
    } catch (err) {
      this._storage = {};
      console.warn('Failed to initialize localStorage. LocalStorage initialized as {}');
    }
  }
  read<T>(key: Key): undefined | T {
    if (typeof this._storage[key] !== 'string') {
      return undefined;
    }
    try {
      return JSON.parse(this._storage[key]);
    } catch (err) {
      console.warn('Failed to read from storage:', err);
      return undefined;
    }
  }
  write<T>(key: Key, value: T): void {
    try {
      this._storage[key] = JSON.stringify(value);
    } catch (err) {
      console.warn('Failed to save to storage:', err);
    }
  }
  remove(key: Key): void {
    delete this._storage[key];
  }
};