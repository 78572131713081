import { rejectBy } from '@helpers/data';
import { isWithinInterval } from '@helpers/date';
import { CalendarCurrentMemberConsultations, CalendarMemberConsultations } from '@types';
export const calendarRejectConsultationsByPeriod = <C extends CalendarMemberConsultations | CalendarCurrentMemberConsultations,>(consultations: C, dateFrom?: string, dateTo?: string): C => {
  if (!dateFrom || !dateTo) {
    return consultations;
  }
  const _dateFrom = new Date(dateFrom);
  const _dateTo = new Date(dateTo);
  return (rejectBy(consultations, consultation => {
    return isWithinInterval(new Date(consultation.timing.beginTime), {
      start: _dateFrom,
      end: _dateTo
    });
  }) as C);
};