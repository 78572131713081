import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { merge } from '@helpers/data';
import { isProduction } from '@helpers/misc';
import { realtimeMiddleware } from '@middlewares/realtime';
import { initializeStore, RootAction } from '@store';
import { appGetDefaultState, appReducer } from '@reducers/app';
import { uiDefaultState, uiReducer } from '@reducers/ui';
import { ReduxAction, ReduxPayloadAction, RootState, RootStore } from './types';
export { createSelector as appCreateSelector } from '@reduxjs/toolkit';

/**
 * Actions
 */

export const createReduxAction = <T,>(type: T): ReduxAction<T> => {
  return {
    type
  };
};
export const createReduxPayloadAction = <T, P>(type: T, payload: P): ReduxPayloadAction<T, P> => {
  return {
    type,
    payload
  };
};

/**
 * Reducers
 */

export const rootDefaultState: RootState = {
  ui: uiDefaultState,
  app: appGetDefaultState()
};
export const rootReducer = (state = rootDefaultState, action: RootAction): RootState => {
  if (action.type === HYDRATE) {
    const {
      payload
    } = action;
    let nextState = state;
    if (state.ui.geometry.type === rootDefaultState.ui.geometry.type && state.ui.geometry.type !== payload.ui.geometry.type) {
      nextState = merge({},
      //
      nextState, ({
        ui: {
          geometry: {
            type: payload.ui.geometry.type
          }
        }
      } as RootState));
    }
    return nextState;
  }
  return combineReducers({
    ui: uiReducer,
    app: appReducer
  })(state, action);
};

/**
 * Store
 */

export const createRootStore = (preloadedState?: RootState): RootStore => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        thunk: true,
        immutableCheck: true,
        serializableCheck: false
      }).concat(realtimeMiddleware);
    },
    enhancers: [createReduxEnhancer()],
    devTools: !isProduction()
  });
};
export const wrapper = createWrapper<RootStore>(() => initializeStore(),
//
{
  serializeState: state => JSON.stringify(state),
  deserializeState: state => {
    /**
     * @todo
     * Reconsider the solution below in case of problems with parsing of JSON
     */
    return typeof state === 'string' //
    ? JSON.parse(state) : state;
  },
  debug: false
});