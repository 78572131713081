import { AppFCC } from '@types';
import { Image } from '@kit/image';
import { AspectRatioBoxStyled } from './styled';
import { AspectRatioImgProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AspectRatioImg: AppFCC<AspectRatioImgProps> = props => {
  const {
    ratio,
    className,
    imageClassName,
    ...restProps
  } = props;
  return _jsx(AspectRatioBoxStyled //
  , {
    ratio: ratio,
    className: className,
    children: _jsx(Image //
    , {
      width: "100%",
      className: imageClassName,
      ...restProps
    })
  });
};