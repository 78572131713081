export enum AVATAR_ID {
  AVATAR_MALE_STUB = 'avatar-male-stub',
  AVATAR_MALE_DEFAULT = 'avatar-male-default',
  AVATAR_MALE_1 = 'avatar-male-1',
  AVATAR_MALE_2 = 'avatar-male-2',
  AVATAR_MALE_3 = 'avatar-male-3',
  AVATAR_MALE_4 = 'avatar-male-4',
  AVATAR_MALE_5 = 'avatar-male-5',
  AVATAR_FEMALE_STUB = 'avatar-female-stub',
  AVATAR_FEMALE_DEFAULT = 'avatar-female-default',
  AVATAR_FEMALE_1 = 'avatar-female-1',
  AVATAR_FEMALE_2 = 'avatar-female-2',
  AVATAR_FEMALE_3 = 'avatar-female-3',
  AVATAR_FEMALE_4 = 'avatar-female-4',
  AVATAR_FEMALE_5 = 'avatar-female-5',
}