import { useEffect } from 'react';
import { configureScope, setUser } from '@sentry/nextjs';
import { AppFC } from '@types';
import { accountGetAccountId, accountGetIsAuthorized } from '@selectors/account';
import { useTypedSelector } from '@hooks/store';
export const SentryManager: AppFC = () => {
  const isAuhorized = useTypedSelector(accountGetIsAuthorized);
  const memberId = useTypedSelector(accountGetAccountId);
  useEffect(() => {
    if (!isAuhorized || !memberId) {
      return;
    }
    setUser({
      uid: memberId
    });
    return () => {
      configureScope(scope => scope.setUser(null));
    };
  }, [isAuhorized, memberId]);
  return null;
};