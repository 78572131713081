import { ERROR_CODE } from '@config/errors';
import { POPUP } from '@config/popups';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { getMessageTextByCode } from '@helpers/errors';
import { isRejectedCall } from '@helpers/media-call';
import { RealtimeChatMediaCallClientNotEnoughMoneyCallEndEventPayloadData } from '@services/dto/realtime/realtime-chat-event/realtime-chat-media-call-client-not-enough-money-call-end';
import { addPopup } from '@actions/popups/add-popup';
import { mediaCallClear } from '@slices/media-call';
import { mediaCallsGetCallByChatId } from '@selectors/media-call';
import { AlertDialogProps } from '@popups/modals/alert-dialog';
import { RealtimeChatMediaCallClientNotEnoughMoneyCallEnd } from './types';
export const realtimeChatMediaCallClientNotEnoughMoneyCallEnd: RealtimeChatMediaCallClientNotEnoughMoneyCallEnd = event => {
  return (dispatch, getState) => {
    const data = readJson<RealtimeChatMediaCallClientNotEnoughMoneyCallEndEventPayloadData>(event.payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      chatId
    } = data;
    const currentMediaCall = mediaCallsGetCallByChatId(getState(), chatId);
    if (!isRejectedCall(currentMediaCall)) {
      dispatch(mediaCallClear({
        chatId
      }));
    }
    dispatch(addPopup<AlertDialogProps>({
      id: POPUP.ALERT_DIALOG,
      props: {
        text: getMessageTextByCode(ERROR_CODE.CLIENT_NOT_ENOUGH_COINS_CALL_END)
      }
    }));
  };
};