import { loadEnvConfig } from '@next/env';

export const isProduction = (): boolean => {
  /**
   * NODE_ENV needs to be read as
   * process.env.NODE_ENV
   * due to both server and client usage
   */
  return process.env.NODE_ENV === 'production';
};

export const isDevelopment = (): boolean => {
  /**
   * NODE_ENV needs to be read as
   * process.env.NODE_ENV
   * due to both server and client usage
   */
  return process.env.NODE_ENV === 'development';
};

export const isTest = (): boolean => {
  /**
   * NODE_ENV needs to be read as
   * process.env.NODE_ENV
   * due to both server and client usage
   */
  return process.env.NODE_ENV === 'test';
};

export const loadEnv = (): void => {
  const _isProduction = isProduction();
  loadEnvConfig(process.cwd(), !_isProduction);
  console.info('Is production: ', _isProduction);
};
