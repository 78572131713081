import { cx } from '@emotion/css';
import { AppFCC } from '@types';
import { AvatarRoundedProps } from '@kit/avatar/avatar-rounded/types';
import { avatarRoundedImageImageClassName, AvatarRoundedImageStyled, AvatarRoundedLeftTopIconContainerStyled, AvatarRoundedRightTopIconContainerStyled, AvatarRoundedStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AvatarRounded: AppFCC<AvatarRoundedProps> = props => {
  const {
    src,
    alt,
    ratio,
    leftTopIcon,
    rightTopIcon,
    className,
    imageClassName,
    ...restProps
  } = props;
  return _jsxs(AvatarRoundedStyled, {
    className: className,
    children: [_jsx(AvatarRoundedImageStyled //
    , {
      src: src,
      alt: alt,
      ratio: ratio,
      loaderDisabled: true,
      imageClassName: cx(avatarRoundedImageImageClassName, imageClassName),
      ...restProps
    }), !leftTopIcon ? null : _jsx(AvatarRoundedLeftTopIconContainerStyled, {
      children: leftTopIcon
    }), !rightTopIcon ? null : _jsx(AvatarRoundedRightTopIconContainerStyled, {
      children: rightTopIcon
    })]
  });
};