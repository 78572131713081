import { isClient, isSpecialist } from '@helpers/member';
import { clientsGetById } from '@services/clients';
import { specialistsGetById } from '@services/specialists';
import { clientsReceiveGetById } from '@slices/clients';
import { specialistsReceiveGetById } from '@slices/specialists';
import { MembersFetchGetMember } from './types';
export const membersFetchGetMember: MembersFetchGetMember = payload => {
  return async dispatch => {
    const {
      memberId,
      memberRoleType
    } = payload;
    if (isClient(memberRoleType)) {
      const data = await clientsGetById({
        clientId: memberId
      }).promise;
      dispatch(clientsReceiveGetById({
        client: data,
        clientId: memberId
      }));
      return data;
    }
    if (isSpecialist(memberRoleType)) {
      const data = await specialistsGetById({
        specialistId: memberId
      }).promise;
      dispatch(specialistsReceiveGetById({
        specialist: data,
        specialistId: memberId
      }));
      return data;
    }
  };
};