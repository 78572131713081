import { BaseMessage, CALENDAR_MESSAGE_CONSULTATION_INVITATION_STATE, CALENDAR_MESSAGE_CONSULTATION_STATE, CalendarMessage, CalendarMessageCalendarConsultation, ComplimentMessage, DynamicMessage, ImageMessage, MEDIA_CALL_MESSAGE_STATE, MediaCallMessage, MediaCallMessageCall, Message, MESSAGE_DYNAMIC, MESSAGE_TYPE, SystemMessage, TextMessage } from '@types';
export const messagesIsStaticMessage = (value: undefined | Message): boolean => {
  return value?.dynamic === MESSAGE_DYNAMIC.STATIC;
};
export const messagesIsDynamicMessage = (value: undefined | BaseMessage): value is DynamicMessage => {
  return value?.dynamic === MESSAGE_DYNAMIC.DYNAMIC;
};
export const messagesIsTextMessage = (value: undefined | Message): value is TextMessage => {
  return value?.type === MESSAGE_TYPE.TEXT;
};
export const messagesIsImageMessage = (value: undefined | Message): value is ImageMessage => {
  return value?.type === MESSAGE_TYPE.ATTACHMENT;
};
export const messagesIsComplimentMessage = (value: undefined | Message): value is ComplimentMessage => {
  return value?.type === MESSAGE_TYPE.COMPLIMENT;
};
export const messagesIsCallMessage = (value: undefined | Message): value is MediaCallMessage => {
  return value?.type === MESSAGE_TYPE.MEDIA_CALL;
};
export const messagesIsSystemMessage = (value: undefined | Message): value is SystemMessage => {
  return value?.type === MESSAGE_TYPE.SYSTEM;
};
export const messagesIsCalendarMessage = (value: undefined | Message): value is CalendarMessage => {
  return value?.type === MESSAGE_TYPE.CALENDAR;
};
export const messagesCallIsMissed = (call: undefined | MediaCallMessageCall): boolean => {
  return call?.state === MEDIA_CALL_MESSAGE_STATE.MISSED_INCOMING;
};
export const messagesCallIsIncomingRejected = (call: undefined | MediaCallMessageCall): boolean => {
  return call?.state === MEDIA_CALL_MESSAGE_STATE.REJECTED_INCOMING;
};
export const messagesCallIsOutgoingUnanswered = (call: undefined | MediaCallMessageCall): boolean => {
  return call?.state === MEDIA_CALL_MESSAGE_STATE.UNANSWERED_OUTGOING;
};
export const messagesCallIsOutgoingBusy = (call: undefined | MediaCallMessageCall): boolean => {
  return call?.state === MEDIA_CALL_MESSAGE_STATE.BUSY_OUTGOING;
};
export const messagesConsultationIsCreated = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return consultation?.state === CALENDAR_MESSAGE_CONSULTATION_STATE.CREATED;
};
export const messagesConsultationIsApproved = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return consultation?.state === CALENDAR_MESSAGE_CONSULTATION_STATE.APPROVED;
};
export const messagesConsultationIsCanceled = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return consultation?.state === CALENDAR_MESSAGE_CONSULTATION_STATE.CANCELED;
};
export const messagesConsultationIsRejectedByMember = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return consultation?.state === CALENDAR_MESSAGE_CONSULTATION_STATE.REJECTED_BY_MEMBER;
};
export const messagesConsultationIsRejectedBySystem = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return consultation?.state === CALENDAR_MESSAGE_CONSULTATION_STATE.REJECTED_BY_SYSTEM;
};
export const messagesConsultationIsRefused = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return messagesConsultationIsRejectedByMember(consultation) || messagesConsultationIsRejectedBySystem(consultation) || messagesConsultationIsCanceled(consultation);
};
export const messagesConsultationIsDone = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return consultation?.state === CALENDAR_MESSAGE_CONSULTATION_STATE.DONE;
};
export const messagesConsultationInvitationIsPending = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return consultation?.invitationState === CALENDAR_MESSAGE_CONSULTATION_INVITATION_STATE.PENDING;
};
export const messagesConsultationInvitationIsApproved = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return consultation?.invitationState === CALENDAR_MESSAGE_CONSULTATION_INVITATION_STATE.APPROVED;
};
export const messagesConsultationIsExpired = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return messagesConsultationIsDone(consultation) && messagesConsultationInvitationIsPending(consultation);
};
export const messagesConsultationIsComplete = (consultation: undefined | CalendarMessageCalendarConsultation): boolean => {
  return messagesConsultationIsDone(consultation) && messagesConsultationInvitationIsApproved(consultation);
};