import { combineReducers } from 'redux';
import { APP_RESET_STORE } from '@actions/action-types';
import { AppAction } from '@actions/app';
import { accountReducer, AccountState } from '@slices/account';
import { accountGetDefaultState } from '@slices/account';
import { calendarDefaultState, calendarReducer, CalendarState } from '@slices/calendar';
import { chatsDefaultState, chatsReducer, ChatsState } from '@slices/chats';
import { clientsDefaultState, clientsReducer, ClientsState } from '@slices/clients';
import { currentClientDefaultState, currentClientReducer, CurrentClientState } from '@slices/current-client';
import { currentSpecialistDefaultState, currentSpecialistReducer, CurrentSpecialistState } from '@slices/current-specialist';
import { invitationsDefaultState, invitationsReducer, InvitationsState } from '@slices/invitations';
import { mediaCallDefaultState, mediaCallReducer, MediaCallState } from '@slices/media-call';
import { messagesDefaultState, messagesReducer, MessagesState } from '@slices/messages';
import { notificationsDefaultState, notificationsReducer, NotificationsState } from '@slices/notifications';
import { onlineDefaultState, onlineReducer, OnlineState } from '@slices/online';
import { paymentsDefaultState, paymentsReducer, PaymentsState } from '@slices/payments';
import { pricesDefaultState, pricesReducer, PricesState } from '@slices/prices';
import { realtimeDefaultState, realtimeReducer, RealtimeState } from '@slices/realtime';
import { searchDefaultState, searchReducer, SearchState } from '@slices/search';
import { specialistsDefaultState, specialistsReducer, SpecialistsState } from '@slices/specialists';
import { subjectsDefaultState, subjectsReducer, SubjectsState } from '@slices/subjects';
import { supportDefaultState, supportReducer, SupportState } from '@slices/support';
import { topSpecialistsDefaultState, topSpecialistsReducer, TopSpecialistsState } from '@slices/top-specialists';
import { viewsDefaultState, viewsReducer, ViewsState } from '@slices/views';
export type AppState = {
  account: AccountState;
  currentClient: CurrentClientState;
  notifications: NotificationsState;
  clients: ClientsState;
  currentSpecialist: CurrentSpecialistState;
  specialists: SpecialistsState;
  subjects: SubjectsState;
  chats: ChatsState;
  messages: MessagesState;
  invitations: InvitationsState;
  topSpecialists: TopSpecialistsState;
  views: ViewsState;
  search: SearchState;
  mediaCalls: MediaCallState;
  prices: PricesState;
  payments: PaymentsState;
  support: SupportState;
  online: OnlineState;
  realtime: RealtimeState;
  calendar: CalendarState;
};
export const appGetDefaultState = (): AppState => {
  return {
    account: accountGetDefaultState(),
    currentClient: currentClientDefaultState,
    notifications: notificationsDefaultState,
    clients: clientsDefaultState,
    currentSpecialist: currentSpecialistDefaultState,
    specialists: specialistsDefaultState,
    subjects: subjectsDefaultState,
    chats: chatsDefaultState,
    messages: messagesDefaultState,
    invitations: invitationsDefaultState,
    topSpecialists: topSpecialistsDefaultState,
    views: viewsDefaultState,
    search: searchDefaultState,
    mediaCalls: mediaCallDefaultState,
    prices: pricesDefaultState,
    payments: paymentsDefaultState,
    support: supportDefaultState,
    online: onlineDefaultState,
    realtime: realtimeDefaultState,
    calendar: calendarDefaultState
  };
};
export const appReducer = (state = appGetDefaultState(), action: AppAction): AppState => {
  /**
   * @todo Think how to reset store on AUTH_SET_UNAUTHORIZED action.
   * The condition below breaks data that is used for unauthorized users.
   * E.g. it resets member lists after loading:
   * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/277
   * (first point)
   */
  if (action.type === APP_RESET_STORE) {
    return appGetDefaultState();
  }
  return combineReducers({
    account: accountReducer,
    currentClient: currentClientReducer,
    clients: clientsReducer,
    currentSpecialist: currentSpecialistReducer,
    specialists: specialistsReducer,
    notifications: notificationsReducer,
    subjects: subjectsReducer,
    chats: chatsReducer,
    messages: messagesReducer,
    invitations: invitationsReducer,
    topSpecialists: topSpecialistsReducer,
    views: viewsReducer,
    search: searchReducer,
    mediaCalls: mediaCallReducer,
    prices: pricesReducer,
    payments: paymentsReducer,
    support: supportReducer,
    online: onlineReducer,
    realtime: realtimeReducer,
    calendar: calendarReducer
  })(state, action);
};