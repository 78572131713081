import { uniq } from '@helpers/data';
import { Chat } from '@types';
import { ChatsSetLastMessageReducer } from './types';
export const chatsSetLastMessageReducer: ChatsSetLastMessageReducer = (state, action) => {
  const {
    chatId,
    lastMessage
  } = action.payload;
  const chat = (state.chatsById[chatId] as undefined | Chat);
  if (!chat) {
    return state;
  }
  return {
    ...state,
    chatsIds: uniq([lastMessage.chatId, ...state.chatsIds]),
    chatsById: {
      ...state.chatsById,
      [chatId]: {
        ...chat,
        lastMessage
      }
    }
  };
};