import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { ClientsGetByIdRequestData, ClientsGetByIdResponseData, ClientsGetByIdResponseDto } from '@services/dto/clients';
import { clientsGetByIdResponseFromDto } from '@mapping/clients/clients-get-by-id';
type ClientsGetById = (requestData: ClientsGetByIdRequestData) => AuthedJsonFetchResult<ClientsGetByIdResponseData>;
export const clientsGetById: ClientsGetById = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<ClientsGetByIdResponseDto>(ENDPOINTS.clients.getById(requestData.clientId), {
    method: 'GET'
  });
  return {
    promise: promise.then(clientsGetByIdResponseFromDto),
    cancel
  };
};