import { appCreateSlice } from '@store/rtk';
import { specialistsReceiveAddToFavoritesReducer } from '@slices/specialists/specialists-receive-add-to-favorites';
import { specialistsReceiveGetByIdReducer } from '@slices/specialists/specialists-receive-get-by-id';
import { specialistsReceiveInviteMemberReducer } from '@slices/specialists/specialists-receive-invite-member';
import { specialistsReceiveOnlineStatusByIdReducer } from '@slices/specialists/specialists-receive-online-status-by-id';
import { specialistsReceiveRemoveFromFavoritesReducer } from '@slices/specialists/specialists-receive-remove-from-favorites';
import { specialistsReceiveSendComplaintReducer } from '@slices/specialists/specialists-receive-send-complaint';
import { specialistsSetChatIdWithCurrentUserReducer } from '@slices/specialists/specialists-set-chat-id-with-current-user';
import { specialistsSetInvitationAllowedReducer } from '@slices/specialists/specialists-set-invitation-allowed';
import { SpecialistsState } from './types';
export const specialistsDefaultState: SpecialistsState = {
  specialistsById: {}
};
export const specialistsSlice = appCreateSlice({
  name: 'specialists',
  initialState: specialistsDefaultState,
  reducers: {
    specialistsReceiveAddToFavorites: specialistsReceiveAddToFavoritesReducer,
    specialistsReceiveGetById: specialistsReceiveGetByIdReducer,
    specialistsReceiveInviteMember: specialistsReceiveInviteMemberReducer,
    specialistsReceiveOnlineStatusById: specialistsReceiveOnlineStatusByIdReducer,
    specialistsReceiveRemoveFromFavorites: specialistsReceiveRemoveFromFavoritesReducer,
    specialistsReceiveSendComplaint: specialistsReceiveSendComplaintReducer,
    specialistsSetChatIdWithCurrentUser: specialistsSetChatIdWithCurrentUserReducer,
    specialistsSetInvitationAllowed: specialistsSetInvitationAllowedReducer
  }
});
export const {
  actions: {
    specialistsReceiveAddToFavorites,
    specialistsReceiveGetById,
    specialistsReceiveInviteMember,
    specialistsReceiveOnlineStatusById,
    specialistsReceiveRemoveFromFavorites,
    specialistsReceiveSendComplaint,
    specialistsSetChatIdWithCurrentUser,
    specialistsSetInvitationAllowed
  },
  reducer: specialistsReducer
} = specialistsSlice;