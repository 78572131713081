export const DATE_FORMAT = 'yyyy-MM-dd';
export const ISO_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const LLLL_YYYY_DATE_FORMAT = 'LLLL yyyy';
export const DD_MM_YYYY_DATE_FORMAT = 'dd.MM.yyyy';
export const DD_MMM_DATE_FORMAT = 'dd MMM';
export const DD_MMMM_DATE_FORMAT = 'dd MMMM';
export const DD_MMMM_YYYY_DATE_FORMAT = 'dd MMMM yyyy';
export const HH_MM_TIME_FORMAT = 'HH:mm';
export const D_FORMAT = 'd';
export const D_MM = 'd.MM';
export const EEEEEE = 'EEEEEE';
export const EEEE_dd_MM_FORMAT = 'EEEE, dd.MM';
export const EEEEEE_dd_MM_yy_FORMAT = 'EEEEEE, dd.MM.yy';
export const EEE_DATE_FORMAT = 'EEE';
export const d_MM_DATE_FORMAT = 'd.MM';
export const EEEEEE_DATE_FORMAT = 'EEEEEE';