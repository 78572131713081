import { authDecodeToken } from '@helpers/auth';
import { AccountSetAuthorizedReducer } from './types';
export const accountSetAuthorizedReducer: AccountSetAuthorizedReducer = (state, action) => {
  const {
    authData
  } = action.payload;
  const decodedToken = authDecodeToken(authData.accessToken);
  if (!decodedToken) {
    return state;
  }
  return {
    ...state,
    isAuthorized: true,
    id: decodedToken.user_id,
    prevId: state.id,
    roleType: decodedToken.role_type ? Number(decodedToken.role_type) : undefined,
    email: decodedToken.email,
    timezoneId: decodedToken.timezone_id
  };
};