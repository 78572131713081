import { calendarIsCurrentClient, calendarIsCurrentClientCalendar, calendarIsCurrentSpecialist, calendarIsCurrentSpecialistCalendar } from '@helpers/calendar';
import { rejectById } from '@helpers/data';
import { calendarSetConsultationsState } from '@mapping/calendar/calendar-set-consultations-state';
import { CALENDAR_CONSULTATION_STATE } from '@types';
import { RealtimeReceiveCalendarConsultationDoneReducer } from './types';
export const realtimeReceiveCalendarConsultationDoneReducer: RealtimeReceiveCalendarConsultationDoneReducer = (state, action) => {
  const {
    consultationId,
    memberId
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentSpecialist(currentMember) && calendarIsCurrentSpecialistCalendar(currentMember.calendar)) {
    const currentMemberCalendar = currentMember.calendar;
    const consultations = calendarSetConsultationsState(currentMemberCalendar.consultations, consultationId, CALENDAR_CONSULTATION_STATE.DONE);
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...currentMemberCalendar,
          consultations
        }
      }
    };
  }
  if (calendarIsCurrentClient(currentMember) && calendarIsCurrentClientCalendar(currentMember.calendar)) {
    const currentMemberCalendar = currentMember.calendar;
    const consultations = calendarSetConsultationsState(currentMemberCalendar.consultations, consultationId, CALENDAR_CONSULTATION_STATE.DONE);
    let nextState = state;
    nextState = {
      ...nextState,
      currentMember: {
        ...currentMember,
        calendar: {
          ...currentMemberCalendar,
          consultations
        }
      }
    };
    const member = nextState.membersById?.[memberId];
    const memberCalendar = member?.calendar;
    if (memberCalendar) {
      nextState = {
        ...nextState,
        membersById: {
          ...state.membersById,
          [memberId]: {
            ...member,
            calendar: {
              ...memberCalendar,
              consultations: rejectById(memberCalendar.consultations, consultationId)
            }
          }
        }
      };
    }
    return nextState;
  }
  return state;
};