import { mapValues } from '@helpers/data';
import { invitationIsNew } from '@helpers/invitations';
import { INVITATION_STATE } from '@types';
import { InvitationsReadAllReducer } from './types';
export const invitationsReadAllReducer: InvitationsReadAllReducer = state => {
  const {
    invitationsById
  } = state;
  return {
    ...state,
    invitationsById: mapValues(invitationsById, invitation => {
      return {
        ...invitation,
        state: invitationIsNew(invitation) ? INVITATION_STATE.READ : invitation.state
      };
    })
  };
};