import { appCreateSlice } from '@store/rtk';
import { supportAddMessageReducer } from '@slices/support/support-add-message';
import { supportReceiveGetLastMessageReducer } from '@slices/support/support-receive-get-last-message';
import { supportReceiveGetMessageListReducer } from '@slices/support/support-receive-get-message-list';
import { supportReceiveSetMessagesReadReducer } from '@slices/support/support-receive-set-messages-read';
import { supportSetUnreadMessagesCounterReducer } from '@slices/support/support-set-unread-messages-counter';
import { SupportState } from './types';
export const supportDefaultState: SupportState = {
  messagesById: undefined,
  messageIds: undefined,
  limit: 0,
  offset: 0,
  total: 0,
  hasMore: false,
  dataLoaded: false,
  unreadMessagesCount: undefined
};
const supportSlice = appCreateSlice({
  name: 'support',
  initialState: supportDefaultState,
  reducers: {
    supportAddMessage: supportAddMessageReducer,
    supportReceiveGetLastMessage: supportReceiveGetLastMessageReducer,
    supportReceiveGetMessageList: supportReceiveGetMessageListReducer,
    supportReceiveSetMessagesRead: supportReceiveSetMessagesReadReducer,
    supportSetUnreadMessagesCounter: supportSetUnreadMessagesCounterReducer
  }
});
export const {
  actions: {
    supportAddMessage,
    supportReceiveGetLastMessage,
    supportReceiveGetMessageList,
    supportReceiveSetMessagesRead,
    supportSetUnreadMessagesCounter
  },
  reducer: supportReducer
} = supportSlice;