import { camelKeysRecursive, readJson } from '@helpers/data';
import { enforceDateStringToISO } from '@helpers/date';
import { messagesIsCalendarMessage } from '@helpers/messages';
import { RealtimeChatBaseMessageData, RealtimeChatDynamicMessageData, RealtimeChatMessageCalendarData, RealtimeChatMessageData, RealtimeChatMessageReceivedEventPayloadData } from '@services/dto/realtime/realtime-chat-event/realtime-chat-message-received';
import { MESSAGE_DYNAMIC, MESSAGE_TYPE } from '@types';
export const realtimeChatMessageIsCalendarMessageData = (data: RealtimeChatMessageData): data is RealtimeChatMessageCalendarData => {
  return data.type === MESSAGE_TYPE.CALENDAR;
};
export const realtimeChatMessageIsDynamicMessageData = (data: RealtimeChatBaseMessageData): data is RealtimeChatDynamicMessageData => {
  return data.dynamic === MESSAGE_DYNAMIC.DYNAMIC;
};
export const realtimeChatMessageReceivedFromDto = (dto: string): undefined | RealtimeChatMessageReceivedEventPayloadData => {
  const data = readJson<RealtimeChatMessageReceivedEventPayloadData>(dto, camelKeysRecursive);
  if (!data) {
    return;
  }
  let mappedChat = data.chat;
  if (mappedChat.lastMessage) {
    const {
      lastMessage
    } = mappedChat;
    mappedChat = {
      ...mappedChat,
      lastMessage: {
        ...lastMessage,
        creationTime: enforceDateStringToISO(lastMessage.creationTime)
      },
      lastVisitTime: mappedChat.lastVisitTime ? enforceDateStringToISO(mappedChat.lastVisitTime) : undefined
    };
  }
  if (mappedChat.lastMessage && realtimeChatMessageIsDynamicMessageData(mappedChat.lastMessage)) {
    mappedChat = {
      ...mappedChat,
      lastMessage: {
        ...mappedChat.lastMessage,
        lastChangeTime: enforceDateStringToISO(mappedChat.lastMessage.lastChangeTime)
      }
    };
  }
  if (mappedChat.lastMessage && realtimeChatMessageIsCalendarMessageData(mappedChat.lastMessage)) {
    const {
      lastMessage
    } = mappedChat;
    const {
      calendar
    } = lastMessage;
    mappedChat = {
      ...mappedChat,
      lastMessage: {
        ...lastMessage,
        calendar: {
          ...calendar,
          consultation: {
            ...calendar.consultation,
            timing: {
              ...calendar.consultation.timing,
              beginTime: enforceDateStringToISO(calendar.consultation.timing.beginTime)
            }
          }
        }
      }
    };
  }
  let mappedMessage = data.message;
  mappedMessage = {
    ...mappedMessage,
    creationTime: enforceDateStringToISO(mappedMessage.creationTime)
  };
  if (realtimeChatMessageIsDynamicMessageData(mappedMessage)) {
    mappedMessage = {
      ...mappedMessage,
      lastChangeTime: enforceDateStringToISO(mappedMessage.lastChangeTime)
    };
  }
  if (messagesIsCalendarMessage(mappedMessage)) {
    const {
      calendar
    } = mappedMessage;
    mappedMessage = {
      ...mappedMessage,
      calendar: {
        ...calendar,
        consultation: {
          ...calendar.consultation,
          timing: {
            ...calendar.consultation.timing,
            beginTime: enforceDateStringToISO(calendar.consultation.timing.beginTime)
          }
        }
      }
    };
  }
  return {
    ...data,
    chat: mappedChat,
    message: mappedMessage
  };
};