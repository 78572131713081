import { SpecialistsSetInvitationAllowedReducer } from './types';
export const specialistsSetInvitationAllowedReducer: SpecialistsSetInvitationAllowedReducer = (state, action) => {
  const {
    specialistId,
    invitationAllowed
  } = action.payload;
  return {
    ...state,
    specialistsById: {
      ...state.specialistsById,
      [specialistId]: {
        ...state.specialistsById[specialistId],
        invitationAllowed
      }
    }
  };
};