import { MediaCallRemoveRemoteVideoTrackReducer } from './types';
export const mediaCallRemoveRemoteVideoTrackReducer: MediaCallRemoveRemoteVideoTrackReducer = (state, action) => {
  const {
    chatId
  } = action.payload;
  const currentCall = state.mediaCallsById[chatId];
  return {
    ...state,
    mediaCallsById: {
      ...state.mediaCallsById,
      [chatId]: {
        ...currentCall,
        _remoteVideoTrack: undefined
      }
    }
  };
};