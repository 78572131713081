import { CurrentSpecialistReceiveGetStatisticsReducer } from './types';
export const currentSpecialistReceiveGetStatisticsReducer: CurrentSpecialistReceiveGetStatisticsReducer = (state, action) => {
  const {
    statisticPeriodType,
    amount
  } = action.payload;
  return {
    ...state,
    statisticsByPeriodType: {
      ...state.statisticsByPeriodType,
      [statisticPeriodType]: {
        statisticPeriodType,
        amount
      }
    }
  };
};