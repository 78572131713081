module.exports.flexUtilites = ({ addUtilities }) => {
  const justifyContentVariants = [
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
  ];

  const alignItemsVariants = [
    'flex-start',
    'flex-end',
    'center',
    'stretch',
    'baseline',
  ];

  const flexDirectionVariants = [
    'row',
    'column',
    'row-reverse',
    'column-reverse',
  ];

  let flexUtilites = {};

  flexDirectionVariants.forEach((flexDirectionVariant) => {
    justifyContentVariants.forEach((justifyContentVariant) => {
      alignItemsVariants.forEach((alignItemsVariant) => {
        const flexDirectionName = flexDirectionVariant //
          .replace('column', 'col');
        const justifyContentName = justifyContentVariant //
          .replace(/(flex-)|(space-)/, '');
        const alignItemsName = alignItemsVariant //
          .replace(/(flex-)|(space-)/, '');

        flexUtilites = {
          ...flexUtilites,
          [`.${flexDirectionName}-${justifyContentName}-${alignItemsName}`]: {
            display: 'flex',
            'flex-direction': flexDirectionVariant,
            'justify-content': justifyContentVariant,
            'align-items': alignItemsVariant,
          },
        };
      });
    });
  });

  addUtilities(flexUtilites, ['responsive']);
};
