import { camelKeysRecursive, readJson } from '@helpers/data';
import { isIncomingCall, isOutgoingCall } from '@helpers/media-call';
import { RealtimeChatMediaCallHasBeenRejectedEventPayloadData } from '@services/dto/realtime/realtime-chat-event/realtime-chat-media-call-has-been-rejected';
import { MEDIA_CALL_STATE } from '@types';
import { mediaCallClear, mediaCallSetState } from '@slices/media-call';
import { accountGetAccountId } from '@selectors/account';
import { mediaCallsGetCallByChatId } from '@selectors/media-call';
import { RealtimeChatMediaCallHasBeenRejected } from './types';
export const realtimeChatMediaCallHasBeenRejected: RealtimeChatMediaCallHasBeenRejected = event => {
  return (dispatch, getState) => {
    const data = readJson<RealtimeChatMediaCallHasBeenRejectedEventPayloadData>(event.payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      chatId,
      rejectInitiatorUserId
    } = data;
    const currentMediaCall = mediaCallsGetCallByChatId(getState(), chatId);
    if (!currentMediaCall) {
      return;
    }
    const currentMemberId = accountGetAccountId(getState());
    if (currentMemberId === rejectInitiatorUserId && isOutgoingCall(currentMediaCall) || isIncomingCall(currentMediaCall)) {
      dispatch(mediaCallClear({
        chatId
      }));
    } else {
      dispatch(mediaCallSetState({
        chatId,
        mediaCallState: MEDIA_CALL_STATE.REJECTED_BY_USER
      }));
    }
  };
};