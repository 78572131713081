import { POPUP } from '@config/popups';
import { Popup, Popups } from '@types';
import { appCreateSelector, RootState } from '@store';

/**
 * regular selectors
 */

export const popupsGet = (state: RootState): Popups => {
  return state.ui.popups;
};

/**
 * memoized reselect selectors
 */

export const popupsGetLastPopup: (state: RootState) => undefined | Popup = appCreateSelector([popupsGet],
//
popups => {
  return popups[popups.length - 1];
});
export const popupsGetListByIds: (state: RootState, popupIds: POPUP[]) => Popups = appCreateSelector([popupsGet, (_state, popupIds: POPUP[]) => popupIds], (allPopups, popupIds) => {
  return allPopups.filter(popup => popupIds.includes(popup.id));
});
export const popupsGetHasOpenPopup: (state: RootState) => boolean = appCreateSelector([popupsGet],
//
popups => {
  return Boolean(popups.length);
});