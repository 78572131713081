import { EVENT_NAMES } from '@config/dom';
import { AUTH_API_URL } from '@config/env';
import { HTTP_STATUS_CODE } from '@config/net';
import { alwaysArray, reject, rejectNullable } from '@helpers/data';
import { addEvent } from '@helpers/dom';
import { BaseNetError } from '@errors/base-net-error';
import { AuthExternalResponseDto } from '@services/dto/auth';
import { authTokenResponseFromDto } from '@mapping/auth/auth-token';
import { IExternalAuthEventHandler, IExternalAuthEventHandlerCallback, IExternalAuthEventHandlerConstructor, IExternalAuthEventHandlerSubscribeResult, IExternalAuthEventHandlerUnsubscribeResult } from './types';
export const ExternalAuthEventHandler: IExternalAuthEventHandlerConstructor = class ExternalAuthEventHandler implements IExternalAuthEventHandler {
  private _subscriptions: IExternalAuthEventHandlerCallback[] = [];
  private _unsubscribeMessageEvent: undefined | (() => void);

  /**
   * _handleEvent must be assigned on object, not on its prototype.
   * addEvent relies on that rule in _subscribeMessageEvent, _unsubscribeMessageEvent.
   */
  private _handleEvent = (event: MessageEvent<AuthExternalResponseDto>) => {
    if (event.origin !== AUTH_API_URL) {
      return;
    }
    const {
      Errors,
      Data
    } = event.data;
    if (Data) {
      const data = authTokenResponseFromDto(Data);
      this._subscriptions?.forEach(subscription => {
        subscription({
          data,
          error: undefined
        });
      });
      return;
    }
    if (Errors) {
      const error = new BaseNetError(HTTP_STATUS_CODE.BAD_REQUEST,
      //
      'Bad request', {
        data: null,
        errors: rejectNullable(alwaysArray(Errors)) //
        .map(({
          Code
        }) => ({
          code: Code,
          data: undefined
        }))
      });
      this._subscriptions?.forEach(subscription => {
        subscription({
          data: undefined,
          error
        });
      });
      return;
    }
  };
  private _subscribeMessageEvent() {
    this._unsubscribeMessageEvent = addEvent(window, EVENT_NAMES.MESSAGE, this._handleEvent);
  }
  subscribe(callback: IExternalAuthEventHandlerCallback): IExternalAuthEventHandlerSubscribeResult {
    if (!this._subscriptions.length) {
      this._subscribeMessageEvent();
    }
    this._subscriptions.push(callback);
    return () => {
      this.unsubscribe(callback);
    };
  }
  unsubscribe(callback: IExternalAuthEventHandlerCallback): IExternalAuthEventHandlerUnsubscribeResult {
    this._subscriptions = reject(this._subscriptions, callback);
    if (!this._subscriptions.length) {
      this._unsubscribeMessageEvent?.();
    }
  }
};