import { omit, reject } from '@helpers/data';
import { MessagesReceiveAddMessageReducer } from './types';
export const messagesReceiveAddMessageReducer: MessagesReceiveAddMessageReducer = (state, action) => {
  const {
    message,
    temporaryMessageId
  } = action.payload;
  const {
    byChatId
  } = state;
  const messagesByChatId = byChatId[message.chatId] ?? {};
  const {
    messagesIds = [],
    messagesById = {}
  } = messagesByChatId;
  const cleanedMessagesById = omit(messagesById, temporaryMessageId);
  const cleanedMessageIds = reject(messagesIds, temporaryMessageId);
  return {
    ...state,
    byChatId: {
      ...byChatId,
      [message.chatId]: {
        ...messagesByChatId,
        messagesById: {
          ...cleanedMessagesById,
          [message.id]: message
        },
        messagesIds: [message.id, ...cleanedMessageIds]
      }
    }
  };
};