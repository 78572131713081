import { AUTH_CLIENT_ID, AUTH_GRANT_TYPE } from '@config/auth';
import { AuthRefreshTokenRequestData, AuthRefreshTokenRequestDto, AuthRefreshTokenResponseData, AuthRefreshTokenResponseDto } from '@services/dto/auth';
import { authTokenResponseFromDto } from './auth-token';
export const authRefreshTokenRequestToDto = (data: AuthRefreshTokenRequestData): AuthRefreshTokenRequestDto => {
  return {
    client_id: AUTH_CLIENT_ID,
    grant_type: AUTH_GRANT_TYPE.REFRESH_TOKEN,
    refresh_token: data.refreshToken
  };
};
export const authRefreshTokenResponseFromDto = (dto: AuthRefreshTokenResponseDto): AuthRefreshTokenResponseData => {
  return authTokenResponseFromDto(dto);
};