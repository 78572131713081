import { calendarIsCurrentSpecialist, calendarIsCurrentSpecialistCalendar } from '@helpers/calendar';
import { uniqById } from '@helpers/data';
import { calendarRejectWorkingTimesByPreiod } from '@mapping/calendar/calendar-reject-working-times-by-preiod';
import { CalendarReceiveGetCurrentMemberWorkingTimesReducer } from './types';
export const calendarReceiveGetCurrentMemberWorkingTimesReducer: CalendarReceiveGetCurrentMemberWorkingTimesReducer = (state, action) => {
  const {
    data,
    dateFrom,
    dateTo
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentSpecialist(currentMember) && calendarIsCurrentSpecialistCalendar(currentMember.calendar)) {
    const calendar = currentMember.calendar;
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...calendar,
          workingTimes: uniqById([...data, ...calendarRejectWorkingTimesByPreiod(calendar.workingTimes, dateFrom, dateTo)])
        }
      }
    };
  }
  return state;
};