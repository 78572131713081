import { combineReducers } from 'redux';
import { geometryDefaultState, geometryReducer, GeometryState } from '@slices/geometry';
import { loadingScreenDefaultState, loadingScreenReducer, LoadingScreenState } from '@slices/loading-screen';
import { notificationsUiDefaultState, notificationsUiReducer, NotificationsUiState } from '@slices/notifications-ui';
import { popupsDefaultState, popupsReducer, PopupsState } from '@slices/popups';
import { sidebarDefaultState, sidebarReducer, SidebarState } from '@slices/sidebar';
export type UIState = {
  geometry: GeometryState;
  popups: PopupsState;
  sidebar: SidebarState;
  loadingScreen: LoadingScreenState;
  notificationsUi: NotificationsUiState;
};
export const uiDefaultState: UIState = {
  geometry: geometryDefaultState,
  popups: popupsDefaultState,
  sidebar: sidebarDefaultState,
  loadingScreen: loadingScreenDefaultState,
  notificationsUi: notificationsUiDefaultState
};
export const uiReducer = combineReducers({
  geometry: geometryReducer,
  popups: popupsReducer,
  sidebar: sidebarReducer,
  loadingScreen: loadingScreenReducer,
  notificationsUi: notificationsUiReducer
});