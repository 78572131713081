import { ENDPOINTS } from '@config/endpoints';
import { OAUTH_GOOGLE_APP_ID, OAUTH_GOOGLE_URL } from '@config/env';
import { jsonToBase64 } from '@helpers/data';
import { encodeUrl } from '@helpers/routing';
import { AccountAssociatingGoogleRequestData, AccountAssociatingGoogleRequestDto, AccountAuthGoogleRequestData, AccountAuthGoogleRequestDto } from '@services/dto/account/account-auth-google';
import { accountAssociatingGoogleRequestToDto, accountAuthGoogleRequestToDto } from '@mapping/account/account-auth-google';
export abstract class GoogleAuth {
  private static encodeAuthUrl(dto: AccountAuthGoogleRequestDto | AccountAssociatingGoogleRequestDto): string {
    return encodeUrl(dto.appUrl, {
      client_id: dto.appId,
      access_type: dto.accessType,
      redirect_uri: dto.redirectUri,
      response_type: dto.responseType,
      scope: dto.scope.join(' '),
      include_granted_scopes: dto.includeGrantedScopes,
      prompt: dto.prompt,
      state: jsonToBase64(dto.state)
    });
  }
  static createAuthUrl(data: Omit<AccountAuthGoogleRequestData,
  //
  'appUrl' | 'appId' | 'redirectUri'>): string {
    return GoogleAuth.encodeAuthUrl(accountAuthGoogleRequestToDto({
      appUrl: OAUTH_GOOGLE_URL,
      appId: OAUTH_GOOGLE_APP_ID,
      redirectUri: ENDPOINTS.auth.externalAuthCallback(),
      ...data
    }));
  }
  static createAssociatingUrl(data: Omit<AccountAssociatingGoogleRequestData, 'appUrl' | 'appId' | 'redirectUri'>): string {
    return GoogleAuth.encodeAuthUrl(accountAssociatingGoogleRequestToDto({
      appUrl: OAUTH_GOOGLE_URL,
      appId: OAUTH_GOOGLE_APP_ID,
      redirectUri: ENDPOINTS.account.externalLinkCallback(),
      ...data
    }));
  }
}