import { omit } from '@helpers/data';
import { enforceDateStringToISO } from '@helpers/date';
import { ClientsGetByIdResponseData, ClientsGetByIdResponseDto } from '@services/dto/clients';
import { FILE_TYPE } from '@types';
export const clientsGetByIdResponseFromDto = (dto: ClientsGetByIdResponseDto): ClientsGetByIdResponseData => {
  return omit({
    ...dto.data,
    birthDate: dto.data.birthDate ? enforceDateStringToISO(dto.data.birthDate) : undefined,
    lastActivityTime: dto.data.lastActivityTime ? enforceDateStringToISO(dto.data.lastActivityTime) : undefined,
    media: [...dto.data.photos.map(file => ({
      ...file,
      fileType: FILE_TYPE.PHOTO
    }))]
  }, 'photos');
};