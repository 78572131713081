module.exports.screens = {
  mobile: { raw: 'min-width: 0px' },
  'mobile-h': { raw: '(orientation: landscape) and (min-width: 540px)' },

  tablet: { raw: '(orientation: portrait) and (min-width: 768px)' },
  'tablet-h': { raw: '(orientation: landscape) and (min-width: 1024px)' },

  desktop: {
    raw: `
      (hover: hover) and (pointer: fine) and (max-resolution: 2dppx) and (min-width: 1200px),
      (hover: hover) and (pointer: fine) and (-webkit-max-device-pixel-ratio: 2) and (min-width: 1200px)
    `,
  },
  'desktop-lg': {
    raw: `
      (hover: hover) and (pointer: fine) and (max-resolution: 2dppx) and (min-width: 1700px),
      (hover: hover) and (pointer: fine) and (-webkit-max-device-pixel-ratio: 2) and (min-width: 1700px)
    `,
  },
};
