import { ERROR_CODE } from '@config/errors';
import { REGEX } from '@config/regex';
import { ensureIsFramed } from '@helpers/dom';
import { getOriginDomain } from '@helpers/url';
import { UrlIsLimitedByOriginValidator } from './types';
export const urlIsLimitedByOriginValidator: UrlIsLimitedByOriginValidator = (value, options) => {
  if (value == null) {
    return;
  }
  const isFramed = ensureIsFramed();
  const domain = getOriginDomain();
  if (isFramed || value.match(REGEX.CONTAINS_URL) && !value.match(new RegExp(domain))) {
    return options?.message ?? ERROR_CODE.FIELD_IS_INVALID;
  }
};