import { CoinPrices } from './prices';
export enum PAYMENT_TYPE {
  DIRECT = 1,
  // use this on client
  RECURRING = 2,
}
export enum PAYMENT_METHOD_KEY {
  CARD = 1,
  CARD_MIR = 7,
  CARD_TEST = 5,
  TEST = 6,
  QIWI_WALLET = 2,
  WEB_MONEY = 3,
  YOOMONEY = 4,
}
export enum PAYMENT_AGGREGATOR_ID {
  PLATRON = 1,
  PAYABLE = 2,
  SOFTPAY = 3,
  CYPIX = 4,
}
export const PAYMENT_METHODS_BY_KEY = {
  [PAYMENT_METHOD_KEY.CARD]: {
    id: PAYMENT_METHOD_KEY.CARD,
    imageSrc: '/images/payments/methods/card.png'
  },
  [PAYMENT_METHOD_KEY.CARD_MIR]: {
    id: PAYMENT_METHOD_KEY.CARD_MIR,
    imageSrc: '/images/payments/methods/card-mir.png'
  },
  [PAYMENT_METHOD_KEY.CARD_TEST]: {
    id: PAYMENT_METHOD_KEY.CARD_TEST,
    imageSrc: '/images/payments/methods/card-test.png'
  },
  [PAYMENT_METHOD_KEY.TEST]: {
    id: PAYMENT_METHOD_KEY.TEST,
    imageSrc: '/images/payments/methods/card-test.png'
  },
  [PAYMENT_METHOD_KEY.QIWI_WALLET]: {
    id: PAYMENT_METHOD_KEY.QIWI_WALLET,
    imageSrc: '/images/payments/methods/qiwi.png'
  },
  [PAYMENT_METHOD_KEY.WEB_MONEY]: {
    id: PAYMENT_METHOD_KEY.WEB_MONEY,
    imageSrc: '/images/payments/methods/webmoney.png'
  },
  [PAYMENT_METHOD_KEY.YOOMONEY]: {
    id: PAYMENT_METHOD_KEY.YOOMONEY,
    imageSrc: '/images/payments/methods/yoomoney.png'
  }
};
export enum PAYMENT_PURPOSE {
  BUY_COINS = 1,
  BUY_PREMIUM = 2,
}
export type PaymentMethodId = number;
export type PaymentMethod = {
  id: PaymentMethodId;
  name: string;
  key: PAYMENT_METHOD_KEY;
  isDefault: boolean;
};
export type PaymentMethods = PaymentMethod[];
export enum PROMOTION_TYPE {
  ACTION = 1,
}
export type BankCardId = number;
export type BankCard = {
  id: BankCardId;
  maskedNumber: undefined | string;
  creationTime: string;
};
export type BankCards = BankCard[];
export enum PAY_MODE {
  FRAME = 'frame',
  TAB = 'tab',
  WINDOW = 'window',
}
export type PayFrameData = {
  src?: string;
};
export type PayTabData = {
  href?: string;
};
export type PayWindowData = {
  href?: string;
};
export type PayData = {
  initialInvoiceRequired: boolean;
} & ({
  mode: PAY_MODE.FRAME;
} //
| {
  mode: PAY_MODE.TAB;
} | {
  mode: PAY_MODE.WINDOW;
});
export type PaymentSettings = {
  paymentMethods: PaymentMethods;
  coinPrices: CoinPrices;
  bankCards: BankCards;
  payData: PayData;
};
export type PaymentAggregatorPayData = {
  paymentMethodKey: PAYMENT_METHOD_KEY;
  aggregatorId: PAYMENT_AGGREGATOR_ID;
  recurrentPaymentInitiated: boolean;
} & ({
  mode: PAY_MODE.FRAME;
  frame: {
    src?: string;
  };
} | {
  mode: PAY_MODE.TAB;
  tab: {
    href?: string;
  };
} | {
  mode: PAY_MODE.WINDOW;
  window: {
    href?: string;
  };
});
export enum CURRENCY {
  RUB = 1,
  EUR = 2,
}
export enum CURRENCY_SYM {
  RUB = '₽',
  EUR = '€',
}
export enum CURRENCY_CODE {
  RUB = 'RUB',
  EUR = 'EUR',
}