import { ENDPOINTS } from '@config/endpoints';
import { OAUTH_FACEBOOK_APP_ID, OAUTH_FACEBOOK_URL } from '@config/env';
import { jsonToBase64 } from '@helpers/data';
import { encodeUrl } from '@helpers/routing';
import { AccountAssociatingFacebookRequestData, AccountAssociatingFacebookRequestDto, AccountAuthFacebookRequestData, AccountAuthFacebookRequestDto } from '@services/dto/account/account-auth-facebook';
import { accountAssociatingFacebookRequestToDto, accountAuthFacebookRequestToDto } from '@mapping/account/account-auth-facebook';
export abstract class FacebookAuth {
  private static encodeAuthUrl(dto: AccountAuthFacebookRequestDto | AccountAssociatingFacebookRequestDto): string {
    return encodeUrl(dto.appUrl, {
      client_id: dto.appId,
      display: dto.display,
      redirect_uri: dto.redirectUri,
      response_type: dto.responseType,
      scope: dto.scope.join(' '),
      state: jsonToBase64(dto.state)
    });
  }
  static createAuthUrl(data: Omit<AccountAuthFacebookRequestData, 'appUrl' | 'appId' | 'redirectUri'>): string {
    return FacebookAuth.encodeAuthUrl(accountAuthFacebookRequestToDto({
      appUrl: OAUTH_FACEBOOK_URL,
      appId: OAUTH_FACEBOOK_APP_ID,
      redirectUri: ENDPOINTS.auth.externalAuthCallback(),
      ...data
    }));
  }
  static createAssociatingUrl(data: Omit<AccountAssociatingFacebookRequestData, 'appUrl' | 'appId' | 'redirectUri'>): string {
    return FacebookAuth.encodeAuthUrl(accountAssociatingFacebookRequestToDto({
      appUrl: OAUTH_FACEBOOK_URL,
      appId: OAUTH_FACEBOOK_APP_ID,
      redirectUri: ENDPOINTS.account.externalLinkCallback(),
      ...data
    }));
  }
}