import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsT1NotificationLinkVisitedEvent } from '@logging/logging-events';
import { AnalyticsT1NotificationLinkVisitedEventRequestData } from '@services/dto/analytics/analytics-support-send-event/analytics-t1-notification-link-visited-event';
export const isT1NotificationLinkVisitedEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsT1NotificationLinkVisitedEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.T1_NOTIFICATION_LINK_VISITED;
};
export const sendT1NotificationLinkVisited = (eventBus: IEventBus, data: AnalyticsT1NotificationLinkVisitedEventRequestData['data']): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsT1NotificationLinkVisitedEventRequestData['data']>({
    action: ANALYTICS_ACTION.T1_NOTIFICATION_LINK_VISITED,
    data
  }));
};