import { authedHttpFetch } from '@services/base/authed-http-fetch';
import { jsonOwnHeaders } from '@services/base/json-fetch';
import { AuthedJsonFetchConfig, AuthedJsonFetchHeaders, AuthedJsonFetchOwnHeaders, AuthedJsonFetchResult } from './types';
const authedJsonOwnHeaders = jsonOwnHeaders;
export const authedJsonFetch = <R, D = undefined, P = undefined, H extends AuthedJsonFetchHeaders = undefined>(url: string, config?: AuthedJsonFetchConfig<D, P, H>): AuthedJsonFetchResult<R> => {
  const nextConfig = {
    ...config,
    headers: {
      ...authedJsonOwnHeaders,
      ...config?.headers
    }
  };
  const {
    promise,
    cancel
  } = authedHttpFetch<R, D, P, AuthedJsonFetchOwnHeaders | (AuthedJsonFetchOwnHeaders & H)>(url, nextConfig);
  return {
    promise: promise.then(({
      data
    }) => data),
    cancel
  };
};