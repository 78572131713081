import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SpecialistsGetByIdRequestData, SpecialistsGetByIdResponseData, SpecialistsGetByIdResponseDto } from '@services/dto/specialists';
import { specialistsGetByIdResponseFromDto } from '@mapping/specialists';
type SpecialistsGetById = (requestData: SpecialistsGetByIdRequestData) => AuthedJsonFetchResult<SpecialistsGetByIdResponseData>;
export const specialistsGetById: SpecialistsGetById = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SpecialistsGetByIdResponseDto>(ENDPOINTS.specialists.getById(requestData.specialistId), {
    method: 'GET'
  });
  return {
    promise: promise.then(specialistsGetByIdResponseFromDto),
    cancel
  };
};