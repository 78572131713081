export const NODE_ENV = process.env.NODE_ENV ?? '';
export const FRONTEND_APPLICATION_HOST = process.env.FRONTEND_APPLICATION_HOST ?? '';
export const FRONTEND_ADMIN_APPLICATION_HOST = process.env.FRONTEND_ADMIN_APPLICATION_HOST ?? '';
export const IMAGES_HOST = process.env.IMAGES_HOST ?? '';
export const AUTH_API_URL = process.env.AUTH_API_URL ?? '';
export const WEB_API_URL = process.env.WEB_API_URL ?? '';
export const CALENDAR_API_URL = process.env.CALENDAR_API_URL ?? '';
export const SUPPORT_API_URL = process.env.SUPPORT_API_URL ?? '';
export const REALTIME_API_URL = process.env.REALTIME_API_URL ?? '';
export const UPLOADS_API_URL = process.env.UPLOADS_API_URL ?? '';
export const CLOUDINARY_API_URL = process.env.CLOUDINARY_API_URL ?? '';
export const BLOB_STORAGE_URL = process.env.BLOB_STORAGE_URL ?? '';
export const ANALYTICS_API_URL = process.env.ANALYTICS_API_URL ?? '';
export const SENTRY_DSN = process.env.SENTRY_DSN ?? '';
export const SENTRY_TOKEN = process.env.SENTRY_TOKEN ?? '';
export const SENTRY_ENV = process.env.SENTRY_ENV ?? '';
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID ?? '';
export const YM_TRACKING_ID = process.env.YM_TRACKING_ID ?? '';
export const FB_PIXEL_ID = process.env.FB_PIXEL_ID ?? '';
export const FB_DOMAIN_VERIFICATION_CONTENT = process.env.FB_DOMAIN_VERIFICATION_CONTENT ?? '';
export const VK_OPEN_API_ID = process.env.VK_OPEN_API_ID ?? '';
export const ONESIGNAL_APP_ID = process.env.ONESIGNAL_APP_ID ?? '';
export const ONESIGNAL_SAFARI_WEB_ID = process.env.ONESIGNAL_SAFARI_WEB_ID ?? '';
export const OAUTH_FACEBOOK_URL = process.env.OAUTH_FACEBOOK_URL ?? '';
export const OAUTH_FACEBOOK_APP_ID = process.env.OAUTH_FACEBOOK_APP_ID ?? '';
export const OAUTH_VK_URL = process.env.OAUTH_VK_URL ?? '';
export const OAUTH_VK_APP_ID = process.env.OAUTH_VK_APP_ID ?? '';
export const OAUTH_GOOGLE_URL = process.env.OAUTH_GOOGLE_URL ?? '';
export const OAUTH_GOOGLE_APP_ID = process.env.OAUTH_GOOGLE_APP_ID ?? '';
export const CLOUDINARY_CLOUD_ID = process.env.CLOUDINARY_CLOUD_ID ?? '';
export const CLOUDINARY_API_KEY = process.env.CLOUDINARY_API_KEY ?? '';
export const KEEP_SEARCH_RESULTS_TIME = parseFloat(process.env.KEEP_SEARCH_RESULTS_TIME ?? '') ?? 0;
export const LAST_COMMIT_HASH = process.env.LAST_COMMIT_HASH ?? '';
export const LAST_RELEASE_DATE = process.env.LAST_RELEASE_DATE ?? '';
type EnvVars = Record<string, string>;
const warnIfEnvVarNotDefined = (vars: EnvVars) => {
  Object.entries(vars).forEach(entry => {
    const [key, value] = entry;
    if (!value) {
      console.warn(`${key} env variable is not defined. Please set correct one`);
    }
  });
};
warnIfEnvVarNotDefined(module.exports);