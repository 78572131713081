import { ERROR_CODE } from '@config/errors';
import { intersection } from '@helpers/data';
import { FileTypeValidator } from './types';
export const fileTypeValidator: FileTypeValidator = (value, options) => {
  if (value == null || !value.length) {
    return;
  }
  const fileTypes = value.map(file => file.type);
  const _intersection = intersection(options.fileTypes, fileTypes);
  const invalidFileType = !_intersection.length;
  if (invalidFileType) {
    return options.message ?? ERROR_CODE.FIELD_IS_INVALID;
  }
};