import { uniqById } from '@helpers/data';
import { calendarRejectConsultationsByPeriod } from '@mapping/calendar/calendar-reject-consultations-by-period';
import { CalendarReceiveGetMemberConsultationsReducer } from './types';
export const calendarReceiveGetMemberConsultationsReducer: CalendarReceiveGetMemberConsultationsReducer = (state, action) => {
  const {
    memberId,
    dateFrom,
    dateTo,
    data
  } = action.payload;
  const member = state.membersById?.[memberId];
  if (!member) {
    return state;
  }
  const calendar = member.calendar;
  if (!calendar) {
    return state;
  }
  return {
    ...state,
    membersById: {
      ...state.membersById,
      [memberId]: {
        ...member,
        calendar: {
          ...calendar,
          ...data,
          consultations: uniqById([...data, ...calendarRejectConsultationsByPeriod(calendar.consultations ?? [], dateFrom, dateTo)])
        }
      }
    }
  };
};