import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsBuyCoinsTryEvent, IAnalyticsEvent } from '@logging/logging-events';
import { AnalyticsBuyCoinsTryEventRequestData } from '@services/dto/analytics/analytics-send-event/analytics-buy-coins-try-event';
export const isBuyCoinsTryEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsBuyCoinsTryEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.BUY_COINS_TRY;
};
export const sendBuyCoinsTry = (eventBus: IEventBus, data: AnalyticsBuyCoinsTryEventRequestData['data']): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsBuyCoinsTryEventRequestData['data']>({
    action: ANALYTICS_ACTION.BUY_COINS_TRY,
    data
  }));
};