import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsModalViewEvent } from '@logging/logging-events';
import { AnalyticsModalViewCalendarData, AnalyticsModalViewData, AnalyticsModalViewEventRequestData } from '@services/dto/analytics/analytics-send-event/analytics-modal-view-event';
import { ANALYTICS_MODAL_ID } from '@types';
export const isModalViewEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsModalViewEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.MODAL_VIEW;
};
export const isAnalyticsModalViewCalendarData = (data: AnalyticsModalViewData): data is AnalyticsModalViewCalendarData => {
  return [ANALYTICS_MODAL_ID.CALENDAR, ANALYTICS_MODAL_ID.CALENDAR_COINS_RESERVATION, ANALYTICS_MODAL_ID.CALENDAR_LACK_OF_COINS_FOR_RESERVATION].includes(data.modalId);
};
export const sendModalView = (eventBus: IEventBus, data: AnalyticsModalViewEventRequestData['data']): void => {
  eventBus.handle(new AnalyticsEvent<AnalyticsModalViewEventRequestData['data']>({
    action: ANALYTICS_ACTION.MODAL_VIEW,
    data
  }));
};