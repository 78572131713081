import { SUPPORT_CHAT_ID } from '@config/chats';
import { NOTIFICATION_TYPE } from '@config/notifications';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimeSupportMessageReceivedEventPayloadData } from '@services/dto/realtime/realtime-support-event/realtime-support-message-received';
import { notificationsUiHandleAdd } from '@actions/notifications-ui/notifications-ui-handle-add';
import { supportAddMessage, supportSetUnreadMessagesCounter } from '@slices/support';
import { messagesGetSelectedChatId } from '@selectors/messages';
import { supportGetChatImageUrl, supportGetChatMateUserName, supportGetLastMessageText, supportGetUnreadMessagesCount } from '@selectors/support';
import { RealtimeSupportMessageReceived } from './types';
export const realtimeSupportMessageReceived: RealtimeSupportMessageReceived = event => {
  return (dispatch, getState) => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeSupportMessageReceivedEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      message
    } = data;
    dispatch(supportAddMessage({
      message
    }));
    const state = getState();
    const unreadMessagesCount = supportGetUnreadMessagesCount(state);
    dispatch(supportSetUnreadMessagesCounter({
      unreadMessagesCount: (unreadMessagesCount ?? 0) + 1
    }));
    const selectedChatId = messagesGetSelectedChatId(state);
    if (!selectedChatId || selectedChatId !== SUPPORT_CHAT_ID) {
      const message = supportGetLastMessageText(state);
      const avatar = supportGetChatImageUrl();
      const name = supportGetChatMateUserName();
      dispatch(notificationsUiHandleAdd({
        type: NOTIFICATION_TYPE.NEW_MESSAGE,
        chatId: SUPPORT_CHAT_ID,
        message: message ?? '',
        avatar,
        name
      }));
    }
  };
};