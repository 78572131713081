import { SEARCH_MAX_AGE } from '@config/search';
import { appCreateSlice } from '@store/rtk';
import { searchErrorGetListReducer } from '@slices/search/search-error-get-list';
import { searchReceiveGetListReducer } from '@slices/search/search-receive-get-list';
import { searchReceiveGetMaxAgeReducer } from '@slices/search/search-receive-get-max-age';
import { searchResetStateReducer } from '@slices/search/search-reset-state';
import { SearchState } from './types';
export const searchDefaultState: SearchState = {
  maxAge: SEARCH_MAX_AGE,
  listItemsById: {},
  listItemIds: [],
  hasMore: false,
  limit: 12,
  offset: 0,
  totalCount: 0,
  dataLoaded: false,
  hasError: false,
  lastSearchTime: undefined,
  lastSearchFilters: {}
};
const searchSlice = appCreateSlice({
  name: 'search',
  initialState: searchDefaultState,
  reducers: {
    searchErrorGetList: searchErrorGetListReducer,
    searchReceiveGetList: searchReceiveGetListReducer,
    searchReceiveGetMaxAge: searchReceiveGetMaxAgeReducer,
    searchResetState: searchResetStateReducer
  }
});
export const {
  actions: {
    searchErrorGetList,
    searchReceiveGetList,
    searchReceiveGetMaxAge,
    searchResetState
  },
  reducer: searchReducer
} = searchSlice;