import { CurrentSpecialistReceiveUpdateReducer } from './types';
export const currentSpecialistReceiveUpdateReducer: CurrentSpecialistReceiveUpdateReducer = (state, action) => {
  if (!state.me) {
    return state;
  }
  const {
    specialistProfile
  } = action.payload;
  return {
    ...state,
    me: {
      ...state.me,
      ...specialistProfile
    }
  };
};