import { CurrentClientSetFetchedReducer } from './types';
export const currentClientSetFetchedReducer: CurrentClientSetFetchedReducer = state => {
  return {
    ...state,
    /**
     * @todo
     * Reconsider the field below.
     * To define whether data is loaded or not we can check 'me' field value
     * existence (me == null) or use of getCurrentMemberExists selector.
     * Probably duplication of functionality.
     */
    isFetchingMe: false
  };
};