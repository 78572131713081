import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { RealtimeInactiveRequestData, RealtimeInactiveResponseData } from '@services/dto/realtime/realtime-inactive';
type RealtimeInactive = (requestData: RealtimeInactiveRequestData) => AuthedJsonFetchResult<RealtimeInactiveResponseData>;
export const realtimeInactive: RealtimeInactive = requestData => {
  return authedJsonFetch(ENDPOINTS.realtime.inactive(requestData.sessionId),
  //
  {
    method: 'POST'
  });
};