import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { RealtimeOfflineRequestData, RealtimeOfflineRequestDto, RealtimeOfflineResponseData, RealtimeOfflineResponseDto } from '@services/dto/realtime/realtime-offline';
import { realtimeOfflineRequestToDto } from '@mapping/realtime/realtime-offline';
type RealtimeOffline = (requestData: RealtimeOfflineRequestData) => AuthedJsonFetchResult<RealtimeOfflineResponseData>;
export const realtimeOffline: RealtimeOffline = requestData => {
  return authedJsonFetch<RealtimeOfflineResponseDto, RealtimeOfflineRequestDto>(ENDPOINTS.realtime.offline(requestData.sessionId),
  //
  {
    method: 'POST',
    data: realtimeOfflineRequestToDto(requestData)
  });
};