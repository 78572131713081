import { ERROR_CODE } from '@config/errors';
import { CallError } from '@errors/call-error';
import { ErrorEvent, FatalEvent, IEventBus } from '@logging';
export const sendError = (eventBus: IEventBus, error: Error, statusCode?: number): void => {
  if (error instanceof CallError) {
    eventBus.handle(new ErrorEvent({
      error
    }));
    return;
  }
  if (!statusCode || statusCode === ERROR_CODE.UNEXPECTED_ERROR) {
    eventBus.handle(new FatalEvent({
      error
    }));
    return;
  }
  eventBus.handle(new ErrorEvent({
    error
  }));
};