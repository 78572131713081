import { AppFCC } from '@types';
import { PopupWidget } from '@widgets/popups/popup';
import { AlertDialog } from '@kit/popups/alert-dialog';
import { AlertDialogWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AlertDialogWidget: AppFCC<AlertDialogWidgetProps> = props => {
  const {
    className,
    textClassName,
    popupId,
    text,
    submitButtonClassName,
    submitButtonText,
    submitButtonColor,
    disabled,
    onSubmit,
    onClose
  } = props;
  return _jsx(PopupWidget //
  , {
    onClose: onClose,
    popupId: popupId,
    children: _jsx(AlertDialog, {
      className: className,
      textClassName: textClassName,
      submitButtonClassName: submitButtonClassName,
      text: text,
      submitButtonColor: submitButtonColor,
      submitButtonText: submitButtonText,
      submitButtonOnClick: onSubmit,
      closeButtonOnClick: onClose,
      disabled: disabled
    })
  });
};