import { calculateAvatarUrl, calculateDefaultAvatarId, calculateStubAvatarId, isStubAvatar } from '@helpers/member';
import { AVATAR_ID, CurrentClient, LEVEL, SEX, WalletAmount } from '@types';
import { appCreateSelector, RootState } from '@store';
import { CurrentClientState } from '@slices/current-client';

/**
 * regular selectors
 */

export const currentClientGet = (state: RootState): CurrentClientState => {
  return state.app.currentClient;
};

/**
 * memoized reselect selectors
 */

export const currentClientGetMe: (state: RootState) => undefined | CurrentClient = appCreateSelector([currentClientGet], currentClient => currentClient.me);
export const currentClientGetCoinAmount: (state: RootState) => WalletAmount = appCreateSelector([currentClientGet], currentClient => currentClient.coinAmount);
export const currentClientGetTempSex: (state: RootState) => undefined | SEX = appCreateSelector([currentClientGet], currentClient => currentClient.tempSex);
export const currentClientGetIsFetchingMe: (state: RootState) => boolean = appCreateSelector([currentClientGet], currentClient => currentClient.isFetchingMe);
export const currentClientGetAvatarId: (state: RootState) => undefined | AVATAR_ID = appCreateSelector(currentClientGetMe, currentClient => currentClient?.avatarId);
export const currentClientGetHasStubAvatar: (state: RootState) => undefined | boolean = appCreateSelector(currentClientGetAvatarId, avatarId => {
  if (!avatarId) {
    return undefined;
  }
  return isStubAvatar(avatarId);
});
export const currentClientGetAvatarUrl: (state: RootState) => undefined | string = appCreateSelector([currentClientGetMe, currentClientGetTempSex, currentClientGetAvatarId], (currentClient, tempSex, currentAvatarId) => {
  if (!currentClient) {
    return undefined;
  }
  if (!tempSex || tempSex === currentClient.sex) {
    return calculateAvatarUrl(currentAvatarId ?? calculateStubAvatarId(currentClient.sex));
  }
  return calculateAvatarUrl(calculateDefaultAvatarId(tempSex));
});
export const currentClientGetLevel: (state: RootState) => undefined | LEVEL = appCreateSelector(currentClientGetMe, currentClient => currentClient?.level);