import { rejectById } from '@helpers/data';
import { CurrentSpecialistReceiveDeleteVideoReducer } from './types';
export const currentSpecialistsReceiveDeleteVideoReducer: CurrentSpecialistReceiveDeleteVideoReducer = (state, action) => {
  if (!state.me) {
    return state;
  }
  const {
    data
  } = action.payload;
  const {
    videoId
  } = data;
  const media = rejectById(state.me.media, videoId);
  return {
    ...state,
    me: {
      ...state.me,
      media
    }
  };
};