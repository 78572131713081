import { findById, rejectById, rejectNullable } from '@helpers/data';
import { calculateElapsedTimeInYears } from '@helpers/date';
import { isPhoto } from '@helpers/media-files';
import { isClient, isSpecialist, photoOrAvatarUrl } from '@helpers/member';
import { Client, MediaFiles, MemberId, ONLINE_STATUS, Photo, Photos, ROLE_TYPE, SEX, Specialist, SubjectIds, Subjects } from '@types';
import { appCreateSelector, RootState } from '@store';
import { clientsGetClient, clientsGetClientAvatarId } from './clients';
import { specialistsGetSpecialist } from './specialists';
import { subjectsGetSubjectById } from './subjects';

/**
 * memoized reselect selectors
 */

export const membersGetMemberById: (state: RootState, memberId: MemberId) => undefined | Client | Specialist = appCreateSelector([(state: RootState) => state,
//
(_state, memberId: MemberId) => memberId], (state, memberId) => {
  const client = clientsGetClient(state, memberId);
  if (client) {
    return client;
  }
  const specialist = specialistsGetSpecialist(state, memberId);
  if (specialist) {
    return specialist;
  }
});
export const membersGetMemberRoleType: (state: RootState, memberId: MemberId) => undefined | ROLE_TYPE = appCreateSelector(membersGetMemberById,
//
member => member?.roleType);
export const membersGetMemberRating: (state: RootState, memberId: MemberId) => undefined | number = appCreateSelector(membersGetMemberById, member => member?.rating);
export const membersGetMemberName: (state: RootState, memberId: MemberId) => undefined | string = appCreateSelector(membersGetMemberById, member => member?.name);
export const membersGetMemberSex: (state: RootState, memberId: MemberId) => undefined | SEX = appCreateSelector(membersGetMemberById, member => member?.sex);
export const membersGetMemberBirthDate: (state: RootState, memberId: MemberId) => undefined | string = appCreateSelector(membersGetMemberById, member => member?.birthDate);
export const membersGetMemberAge: (state: RootState, memberId: MemberId) => undefined | number = appCreateSelector(membersGetMemberBirthDate, birthDate => {
  return calculateElapsedTimeInYears(birthDate);
});
export const membersGetMemberDefaultPhotoId: (state: RootState, memberId: MemberId) => undefined | number = appCreateSelector(membersGetMemberById, member => member?.defaultPhotoId);
export const membersGetMemberMedia: (state: RootState, memberId: MemberId) => undefined | MediaFiles = appCreateSelector(membersGetMemberById,
//
member => member?.media);
export const membersGetMemberPhotos: (state: RootState, memberId: MemberId) => undefined | Photos = appCreateSelector(membersGetMemberMedia, media => media?.filter(isPhoto));
export const membersGetMemberDefaultPhoto: (state: RootState, memberId: MemberId) => undefined | Photo = appCreateSelector([membersGetMemberDefaultPhotoId, membersGetMemberPhotos], (defaultPhotoId, photos) => {
  if (!defaultPhotoId || !photos) {
    return undefined;
  }
  return findById(photos, defaultPhotoId);
});

/**
 * @deprecated
 * Candidate to be deleted in case of doing cropping on the client rather
 * than on the server.
 */
export const membersGetMemberPhotosWithoutDefault: (state: RootState, memberId: MemberId) => undefined | Photos = appCreateSelector([membersGetMemberPhotos, membersGetMemberDefaultPhotoId], (photos, defaultPhotoId) => {
  if (!photos) {
    return undefined;
  }
  return rejectById(photos, defaultPhotoId);
});

/**
 * @deprecated
 * Candidate to be deleted in case of doing cropping on the client rather
 * than on the server.
 */
export const membersGetMemberDefaultPhotoOriginPhotoId: (state: RootState, memberId: MemberId) => undefined | number = appCreateSelector(membersGetMemberDefaultPhoto, defaultPhoto => defaultPhoto?.originPhotoId);
export const membersGetMemberDefaultPhotoOrAvatarUrl: (state: RootState, memberId: MemberId) => undefined | string = appCreateSelector([membersGetMemberById, (state: RootState) => state, (state: RootState, memberId: MemberId) => memberId], (member, state, memberId) => {
  if (!member) {
    return undefined;
  }
  const defaultPhoto = membersGetMemberDefaultPhoto(state, memberId);
  const roleType = membersGetMemberRoleType(state, memberId);
  const sex = membersGetMemberSex(state, memberId);
  if (isClient(roleType)) {
    const avatarId = clientsGetClientAvatarId(state, memberId);
    return photoOrAvatarUrl(defaultPhoto?.url, avatarId, sex);
  }
  if (isSpecialist(roleType)) {
    return photoOrAvatarUrl(defaultPhoto?.url, undefined, sex);
  }
  return undefined;
});
export const membersGetMemberDefaultSubjectId: (state: RootState, memberId: MemberId) => undefined | number = appCreateSelector(membersGetMemberById, member => member?.defaultSubjectId);
export const membersGetMemberSubjectIds: (state: RootState, memberId: MemberId) => undefined | SubjectIds = appCreateSelector(membersGetMemberById, member => member?.subjectIds);
export const membersGetMemberSubjects: (state: RootState, memberId: MemberId) => undefined | Subjects = appCreateSelector([membersGetMemberDefaultSubjectId, membersGetMemberSubjectIds, (state: RootState) => state], (defaultSubjectId, subjectIds, state) => {
  if (!defaultSubjectId || !subjectIds) {
    return undefined;
  }
  const _subjectIds = [defaultSubjectId, ...subjectIds];
  return rejectNullable(_subjectIds.map(id => subjectsGetSubjectById(state, id)));
});
export const membersGetMemberAboutSelf: (state: RootState, memberId: MemberId) => undefined | string = appCreateSelector(membersGetMemberById, member => member?.aboutSelf);
export const membersGetMemberOnlineStatus: (state: RootState, memberId: MemberId) => undefined | ONLINE_STATUS = appCreateSelector(membersGetMemberById, member => member?.onlineStatus);
export const membersGetMemberLastActivityTime: (state: RootState, memberId: MemberId) => undefined | string = appCreateSelector(membersGetMemberById, member => member?.lastActivityTime);
export const membersGetMemberInvitationAllowed: (state: RootState, memberId: MemberId) => undefined | boolean = appCreateSelector(membersGetMemberById, member => member?.invitationAllowed);
export const membersGetMemberInvitationRequested: (state: RootState, memberId: MemberId) => undefined | boolean = appCreateSelector(membersGetMemberById, member => member?.invitationRequested);
export const membersGetMemberIsFavoriteUser: (state: RootState, memberId: MemberId) => undefined | boolean = appCreateSelector(membersGetMemberById, member => member?.isFavoriteUser);
export const membersGetMemberHasComplaint: (state: RootState, memberId: MemberId) => undefined | boolean = appCreateSelector(membersGetMemberById, member => member?.hasComplaint);
export const membersGetMemberHasComplaintAgainstMe: (state: RootState, memberId: MemberId) => undefined | boolean = appCreateSelector(membersGetMemberById, member => member?.hasComplaintAgainstMe);
export const membersGetMemberInBlackList: (state: RootState, memberId: MemberId) => undefined | boolean = appCreateSelector(membersGetMemberById, member => member?.inBlackList);
export const membersGetMemberChatIdWithCurrentUser: (state: RootState, memberId: MemberId) => undefined | string = appCreateSelector(membersGetMemberById, member => member?.chatIdWithCurrentUser);