import { SEARCH_MAX_AGE } from '@config/search';
import { SearchReceiveGetMaxAgeReducer } from './types';
export const searchReceiveGetMaxAgeReducer: SearchReceiveGetMaxAgeReducer = (state, action) => {
  const {
    maxAge
  } = action.payload;
  return {
    ...state,
    maxAge: maxAge || SEARCH_MAX_AGE
  };
};