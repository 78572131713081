import { AccountTimezoneId, CULTURE } from '@types';
export const timezoneTranslations: Record<AccountTimezoneId, undefined | Record<CULTURE, string>> = {
  'Africa/Abidjan': {
    [CULTURE.RU]: "Абиджан, Кот-д'Ивуар"
  },
  'Africa/Cairo': {
    [CULTURE.RU]: 'Каир, Египет'
  },
  'Africa/Casablanca': {
    [CULTURE.RU]: 'Касабланка, Марокко'
  },
  'Africa/Johannesburg': {
    [CULTURE.RU]: 'Йоханнесбург, ЮАР'
  },
  'Africa/Juba': {
    [CULTURE.RU]: 'Джуба, Южный Судан'
  },
  'Africa/Khartoum': {
    [CULTURE.RU]: 'Хартум, Судан'
  },
  'Africa/Lagos': {
    [CULTURE.RU]: 'Лагос, Нигерия'
  },
  'Africa/Nairobi': {
    [CULTURE.RU]: 'Найроби, Кения'
  },
  'Africa/Sao_Tome': {
    [CULTURE.RU]: 'Сан-Томе, Сан-Томе и Принсипи'
  },
  'Africa/Tripoli': {
    [CULTURE.RU]: 'Триполи, Ливия'
  },
  'Africa/Windhoek': {
    [CULTURE.RU]: 'Виндхук, Намибия'
  },
  'America/Adak': {
    [CULTURE.RU]: 'Адак, США'
  },
  'America/Anchorage': {
    [CULTURE.RU]: 'Анкоридж, США'
  },
  'America/Araguaina': {
    [CULTURE.RU]: 'Арагуайна, Бразилия'
  },
  'America/Asuncion': {
    [CULTURE.RU]: 'Асунсьон, Парагвай'
  },
  'America/Bahia': {
    [CULTURE.RU]: 'Баия, Бразилия'
  },
  'America/Bogota': {
    [CULTURE.RU]: 'Богота, Колумбия'
  },
  'America/Argentina/Buenos_Aires': {
    [CULTURE.RU]: 'Буэнос-Айрес, Аргентина'
  },
  'America/Cancun': {
    [CULTURE.RU]: 'Канкун, Мексика'
  },
  'America/Caracas': {
    [CULTURE.RU]: 'Каракас, Венесуэла'
  },
  'America/Cayenne': {
    [CULTURE.RU]: 'Кайенна, Французская Гвиана'
  },
  'America/Chicago': {
    [CULTURE.RU]: 'Чикаго, США'
  },
  'America/Chihuahua': {
    [CULTURE.RU]: 'Мехико, Мексика'
  },
  'America/Cuiaba': {
    [CULTURE.RU]: 'Манаус, Бразилия'
  },
  'America/Denver': {
    [CULTURE.RU]: 'Денвер, США'
  },
  'America/Godthab': {
    [CULTURE.RU]: 'Нуук, Гренландия'
  },
  'America/Grand_Turk': {
    [CULTURE.RU]: 'Кокберн-Таун, острова Теркс и Кайкос'
  },
  'America/Guatemala': {
    [CULTURE.RU]: 'Гватемала-Сити, Гватемала'
  },
  'America/Halifax': {
    [CULTURE.RU]: 'Галифакс, Канада'
  },
  'America/Havana': {
    [CULTURE.RU]: 'Гавана, Куба'
  },
  'America/Indiana/Indianapolis': {
    [CULTURE.RU]: 'Нью-Йорк, США'
  },
  'America/La_Paz': {
    [CULTURE.RU]: 'Ла-Пас, Боливия'
  },
  'America/Los_Angeles': {
    [CULTURE.RU]: 'Лос-Анджелес, США'
  },
  'America/Mazatlan': {
    [CULTURE.RU]: 'Мазатлан, Мексика'
  },
  'America/Mexico_City': {
    [CULTURE.RU]: 'Мехико, Мексика'
  },
  'America/Miquelon': {
    [CULTURE.RU]: 'Сен-Пьер, Сен-Пьер и Микелон'
  },
  'America/Montevideo': {
    [CULTURE.RU]: 'Монтевидео, Уругвай'
  },
  'America/New_York': {
    [CULTURE.RU]: 'Нью-Йорк, США'
  },
  'America/Nuuk': {
    [CULTURE.RU]: 'Нуук, Гринландия'
  },
  'America/Phoenix': {
    [CULTURE.RU]: 'Феникс, США'
  },
  'America/Port-au-Prince': {
    [CULTURE.RU]: 'Порт-о-Пренс, Гаити'
  },
  'America/Punta_Arenas': {
    [CULTURE.RU]: 'Пунта-Аренас, Чили'
  },
  'America/Regina': {
    [CULTURE.RU]: 'Саскатун, Канада'
  },
  'America/Santiago': {
    [CULTURE.RU]: 'Сантьяго, Чили'
  },
  'America/Sao_Paulo': {
    [CULTURE.RU]: 'Сан-Паулу, Бразилия'
  },
  'America/St_Johns': {
    [CULTURE.RU]: 'Сент-Джонс, Канада'
  },
  'America/Tijuana': {
    [CULTURE.RU]: 'Тихуана, Мексика'
  },
  'America/Whitehorse': {
    [CULTURE.RU]: 'Уайтхорс, Канада'
  },
  'Asia/Almaty': {
    [CULTURE.RU]: 'Алматы, Казахстан'
  },
  'Asia/Amman': {
    [CULTURE.RU]: 'Амман, Иордания'
  },
  'Asia/Baghdad': {
    [CULTURE.RU]: 'Багдад, Ирак'
  },
  'Asia/Baku': {
    [CULTURE.RU]: 'Баку, Азербайджан'
  },
  'Asia/Bangkok': {
    [CULTURE.RU]: 'Бангкок, Тайланд'
  },
  'Asia/Barnaul': {
    [CULTURE.RU]: 'Барнаул, Россия'
  },
  'Asia/Beirut': {
    [CULTURE.RU]: 'Бейрут, Ливан'
  },
  'Asia/Calcutta': {
    [CULTURE.RU]: 'Мумбаи, Индия'
  },
  'Asia/Chita': {
    [CULTURE.RU]: 'Чита, Россия'
  },
  'Asia/Colombo': {
    [CULTURE.RU]: 'Коломбо, Шри-Ланка'
  },
  'Asia/Damascus': {
    [CULTURE.RU]: 'Алеппо, Сирия'
  },
  'Asia/Dhaka': {
    [CULTURE.RU]: 'Дакка, Бангладеш'
  },
  'Asia/Dubai': {
    [CULTURE.RU]: 'Дубай, Объединенные Арабские Эмираты'
  },
  'Asia/Hebron': {
    [CULTURE.RU]: 'Восточный Иерусалим, палестинская территория'
  },
  'Asia/Hovd': {
    [CULTURE.RU]: 'Улаангом, Монголия'
  },
  'Asia/Irkutsk': {
    [CULTURE.RU]: 'Иркутск, Россия'
  },
  'Asia/Jerusalem': {
    [CULTURE.RU]: 'Иерусалим, Израиль'
  },
  'Asia/Kabul': {
    [CULTURE.RU]: 'Кабул, Афганистан'
  },
  'Asia/Kamchatka': {
    [CULTURE.RU]: 'Петропавловск-Камчатский, Россия'
  },
  'Asia/Karachi': {
    [CULTURE.RU]: 'Карачи, Пакистан'
  },
  'Asia/Kathmandu': {
    [CULTURE.RU]: 'Катманду, Непал'
  },
  'Asia/Kolkata': {
    [CULTURE.RU]: 'Колката, Индия'
  },
  'Asia/Krasnoyarsk': {
    [CULTURE.RU]: 'Красноярск, Россия'
  },
  'Asia/Magadan': {
    [CULTURE.RU]: 'Магадан, Россия'
  },
  'Asia/Novosibirsk': {
    [CULTURE.RU]: 'Новосибирск, Россия'
  },
  'Asia/Omsk': {
    [CULTURE.RU]: 'Омск, Россия'
  },
  'Asia/Pyongyang': {
    [CULTURE.RU]: 'Пхеньян, Северная Корея'
  },
  'Asia/Qyzylorda': {
    [CULTURE.RU]: 'Актобе, Казахстан'
  },
  'Asia/Rangoon': {
    [CULTURE.RU]: 'Янгон, Мьянма'
  },
  'Asia/Riyadh': {
    [CULTURE.RU]: 'Джидда, Саудовская Аравия'
  },
  'Asia/Sakhalin': {
    [CULTURE.RU]: 'Южно-Сахалинск, Россия'
  },
  'Asia/Seoul': {
    [CULTURE.RU]: 'Сеул, Южная Корея'
  },
  'Asia/Shanghai': {
    [CULTURE.RU]: 'Шанхай, Китай'
  },
  'Asia/Singapore': {
    [CULTURE.RU]: 'Сингапур, Сингапур'
  },
  'Asia/Srednekolymsk': {
    [CULTURE.RU]: 'Среднеколымск, Россия'
  },
  'Asia/Taipei': {
    [CULTURE.RU]: 'Тайбэй, Тайвань'
  },
  'Asia/Tashkent': {
    [CULTURE.RU]: 'Ташкент, Узбекистан'
  },
  'Asia/Tbilisi': {
    [CULTURE.RU]: 'Тбилиси, Грузия'
  },
  'Asia/Tehran': {
    [CULTURE.RU]: 'Тегеран, Иран'
  },
  'Asia/Tokyo': {
    [CULTURE.RU]: 'Токио, Япония'
  },
  'Asia/Tomsk': {
    [CULTURE.RU]: 'Томск, Россия'
  },
  'Asia/Ulaanbaatar': {
    [CULTURE.RU]: 'Улан-Батор, Монголия'
  },
  'Asia/Vladivostok': {
    [CULTURE.RU]: 'Владивосток, Россия'
  },
  'Asia/Yangon': {
    [CULTURE.RU]: 'Янгоун, Мьянма'
  },
  'Asia/Yakutsk': {
    [CULTURE.RU]: 'Якутск, Россия'
  },
  'Asia/Yekaterinburg': {
    [CULTURE.RU]: 'Екатеринбург, Россия'
  },
  'Asia/Yerevan': {
    [CULTURE.RU]: 'Ереван, Армения'
  },
  'Atlantic/Azores': {
    [CULTURE.RU]: 'Понта Делгада, Португалия'
  },
  'Atlantic/Cape_Verde': {
    [CULTURE.RU]: 'Прайя, Кабо-Верде'
  },
  'Atlantic/Reykjavik': {
    [CULTURE.RU]: 'Рейкьявик, Исландия'
  },
  'Australia/Adelaide': {
    [CULTURE.RU]: 'Аделаида, Австралия'
  },
  'Australia/Brisbane': {
    [CULTURE.RU]: 'Брисбен, Австралия'
  },
  'Australia/Darwin': {
    [CULTURE.RU]: 'Дарвин, Австралия'
  },
  'Australia/Eucla': {
    [CULTURE.RU]: 'Эвкла, Австралия'
  },
  'Australia/Hobart': {
    [CULTURE.RU]: 'Хобарт, Австралия'
  },
  'Australia/Lord_Howe': {
    [CULTURE.RU]: 'Лорд Хоу, Австралия'
  },
  'Australia/Perth': {
    [CULTURE.RU]: 'Перт, Австралия'
  },
  'Australia/Sydney': {
    [CULTURE.RU]: 'Сидней, Австралия'
  },
  'Etc/GMT+2': {
    [CULTURE.RU]: 'Etc/GMT-2'
  },
  'Etc/GMT+8': {
    [CULTURE.RU]: 'Etc/GMT-8'
  },
  'Etc/GMT+9': {
    [CULTURE.RU]: 'Etc/GMT-9'
  },
  'Etc/GMT+11': {
    [CULTURE.RU]: 'Etc/GMT-11'
  },
  'Etc/GMT+12': {
    [CULTURE.RU]: 'Etc/GMT-12'
  },
  'Etc/GMT-12': {
    [CULTURE.RU]: 'Etc/GMT+12'
  },
  'Etc/GMT-13': {
    [CULTURE.RU]: 'Etc/GMT+13'
  },
  'Etc/UTC': {
    [CULTURE.RU]: 'Etc/UTC'
  },
  'Europe/Astrakhan': {
    [CULTURE.RU]: 'Астрахань, Россия'
  },
  'Europe/Berlin': {
    [CULTURE.RU]: 'Берлин, Германия'
  },
  'Europe/Bucharest': {
    [CULTURE.RU]: 'Бухарест, Румыния'
  },
  'Europe/Budapest': {
    [CULTURE.RU]: 'Будапешт, Венгрия'
  },
  'Europe/Chisinau': {
    [CULTURE.RU]: 'Кишинев, Молдова'
  },
  'Europe/Istanbul': {
    [CULTURE.RU]: 'Стамбул, Турция'
  },
  'Europe/Kaliningrad': {
    [CULTURE.RU]: 'Калининград, Россия'
  },
  'Europe/Kyiv': {
    [CULTURE.RU]: 'Киев, Украина'
  },
  'Europe/London': {
    [CULTURE.RU]: 'Лондон, Великобритания'
  },
  'Europe/Minsk': {
    [CULTURE.RU]: 'Минск, Беларусь'
  },
  'Europe/Moscow': {
    [CULTURE.RU]: 'Москва, Россия'
  },
  'Europe/Paris': {
    [CULTURE.RU]: 'Париж, Франция'
  },
  'Europe/Samara': {
    [CULTURE.RU]: 'Самара, Россия'
  },
  'Europe/Saratov': {
    [CULTURE.RU]: 'Саратов, Россия'
  },
  'Europe/Volgograd': {
    [CULTURE.RU]: 'Волгоград, Россия'
  },
  'Europe/Warsaw': {
    [CULTURE.RU]: 'Варшава, Польша'
  },
  'Indian/Mauritius': {
    [CULTURE.RU]: 'Порт-Луи, Маврикий'
  },
  'Pacific/Apia': {
    [CULTURE.RU]: 'Апиа, Самоа'
  },
  'Pacific/Auckland': {
    [CULTURE.RU]: 'Окленд, Новая Зеландия'
  },
  'Pacific/Bougainville': {
    [CULTURE.RU]: 'Арава, Папуа-Новая Гвинея'
  },
  'Pacific/Chatham': {
    [CULTURE.RU]: 'Чатем, Новая Зеландия'
  },
  'Pacific/Easter': {
    [CULTURE.RU]: 'Пасха, Чили'
  },
  'Pacific/Fiji': {
    [CULTURE.RU]: 'Насину, Фиджи'
  },
  'Pacific/Guadalcanal': {
    [CULTURE.RU]: 'Хониара, Соломоновы острова'
  },
  'Pacific/Honolulu': {
    [CULTURE.RU]: 'Гонолулу, США'
  },
  'Pacific/Kiritimati': {
    [CULTURE.RU]: 'Киритимати, Кирибат'
  },
  'Pacific/Marquesas': {
    [CULTURE.RU]: 'Маркизские острова, Французская Полинезия'
  },
  'Pacific/Norfolk': {
    [CULTURE.RU]: 'Кингстон, остров Норфолк'
  },
  'Pacific/Port_Moresby': {
    [CULTURE.RU]: 'Порт-Морсби, Папуа-Новая Гвинея'
  },
  'Pacific/Tongatapu': {
    [CULTURE.RU]: 'Нукуалофа, Тонга'
  }
};