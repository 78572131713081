import { omit } from '@helpers/data';
import { enforceDateStringToISO } from '@helpers/date';
import { SpecialistsGetMeResponseData, SpecialistsGetMeResponseDto } from '@services/dto/specialists';
import { documentGetResponseFromDto } from '@mapping/documents';
import { photoGetResponseFromDto } from '@mapping/photos';
import { mediaGetResponseFromDto } from '@mapping/videos';
export const specialistsGetMeResponseFromDto = (dto: SpecialistsGetMeResponseDto): SpecialistsGetMeResponseData => {
  return omit({
    ...dto.data,
    birthDate: dto.data.birthDate ? enforceDateStringToISO(dto.data.birthDate) : undefined,
    documents: dto.data.files.map(file => documentGetResponseFromDto({
      data: file
    })),
    media: [...(dto.data.media ? dto.data.media.map(file => mediaGetResponseFromDto({
      data: file
    })) : []), ...dto.data.photos.map(file => photoGetResponseFromDto({
      data: file
    }))]
  }, 'files', 'photos');
};