import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { noop, wait } from '@helpers/misc';
import { REALTIME_HUB_METHOD_NAME } from '@types';
import { IRealtime, IRealtimeConstructor, IRealtimeConstructorArgs, RealtimeConnection, RealtimeOff, RealtimeOnCallback, RealtimeOnDisconnected, RealtimeOnReconnected, RealtimeOnReconnecting, RealtimeOnStarted, RealtimeState } from './types';
export const Realtime: IRealtimeConstructor = class Realtime implements IRealtime {
  private _restartAttempts = 0;
  private _maxRestartAttempts = 0;
  private _restartAttemptsTimeout = 0;
  private _maxReconnectAttempts = 0;
  private _reconnectAttemptsTimeout = 0;
  private readonly _connection: undefined | RealtimeConnection;
  private readonly _onStarted: undefined | RealtimeOnStarted;
  private readonly _onReconnecting: undefined | RealtimeOnReconnecting;
  private readonly _onReconnected: undefined | RealtimeOnReconnected;
  private readonly _onDisconnected: undefined | RealtimeOnDisconnected;
  constructor(args: IRealtimeConstructorArgs) {
    const {
      url,
      accessTokenFactory,
      options,
      onStarted,
      onReconnecting,
      onReconnected,
      onDisconnected
    } = args;
    const {
      isDevelopment,
      maxRestartAttempts = 0,
      restartAttemptsTimeout = 0,
      maxReconnectAttempts = 0,
      reconnectAttemptsTimeout = 0
    } = options ?? {};
    this._maxRestartAttempts = maxRestartAttempts;
    this._restartAttemptsTimeout = restartAttemptsTimeout;
    this._maxReconnectAttempts = maxReconnectAttempts;
    this._reconnectAttemptsTimeout = reconnectAttemptsTimeout;
    this._onStarted = onStarted;
    this._onReconnecting = onReconnecting;
    this._onReconnected = onReconnected;
    this._onDisconnected = onDisconnected;
    try {
      this._connection = new HubConnectionBuilder().withUrl(url, {
        accessTokenFactory,
        logMessageContent: isDevelopment,
        logger: isDevelopment //
        ? LogLevel.Warning : LogLevel.Error
      }).configureLogging(LogLevel.Information).withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          return retryContext.previousRetryCount < this._maxReconnectAttempts ? this._reconnectAttemptsTimeout : null;
        }
      }).build();
    } catch (error) {
      console.error('Realtime not initialized:', error);
      return;
    }
    this._connection.onclose((): void => {
      console.info('Realtime closed');
      if (this._onDisconnected) {
        this._onDisconnected();
      }
      if (this._connection?.state === HubConnectionState.Disconnected) {
        return;
      }
      this.start();
    });
    if (this._onReconnecting) {
      this._connection.onreconnecting(this._onReconnecting);
    }
    if (this._onReconnected) {
      this._connection.onreconnected(this._onReconnected);
    }
  }
  get state(): RealtimeState {
    return this._connection?.state ?? HubConnectionState.Disconnected;
  }
  async start(): Promise<void> {
    if (!this._connection) {
      console.error('Realtime not initialized');
      return;
    }
    try {
      console.info('Realtime starting');
      await this._connection.start();
      console.info('Realtime started');
      this._restartAttempts = 0;
      this._onStarted?.();
    } catch (error) {
      console.info('Realtime not started:', error);
      if (this._restartAttempts > this._maxRestartAttempts) {
        return;
      }
      this._restartAttempts++;
      await wait(this._restartAttemptsTimeout);
      await this.start();
    }
  }
  async stop(): Promise<void> {
    console.info('Realtime stopping');
    if (!this._connection) {
      console.error('Realtime not initialized');
      return;
    }
    await this._connection.stop();
    console.info('Realtime stopped');
  }
  on(hubMethodName: REALTIME_HUB_METHOD_NAME, callback: RealtimeOnCallback): RealtimeOff {
    if (!this._connection) {
      console.error('Realtime not initialized');
      return noop;
    }
    this._connection.on(hubMethodName, callback);
    const off = () => {
      if (!this._connection) {
        console.error('Realtime not initialized');
        return;
      }
      this._connection.off(hubMethodName, callback);
    };
    return off;
  }
  invoke<P>(hubMethodName: REALTIME_HUB_METHOD_NAME, payload: P): void {
    if (!this._connection) {
      console.error('Realtime not initialized');
      return;
    }
    this._connection.invoke(hubMethodName, payload);
  }
};