import { NOTIFICATION_TYPE } from '@config/notifications';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimeCalendarCoinsRefundEventPayloadData } from '@services/dto/realtime/realtime-calendar-event/realtime-calendar-coins-refund';
import { notificationsUiHandleAdd } from '@actions/notifications-ui/notifications-ui-handle-add';
import { RealtimeCalendarCoinsRefund } from './types';
export const realtimeCalendarCoinsRefund: RealtimeCalendarCoinsRefund = event => {
  return dispatch => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeCalendarCoinsRefundEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      specialistUserName,
      photoUrl,
      coinsAmount
    } = data;
    dispatch(notificationsUiHandleAdd({
      type: NOTIFICATION_TYPE.NEW_REFUND,
      avatar: photoUrl,
      name: specialistUserName,
      coinsAmount
    }));
  };
};