import { AVATAR_ID } from './avatars';
import { MemberId, MemberRating, ONLINE_STATUS, PROF_SPECIALITY, SEX } from './member';
import { SubjectId } from './subjects';
export enum INVITATION_STATE {
  NEW = 1,
  ACCEPTED = 2,
  REJECTED = 3,
  READ = 4,
}
export type InvitationProfile = {
  id: MemberId;
  name: string;
  sex: SEX;
  age: number;
  avatarId?: AVATAR_ID;
  photoUrl?: string;
  isSpecialist: boolean;
  rating: MemberRating;
  profSpeciality?: PROF_SPECIALITY;
  isCertified?: boolean;
  onlineStatus: ONLINE_STATUS;
  subjectIds: SubjectId[];
  lastActivityTime?: string;
};
export type InvitationId = number;
export type Invitation = {
  id: InvitationId;
  initiatorUserId: MemberId;
  creationTime: string;
  state: INVITATION_STATE;
  profile: InvitationProfile;
};
export type Invitations = Invitation[];
export type InvitationsList = {
  items: Invitations;
  offset: number;
  limit: number;
  hasMore: boolean;
  totalCount: number;
  dataLoaded: boolean;
};