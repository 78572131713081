import { NotificationsUiNotifications, NotificationUiId } from '@types';
import { appCreateSelector, RootState } from '@store';
import { NotificationsUiById, NotificationsUiState } from '@slices/notifications-ui';

/**
 * regular selectors
 */

export const notificationsUiGet = (state: RootState): NotificationsUiState => {
  return state.ui.notificationsUi;
};

/**
 * memoized reselect selectors
 */

export const notificationsUiGetById: (state: RootState) => NotificationsUiById = appCreateSelector([notificationsUiGet], notificationsUi => notificationsUi.byId);
export const notificationsUiGetIds: (state: RootState) => NotificationUiId[] = appCreateSelector([notificationsUiGet], notificationsUi => notificationsUi.ids);
export const notificationsUiGetList: (state: RootState) => NotificationsUiNotifications = appCreateSelector([notificationsUiGetIds, notificationsUiGetById], (ids, byId) => {
  return ids.map(id => byId[id]);
});