import { AVATAR_ID, ChatId, Documents, MediaFiles, MemberId, MemberProfileState, MemberRating, ONLINE_STATUS, PhotoId, PROF_SPECIALITY, ROLE_TYPE, SEX, SubjectId, SubjectIds } from '@types';
export type CurrentSpecialist = {
  roleType: ROLE_TYPE;
  name: string;
  birthDate: undefined | string;
  sex: undefined | SEX;
  aboutSelf: undefined | string;
  methodicDescription: undefined | string;
  defaultSubjectId: undefined | SubjectId;
  subjectIds: SubjectIds;
  avatarId?: AVATAR_ID;
  defaultPhotoId: undefined | PhotoId;
  media: MediaFiles;
  documents: Documents;
  state: MemberProfileState;
  isCertified: boolean;
  profSpeciality: PROF_SPECIALITY;
  rating: MemberRating;
};
export type Specialist = {
  roleType: ROLE_TYPE;
  name: string;
  birthDate: undefined | string;
  sex: undefined | SEX;
  aboutSelf: undefined | string;
  methodicDescription: undefined | string;
  defaultSubjectId: undefined | SubjectId;
  subjectIds: SubjectIds;
  defaultPhotoId: undefined | PhotoId;
  media: MediaFiles;
  documents: Documents;
  isCertified: boolean;
  profSpeciality: PROF_SPECIALITY;
  rating: MemberRating;
  onlineStatus: ONLINE_STATUS;
  lastActivityTime: undefined | string;
  // Fields below are returned when authorized request only
  invitationAllowed?: boolean;
  invitationRequested?: boolean;
  isFavoriteUser?: boolean;
  chatIdWithCurrentUser?: ChatId;
  inBlackList?: boolean;
  hasComplaint?: boolean;
  hasComplaintAgainstMe?: boolean;
};
export type SpecialistsById = Record<MemberId, Specialist>;
export type SpecialistBalance = {
  amount: number;
};
export enum STATISTICS_PERIOD_TYPE {
  TODAY = 1,
  CURRENT_MONTH = 2,
}
export type SpecialistStatistics = {
  amount: number;
  statisticPeriodType: STATISTICS_PERIOD_TYPE;
};
export type SpecialistStatisticsByPediodType = { [key in STATISTICS_PERIOD_TYPE]?: SpecialistStatistics };
export enum SPECIALIST_WALLET_TYPE {
  UKR_BANK_CARD = 1,
  RUS_BANK_CARD = 2,
  // WEBMONEY = 3,
  QIWI = 4,
}
export type SpecialistWalletId = number;
export type SpecialistWallet = {
  id: SpecialistWalletId;
  type: SPECIALIST_WALLET_TYPE;
  number: string;
  ownerName?: string;
};
export type SpecialistWallets = SpecialistWallet[];