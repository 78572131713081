import { ERROR_CODE } from '@config/errors';
import { REGEX } from '@config/regex';
import { UrlIsNotAllowedValidator } from './types';
export const urlIsNotAllowedValidator: UrlIsNotAllowedValidator = (value, options) => {
  if (value == null) {
    return;
  }
  if (value.match(REGEX.CONTAINS_URL)) {
    return options?.message ?? ERROR_CODE.FIELD_IS_INVALID;
  }
};