import { CurrentSpecialistReceiveUploadDocumentReducer } from './types';
export const currentSpecialistReceiveUploadDocumentReducer: CurrentSpecialistReceiveUploadDocumentReducer = (state, action) => {
  if (!state.me) {
    return state;
  }
  const {
    document
  } = action.payload;
  return {
    ...state,
    me: {
      ...state.me,
      documents: [...state.me.documents, document]
    }
  };
};