import { SpecialistsSetChatIdWithCurrentUserReducer } from './types';
export const specialistsSetChatIdWithCurrentUserReducer: SpecialistsSetChatIdWithCurrentUserReducer = (state, action) => {
  const {
    specialistId,
    chatIdWithCurrentUser
  } = action.payload;
  return {
    ...state,
    specialistsById: {
      ...state.specialistsById,
      [specialistId]: {
        ...state.specialistsById[specialistId],
        chatIdWithCurrentUser
      }
    }
  };
};