import { ClientsReceiveOnlineStatusByIdReducer } from './types';
export const clientsReceiveOnlineStatusByIdReducer: ClientsReceiveOnlineStatusByIdReducer = (state, action) => {
  const {
    clientId,
    onlineStatus
  } = action.payload;
  return {
    ...state,
    clientsById: {
      ...state.clientsById,
      [clientId]: {
        ...state.clientsById[clientId],
        onlineStatus
      }
    }
  };
};