import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { ClientsGetBalanceResponseData, ClientsGetBalanceResponseDto } from '@services/dto/clients';
import { clientsGetBalanceResponseFromDto } from '@mapping/clients';
type ClientsGetBalance = () => AuthedJsonFetchResult<ClientsGetBalanceResponseData>;
export const clientsGetBalance: ClientsGetBalance = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<ClientsGetBalanceResponseDto>(ENDPOINTS.clients.getBalance(), {
    method: 'GET'
  });
  return {
    promise: promise.then(clientsGetBalanceResponseFromDto),
    cancel
  };
};