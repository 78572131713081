import { appCreateSlice } from '@store/rtk';
import { clientsReceiveAddToFavoritesReducer } from '@slices/clients/clients-receive-add-to-favorites';
import { clientsReceiveGetByIdReducer } from '@slices/clients/clients-receive-get-by-id';
import { clientsReceiveInviteMemberReducer } from '@slices/clients/clients-receive-invite-member';
import { clientsReceiveOnlineStatusByIdReducer } from '@slices/clients/clients-receive-online-status-by-id';
import { clientsReceiveRemoveFromFavoritesReducer } from '@slices/clients/clients-receive-remove-from-favorites';
import { clientsReceiveSendComplaintReducer } from '@slices/clients/clients-receive-send-complaint';
import { clientsSetChatIdWithCurrentUserReducer } from '@slices/clients/clients-set-chat-id-with-current-user';
import { clientsSetInvitationAllowedReducer } from '@slices/clients/clients-set-invitation-allowed';
import { ClientsState } from './types';
export const clientsDefaultState: ClientsState = {
  clientsById: {}
};
export const clientsSlice = appCreateSlice({
  name: 'clients',
  initialState: clientsDefaultState,
  reducers: {
    clientsReceiveAddToFavorites: clientsReceiveAddToFavoritesReducer,
    clientsReceiveGetById: clientsReceiveGetByIdReducer,
    clientsReceiveInviteMember: clientsReceiveInviteMemberReducer,
    clientsReceiveOnlineStatusById: clientsReceiveOnlineStatusByIdReducer,
    clientsReceiveRemoveFromFavorites: clientsReceiveRemoveFromFavoritesReducer,
    clientsReceiveSendComplaint: clientsReceiveSendComplaintReducer,
    clientsSetChatIdWithCurrentUser: clientsSetChatIdWithCurrentUserReducer,
    clientsSetInvitationAllowed: clientsSetInvitationAllowedReducer
  }
});
export const {
  actions: {
    clientsReceiveAddToFavorites,
    clientsReceiveGetById,
    clientsReceiveInviteMember,
    clientsReceiveOnlineStatusById,
    clientsReceiveRemoveFromFavorites,
    clientsReceiveSendComplaint,
    clientsSetChatIdWithCurrentUser,
    clientsSetInvitationAllowed
  },
  reducer: clientsReducer
} = clientsSlice;