import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SpecialistsGetBalanceResponseData, SpecialistsGetBalanceResponseDto } from '@services/dto/specialists';
import { specialistsGetBalanceResponseFromDto } from '@mapping/specialists';
type SpecialistsGetBalance = () => AuthedJsonFetchResult<SpecialistsGetBalanceResponseData>;
export const specialistsGetBalance: SpecialistsGetBalance = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SpecialistsGetBalanceResponseDto>(ENDPOINTS.specialists.getBalance(), {
    method: 'GET'
  });
  return {
    promise: promise.then(specialistsGetBalanceResponseFromDto),
    cancel
  };
};