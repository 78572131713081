import { priceToDto } from '@helpers/payments';
import { SpecialistsWithdrawFundsRequestData, SpecialistsWithdrawFundsRequestDto, SpecialistsWithdrawFundsResponseData, SpecialistsWithdrawFundsResponseDto } from '@services/dto/specialists';
export const specialistsWithdrawFundsRequestToDto = (data: SpecialistsWithdrawFundsRequestData): SpecialistsWithdrawFundsRequestDto => {
  return {
    walletId: data.walletId,
    amount: priceToDto(data.amount)
  };
};
export const specialistsWithdrawFundsResponseFromDto = (dto: SpecialistsWithdrawFundsResponseDto): SpecialistsWithdrawFundsResponseData => {
  return dto.data;
};