import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { ClientsGetMeResponseData, ClientsGetMeResponseDto } from '@services/dto/clients';
import { clientsGetMeResponseFromDto } from '@mapping/clients';
type ClientsGetMe = () => AuthedJsonFetchResult<ClientsGetMeResponseData>;
export const clientsGetMe: ClientsGetMe = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<ClientsGetMeResponseDto>(ENDPOINTS.clients.me(), {
    method: 'GET'
  });
  return {
    promise: promise.then(clientsGetMeResponseFromDto),
    cancel
  };
};