export type AuthToken = {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
  refreshToken: string;
  scope: string;
};
export type AuthDecodedToken = {
  exp: number;
  user_id: string;
  role_type: string;
  email: string;
  email_confirmed: string;
  name: string;
  culture: string;
  timezone_id: string;
  timezone_offset: string;
};
export enum PROVIDER {
  FB = 'Facebook',
  VK = 'Vkontakte',
  GOOGLE = 'Google',
}