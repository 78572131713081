import { IPaymentsPayWindow } from '@payments/payments-pay-window';
import { INav } from '@routing/nav';
import { ILocalStorage } from '@storage';
import { RealtimeEvent } from '@services/dto/realtime/realtime-event';
import { REALTIME_EVENT_TYPE } from '@types';
import { RootDispatch } from '@store';
import { accountFetchLogoff } from '@actions/account/account-fetch-logoff';
import { realtimeAuthLogout } from '@actions/realtime/realtime-auth-logout';
import { realtimeBalanceChanged } from '@actions/realtime/realtime-balance-changed';
import { realtimeCalendarCoinsRefund } from '@actions/realtime/realtime-calendar/realtime-calendar-coins-refund';
import { realtimeCalendarConsultationApproved } from '@actions/realtime/realtime-calendar/realtime-calendar-consultation-approved';
import { realtimeCalendarConsultationCreated } from '@actions/realtime/realtime-calendar/realtime-calendar-consultation-created';
import { realtimeCalendarConsultationDone } from '@actions/realtime/realtime-calendar/realtime-calendar-consultation-done';
import { realtimeCalendarConsultationRejected } from '@actions/realtime/realtime-calendar/realtime-calendar-consultation-rejected';
import { realtimeChatComplimentReceived } from '@actions/realtime/realtime-chat/realtime-chat-compliment-received';
import { realtimeChatHasBeenRead } from '@actions/realtime/realtime-chat/realtime-chat-has-been-read';
import { realtimeChatIncomingMediaCallReceived } from '@actions/realtime/realtime-chat/realtime-chat-incoming-media-call-received';
import { realtimeChatMediaCallAcceptedAlready } from '@actions/realtime/realtime-chat/realtime-chat-media-call-accepted-already';
import { realtimeChatMediaCallClientNotEnoughMoneyCallEnd } from '@actions/realtime/realtime-chat/realtime-chat-media-call-client-not-enough-money-call-end';
import { realtimeChatMediaCallEnded } from '@actions/realtime/realtime-chat/realtime-chat-media-call-ended';
import { realtimeChatMediaCallHasBeenRejected } from '@actions/realtime/realtime-chat/realtime-chat-media-call-has-been-rejected/realtime-chat-media-call-has-been-rejected';
import { realtimeChatMessageReceived } from '@actions/realtime/realtime-chat/realtime-chat-message-received';
import { realtimeChatPrepaymentBalanceChanged } from '@actions/realtime/realtime-chat/realtime-chat-prepayment-balance-changed';
import { realtimeInvitationReceived } from '@actions/realtime/realtime-invitation/realtime-invitation-received';
import { realtimeInvitationsReadAll } from '@actions/realtime/realtime-invitation/realtime-invitations-read-all';
import { realtimeMemberAssessRating } from '@actions/realtime/realtime-member-assess-rating';
import { realtimePaymentIsFailure } from '@actions/realtime/realtime-payment/realtime-payment-is-failure';
import { realtimePaymentIsSuccessful } from '@actions/realtime/realtime-payment/realtime-payment-is-successful';
import { realtimeSupportMessageReceived } from '@actions/realtime/realtime-support-message-received';
import { realtimeVideoUpload } from '@actions/realtime/realtime-video-upload';
import { realtimeViewReceived } from '@actions/realtime/realtime-view/realtime-view-received';
import { realtimeViewsReadAll } from '@actions/realtime/realtime-view/realtime-views-read-all';
import { IRealtimeEventHandler, IRealtimeEventHandlerConstructor, IRealtimeEventHandlerConstructorArgs, IRealtimeEventHandlerReceiveEvent } from './types';
export const RealtimeEventHandler: IRealtimeEventHandlerConstructor = class RealtimeEventHandler implements IRealtimeEventHandler {
  private readonly _storage: ILocalStorage;
  private readonly _navigator: INav;
  private readonly _dispatch: RootDispatch;
  private readonly _paymentsPayWindow: IPaymentsPayWindow;
  constructor(args: IRealtimeEventHandlerConstructorArgs) {
    const {
      storage,
      navigator,
      dispatch,
      paymentsPayWindow
    } = args;
    this._navigator = navigator;
    this._dispatch = dispatch;
    this._storage = storage;
    this._paymentsPayWindow = paymentsPayWindow;
  }
  private readonly _handleChatEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.CHAT_MESSAGE_RECEIVED:
        this._dispatch(realtimeChatMessageReceived(event));
        break;
      case REALTIME_EVENT_TYPE.CHAT_HAS_BEEN_READ:
        this._dispatch(realtimeChatHasBeenRead(event));
        break;
      case REALTIME_EVENT_TYPE.CHAT_INCOMING_MEDIA_CALL_RECEIVED:
        this._dispatch(realtimeChatIncomingMediaCallReceived(event));
        break;
      case REALTIME_EVENT_TYPE.CHAT_COMPLIMENT_RECEIVED:
        this._dispatch(realtimeChatComplimentReceived(event));
        break;
      case REALTIME_EVENT_TYPE.CHAT_MEDIA_CALL_HAS_BEEN_REJECTED:
        this._dispatch(realtimeChatMediaCallHasBeenRejected(event));
        break;
      case REALTIME_EVENT_TYPE.CHAT_MEDIA_CALL_ENDED:
        this._dispatch(realtimeChatMediaCallEnded(event));
        break;
      case REALTIME_EVENT_TYPE.CALL_ACCEPTED_ALREADY:
        this._dispatch(realtimeChatMediaCallAcceptedAlready(event));
        break;
      case REALTIME_EVENT_TYPE.CLIENT_NOT_ENOUGH_MONEY_CALL_END:
        this._dispatch(realtimeChatMediaCallClientNotEnoughMoneyCallEnd(event));
        break;
      case REALTIME_EVENT_TYPE.CHAT_PREPAYMENT_BALANCE_CHANGED:
        this._dispatch(realtimeChatPrepaymentBalanceChanged(event));
        break;
      case REALTIME_EVENT_TYPE.USER_LOGOUT:
        this._dispatch(accountFetchLogoff({
          storage: this._storage,
          navigator: this._navigator
        }));
        break;
      default:
    }
  };
  private readonly _handlePaymentEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.PAYMENT_IS_SUCCESSFUL:
        this._dispatch(realtimePaymentIsSuccessful(event,
        //
        {
          paymentsPayWindow: this._paymentsPayWindow
        }));
        break;
      case REALTIME_EVENT_TYPE.PAYMENT_IS_FAILURE:
        this._dispatch(realtimePaymentIsFailure(event,
        //
        {
          paymentsPayWindow: this._paymentsPayWindow
        }));
        break;
      default:
    }
  };
  private readonly _handleInvitationEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.INVITATION_RECEIVED:
        this._dispatch(realtimeInvitationReceived(event));
        break;
      case REALTIME_EVENT_TYPE.INVITATIONS_READ_ALL:
        this._dispatch(realtimeInvitationsReadAll());
        break;
      default:
    }
  };
  private readonly _handleViewEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.VIEW_RECEIVED:
        this._dispatch(realtimeViewReceived(event));
        break;
      case REALTIME_EVENT_TYPE.VIEWS_READ_ALL:
        this._dispatch(realtimeViewsReadAll());
        break;
      default:
    }
  };
  private readonly _handleCalendarConsultationEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.CALENDAR_CONSULTATION_CREATED:
        this._dispatch(realtimeCalendarConsultationCreated(event));
        break;
      case REALTIME_EVENT_TYPE.CALENDAR_CONSULTATION_APPROVED:
        this._dispatch(realtimeCalendarConsultationApproved(event));
        break;
      case REALTIME_EVENT_TYPE.CALENDAR_CONSULTATION_REJECTED:
        this._dispatch(realtimeCalendarConsultationRejected(event));
        break;
      case REALTIME_EVENT_TYPE.CALENDAR_CONSULTATION_DONE:
        this._dispatch(realtimeCalendarConsultationDone(event));
        break;
      case REALTIME_EVENT_TYPE.CALENDAR_COINS_REFUND:
        this._dispatch(realtimeCalendarCoinsRefund(event));
        break;
      default:
    }
  };
  private readonly _handleBalanceEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.BALANCE_CHANGED:
        this._dispatch(realtimeBalanceChanged(event));
        break;
      default:
    }
  };
  private readonly _handleMemberEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.MEMBER_ASSESS_RATING:
        this._dispatch(realtimeMemberAssessRating(event));
        break;
      default:
    }
  };
  private readonly _handleSupportEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.SUPPORT_MESSAGE_RECEIVED:
        this._dispatch(realtimeSupportMessageReceived(event));
        break;
      default:
    }
  };
  private readonly _handleVideoUploadEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.VIDEO_UPLOAD_COMPLETED:
        this._dispatch(realtimeVideoUpload(event));
        break;
      default:
    }
  };
  private readonly _handleAuthEvent = (event: RealtimeEvent) => {
    switch (event.type) {
      case REALTIME_EVENT_TYPE.USER_LOGOUT:
        this._dispatch(realtimeAuthLogout(event));
        break;
      default:
    }
  };
  public readonly receiveEvent: IRealtimeEventHandlerReceiveEvent = event => {
    /**
     * @todo
     * Reconsider the type assertion below
     */
    const _event = (event as RealtimeEvent);
    console.info('Received realtime event: ', event);
    this._handleChatEvent(_event);
    this._handlePaymentEvent(_event);
    this._handleInvitationEvent(_event);
    this._handleViewEvent(_event);
    this._handleCalendarConsultationEvent(_event);
    this._handleBalanceEvent(_event);
    this._handleMemberEvent(_event);
    this._handleSupportEvent(_event);
    this._handleVideoUploadEvent(_event);
    this._handleAuthEvent(_event);
  };
};