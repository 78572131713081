import { useCallback, useEffect, useMemo, useState } from 'react';
import { GEOMETRY_RESIZE_TIMEOUT } from '@config/geometry';
import { defineBrowserName, defineOS } from '@helpers/geometry';
import { AppFC } from '@types';
import { geometryResize } from '@slices/geometry';
import { useOnResize, UseOnResizeOnResize, UseOnResizeOptions } from '@hooks/geometry';
import { useTypedDispatch } from '@hooks/store';
export const GeometryOnResizeManager: AppFC = () => {
  const dispatch = useTypedDispatch();
  const [osName, setOSName] = useState<string>();
  const [browserName, setBrowserName] = useState<string>();
  useEffect(() => {
    /**
     * We define OS and Browser names inside useEffect to prevent running of
     * the code on server.
     */
    setOSName(defineOS(window.navigator.userAgent));
    setBrowserName(defineBrowserName(window.navigator.userAgent));
  }, []);

  /**
   * We set 500ms timeout and leading: false to correctly handle
   * the resize event on device rotation in Chrome on iOS:
   * https://stackoverflow.com/questions/73493417/ios-chrome-yields-incorrect-window-innerwidth-and-innerheight
   */
  const useOnResizeOptions: UseOnResizeOptions = useMemo(() => {
    const isIOSChrome = /ios/gi.test(osName ?? '') &&
    //
    /chrome/gi.test(browserName ?? '');
    return {
      wait: isIOSChrome ? 500 : GEOMETRY_RESIZE_TIMEOUT,
      leading: isIOSChrome ? false : true
    };
  }, [browserName, osName]);
  const horizontalResize: UseOnResizeOnResize = useCallback(width => {
    dispatch(geometryResize({
      width
    }));
  }, [dispatch]);
  useOnResize(horizontalResize, {
    horizontalOnly: true
  });
  useOnResize(horizontalResize, {
    horizontalOnly: true,
    ...useOnResizeOptions
  });
  const setAppHeight = useCallback(() => {
    if (/chrome/gi.test(browserName ?? '')) {
      document.documentElement.style.setProperty('--app-height', `${document.documentElement.offsetHeight}px`);
      return;
    }
    if (/firefox/gi.test(browserName ?? '')) {
      document.documentElement.style.setProperty('--app-height', `${document.documentElement.offsetHeight}px`);
      return;
    }
    if (/safari/gi.test(browserName ?? '')) {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
      return;
    }
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
  }, [browserName]);
  useOnResize(setAppHeight, {
    verticalOnly: true,
    ...useOnResizeOptions
  });
  return null;
};