import { ENDPOINTS } from '@config/endpoints';
import { HTTP_CONTENT_TYPE, HTTP_HEADER_NAME } from '@config/net';
import { encodeQuery } from '@helpers/routing';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { AnalyticsSendEventRequestData, AnalyticsSendEventResponseData, AnalyticsSendEventResponseDto } from '@services/dto/analytics/analytics-send-event';
import { analyticsSendEventRequestToDto } from '@mapping/analytics';
type AnalyticsSendEvent = (requestData: AnalyticsSendEventRequestData) => AuthedJsonFetchResult<AnalyticsSendEventResponseData>;
const headers = {
  [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE.X_WWW_FORM_URLENCODED
};
type AnalyticsSendEventHeaders = {
  [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE;
};
export const analyticsSendEvent: AnalyticsSendEvent = requestData => {
  return authedJsonFetch<AnalyticsSendEventResponseDto, string, undefined, AnalyticsSendEventHeaders>(ENDPOINTS.analytics.sendEvent(requestData.eventType), {
    method: 'POST',
    data: encodeQuery(analyticsSendEventRequestToDto(requestData)),
    headers
  });
};