import { keyById, mapById, uniq } from '@helpers/data';
import { MessagesIds } from '@slices/messages';
import { MessagesReceiveGetListReducer } from './types';
export const messagesReceiveGetListReducer: MessagesReceiveGetListReducer = (state, action) => {
  const {
    messagesList,
    brokenMessages
  } = action.payload;
  const {
    byChatId
  } = state;
  const messagesByChatId = byChatId[messagesList.chatId] ?? {};
  const existingMessagesById = messagesByChatId.messagesById ?? {};
  const existingMessagesIds = messagesByChatId.messagesIds ?? [];
  const processedMessagesList = [...brokenMessages, ...messagesList.data];
  const cleanedMessagesById = {
    ...existingMessagesById,
    ...keyById(processedMessagesList)
  };
  const cleanedMessageIds = uniq([...(mapById(processedMessagesList) as MessagesIds), ...existingMessagesIds]);
  return {
    ...state,
    byChatId: {
      ...byChatId,
      [messagesList.chatId]: {
        ...messagesByChatId,
        messagesById: cleanedMessagesById,
        messagesIds: cleanedMessageIds,
        hasMore: messagesList.limit === messagesList.data.length,
        limit: messagesList.limit,
        offset: messagesList.offset
      }
    }
  };
};