import { YM_TRACKING_ID } from '@config/env';
import { SEVERITY } from '@config/logging';
import { isFillProfileEvent } from '@helpers/logging/analytics/fill-profile-event';
import { isLPClickEvent } from '@helpers/logging/analytics/lp-click-event';
import { isPageViewEvent } from '@helpers/logging/analytics/page-view-event';

// import { isServer } from '@helpers/misc';
import { IAnalyticsEvent } from '@logging/logging-events';
import { IYMEventHandler } from './types';
// import { initYmScript } from './ym-scripts';

export class YMEventHandler<D> implements IYMEventHandler<D> {
  private trackingId = Number(YM_TRACKING_ID);
  public severities = [SEVERITY.ANALYTICS];
  public constructor() {
    if (!this.trackingId) {
      console.warn("YM wasn't initialized");
      return;
    }

    // if (isServer()) {
    //   return;
    // }

    /**
     * Temporarily disabled initialization of script.
     * It was replaced by the inlined one in _document.tsx.
     */
    // initYmScript(this.trackingId);
  }

  public handle<D>(event: IAnalyticsEvent<D>): void {
    if (!window.ym || !this.trackingId) {
      return;
    }
    if (isPageViewEvent(event)) {
      const {
        data
      } = event.payload;
      window.ym(this.trackingId, 'hit', data.url);
    }
    if (isFillProfileEvent(event)) {
      window.ym(this.trackingId, 'reachGoal', 'FILLPROF');
    }
    if (isLPClickEvent(event)) {
      window.ym(this.trackingId, 'reachGoal', 'LPCLICK');
    }
  }
}