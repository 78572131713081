import { CalendarDecreaseConsultationsCounterReducer } from './types';
export const calendarDecreaseConsultationsCounterReducer: CalendarDecreaseConsultationsCounterReducer = (state, action) => {
  const {
    decrement
  } = action.payload;
  const {
    pendingConsultationsCount
  } = state;
  return {
    ...state,
    pendingConsultationsCount: Math.max(0, pendingConsultationsCount - decrement)
  };
};