import jwtDecode from 'jwt-decode';
import { QUERY_PARAM } from '@config/routing';
import { parseQueryString } from '@helpers/routing';
import { AuthDecodedToken } from '@types';
import { ParseQueryCode, ParseQueryUserId } from './types';
export const authDecodeToken = (encodedToken: undefined | string): undefined | AuthDecodedToken => {
  if (!encodedToken) {
    return;
  }
  return jwtDecode<AuthDecodedToken>(encodedToken);
};
export const parseQueryCode: ParseQueryCode = query => {
  return parseQueryString(query, QUERY_PARAM.CODE);
};
export const parseQueryUserId: ParseQueryUserId = query => {
  return parseQueryString(query, QUERY_PARAM.USER_ID);
};