import { omit, uniq } from '@helpers/data';
import { MessagesAddBrokenMessageReducer } from './types';
export const messagesAddBrokenMessageReducer: MessagesAddBrokenMessageReducer = (state, action) => {
  const {
    message
  } = action.payload;
  const {
    byChatId
  } = state;
  const messagesByChatId = byChatId[message.chatId] ?? {};
  const {
    messagesById = {},
    messagesIds = []
  } = messagesByChatId;
  const cleanedMessageIds = uniq([message.id, ...messagesIds]);
  const cleanedMessagesById = omit(messagesById, message.id);
  return {
    ...state,
    byChatId: {
      ...byChatId,
      [message.chatId]: {
        ...messagesByChatId,
        messagesById: {
          ...cleanedMessagesById,
          [message.id]: message
        },
        messagesIds: cleanedMessageIds
      }
    }
  };
};