import { useEffect, useRef } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { GEOMETRY_RESIZE_TIMEOUT } from '@config/geometry';
import { addEvent } from '@helpers/dom';
import { throttle } from '@helpers/misc';
import { UseOnResize } from './types';
export const useOnResize: UseOnResize = (onResize, options) => {
  const {
    horizontalOnly,
    verticalOnly,
    wait = GEOMETRY_RESIZE_TIMEOUT,
    leading = true,
    trailing = true
  } = options ?? {};
  const width = useRef(0);
  const height = useRef(0);
  useEffect(() => {
    const resize = (event: UIEvent) => {
      const target = (event.target as Window);
      const nextWidth = target.innerWidth;
      const nextHeight = target.innerHeight;
      if (horizontalOnly && width.current === nextWidth) {
        return;
      }
      if (verticalOnly && height.current === nextHeight) {
        return;
      }
      width.current = nextWidth;
      height.current = nextHeight;
      onResize(nextWidth, nextHeight);
    };
    const throttledResize = throttle(resize, wait, {
      leading,
      trailing
    });
    return addEvent(window, EVENT_NAMES.RESIZE, throttledResize);
  }, [onResize, horizontalOnly, wait, leading, trailing, verticalOnly]);
};