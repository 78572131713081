import { ENDPOINTS } from '@config/endpoints';
import { jsonFetch, JsonFetchResult } from '@services/base/json-fetch';
import { SpecialistsGetTopRequestData, SpecialistsGetTopRequestParams, SpecialistsGetTopResponseData, SpecialistsGetTopResponseDto } from '@services/dto/specialists';
import { specialistsGetTopRequestToParams, specialistsGetTopResponseFromDto } from '@mapping/specialists';
type SpecialistsGetTop = (requestData: SpecialistsGetTopRequestData) => JsonFetchResult<SpecialistsGetTopResponseData>;
export const specialistsGetTop: SpecialistsGetTop = requestData => {
  const {
    promise,
    cancel
  } = jsonFetch<SpecialistsGetTopResponseDto, undefined, SpecialistsGetTopRequestParams>(ENDPOINTS.specialists.top(), {
    method: 'GET',
    params: specialistsGetTopRequestToParams(requestData)
  });
  return {
    promise: promise.then(specialistsGetTopResponseFromDto),
    cancel
  };
};