import { Chat } from '@types';
import { ChatsDecreaseUnreadMessagesCounterReducer } from './types';
export const chatsDecreaseUnreadMessagesCounterReducer: ChatsDecreaseUnreadMessagesCounterReducer = (state, action) => {
  const {
    chatId,
    decrement
  } = action.payload;
  const chat = (state.chatsById[chatId] as undefined | Chat);
  const chatsById = chat ? {
    ...state.chatsById,
    [chatId]: {
      ...chat,
      unreadedMessagesCount: Math.max(0, chat.unreadedMessagesCount - decrement)
    }
  } : state.chatsById;
  return {
    ...state,
    chatsById,
    unreadMessagesCount: Math.max(0, state.unreadMessagesCount - decrement)
  };
};