import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { CountersGetUnreadResponseData, CountersGetUnreadResponseDto } from '@services/dto/counters';
import { countersGetUnreadResponseFromDto } from '@mapping/counters';
type CountersGetUnread = () => AuthedJsonFetchResult<CountersGetUnreadResponseData>;
export const countersGetUnread: CountersGetUnread = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<CountersGetUnreadResponseDto>(ENDPOINTS.counters.getUnread(), {
    method: 'GET'
  });
  return {
    promise: promise.then(countersGetUnreadResponseFromDto),
    cancel
  };
};