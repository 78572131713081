import { ENDPOINTS } from '@config/endpoints';
import { HTTP_CONTENT_TYPE, HTTP_HEADER_NAME } from '@config/net';
import { encodeQuery } from '@helpers/routing';
import { httpFetch, HttpFetchResult } from '@services/base/http-fetch';
import { AuthRefreshTokenRequestData, AuthRefreshTokenResponseData, AuthRefreshTokenResponseDto } from '@services/dto/auth';
import { authRefreshTokenRequestToDto, authRefreshTokenResponseFromDto } from '@mapping/auth';
type AuthRefreshToken = (data: AuthRefreshTokenRequestData) => HttpFetchResult<AuthRefreshTokenResponseData>;
type AuthRefreshTokenHeaders = {
  [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE.X_WWW_FORM_URLENCODED;
};
export const authRefreshToken: AuthRefreshToken = data => {
  const {
    promise,
    cancel
  } = httpFetch<AuthRefreshTokenResponseDto, string, undefined, AuthRefreshTokenHeaders>(ENDPOINTS.auth.token(), {
    method: 'POST',
    headers: {
      [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE.X_WWW_FORM_URLENCODED
    },
    data: encodeQuery(authRefreshTokenRequestToDto(data))
  });
  return {
    promise: promise //
    .then(response => {
      return {
        ...response,
        data: authRefreshTokenResponseFromDto(response.data)
      };
    }),
    cancel
  };
};