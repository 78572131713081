import { appCreateSlice } from '@store/rtk';
import { currentClientReceiveDeletePhotoReducer } from '@slices/current-client/current-client-receive-delete-photo';
import { currentClientReceiveGetReducer } from '@slices/current-client/current-client-receive-get';
import { currentClientReceiveSetAvatarReducer } from '@slices/current-client/current-client-receive-set-avatar';
import { currentClientReceiveSetDefaultPhotoReducer } from '@slices/current-client/current-client-receive-set-default-photo';
import { currentClientReceiveUpdateReducer } from '@slices/current-client/current-client-receive-update';
import { currentClientReceiveUploadPhotoReducer } from '@slices/current-client/current-client-receive-upload-photo';
import { currentClientSetBalanceReducer } from '@slices/current-client/current-client-set-balance';
import { currentClientSetFetchedReducer } from '@slices/current-client/current-client-set-fetched';
import { currentClientSetLevelReducer } from '@slices/current-client/current-client-set-level';
import { currentClientSetTempSexReducer } from '@slices/current-client/current-client-set-temp-sex';
import { CurrentClientState } from './types';
export const currentClientDefaultState: CurrentClientState = {
  me: undefined,
  coinAmount: 0,
  tempSex: undefined,
  isFetchingMe: true
};
const currentClientSlice = appCreateSlice({
  name: 'currentClient',
  initialState: currentClientDefaultState,
  reducers: {
    currentClientReceiveDeletePhoto: currentClientReceiveDeletePhotoReducer,
    currentClientReceiveGet: currentClientReceiveGetReducer,
    currentClientReceiveSetAvatar: currentClientReceiveSetAvatarReducer,
    currentClientReceiveSetDefaultPhoto: currentClientReceiveSetDefaultPhotoReducer,
    currentClientReceiveUpdate: currentClientReceiveUpdateReducer,
    currentClientReceiveUploadPhoto: currentClientReceiveUploadPhotoReducer,
    currentClientSetBalance: currentClientSetBalanceReducer,
    currentClientSetFetched: currentClientSetFetchedReducer,
    currentClientSetLevel: currentClientSetLevelReducer,
    currentClientSetTempSex: currentClientSetTempSexReducer
  }
});
export const {
  actions: {
    currentClientReceiveDeletePhoto,
    currentClientReceiveGet,
    currentClientReceiveSetAvatar,
    currentClientReceiveSetDefaultPhoto,
    currentClientReceiveUpdate,
    currentClientReceiveUploadPhoto,
    currentClientSetBalance,
    currentClientSetFetched,
    currentClientSetLevel,
    currentClientSetTempSex
  },
  reducer: currentClientReducer
} = currentClientSlice;