import { ENDPOINTS } from '@config/endpoints';
import { HTTP_CONTENT_TYPE, HTTP_HEADER_NAME } from '@config/net';
import { encodeQuery } from '@helpers/routing';
import { httpFetch, HttpFetchResult } from '@services/base/http-fetch';
import { AuthGetTokenRequestData, AuthGetTokenResponseData, AuthGetTokenResponseDto } from '@services/dto/auth';
import { authGetTokenRequestToDto, authGetTokenResponseFromDto } from '@mapping/auth';
type AuthGetToken = (requestData: AuthGetTokenRequestData) => HttpFetchResult<AuthGetTokenResponseData>;
type AuthTokenHeaders = {
  [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE.X_WWW_FORM_URLENCODED;
};
export const authGetToken: AuthGetToken = data => {
  const {
    promise,
    cancel
  } = httpFetch<AuthGetTokenResponseDto, string, undefined, AuthTokenHeaders>(ENDPOINTS.auth.token(), {
    method: 'POST',
    headers: {
      [HTTP_HEADER_NAME.CONTENT_TYPE]: HTTP_CONTENT_TYPE.X_WWW_FORM_URLENCODED
    },
    data: encodeQuery(authGetTokenRequestToDto(data))
  });
  return {
    promise: promise //
    .then(response => {
      return {
        ...response,
        data: authGetTokenResponseFromDto(response.data)
      };
    }),
    cancel
  };
};