import { appCreateSlice } from '@store/rtk';
import { topSpecialistsReceiveGetListReducer } from '@slices/top-specialists/top-specialists-receive-get-list';
import { TopSpecialistsState } from './types';
export const topSpecialistsDefaultState: TopSpecialistsState = {
  listItemsById: {},
  listItemIds: [],
  hasMore: false,
  limit: 12,
  offset: 0,
  totalCount: 0
};
export const topSpecialistsSlice = appCreateSlice({
  name: 'topSpecalists',
  initialState: topSpecialistsDefaultState,
  reducers: {
    topSpecialistsReceiveGetList: topSpecialistsReceiveGetListReducer
  }
});
export const {
  actions: {
    topSpecialistsReceiveGetList
  },
  reducer: topSpecialistsReducer
} = topSpecialistsSlice;