import { appCreateSlice } from '@store/rtk';
import { currentSpecialistReceiveDeleteDocumentReducer } from '@slices/current-specialist/current-specialist-receive-delete-document';
import { currentSpecialistReceiveDeletePhotoReducer } from '@slices/current-specialist/current-specialist-receive-delete-photo';
import { currentSpecialistsReceiveDeleteVideoReducer } from '@slices/current-specialist/current-specialist-receive-delete-video';
import { currentSpecialistReceiveGetReducer } from '@slices/current-specialist/current-specialist-receive-get';
import { currentSpecialistReceiveGetStatisticsReducer } from '@slices/current-specialist/current-specialist-receive-get-statistics';
import { currentSpecialistReceiveGetWalletsReducer } from '@slices/current-specialist/current-specialist-receive-get-wallets';
import { currentSpecialistReceiveSaveWalletsReducer } from '@slices/current-specialist/current-specialist-receive-save-wallets';
import { currentSpecialistReceiveSetDefaultPhotoReducer } from '@slices/current-specialist/current-specialist-receive-set-default-photo';
import { currentSpecialistReceiveUpdateReducer } from '@slices/current-specialist/current-specialist-receive-update';
import { currentSpecialistReceiveUploadDocumentReducer } from '@slices/current-specialist/current-specialist-receive-upload-document';
import { currentSpecialistReceiveUploadPhotoReducer } from '@slices/current-specialist/current-specialist-receive-upload-photo';
import { currentSpecialistReceiveUploadVideoReducer } from '@slices/current-specialist/current-specialist-receive-upload-video';
import { currentSpecialistReceiveUploadVideoCompleteReducer } from '@slices/current-specialist/current-specialist-receive-upload-video-complete';
import { currentSpecialistSetBalanceReducer } from '@slices/current-specialist/current-specialist-set-balance';
import { currentSpecialistSetFetchedReducer } from '@slices/current-specialist/current-specialist-set-fetched';
import { CurrentSpecialistState } from './types';
export const currentSpecialistDefaultState: CurrentSpecialistState = {
  me: undefined,
  balance: {
    amount: 0
  },
  wallets: undefined,
  statisticsByPeriodType: {},
  /**
   * @todo
   * Reconsider the field below.
   * To define whether data is loaded or not we can check 'me' field value
   * existence (me == null) or use of getCurrentMemberExists selector.
   * Probably duplication of functionality.
   */
  isFetchingMe: true
};
export const currentSpecialistSlice = appCreateSlice({
  name: 'currentSpecialist',
  initialState: currentSpecialistDefaultState,
  reducers: {
    currentSpecialistReceiveDeleteDocument: currentSpecialistReceiveDeleteDocumentReducer,
    currentSpecialistReceiveDeletePhoto: currentSpecialistReceiveDeletePhotoReducer,
    currentSpecialistReceiveDeleteVideo: currentSpecialistsReceiveDeleteVideoReducer,
    currentSpecialistReceiveGet: currentSpecialistReceiveGetReducer,
    currentSpecialistReceiveGetStatistics: currentSpecialistReceiveGetStatisticsReducer,
    currentSpecialistReceiveGetWallets: currentSpecialistReceiveGetWalletsReducer,
    currentSpecialistReceiveSaveWallets: currentSpecialistReceiveSaveWalletsReducer,
    currentSpecialistReceiveSetDefaultPhoto: currentSpecialistReceiveSetDefaultPhotoReducer,
    currentSpecialistReceiveUpdate: currentSpecialistReceiveUpdateReducer,
    currentSpecialistReceiveUploadDocument: currentSpecialistReceiveUploadDocumentReducer,
    currentSpecialistReceiveUploadPhoto: currentSpecialistReceiveUploadPhotoReducer,
    currentSpecialistReceiveUploadVideo: currentSpecialistReceiveUploadVideoReducer,
    currentSpecialistReceiveUploadVideoComplete: currentSpecialistReceiveUploadVideoCompleteReducer,
    currentSpecialistSetBalance: currentSpecialistSetBalanceReducer,
    currentSpecialistSetFetchedMe: currentSpecialistSetFetchedReducer
  }
});
export const {
  actions: {
    currentSpecialistReceiveDeleteDocument,
    currentSpecialistReceiveDeletePhoto,
    currentSpecialistReceiveDeleteVideo,
    currentSpecialistReceiveGet,
    currentSpecialistReceiveGetStatistics,
    currentSpecialistReceiveGetWallets,
    currentSpecialistReceiveSaveWallets,
    currentSpecialistReceiveSetDefaultPhoto,
    currentSpecialistReceiveUpdate,
    currentSpecialistReceiveUploadDocument,
    currentSpecialistReceiveUploadPhoto,
    currentSpecialistReceiveUploadVideo,
    currentSpecialistReceiveUploadVideoComplete,
    currentSpecialistSetBalance,
    currentSpecialistSetFetchedMe
  },
  reducer: currentSpecialistReducer
} = currentSpecialistSlice;