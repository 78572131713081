export enum SEARCH_FILTER_TYPE {
  ALL = 1,
  ONLINE = 2,
  FAVORITES = 3,
}
import { MemberId, PROF_SPECIALITY, SEX, SpecialistListItem, SubjectId } from '@types';
import { ClientListItem } from './member-list';
export type SearchListItem = ClientListItem | SpecialistListItem;
export type SearchListItems = SearchListItem[];
export type SearchList = {
  items: SearchListItems;
  totalCount: number;
  offset: number;
  limit: number;
  hasMore: boolean;
  dataLoaded: boolean;
  hasError: boolean;
};
export type SearchListFilters = {
  sex?: SEX;
  subjectId?: SubjectId;
  profSpeciality?: PROF_SPECIALITY;
  isCertified?: boolean;
  ratingFrom?: number;
  ratingTo?: number;
  ageFrom?: number;
  ageTo?: number;
  filterType?: SEARCH_FILTER_TYPE;
  offset?: number;
};
export type SearchListItemsById = Record<MemberId, SearchListItem>;
export type SearchListItemIds = MemberId[];