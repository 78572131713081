import { rejectById } from '@helpers/data';
import { PaymentsReceiveDeleteBankCardReducer } from './types';
export const paymentsReceiveDeleteBankCardReducer: PaymentsReceiveDeleteBankCardReducer = (state, action) => {
  const {
    cardId
  } = action.payload;
  const {
    paymentSettings
  } = state;
  if (!paymentSettings) {
    return state;
  }
  return {
    ...state,
    paymentSettings: {
      ...paymentSettings,
      bankCards: rejectById(paymentSettings.bankCards, cardId)
    }
  };
};