import { keyBy, uniq } from '@helpers/data';
import { ViewsReceiveGetListReducer } from './types';
export const viewsReceiveGetListReducer: ViewsReceiveGetListReducer = (state, action) => {
  const {
    limit,
    offset,
    items,
    totalCount,
    keepResults
  } = action.payload;
  const {
    viewsIds,
    viewsById
  } = state;
  const cleanedViewsById = {
    ...(keepResults ? viewsById : undefined),
    ...keyBy(items, item => item.userId)
  };
  const cleanedViewIds = uniq([...(keepResults ? viewsIds : []), ...items.map(view => view.userId)]);
  return {
    ...state,
    viewsById: cleanedViewsById,
    viewsIds: cleanedViewIds,
    hasMore: offset + limit < totalCount,
    limit,
    offset,
    totalCount,
    dataLoaded: true
  };
};