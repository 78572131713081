import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { SpecialistsGetWalletsResponseData, SpecialistsGetWalletsResponseDto } from '@services/dto/specialists';
import { specialistsGetWalletsResponseFromDto } from '@mapping/specialists';
type SpecialistsGetWallets = () => AuthedJsonFetchResult<SpecialistsGetWalletsResponseData>;
export const specialistsGetWallets: SpecialistsGetWallets = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<SpecialistsGetWalletsResponseDto>(ENDPOINTS.specialists.getWallets(), {
    method: 'GET'
  });
  return {
    promise: promise.then(specialistsGetWalletsResponseFromDto),
    cancel
  };
};