import { calendarIsCurrentClient, calendarIsCurrentClientCalendar } from '@helpers/calendar';
import { rejectById } from '@helpers/data';
import { CalendarRemoveMemberConsultationReducer } from './types';
export const calendarRemoveMemberConsultationReducer: CalendarRemoveMemberConsultationReducer = (state, action) => {
  const {
    consultationId,
    memberId
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentClient(currentMember) && calendarIsCurrentClientCalendar(currentMember.calendar)) {
    const nextState = state;
    const member = state.membersById?.[memberId];
    const memberCalendar = member?.calendar;
    if (memberCalendar) {
      return {
        ...nextState,
        membersById: {
          ...nextState.membersById,
          [memberId]: {
            ...member,
            calendar: {
              ...memberCalendar,
              consultations: rejectById(memberCalendar.consultations, consultationId)
            }
          }
        }
      };
    }
    return nextState;
  }
  return state;
};