import { appCreateSlice } from '@store/rtk';
import { hideSidebarReducer } from '@slices/sidebar/hide-sidebar';
import { showSidebarReducer } from '@slices/sidebar/show-sidebar';
import { SidebarState } from './types';
export const sidebarDefaultState: SidebarState = {
  hidden: false
};
const sidebarSlice = appCreateSlice({
  name: 'sidebar',
  initialState: sidebarDefaultState,
  reducers: {
    showSidebar: showSidebarReducer,
    hideSidebar: hideSidebarReducer
  }
});
export const {
  actions: {
    showSidebar,
    hideSidebar
  },
  reducer: sidebarReducer
} = sidebarSlice;