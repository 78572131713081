import { Provider } from 'react-redux';
import { ExternalAuthEventHandlerContext, initializeExternalAuthEventHandler } from '@auth/external-auth-event-handler';
import { ExternalAuthWindowContext, initializeExternalAuthWindow } from '@auth/external-auth-window';
import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import { initializeMediaCallHandler, MediaCallHandlerContext } from '@media-call-handler';
import { initializePaymentsPayWindow, PaymentsPayWindowContext } from 'payments/payments-pay-window';
import { initializeValidator, ValidatorContext } from '@validation/validator';
import { NavigatorContext } from '@routing';
import { LoggingContext } from '@logging';
import { initializePush, PushContext } from '@push';
import { initializeRealtime, RealtimeContext } from '@realtime';
import { initializeStorage, StorageContext } from '@storage';
import { DBContext, initializeDb } from '@db';
import { AppFCC } from '@types';
import { ProvidersManagerProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ProvidersManager: AppFCC<ProvidersManagerProps> = props => {
  const {
    eventBus,
    navigator,
    children,
    store
  } = props;
  const storage = initializeStorage();
  const db = initializeDb();
  const push = initializePush(store);
  const mediaCallHandler = initializeMediaCallHandler({
    store,
    eventBus
  });
  const realtime = initializeRealtime({
    storage,
    store
  });
  const externalAuthWindow = initializeExternalAuthWindow();
  const externalAuthEventHandler = initializeExternalAuthEventHandler();
  const paymentsPayWindow = initializePaymentsPayWindow();
  const validator = initializeValidator();
  return _jsx(Provider, {
    store: store,
    children: _jsx(CacheProvider, {
      value: cache,
      children: _jsx(LoggingContext.Provider, {
        value: eventBus,
        children: _jsx(StorageContext.Provider, {
          value: storage,
          children: _jsx(DBContext.Provider, {
            value: db,
            children: _jsx(NavigatorContext.Provider, {
              value: navigator,
              children: _jsx(PushContext.Provider, {
                value: push,
                children: _jsx(MediaCallHandlerContext.Provider, {
                  value: mediaCallHandler,
                  children: _jsx(RealtimeContext.Provider, {
                    value: realtime,
                    children: _jsx(ExternalAuthWindowContext.Provider, {
                      value: externalAuthWindow,
                      children: _jsx(ExternalAuthEventHandlerContext.Provider, {
                        value: externalAuthEventHandler,
                        children: _jsx(PaymentsPayWindowContext.Provider, {
                          value: paymentsPayWindow,
                          children: _jsx(ValidatorContext.Provider, {
                            value: validator,
                            children: children
                          })
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  });
};