import { CalendarIncreaseConsultationsCounterReducer } from './types';
export const calendarIncreaseConsultationsCounterReducer: CalendarIncreaseConsultationsCounterReducer = (state, action) => {
  const {
    increment
  } = action.payload;
  const {
    pendingConsultationsCount
  } = state;
  return {
    ...state,
    pendingConsultationsCount: pendingConsultationsCount + increment
  };
};