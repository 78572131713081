import { keyBy, rejectBy } from '@helpers/data';
import { getMessageTextByCode } from '@helpers/errors';
import { BaseError } from '@errors/base-error';
import { ValidationRules } from '@validation/validator';
import { BaseNetErrorBody, BaseNetErrorBodyError, BaseNetErrorBodyFieldError, BaseNetErrorBodyOtherError, BaseNetErrorFieldErrors, BaseNetErrorFieldErrorsByField, BaseNetErrorNonValidationRulesFieldErrors, BaseNetErrorOtherErrors, IBaseNetError, IBaseNetErrorConstructor } from './types';
export const BaseNetError: IBaseNetErrorConstructor = class BaseNetError extends BaseError<BaseNetErrorBody> implements IBaseNetError {
  public status: number;
  private _isBaseNetErrorBodyFieldError = (error: BaseNetErrorBodyError): error is BaseNetErrorBodyFieldError => {
    return Boolean(error.data?.field);
  };
  private _isBaseNetErrorBodyOtherError = (error: BaseNetErrorBodyError): error is BaseNetErrorBodyOtherError => {
    return !this._isBaseNetErrorBodyFieldError(error);
  };
  private _getFieldErrors(): BaseNetErrorFieldErrors {
    return this.body.errors.filter(this._isBaseNetErrorBodyFieldError).map(error => ({
      ...error,
      message: getMessageTextByCode(error.code),
      data: {
        ...error.data,
        message: getMessageTextByCode(error.data.code)
      }
    }));
  }
  public getFieldErrorsByField(): BaseNetErrorFieldErrorsByField {
    return keyBy(this._getFieldErrors(),
    //
    error => error.data.field);
  }
  public getOtherErrors(): BaseNetErrorOtherErrors {
    return this.body.errors //
    .filter(this._isBaseNetErrorBodyOtherError).map(error => ({
      ...error,
      message: getMessageTextByCode(error.code)
    }));
  }
  public getNonValidationRulesFieldErrors(validationRules: ValidationRules): BaseNetErrorNonValidationRulesFieldErrors {
    return rejectBy(this._getFieldErrors(),
    //
    error => {
      if (!error.data) {
        return false;
      }
      return Boolean(validationRules[error.data.field]);
    });
  }
  public constructor(status: number, message: string, body: BaseNetErrorBody) {
    super(message, body);
    this.status = status;
    this.name = 'BaseNetError';
  }
};