import { keyBy, uniq } from '@helpers/data';
import { Chat } from '@types';
import { chatsDefaultState } from '@slices/chats';
import { ChatsReceiveGetListReducer } from './types';
export const chatsReceiveGetListReducer: ChatsReceiveGetListReducer = (state, action) => {
  const {
    recipientName,
    filter,
    limit,
    offset,
    totalCount,
    items
  } = action.payload;
  const {
    chatsIds,
    chatsById,
    filter: prevFilter,
    recipientName: prevRecipientName
  } = state;
  const append = recipientName === prevRecipientName &&
  //
  filter === prevFilter;
  const cleanedChatIds = append ? uniq([...chatsIds, ...items.map(chat => chat.chatId)]) : items.map(chat => chat.chatId);
  const cleanedChatsById = {
    ...chatsById,
    ...keyBy(items.map(item => {
      const prevItem = (chatsById[item.chatId] as undefined | Chat);
      if (!prevItem) {
        return item;
      }
      return {
        ...item,
        /**
         * lastMessage creationTime values are compared to define whether
         * update unreadedMessagesCount or not:
         * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/713
         */
        unreadedMessagesCount: new Date(prevItem.lastMessage?.creationTime ?? 0).getTime() >= new Date(item.lastMessage?.creationTime ?? 0).getTime() ? prevItem.unreadedMessagesCount : item.unreadedMessagesCount
      };
    }), chat => chat.chatId)
  };
  return {
    ...state,
    filter: filter || chatsDefaultState.filter,
    recipientName,
    chatsById: cleanedChatsById,
    chatsIds: cleanedChatIds,
    hasMore: offset + limit < totalCount,
    limit,
    offset,
    totalCount,
    dataLoaded: true
  };
};