import { isVideo } from '@helpers/media-files';
import { toUserAchievements } from '@helpers/member';
import { CurrentSpecialist, Documents, MediaFiles, PROF_SPECIALITY, SpecialistStatistics, SpecialistWallets, STATISTICS_PERIOD_TYPE, USER_ACHIEVEMENT } from '@types';
import { appCreateSelector, RootState } from '@store';
import { CurrentSpecialistState } from '@slices/current-specialist';

/**
 * regular selectors
 */

export const currentSpecialistGet = (state: RootState): CurrentSpecialistState => {
  return state.app.currentSpecialist;
};

/**
 * memoized reselect selectors
 */

export const currentSpecialistGetMe: (state: RootState) => undefined | CurrentSpecialist = appCreateSelector([currentSpecialistGet], currentSpecialist => currentSpecialist.me);
export const currentSpecialistGetIsFetchingMe: (state: RootState /**/) => boolean = appCreateSelector([currentSpecialistGet],
//
currentSpecialist => currentSpecialist.isFetchingMe);
const currentSpecialistGetBalanceAmountSelector: (state: RootState) => undefined | number = appCreateSelector([currentSpecialistGet],
//
currentSpecialist => currentSpecialist.balance?.amount);
export const currentSpecialistGetStatistics: (state: RootState, periodType: STATISTICS_PERIOD_TYPE) => undefined | SpecialistStatistics = appCreateSelector([currentSpecialistGet, (_state, periodType: STATISTICS_PERIOD_TYPE) => periodType],
//
(currentSpecialist, periodType) => currentSpecialist.statisticsByPeriodType[periodType]);
export const currentSpecialistGetWallets: (state: RootState) => undefined | SpecialistWallets = appCreateSelector([currentSpecialistGet], currentSpecialist => currentSpecialist.wallets);
export const currentSpecialistGetDocuments: (state: RootState) => undefined | Documents = appCreateSelector(currentSpecialistGetMe, currentSpecialist => currentSpecialist?.documents);
export const currentSpecialistGetIsCertified: (state: RootState) => undefined | boolean = appCreateSelector(currentSpecialistGetMe, currentSpecialist => currentSpecialist?.isCertified);
export const currentSpecialistGetProfSpeciality: (state: RootState) => undefined | PROF_SPECIALITY = appCreateSelector(currentSpecialistGetMe, currentSpecialist => currentSpecialist?.profSpeciality);
export const currentSpecialistGetAchievements: (state: RootState) => USER_ACHIEVEMENT[] = appCreateSelector([currentSpecialistGetIsCertified, currentSpecialistGetProfSpeciality], (isCertified, profSpeciality) => {
  return toUserAchievements(isCertified, profSpeciality);
});
export const currentSpecialistGetBalanceAmount: (state: RootState) => number = appCreateSelector(currentSpecialistGetBalanceAmountSelector, amount => {
  if (!amount) {
    return 0;
  }
  return amount;
});
export const currentSpecialistGetWalletsSortedByType: (state: RootState) => undefined | SpecialistWallets = appCreateSelector(currentSpecialistGetWallets, wallets => {
  if (!wallets) {
    return undefined;
  }
  return wallets //
  .slice().sort((a, b) => a.type - b.type);
});
export const currentSpecialistGetHasWallets: (state: RootState) => undefined | boolean = appCreateSelector(currentSpecialistGetWallets, wallets => {
  if (!wallets) {
    return undefined;
  }
  return Boolean(wallets.length);
});
export const currentSpecialistGetMedia: (state: RootState) => undefined | MediaFiles = appCreateSelector(currentSpecialistGetMe, currentSpecialist => currentSpecialist?.media);
export const currentSpecialistGetHasVideo: (state: RootState) => undefined | boolean = appCreateSelector(currentSpecialistGetMedia, videos => videos?.some(isVideo));