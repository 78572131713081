import { calendarIsCurrentClient, calendarIsCurrentClientCalendar } from '@helpers/calendar';
import { CalendarReceiveCreateMemberConsultationReducer } from './types';
export const calendarReceiveCreateMemberConsultationReducer: CalendarReceiveCreateMemberConsultationReducer = (state, action) => {
  const {
    memberId,
    data
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentClient(currentMember) && calendarIsCurrentClientCalendar(currentMember.calendar)) {
    let nextState = state;
    const currentMemberCalendar = currentMember.calendar;
    nextState = {
      ...nextState,
      currentMember: {
        ...currentMember,
        calendar: {
          ...currentMemberCalendar,
          consultations: [...currentMemberCalendar.consultations, data]
        }
      }
    };
    const member = state.membersById?.[memberId];
    const memberCalendar = member?.calendar;
    if (memberCalendar) {
      nextState = {
        ...nextState,
        membersById: {
          ...state.membersById,
          [memberId]: {
            ...member,
            calendar: {
              ...memberCalendar,
              consultations: [...memberCalendar.consultations, data]
            }
          }
        }
      };
    }
    return nextState;
  }
  return state;
};