import { ANALYTICS_ACTION } from '@config/logging';
import { generateUuid } from '@helpers/data';
import { getOrigin } from '@helpers/url';
import { INav } from '@routing/nav';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsSessionEvent } from '@logging/logging-events';
import { ILocalStorage } from '@storage';
import { readVisitId, writeSessionId, writeVisitId } from '@storage/analytics';
import { AnalyticsSendAmplitudeSessionRequestData } from '@services/dto/analytics/analytics-send-amplitude-session';
export const isSessionEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsSessionEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.SESSION;
};
export const sendSessionEvent = (eventBus: IEventBus, data: AnalyticsSendAmplitudeSessionRequestData): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsSendAmplitudeSessionRequestData>({
    action: ANALYTICS_ACTION.SESSION,
    data
  }));
};
export const computeAuthSessionData = (args: {
  storage: ILocalStorage;
  navigator: INav;
}): AnalyticsSendAmplitudeSessionRequestData => {
  const {
    storage,
    navigator
  } = args;
  let visitId = readVisitId(storage);
  if (!visitId) {
    visitId = generateUuid();
    writeVisitId(storage, visitId);
  }
  const sessionId = generateUuid();
  writeSessionId(storage, sessionId);
  return {
    visitId,
    sessionId,
    url: `${getOrigin()}${navigator.getUrl()}`
  };
};