import { accountGetEmailIsConfirmed } from '@services/account/account-get-email-is-confirmed';
import { accountReceiveGetEmailIsConfirmed } from '@slices/account';
import { AccountFetchGetEmailIsConfirmed } from './types';
export const accountFetchGetEmailIsConfirmed: AccountFetchGetEmailIsConfirmed = () => {
  return async dispatch => {
    const data = await accountGetEmailIsConfirmed().promise;
    dispatch(accountReceiveGetEmailIsConfirmed({
      emailIsConfirmed: data
    }));
    return data;
  };
};