import { captureException, flush, init, SeverityLevel } from '@sentry/nextjs';
import { SENTRY_DSN, SENTRY_ENV } from '@config/env';
import { SEVERITY } from '@config/logging';
import { HTTP_STATUS_CODE } from '@config/net';
import { BaseNetError } from '@errors/base-net-error';
import { CallError, ICallError } from '@errors/call-error';
import { IErrorEvent, IFatalEvent } from '@logging/logging-events';
import { ISentryEventHandler } from './types';
export class SentryEventHandler implements ISentryEventHandler {
  severities = [SEVERITY.FATAL, SEVERITY.ERROR];
  constructor() {
    if (!SENTRY_DSN) {
      console.warn("Sentry wasn't initialized");
      return;
    }
    init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENV,
      normalizeDepth: 10,
      beforeSend: (event, hint) => {
        const error = hint?.originalException;
        if (!error) {
          return event;
        }
        if (error instanceof BaseNetError) {
          if ([HTTP_STATUS_CODE.BAD_REQUEST, HTTP_STATUS_CODE.UNAUTHORIZED, HTTP_STATUS_CODE.FORBIDDEN, HTTP_STATUS_CODE.NOT_FOUND, HTTP_STATUS_CODE.GONE, HTTP_STATUS_CODE.NO_CONTENT].includes(error.status)) {
            return null;
          }
          return event;
        }
        return event;
      }
    });
  }
  async handle(event: IErrorEvent | IFatalEvent): Promise<void> {
    const {
      severity,
      payload: {
        error
      }
    } = event;
    const level: SeverityLevel = severity === SEVERITY.FATAL //
    ? 'fatal' : 'error';
    if (error instanceof CallError) {
      captureCallErrorException(error, level);
    } else {
      captureException(error, {
        level: level
      });
    }
    await flush(2000);
  }
}
const captureCallErrorException = (error: ICallError, level: SeverityLevel) => {
  const {
    body: {
      tags,
      secondsFromCallStart,
      participant
    }
  } = error;
  captureException(error, {
    level,
    tags,
    contexts: {
      timer: {
        secondsFromCallStart
      },
      participant: {
        ...participant
      }
    }
  });
};