import { useEffect } from 'react';
import { RootDispatch } from '@store';
import { accountFetchGetEmailIsConfirmed } from '@actions/account/account-fetch-get-email-is-confirmed';
import { accountFetchGetTimezone } from '@actions/account/account-fetch-get-timezone';
import { currentMemberFetchGetMe } from '@actions/current-member/current-member-fetch-get-me';
import { currentSpecialistFetchGetWallets } from '@actions/current-specialist/current-specialist-fetch-get-wallets';
import { notificationsFetchPushIsSubscribed } from '@actions/notifications/notifications-fetch-push-is-subscribed';
import { subjectsFetchGet } from '@actions/subjects/subjects-fetch-get';
import { accountGetAccountId, accountGetIsAuthorized } from '@selectors/account';
import { usePush } from '@hooks/push';
import { useTypedSelector } from '@hooks/store';
export const useFetchCommonData = (dispatch: RootDispatch): void => {
  const push = usePush();
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const accountId = useTypedSelector(accountGetAccountId);
  useEffect(() => {
    /**
     * Data below is fetched on login/logout.
     * It is done intentionally because we resets store on logout.
     */
    dispatch(subjectsFetchGet());
    dispatch(notificationsFetchPushIsSubscribed({
      push
    }));
    if (isAuthorized && accountId) {
      dispatch(accountFetchGetEmailIsConfirmed());
      dispatch(accountFetchGetTimezone());
      dispatch(currentMemberFetchGetMe());
      dispatch(currentSpecialistFetchGetWallets());
    }
  }, [accountId, dispatch, isAuthorized, push]);
};