import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsBuyPremiumTryEvent, IAnalyticsEvent } from '@logging/logging-events';
import { AnalyticsBuyPremiumTryEventRequestData } from '@services/dto/analytics/analytics-send-event/analytics-buy-premium-try-event';
export const isBuyPremiumTryEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsBuyPremiumTryEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.BUY_PREMIUM_TRY;
};
export const sendBuyPremiumTry = (eventBus: IEventBus, data: AnalyticsBuyPremiumTryEventRequestData['data']): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsBuyPremiumTryEventRequestData['data']>({
    action: ANALYTICS_ACTION.BUY_PREMIUM_TRY,
    data
  }));
};