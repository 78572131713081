import { FacebookAuth } from '@auth/facebook-auth';
import { GoogleAuth } from '@auth/google-auth';
import { VkAuth } from '@auth/vk-auth';
import { openNewWindow } from '@helpers/routing';
import { CULTURE, PROVIDER } from '@types';
import { IExternalAuthWindow, IExternalAuthWindowConstructor, IExternalAuthWindowOpenArgs } from './types';
export const ExternalAuthWindow: IExternalAuthWindowConstructor = class ExternalAuthWindow implements IExternalAuthWindow {
  private _openedWindow: undefined | null | Window;
  private _openedWindowProvider: undefined | PROVIDER;
  open(args: IExternalAuthWindowOpenArgs): void {
    const {
      provider,
      roleType,
      timezoneId,
      timezoneOffset,
      isDesktop,
      restoreAccount,
      visitId,
      url,
      clientType,
      userAgent
    } = args;
    this._openedWindow?.close();
    const createAuthUrlArgs = {
      roleType,
      culture: CULTURE.RU,
      timezoneId,
      timezoneOffset,
      isDesktop,
      allowRestoreAccount: restoreAccount ?? false,
      visitId,
      url,
      clientType,
      userAgent
    };
    const createAuthUrlFactory = {
      [PROVIDER.FB]: FacebookAuth.createAuthUrl,
      [PROVIDER.VK]: VkAuth.createAuthUrl,
      [PROVIDER.GOOGLE]: GoogleAuth.createAuthUrl
    };
    const authUrl = createAuthUrlFactory[provider](createAuthUrlArgs);
    const window = openNewWindow(authUrl, provider);
    this._openedWindow = window;
    this._openedWindowProvider = provider;
  }
  close(): void {
    this._openedWindow?.close();
  }
  getProvider(): undefined | PROVIDER {
    return this._openedWindowProvider;
  }
};