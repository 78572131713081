import { generateUuid } from '@helpers/data';
import { ReduxAction, ReduxThunkAction } from '@store';
import { addPopup as addPopupSliceAction } from '@slices/popups';
import { AddPopupPayload } from './types';
export function addPopup<P = undefined>(payload: AddPopupPayload<P>): ReduxThunkAction<ReduxAction> {
  return dispatch => {
    const {
      id,
      keepSameId
    } = payload;
    const props = 'props' in payload //
    ? payload.props : undefined;
    return dispatch(addPopupSliceAction({
      id,
      uuid: keepSameId //
      ? generateUuid() : undefined,
      props
    }));
  };
}