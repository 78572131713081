import { ClientsReceiveAddToFavoritesReducer } from './types';
export const clientsReceiveAddToFavoritesReducer: ClientsReceiveAddToFavoritesReducer = (state, action) => {
  const {
    clientId
  } = action.payload;
  return {
    ...state,
    clientsById: {
      ...state.clientsById,
      [clientId]: {
        ...state.clientsById[clientId],
        isFavoriteUser: true
      }
    }
  };
};