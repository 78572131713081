import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { MediaCallStopRequestData, MediaCallStopRequestDto, MediaCallStopResponseData, MediaCallStopResponseDto } from '@services/dto/media-call/media-call-stop';
import { mediaCallStopResponseFromDto } from '@mapping/media-call/media-call-stop';
type MediaCallStop = (requestData: MediaCallStopRequestData) => AuthedJsonFetchResult<MediaCallStopResponseData>;
export const mediaCallStop: MediaCallStop = requestData => {
  const {
    promise,
    cancel
  } = authedJsonFetch<MediaCallStopResponseDto, MediaCallStopRequestDto>(ENDPOINTS.mediaCalls.end(requestData.chatId), {
    method: 'POST',
    data: {
      interactiveKey: requestData.interactiveKey
    }
  });
  return {
    promise: promise.then(mediaCallStopResponseFromDto),
    cancel
  };
};