import { addScript } from '@helpers/dom';
import { isServer } from '@helpers/misc';
import { IOneSignal, IPush, IPushConstructor, IPushConstructorArgs, IPushIsSubscribed, IPushSubscriptionState } from './types';
export const Push: IPushConstructor = class Push implements IPush {
  private _oneSignal: undefined | IOneSignal;
  private _getOneSignal(): IOneSignal {
    return this._oneSignal ?? window.OneSignal;
  }
  private _getSubscriptionState(): Promise<IPushSubscriptionState> {
    return new Promise((resolve, reject) => {
      this._getOneSignal().push(() => {
        Promise.all([this._getOneSignal().isPushNotificationsEnabled(), this._getOneSignal().isOptedOut()]).then(([isPushEnabled, isOptedOut]) => {
          resolve({
            isPushEnabled,
            isOptedOut
          });
        }).catch(reject);
      });
    });
  }
  private _register(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._getOneSignal().push(() => {
        this._getOneSignal() //
        .showNativePrompt().then(resolve).catch(reject);
      });
    });
  }
  public constructor(args: IPushConstructorArgs) {
    if (isServer()) {
      return;
    }
    if (!args.appId) {
      console.warn("OneSignal wasn't initialized");
      return;
    }
    this._oneSignal = args.oneSignal;
    window.OneSignal = window.OneSignal || [];
    if (!this._oneSignal) {
      addScript({
        src: 'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
        async: true
      });
    }
    this._getOneSignal().push(() => {
      const {
        appId,
        safariWebId,
        allowLocalhostAsSecureOrigin,
        //
        promptOptions,
        notifyButton,
        welcomeNotification,
        subdomainName
      } = args;
      this._getOneSignal().init({
        appId,
        safari_web_id: safariWebId,
        notifyButton,
        allowLocalhostAsSecureOrigin,
        promptOptions,
        welcomeNotification,
        subdomainName
      });
    });
    this._getOneSignal().push(() => {
      if (!this._getOneSignal().isPushNotificationsSupported()) {
        console.warn("OneSignal wasn't initialized. Push notifications are not supported");
        return;
      }
      if (args.subscriptionOnChange) {
        this._getOneSignal().on('subscriptionChange', args.subscriptionOnChange);
      }
      if (args.promptOnShow) {
        this._getOneSignal().on('popoverShown', args.promptOnShow);
      }
    });
  }
  async subscribe(): Promise<IPushIsSubscribed> {
    const {
      isOptedOut
    } = await this._getSubscriptionState();
    if (isOptedOut) {
      return new Promise((resolve, reject) => {
        this._getOneSignal().push(() => {
          this._getOneSignal() //
          .setSubscription(true).then(() => resolve(true)).catch(reject);
        });
      });
    }
    await this._register();
    return true;
  }
  async unsubscribe(): Promise<IPushIsSubscribed> {
    const {
      isPushEnabled
    } = await this._getSubscriptionState();
    if (isPushEnabled) {
      return new Promise((resolve, reject) => {
        this._getOneSignal().push(() => {
          this._getOneSignal() //
          .setSubscription(false).then(() => resolve(false)).catch(reject);
        });
      });
    }
    return false;
  }
  async getIsSubscribed(): Promise<IPushIsSubscribed> {
    const {
      isPushEnabled,
      isOptedOut
    } = await this._getSubscriptionState();
    return isPushEnabled && !isOptedOut;
  }
  setExternalUserId(externalUserId: string): void {
    this._getOneSignal().push(() => {
      this._getOneSignal().setExternalUserId(externalUserId);
    });
  }
  removeExternalUserId(): void {
    this._getOneSignal().push(() => {
      this._getOneSignal().removeExternalUserId();
    });
  }
  async showPrompt(): Promise<void> {
    const {
      isPushEnabled,
      isOptedOut
    } = await this._getSubscriptionState();
    if (!isPushEnabled && !isOptedOut) {
      this._getOneSignal().push(() => {
        this._getOneSignal().showSlidedownPrompt({
          force: true
        });
      });
    }
  }
};