import { ClientsReceiveInviteMemberReducer } from './types';
export const clientsReceiveInviteMemberReducer: ClientsReceiveInviteMemberReducer = (state, action) => {
  const {
    clientId
  } = action.payload;
  return {
    ...state,
    clientsById: {
      ...state.clientsById,
      [clientId]: {
        ...state.clientsById[clientId],
        invitationRequested: true
      }
    }
  };
};