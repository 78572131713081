import { useMemo } from 'react';
import Head from 'next/head';
import { FB_DOMAIN_VERIFICATION_CONTENT } from '@config/env';
import { getOrigin } from '@helpers/url';
import { AppFCC } from '@types';
import { useNavigator } from '@hooks/routing';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SEOTagsManager: AppFCC = () => {
  const navigator = useNavigator();
  const title = 'Психологи на любой вкус';
  const description = 'Тебе нужно получить совет психолога, коуча или сексолога? Теперь это так же просто, как написать смс другу. Это даже проще, потому что настоящий друг - один, а психологов на нашей платформе очень много и они прямо сейчас готовы выслушать тебя, дать дельный совет или просто зарядить драйвом.';
  const origin = useMemo(() => getOrigin(), []);
  const imageUrl = useMemo(() => `${origin}/images/logo-preview-card.jpg`,
  //
  [origin]);
  const url = useMemo(() => `${origin}${navigator.getUrl()}`,
  //
  [navigator, origin]);
  return _jsxs(Head, {
    children: [_jsx("title", {
      children: title
    }), _jsx("meta", {
      //
      name: "description",
      content: description
    }, "description"), _jsx("meta", {
      //
      property: "og:title",
      content: title
    }, "og:title"), _jsx("meta", {
      //
      property: "og:description",
      content: description
    }, "og:description"), _jsx("meta", {
      //
      property: "og:image",
      content: imageUrl
    }, "og:image"), _jsx("meta", {
      //
      property: "og:type",
      content: "website"
    }, "og:type"), _jsx("meta", {
      //
      property: "og:site_name",
      content: "Freuders"
    }, "og:site_name"), _jsx("meta", {
      //
      property: "og:url",
      content: url
    }, "og:url"), FB_DOMAIN_VERIFICATION_CONTENT ? _jsx("meta", {
      name: "facebook-domain-verification",
      content: FB_DOMAIN_VERIFICATION_CONTENT
    }) : null]
  });
};