import { useCallback } from 'react';
import { POPUP } from '@config/popups';
import { AppFCC } from '@types';
import { usePopup } from '@hooks/popup';
import { AlertDialogWidget } from '@widgets/popups/alert-dialog';
import { ProfileEditVideoUploadDurationLimitAlertProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ProfileEditVideoUploadDurationLimitAlert: AppFCC<ProfileEditVideoUploadDurationLimitAlertProps> = () => {
  const {
    removePopup
  } = usePopup();
  const popupId = POPUP.PROFILE_EDIT_VIDEO_UPLOAD_DURATION_LIMIT_ALERT;
  const close = useCallback(() => {
    removePopup({
      id: popupId
    });
  }, [removePopup, popupId]);
  return _jsx(AlertDialogWidget, {
    popupId: popupId,
    text: `Видео не должно быть \n дольше 2 минут`,
    submitButtonText: "\u041E\u043A",
    onSubmit: close,
    onClose: close
  });
};