import { useCallback, useEffect } from 'react';
import { NOTIFICATION_DEFAULT_DELAY } from '@config/notifications';
import { AppFCC } from '@types';
import { notificationsUiRemove } from '@slices/notifications-ui';
import { useTypedDispatch } from '@hooks/store';
import { Notification } from '@kit/notifications/notification';
import { NotificationUiWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NotificationUiWidget: AppFCC<NotificationUiWidgetProps> = props => {
  const {
    children,
    onClose,
    notificationId,
    ...rest
  } = props;
  const dispatch = useTypedDispatch();
  const handleClose = useCallback((event: React.MouseEvent) => {
    dispatch(notificationsUiRemove({
      id: notificationId
    }));
    onClose?.(event);
  }, [dispatch, notificationId, onClose]);
  useEffect(() => {
    const timer = setTimeout(handleClose, NOTIFICATION_DEFAULT_DELAY);
    return () => {
      clearTimeout(timer);
    };
  }, [handleClose]);
  return _jsx(Notification //
  , {
    ...rest,
    onClose: handleClose,
    children: children
  });
};