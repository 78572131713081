import { AUTH_CLIENT_ID, AUTH_GRANT_TYPE, VK_AUTH_DISPLAY, VK_AUTH_RESPONSE_TYPE, VK_AUTH_SCOPE } from '@config/auth';
import { FRONTEND_APPLICATION_HOST } from '@config/env';
import { AccountAssociatingVkRequestData, AccountAssociatingVkRequestDto, AccountAuthVkRequestData, AccountAuthVkRequestDto } from '@services/dto/account/account-auth-vk';
import { PROVIDER } from '@types';
export const accountAuthVkRequestToDto = (data: AccountAuthVkRequestData): AccountAuthVkRequestDto => {
  return {
    appUrl: data.appUrl,
    appId: data.appId,
    redirectUri: data.redirectUri,
    responseType: VK_AUTH_RESPONSE_TYPE.CODE,
    display: data.isDesktop //
    ? VK_AUTH_DISPLAY.PAGE : VK_AUTH_DISPLAY.MOBILE,
    scope: [VK_AUTH_SCOPE.EMAIL],
    state: {
      roleType: data.roleType,
      timezoneId: data.timezoneId,
      timezoneOffset: data.timezoneOffset,
      culture: data.culture,
      allowRestoreAccount: data.allowRestoreAccount,
      appId: data.appId,
      visitId: data.visitId,
      url: data.url,
      clientType: data.clientType,
      userAgent: data.userAgent,
      clientId: AUTH_CLIENT_ID,
      grantType: AUTH_GRANT_TYPE.PASSWORD,
      frontEndApplicationHost: FRONTEND_APPLICATION_HOST,
      provider: PROVIDER.VK
    }
  };
};
export const accountAssociatingVkRequestToDto = (data: AccountAssociatingVkRequestData): AccountAssociatingVkRequestDto => {
  return {
    appUrl: data.appUrl,
    appId: data.appId,
    redirectUri: data.redirectUri,
    responseType: VK_AUTH_RESPONSE_TYPE.CODE,
    display: data.isDesktop //
    ? VK_AUTH_DISPLAY.PAGE : VK_AUTH_DISPLAY.MOBILE,
    scope: [VK_AUTH_SCOPE.EMAIL],
    state: {
      roleType: data.roleType,
      timezoneId: data.timezoneId,
      timezoneOffset: data.timezoneOffset,
      culture: data.culture,
      allowRestoreAccount: data.allowRestoreAccount,
      appId: data.appId,
      visitId: data.visitId,
      url: data.url,
      clientType: data.clientType,
      userAgent: data.userAgent,
      clientId: AUTH_CLIENT_ID,
      grantType: AUTH_GRANT_TYPE.PASSWORD,
      frontEndApplicationHost: FRONTEND_APPLICATION_HOST,
      provider: PROVIDER.VK,
      protectedUserId: data.protectedUserId
    }
  };
};