import { photoOrAvatarUrl } from '@helpers/member';
import { Chat, CHAT_FILTER, ChatId, ChatPrepaymentInfo, MemberId, MemberRating, ONLINE_STATUS, PROF_SPECIALITY, ROLE_TYPE, SEX } from '@types';
import { appCreateSelector, RootState } from '@store';
import { ChatsById, ChatsIds, ChatsState } from '@slices/chats';

/**
 * regular selectors
 */

export const chatsGet = (state: RootState): ChatsState => {
  return state.app.chats;
};

/**
 * memoized reselect selectors
 */

export const chatsGetIds: (state: RootState /**/) => ChatsIds = appCreateSelector([chatsGet],
//
chats => chats.chatsIds);
const chatsGetById: (state: RootState /**/) => ChatsById = appCreateSelector([chatsGet],
//
chats => chats.chatsById);
export const chatsGetSortedIds: (state: RootState, filter?: CHAT_FILTER) => ChatsIds = appCreateSelector([chatsGetIds, chatsGetById, (_state, filter?: CHAT_FILTER) => filter],
//
(chatIds, chatsById, filter) => {
  return chatIds.filter(chatId => {
    const chat = (chatsById[chatId] as undefined | Chat);
    return filter === CHAT_FILTER.BLACK_LIST //
    ? chat?.inBlackList : !chat?.inBlackList;
  }).sort((prevChatId, nextChatId) => {
    const prevChat = (chatsById[prevChatId] as undefined | Chat);
    const nextChat = (chatsById[nextChatId] as undefined | Chat);
    return new Date(nextChat?.lastMessage?.creationTime ?? 0).getTime() - new Date(prevChat?.lastMessage?.creationTime ?? 0).getTime();
  });
});
export const chatsGetListRecipientName: (state: RootState) => string | undefined = appCreateSelector([chatsGet],
//
chats => chats.recipientName);
export const chatsGetListTotalCount: (state: RootState) => number = appCreateSelector([chatsGet],
//
chats => chats.totalCount);
export const chatsGetListDataLoaded: (state: RootState) => boolean = appCreateSelector([chatsGet],
//
chats => chats.dataLoaded);
export const chatsGetListHasMore: (state: RootState /**/) => boolean = appCreateSelector([chatsGet],
//
chats => chats.hasMore);
export const chatsGetListLimit: (state: RootState) => number = appCreateSelector([chatsGet],
//
chats => chats.limit);
export const chatsGetListOffset: (state: RootState) => number = appCreateSelector([chatsGet],
//
chats => chats.offset);
export const chatsGetListFilter: (state: RootState) => CHAT_FILTER = appCreateSelector([chatsGet],
//
chats => chats.filter);
export const chatsGetUnreadMessagesCount: (state: RootState) => number = appCreateSelector([chatsGet],
//
chats => chats.unreadMessagesCount);
export const chatsGetChatById: (state: RootState, chatId: ChatId) => undefined | Chat = appCreateSelector([chatsGetById, (_state, chatId: ChatId) => chatId], (chatsById, chatId) => {
  return (chatsById[chatId] as undefined | Chat);
});
export const chatsGetMemberId: (state: RootState, chatId: ChatId) => undefined | MemberId = appCreateSelector([chatsGetChatById],
//
chat => {
  return chat?.chatMateUserId;
});
export const chatsGetMemberRoleType: (state: RootState, chatId: ChatId) => undefined | ROLE_TYPE = appCreateSelector([chatsGetChatById],
//
chat => chat?.chatMateRoleType);
export const chatsGetMemberName: (state: RootState, chatId: ChatId) => undefined | string = appCreateSelector([chatsGetChatById],
//
chat => chat?.chatMateUserName);
export const chatsGetMemberSex: (state: RootState, chatId: ChatId) => undefined | SEX = appCreateSelector([chatsGetChatById],
//
chat => chat?.sex);
export const chatsGetMemberRating: (state: RootState, chatId: ChatId) => undefined | MemberRating = appCreateSelector([chatsGetChatById],
//
chat => chat?.userRating);
export const chatsGetMemberIsCertified: (state: RootState, chatId: ChatId) => undefined | boolean = appCreateSelector([chatsGetChatById],
//
chat => chat?.userIsCertified);
export const chatsGetMemberProfSpeciality: (state: RootState, chatId: ChatId) => undefined | PROF_SPECIALITY = appCreateSelector([chatsGetChatById],
//
chat => chat?.userProfSpeciality);
export const chatsGetMemberOnlineStatus: (state: RootState, chatId: ChatId) => undefined | ONLINE_STATUS = appCreateSelector([chatsGetChatById],
//
chat => chat?.userOnlineStatus);
export const chatsGetMemberLastVisitTime: (state: RootState, chatId: ChatId) => undefined | string = appCreateSelector([chatsGetChatById],
//
chat => chat?.lastVisitTime);
export const chatsGetMemberHasComplaint: (state: RootState, chatId: ChatId) => undefined | boolean = appCreateSelector([chatsGetChatById],
//
chat => chat?.userHasComplaint);
export const chatsGetMemberHasComplaintAgainstMe: (state: RootState, chatId: ChatId) => undefined | boolean = appCreateSelector([chatsGetChatById],
//
chat => chat?.userHasComplaintAgainstMe);
export const chatsGetMemberIsDeleted: (state: RootState, chatId: ChatId) => undefined | boolean = appCreateSelector([chatsGetChatById],
//
chat => chat?.userIsDeleted);
export const chatsGetIsFavorite: (state: RootState, chatId: ChatId) => undefined | boolean = appCreateSelector([chatsGetChatById],
//
chat => chat?.isFavorite);
export const chatsGetInBlackList: (state: RootState, chatId: ChatId) => undefined | boolean = appCreateSelector([chatsGetChatById],
//
chat => chat?.inBlackList);
export const chatsGetImageUrl: (state: RootState, chatId: ChatId) => undefined | string = appCreateSelector([chatsGetChatById],
//
chat => {
  if (!chat) {
    return undefined;
  }
  return photoOrAvatarUrl(chat.photoUrl, chat.avatarId, chat.sex);
});
export const chatsGetUnreadMessagesCountByChatId: (state: RootState, chatId: ChatId) => undefined | number = appCreateSelector([chatsGetChatById], chat => chat?.unreadedMessagesCount);
export const chatsGetHasUnreadMessages: (state: RootState, chatId: ChatId) => undefined | boolean = appCreateSelector([chatsGetUnreadMessagesCountByChatId], unreadMessagesCount => {
  if (unreadMessagesCount == null) {
    return;
  }
  return Boolean(unreadMessagesCount);
});

/**
 * @deprecated
 * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
 */
export const chatsGetPrepaymentInfo: (state: RootState, chatId: ChatId) => undefined | ChatPrepaymentInfo = appCreateSelector([chatsGetChatById], chat => chat?.prepaymentInfo);

/**
 * @deprecated
 * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
 */
export const chatsGetPrepaymentBalanceAmount: (state: RootState, chatId: ChatId) => undefined | number = appCreateSelector([chatsGetPrepaymentInfo], prepaymentInfo => prepaymentInfo?.balanceAmount);

/**
 * @deprecated
 * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
 */
export const chatsGetPrepaymentTotalAmount: (state: RootState, chatId: ChatId) => undefined | number = appCreateSelector([chatsGetPrepaymentInfo], prepaymentInfo => prepaymentInfo?.totalAmount);