import { useMemo } from 'react';
import { AppFCC } from '@types';
import { useImageSource, UseImageSourceInput } from '@hooks/image';
import { EmojiImageStyled } from './styled';
import { EmojiData, EmojiProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const data: EmojiData = {
  'cowboy-hat-face': '/emoji/cowboy-hat-face.png',
  'hugging-face': '/emoji/hugging-face.png',
  'red-heart': '/emoji/red-heart.png',
  'smiling-face': '/emoji/smiling-face.png',
  'smiling-face-with-sunglasses': '/emoji/smiling-face-with-sunglasses.png',
  sparkles: '/emoji/sparkles.png'
};
export const Emoji: AppFCC<EmojiProps> = props => {
  const {
    name,
    ...restProps
  } = props;
  const originSrc = data[name];
  if (!originSrc) {
    console.warn(`Emoji ${name}'s URL could not be found.`);
  }
  const useImageSourceInput: UseImageSourceInput = useMemo(() => ({
    src: originSrc
  }),
  //
  [originSrc]);
  const {
    src
  } = useImageSource(useImageSourceInput);
  return _jsx(EmojiImageStyled //
  , {
    src: src,
    alt: name,
    "aria-label": name,
    ...restProps
  });
};