import { ChatsRemoveFromBlackListReducer } from './types';
export const chatsRemoveFromBlackListReducer: ChatsRemoveFromBlackListReducer = (state, action) => {
  const {
    chatId
  } = action.payload;
  const {
    chatsById
  } = state;
  const chat = chatsById[chatId];
  if (!chat) {
    return state;
  }
  return {
    ...state,
    chatsById: {
      ...chatsById,
      [chatId]: {
        ...chat,
        inBlackList: false
      }
    }
  };
};