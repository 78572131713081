import { useCallback, useEffect } from 'react';
import { EVENT_NAMES } from '@config/dom';
import { addEvent, isEscape } from '@helpers/dom';
import { AppFCC } from '@types';
import { popupsGetLastPopup } from '@selectors/popups';
import { useTypedSelector } from '@hooks/store';
import { Popup } from '@kit/popups/popup';
import { PopupWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const PopupWidget: AppFCC<PopupWidgetProps> = props => {
  const {
    popupId,
    onClose,
    ...restProps
  } = props;
  const lastPopup = useTypedSelector(popupsGetLastPopup);
  const escape = useCallback((event: KeyboardEvent) => {
    if (lastPopup?.id === popupId) {
      onClose?.(event);
    }
  }, [lastPopup?.id, popupId, onClose]);
  useEffect(() => {
    const unsub = addEvent(window, EVENT_NAMES.KEYUP, event => {
      if (event instanceof KeyboardEvent && isEscape(event)) {
        escape(event);
      }
    });
    return unsub;
  }, [escape]);
  return _jsx(Popup //
  , {
    onClose: onClose,
    ...restProps
  });
};