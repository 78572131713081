import { ENDPOINTS } from '@config/endpoints';
import { jsonFetch, JsonFetchResult } from '@services/base/json-fetch';
import { SubjectsGetResponseData, SubjectsGetResponseDto } from '@services/dto/subjects';
import { subjectsGetResponseFromDto } from '@mapping/subjects';
type SubjectsGet = () => JsonFetchResult<SubjectsGetResponseData>;
export const subjectsGet: SubjectsGet = () => {
  const {
    promise,
    cancel
  } = jsonFetch<SubjectsGetResponseDto>(ENDPOINTS.subjects.get(), {
    method: 'GET'
  });
  return {
    promise: promise.then(subjectsGetResponseFromDto),
    cancel
  };
};