import { cx } from '@emotion/css';
import { AppFCC } from '@types';
import { LoadingOverlay } from '@kit/loading-overlay';
import { loadingScreenClassName } from './styled';
import { LoadingScreenProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LoadingScreen: AppFCC<LoadingScreenProps> = props => {
  const {
    className,
    ...restProps
  } = props;
  return _jsx(LoadingOverlay, {
    className: cx(loadingScreenClassName, className),
    ...restProps
  });
};