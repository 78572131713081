import { MemberId, MemberRating, ONLINE_STATUS, PROF_SPECIALITY, SubjectId, SubjectIds } from '@types';
export type SupportMessageId = string;
export type SupportMessageIds = SupportMessageId[];
export type SupportMessagesById = Record<SupportMessageId, SupportMessage>;
export enum SUPPORT_MESSAGE_TYPE {
  T1 = 1,
  T5 = 5,
}
export type SupportMessageRecommendedSpecialist = {
  userId: MemberId;
  defaultSubjectId: SubjectId;
  onlineStatus: ONLINE_STATUS;
  photoUrl: string;
  name: string;
  rating: MemberRating;
  profSpeciality: PROF_SPECIALITY;
  isCertified: boolean;
};
export type SupportMessageRecommendedSpecialists = SupportMessageRecommendedSpecialist[];
export type SupportBaseMessage = {
  id: SupportMessageId;
  isRead: boolean;
  creationTime: string;
};
export type SupportT1Message = SupportBaseMessage & {
  type: SUPPORT_MESSAGE_TYPE.T1;
  data: {
    name: string;
    subjectIds: SubjectIds;
  };
};
export type SupportT5Message = SupportBaseMessage & {
  type: SUPPORT_MESSAGE_TYPE.T5;
  data: {
    name: string;
    subjectIds: SubjectIds;
    specialists: SupportMessageRecommendedSpecialists;
  };
};
export type SupportMessage = SupportT1Message | SupportT5Message;
export type SupportMessages = SupportMessage[];