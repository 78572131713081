import { uniqById } from '@helpers/data';
import { calendarRejectWorkingTimesByPreiod } from '@mapping/calendar/calendar-reject-working-times-by-preiod';
import { CalendarReceiveGetMemberWorkingTimesReducer } from './types';
export const calendarReceiveGetMemberWorkingTimesReducer: CalendarReceiveGetMemberWorkingTimesReducer = (state, action) => {
  const {
    memberId,
    dateFrom,
    dateTo,
    data
  } = action.payload;
  const member = state.membersById?.[memberId];
  if (!member) {
    return state;
  }
  const calendar = member.calendar;
  if (!calendar) {
    return state;
  }
  return {
    ...state,
    membersById: {
      ...state.membersById,
      [memberId]: {
        ...member,
        calendar: {
          ...calendar,
          ...data,
          workingTimes: uniqById([...data, ...calendarRejectWorkingTimesByPreiod(calendar.workingTimes, dateFrom, dateTo)])
        }
      }
    }
  };
};