import { GEOMETRY_TYPE } from '@config/geometry';
import { appCreateSelector, RootState } from '@store';
import { GeometryState } from '@slices/geometry';

/**
 * regular selectors
 */

export const geometryGet = (state: RootState): GeometryState => {
  return state.ui.geometry;
};

/**
 * memoized reselect selectors
 */

export const geometryGetType: (state: RootState) => GEOMETRY_TYPE = appCreateSelector([geometryGet], geometry => geometry.type);