import { MediaCallHandlerLocalAudioTrack, MediaCallHandlerLocalVideoTrack, MediaCallHandlerRemoteAudioTrack, MediaCallHandlerRemoteVideoTrack } from '@media-call-handler';
import { difference } from '@helpers/data';
import { isActiveCall, isConnectingCall, isIncomingCall, isOutgoingCall } from '@helpers/media-call';
import { ChatId, MediaCall, MediaCallRoomId, MediaCalls } from '@types';
import { appCreateSelector, RootState } from '@store';
import { MediaCallsById, MediaCallsIds, MediaCallState } from '@slices/media-call';

/**
 * regular selectors
 */

export const mediaCallsGet = (state: RootState): MediaCallState => {
  return state.app.mediaCalls;
};

/**
 * reselect memoized selectors
 */

export const mediaCallsGetById: (state: RootState) => MediaCallsById = appCreateSelector([mediaCallsGet], mediaCalls => mediaCalls.mediaCallsById);
export const mediaCallsGetIds: (state: RootState) => MediaCallsIds = appCreateSelector([mediaCallsGet], mediaCalls => mediaCalls.mediaCallsIds);
export const mediaCallsGetList: (state: RootState) => MediaCalls = appCreateSelector([mediaCallsGetIds, mediaCallsGetById], (mediaCallsIds, mediaCallsById) => {
  return mediaCallsIds.map(mediaCallId => mediaCallsById[mediaCallId]);
});
export const mediaCallsGetConnectingCall: (state: RootState) => undefined | MediaCall = appCreateSelector([mediaCallsGetList], mediaCallsList => mediaCallsList.find(isConnectingCall));
export const mediaCallsGetConnectingCallChatId: (state: RootState) => undefined | ChatId = appCreateSelector([mediaCallsGetConnectingCall], connectingCall => connectingCall?.chatId);
export const mediaCallsGetHasConnectingCall: (state: RootState) => boolean = appCreateSelector([mediaCallsGetConnectingCall],
//
Boolean);
export const mediaCallsGetConnectingCallIsIncoming: (state: RootState) => boolean = appCreateSelector([mediaCallsGetConnectingCall],
//
isIncomingCall);
export const mediaCallsGetConnectingCallIsOutgoing: (state: RootState) => boolean = appCreateSelector([mediaCallsGetConnectingCall],
//
isOutgoingCall);
export const mediaCallsGetActiveCall: (state: RootState) => undefined | MediaCall = appCreateSelector([mediaCallsGetList], mediaCallsList => mediaCallsList.find(isActiveCall));
export const mediaCallsGetActiveCallChatId: (state: RootState) => undefined | ChatId = appCreateSelector([mediaCallsGetActiveCall], activeCall => activeCall?.chatId);
export const mediaCallsGetHasActiveCall: (state: RootState) => boolean = appCreateSelector([mediaCallsGetActiveCall],
//
Boolean);
export const mediaCallsGetIdsByExclusion: (state: RootState, excludedIds: MediaCallsIds) => MediaCallsIds = appCreateSelector([mediaCallsGetIds, (_state, excludedIds: MediaCallsIds) => excludedIds], difference);
export const mediaCallsGetCallByChatId: (state: RootState, chatId: ChatId) => MediaCall | undefined = appCreateSelector([mediaCallsGetList, (_state, chatId: ChatId) => chatId], (mediaCallsList, chatId) => mediaCallsList.find(mediaCall => mediaCall.chatId === chatId));
export const mediaCallsGetCallRoomIdByChatId: (state: RootState, chatId: ChatId) => MediaCallRoomId | undefined = appCreateSelector([mediaCallsGetCallByChatId], mediaCall => mediaCall?.roomId);
export const mediaCallsGetCallRemoteVideo: (state: RootState, chatId: ChatId) => MediaCallHandlerRemoteVideoTrack | undefined = appCreateSelector([mediaCallsGetCallByChatId], mediaCall => mediaCall?._remoteVideoTrack);
export const mediaCallsGetCallHasRemoteVideo: (state: RootState, chatId: ChatId) => boolean = appCreateSelector([mediaCallsGetCallRemoteVideo],
//
Boolean);
export const mediaCallsGetCallLocalVideo: (state: RootState, chatId: ChatId) => MediaCallHandlerLocalVideoTrack | undefined = appCreateSelector([mediaCallsGetCallByChatId], mediaCall => mediaCall?._localVideoTrack);
export const mediaCallsGetCallHasLocalVideo: (state: RootState, chatId: ChatId) => boolean = appCreateSelector([mediaCallsGetCallLocalVideo],
//
Boolean);
export const mediaCallsGetCallRemoteAudio: (state: RootState, chatId: ChatId) => MediaCallHandlerRemoteAudioTrack | undefined = appCreateSelector([mediaCallsGetCallByChatId], mediaCall => mediaCall?._remoteAudioTrack);
export const mediaCallsGetCallHasRemoteAudio: (state: RootState, chatId: ChatId) => boolean = appCreateSelector([mediaCallsGetCallRemoteAudio],
//
Boolean);
export const mediaCallsGetCallLocalAudio: (state: RootState, chatId: ChatId) => MediaCallHandlerLocalAudioTrack | undefined = appCreateSelector([mediaCallsGetCallByChatId], mediaCall => mediaCall?._localAudioTrack);
export const mediaCallsGetCallHasLocalAudio: (state: RootState, chatId: ChatId) => boolean = appCreateSelector([mediaCallsGetCallLocalAudio],
//
Boolean);
export const mediaCallsCallIsAcquiringVideo: (state: RootState, chatId: ChatId) => boolean = appCreateSelector([mediaCallsGetCallByChatId],
//
mediaCall => Boolean(mediaCall?._acquiringVideo));
export const mediaCallsGetCallIsAcquiringAudio: (state: RootState, chatId: ChatId) => boolean = appCreateSelector([mediaCallsGetCallByChatId],
//
mediaCall => Boolean(mediaCall?._acquiringAudio));
export const mediaCallsGetCallIsAcquiringMedia: (state: RootState, chatId: ChatId) => boolean = appCreateSelector([mediaCallsCallIsAcquiringVideo, mediaCallsGetCallIsAcquiringAudio], (isAcquiringVideo, isAcquiringAudio) => isAcquiringVideo || isAcquiringAudio);