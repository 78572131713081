import { rejectPopup } from '@helpers/popups';
import { Popup } from '@types';
import { PayloadAction } from '@store';
import { PopupsState } from '@slices/popups';
import { AddPopupPayload } from './types';
export function addPopupReducer<P = undefined>(state: PopupsState, action: PayloadAction<AddPopupPayload<P>>): PopupsState {
  const popup = {
    ...action.payload,
    id: (action.payload.id.toLowerCase() as Popup['id'])
  };
  if (popup.uuid) {
    return [...state, {
      ...popup,
      uuid: popup.uuid
    }];
  }
  return [...rejectPopup(state, popup), popup];
}