import { mapValues } from '@helpers/data';
import { MessagesSetReadByChatIdReducer } from './types';
export const messagesSetReadByChatIdReducer: MessagesSetReadByChatIdReducer = (state, action) => {
  const {
    chatId,
    currentMemberId
  } = action.payload;
  const {
    byChatId
  } = state;
  const currentChatMessagesData = byChatId[chatId] ?? {};
  const {
    messagesById = {}
  } = currentChatMessagesData;
  return {
    ...state,
    byChatId: {
      ...byChatId,
      [chatId]: {
        ...currentChatMessagesData,
        messagesById: mapValues(messagesById, message => {
          if (message.senderUserId === currentMemberId) {
            return {
              ...message,
              isRead: true
            };
          }
          return message;
        })
      }
    }
  };
};