import { ru } from 'date-fns/locale';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { formatInTimeZone } from 'date-fns-tz';
import plural from 'plural-ru';
import sklonenie from 'sklonenie';
import { DATE_FORMAT, EEEE_dd_MM_FORMAT, EEEEEE_dd_MM_yy_FORMAT, HH_MM_TIME_FORMAT } from '@config/format';
import { isNullable, rejectBy } from '@helpers/data';
import { addMinutes, getTimezoneId, getTimezoneOffset, intervalToDuration } from '@helpers/date';
import { timezoneTranslations } from '@localization/timezones';
import { CULTURE, SEX } from '@types';
export { default as upperFirst } from 'lodash/upperFirst';
import { default as upperFirst } from 'lodash/upperFirst';
setDefaultOptions({
  locale: ru
});
export const joinWith = <T extends undefined | null | string | number,>(arr: T[], separator = ''): string => {
  return rejectBy(arr, value => isNullable(value) || value === '') //
  .join(separator);
};
export const lowercaseFirstLetter = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};
export const pluralWithValue = (num: number, firstForm: string, secondForm: string, thirdForm: string): string => {
  return `${num} ${plural(num, firstForm, secondForm, thirdForm)}`;
};
export const padNumber = (value: number): string => {
  return value.toString().padStart(2, '0');
};
export const formatDate = (date: Date, dateFormat = DATE_FORMAT, options?: {
  locale?: Locale;
  timezone?: string;
}): string => {
  const _options = {
    ...options,
    locale: options?.locale ?? ru,
    timeZone: options?.timezone ?? getTimezoneId()
  };
  return formatInTimeZone(date,
  //
  _options.timeZone, dateFormat, _options);
};
export const formatTime = (date: string, timezone: undefined | string): string => {
  const formattedTime = formatDate(new Date(date),
  //
  HH_MM_TIME_FORMAT, {
    timezone
  });
  return formattedTime;
};
export const formatTimezoneOffset = (timezoneOffset: undefined | number): undefined | string => {
  if (timezoneOffset == null) {
    return undefined;
  }
  const start = new Date();
  const end = addMinutes(start, timezoneOffset);
  const duration = intervalToDuration({
    start,
    end
  });
  const hours = String(duration.hours);
  const sign = timezoneOffset >= 0 //
  ? '+' : timezoneOffset < 0 ? '-' : '';
  if (duration.minutes) {
    return `GMT${sign}${hours}:${padNumber(duration.minutes)}`;
  }
  return `GMT${sign}${hours}`;
};
export const formatTimezone = (timezone: undefined | string, culture = CULTURE.RU): undefined | string => {
  if (!timezone) {
    return undefined;
  }
  const timezoneTranslation = timezoneTranslations[timezone];
  const formattedTimezone = formatTimezoneOffset(getTimezoneOffset(undefined, timezone));
  return `${timezoneTranslation //
  ? timezoneTranslation[culture] : timezone} (${formattedTimezone})`;
};
export const formatDateRangeWithTimezone = (dateFrom: Date, dateTo: Date, timezone: undefined | string): string => {
  const formattedTimeFrom = formatDate(dateFrom,
  //
  HH_MM_TIME_FORMAT, {
    timezone
  });
  const formattedDateTo = formatDate(dateTo,
  //
  HH_MM_TIME_FORMAT, {
    timezone
  });
  const formattedTimeTo = formatDate(dateFrom,
  //
  EEEE_dd_MM_FORMAT, {
    timezone
  });
  const formattedTimezone = formatTimezoneOffset(getTimezoneOffset(undefined, timezone));
  return `${formattedTimeFrom} - ${formattedDateTo} (${formattedTimezone}),
  ${formattedTimeTo}`;
};
export const formatBeginTimeWithTimezone = (beginTime: Date, timezone: undefined | string): string => {
  const formattedTimeFrom = formatDate(beginTime,
  //
  HH_MM_TIME_FORMAT, {
    timezone
  });
  const formattedTimeTo = formatDate(beginTime,
  //
  EEEEEE_dd_MM_yy_FORMAT, {
    timezone
  });
  const formattedTimezone = formatTimezoneOffset(getTimezoneOffset(undefined, timezone));
  return `${formattedTimeFrom} в ${upperFirst(formattedTimeTo)}, (${formattedTimezone})`;
};

/**
 * Форматирование прошедшего времени
 * @param period - время в минутах
 */
export const formatElapsedTime = (period: number): string => {
  const minRange = 0;
  const hourDivider = 60;
  const dayDivider = hourDivider * 24;
  const weekDivider = dayDivider * 7;
  const monthDivider = dayDivider * 30;
  const monthsElapsed = Math.floor(period / monthDivider);
  const weeksElapsed = Math.floor(period / weekDivider);
  const daysElapsed = Math.floor(period / dayDivider);
  const hoursElapsed = Math.floor(period / hourDivider);
  if (monthsElapsed > minRange) {
    return pluralWithValue(monthsElapsed, 'месяц', 'месяца', 'месяцев');
  }
  if (weeksElapsed > minRange) {
    return pluralWithValue(weeksElapsed, 'неделю', 'недели', 'недель');
  }
  if (daysElapsed > minRange) {
    return pluralWithValue(daysElapsed, 'день', 'дня', 'дней');
  }
  if (hoursElapsed > minRange) {
    return pluralWithValue(hoursElapsed, 'час', 'часа', 'часов');
  }
  return pluralWithValue(Math.ceil(period), 'минуту', 'минуты', 'минут');
};
export const formatCounter = (count: number, maxValue = 30): string => {
  if (!count) {
    return '';
  }
  return count > maxValue ? `${maxValue}+` : `${count}`;
};
export const formatAmount = (amount: number): string => {
  return amount //
  .toLocaleString('en-US').replace(/,/g, ' ');
};

/**
 * @todo
 * Thinks about common solution that will work with plurals and cases.
 */
export const formatCoins = (value: number): string => {
  return plural(value, 'монета', 'монеты', 'монет');
};
export const formatCoinsAccusative = (value: number): string => {
  return plural(value, 'монету', 'монеты', 'монет');
};
export const formatCoinsParental = (value: number): string => {
  return plural(value, 'монеты', 'монет', 'монет');
};
export const formatSubjects = (value: number): string => {
  return pluralWithValue(value, 'тема', 'темы', 'тем');
};
export enum POSSESSIVE {
  SUBJECTIVE = 'именительный',
  GENITIVE = 'родительный',
  DATIVE = 'дательный',
  ACCUSATIVE = 'винительный',
  INSTRUMENTAL = 'творительный',
  PREPOSITIONAL = 'предложный',
}
export const induceFirstName = (firstName: string, possessive: POSSESSIVE, sex?: SEX): string => {
  const name = sklonenie.firstname(firstName, sex);
  return name[possessive];
};
export const extractCardNumberLastDigits = (cardNumber: string): string => {
  return cardNumber.trim().slice(-4);
};

/**
 * @todo check is that method good for get thumbnails from cloudinary videos
 */
export const getThumbUrlByVideoUrl = (url: string | undefined): string | undefined => {
  if (!url) {
    return undefined;
  }
  const jpgUrl = url.split('.');
  jpgUrl.pop();
  return `${jpgUrl.join('.')}.jpg`;
};
export const composeShowMoreTagMoreText = (amount: number): string => {
  return `и еще ${formatSubjects(amount)}`;
};