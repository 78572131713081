import { NOTIFICATION_TYPE } from '@config/notifications';
import { calendarConsultationInitiator } from '@helpers/calendar';
import { camelKeysRecursive, readJson } from '@helpers/data';
import { photoOrAvatarUrl } from '@helpers/member';
import { RealtimeCalendarConsultationCreatedEventPayloadData } from '@services/dto/realtime/realtime-calendar-event/realtime-calendar-consultation-created';
import { notificationsUiHandleAdd } from '@actions/notifications-ui/notifications-ui-handle-add';
import { calendarIncreaseConsultationsCounter, realtimeReceiveCalendarConsultationCreated } from '@slices/calendar';
import { accountGetAccountId } from '@selectors/account';
import { RealtimeCalendarConsultationCreated } from './types';
export const realtimeCalendarConsultationCreated: RealtimeCalendarConsultationCreated = event => {
  return (dispatch, getState) => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeCalendarConsultationCreatedEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      consultation
    } = data;
    const currentMemberId = accountGetAccountId(getState());
    const initiator = calendarConsultationInitiator(consultation, currentMemberId);
    if (!initiator) {
      return;
    }
    dispatch(realtimeReceiveCalendarConsultationCreated({
      data
    }));
    dispatch(notificationsUiHandleAdd({
      type: NOTIFICATION_TYPE.NEW_CALENDAR,
      avatar: photoOrAvatarUrl(initiator.photoUrl, initiator.avatarId, undefined),
      name: initiator.name,
      consultation,
      initiator
    }));
    dispatch(calendarIncreaseConsultationsCounter({
      increment: 1
    }));
  };
};