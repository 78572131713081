import { FRONTEND_APPLICATION_HOST } from '@config/env';
import { enforceDateStringToISO } from '@helpers/date';
import { SpecialistsPostMeRequestData, SpecialistsPostMeRequestDto, SpecialistsPostMeResponseData, SpecialistsPostMeResponseDto } from '@services/dto/specialists';
export const specialistsPostMeRequestToDto = (data: SpecialistsPostMeRequestData): SpecialistsPostMeRequestDto => {
  return {
    ...data,
    frontEndApplicationHost: FRONTEND_APPLICATION_HOST
  };
};
export const specialistsPostMeResponseFromDto = (dto: SpecialistsPostMeResponseDto): SpecialistsPostMeResponseData => {
  return {
    ...dto.data,
    birthDate: dto.data.birthDate ? enforceDateStringToISO(dto.data.birthDate) : undefined
  };
};