import { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { isDescendantOf } from '@helpers/dom';
import { AppFCC } from '@types';
import { PopupScrollableOverlayStyled, PopupScrollableStyled } from './styled';
import { PopupScrollableProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const PopupScrollable: AppFCC<PopupScrollableProps> = props => {
  const {
    onClick,
    children
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    /**
     * setTimeout is used here to disable body scroll after
     * setting of 'top' value for body and --app-global-scrollbar-width css
     * variable value in Popups kit component.
     */
    setTimeout(() => {
      disableBodyScroll(element,
      //
      {
        allowTouchMove: _element => isDescendantOf(_element, element)
      });
    }, 0);
    return () => {
      enableBodyScroll(element);
    };
  }, []);
  return _jsxs(PopupScrollableStyled, {
    ref: ref,
    children: [_jsx(PopupScrollableOverlayStyled //
    , {
      onClick: onClick
    }), children]
  });
};