import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { Action, Store } from 'redux';
import { AppAction } from '@actions/app';
import { AppState } from '@reducers/app';
import { UIState } from '@reducers/ui';
export type { PayloadAction } from '@reduxjs/toolkit';

/**
 * Reducers
 */

/**
 * Reducer Generic Type for RTK Slices
 * @typeParam: S - slice state type
 * @typeParam P - action payload type
 */
export type AppReducer<S, P = void> = (state: S, action: PayloadAction<P>) => S;

/**
 * Actions
 */

export enum DISPATCHER {
  REALTIME = 'REALTIME',
}
export type ReduxAction<T = string> = Action<T> & {
  dispatcher?: DISPATCHER;
};
export type ReduxPayloadAction<T = string, P = unknown> = ReduxAction<T> & {
  payload: P;
};
export type ReduxThunkAction<R = void> = ThunkAction<R, RootState, unknown, ReduxAction | ReduxPayloadAction>;
export type ReduxAsyncAction<R = void> = ReduxThunkAction<Promise<R>>;
export type ReduxThunkDispatch = ThunkDispatch<RootState, unknown, ReduxAction>;
export type RootAction = AppAction | ReduxPayloadAction<typeof HYDRATE, RootState>;

/**
 * Store
 */

export type RootState = {
  ui: UIState;
  app: AppState;
};
export type RootDispatch = ReduxThunkDispatch;
export type RootStore = Store<RootState, RootAction> & {
  dispatch: RootDispatch;
};