import { subjectsGet } from '@services/subjects';
import { subjectsReceiveGet } from '@slices/subjects';
import { SubjectsFetchGet } from './types';
export const subjectsFetchGet: SubjectsFetchGet = () => {
  return async dispatch => {
    const data = await subjectsGet().promise;
    dispatch(subjectsReceiveGet({
      subjects: data
    }));
    return data;
  };
};