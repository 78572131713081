import { SpecialistsReceiveGetByIdReducer } from './types';
export const specialistsReceiveGetByIdReducer: SpecialistsReceiveGetByIdReducer = (state, action) => {
  const {
    specialist,
    specialistId
  } = action.payload;
  return {
    ...state,
    specialistsById: {
      ...state.specialistsById,
      [specialistId]: specialist
    }
  };
};