import { omit, reject } from '@helpers/data';
import { NotificationsUiRemoveReducer } from './types';
export const notificationsUiRemoveReducer: NotificationsUiRemoveReducer = (state, action) => {
  const {
    byId,
    ids
  } = state;
  const {
    id
  } = action.payload;
  return {
    ...state,
    ids: reject(ids, id),
    byId: omit(byId, id)
  };
};