import { POPUP } from '@config/popups';
import { removeAuthData } from '@storage/auth';
import { resetPopupImpressionsNumber } from '@storage/popups';
import { appResetStore } from '@actions/app';
import { accountSetUnauthorized } from '@slices/account';
import { AccountLogout } from './types';
export const accountLogout: AccountLogout = () => dispatch => {
  removeAuthData();
  dispatch(accountSetUnauthorized());
  dispatch(appResetStore());

  /**
   * We don't rely on currentMemberId here because
   * ACCOUNT_EMAIL_CONFIRMATION_REQUIRED popup impressions number is reset
   * on unauthorization.
   */
  const currentMemberId = '';
  resetPopupImpressionsNumber(undefined, POPUP.ACCOUNT_EMAIL_CONFIRMATION_REQUIRED, currentMemberId);
};