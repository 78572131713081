export const AUTH_CLIENT_ID = 'Freuders.WebAPI';
export const AUTH_TOKEN_TYPE = 'Bearer';
export enum AUTH_GRANT_TYPE {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}
export enum GOOGLE_ACCESS_TYPE {
  ONLINE = 'online',
  OFFLINE = 'offline',
}
export enum GOOGLE_AUTH_SCOPE {
  EMAIL = 'email',
  PROFILE = 'profile',
}
export enum GOOGLE_AUTH_RESPONSE_TYPE {
  CODE = 'code',
}
export enum GOOGLE_AUTH_PROMPT {
  NONE = 'none',
  CONSENT = 'consent',
  SELECT_ACCOUNT = 'select_account',
}
export enum VK_AUTH_DISPLAY {
  POPUP = 'popup',
  PAGE = 'page',
  MOBILE = 'mobile',
}
export enum VK_AUTH_SCOPE {
  EMAIL = 'email',
}
export enum VK_AUTH_RESPONSE_TYPE {
  CODE = 'code',
  TOKEN = 'token',
}
export enum FACEBOOK_AUTH_DISPLAY {
  POPUP = 'popup',
  PAGE = 'page',
  IFRAME = 'iframe',
  ASYNC = 'async',
  TOUCH = 'touch',
}
export enum FACEBOOK_AUTH_SCOPE {
  USER_BIRTHDAY = 'user_birthday',
  USER_GENDER = 'user_gender',
  EMAIL = 'email',
}
export enum FACEBOOK_AUTH_RESPONSE_TYPE {
  CODE = 'code',
  TOKEN = 'token',
  CODE_AND_TOKEN = 'code%20token',
  GRANTED_SCOPES = 'granted_scopes',
}