import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsVideoViewProgressEvent } from '@logging/logging-events';
import { AnalyticsVideoViewProgressEventRequestData } from '@services/dto/analytics/analytics-send-event/analytics-video-view-progress-event';
export const isSendVideoViewProgressEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsVideoViewProgressEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.VIDEO_VIEW_PROGRESS;
};
export const sendVideoViewProgress = (eventBus: IEventBus, data: AnalyticsVideoViewProgressEventRequestData['data']): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<AnalyticsVideoViewProgressEventRequestData['data']>({
    action: ANALYTICS_ACTION.VIDEO_VIEW_PROGRESS,
    data
  }));
};