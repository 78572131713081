import { calendarIsCurrentSpecialist, calendarIsCurrentSpecialistCalendar } from '@helpers/calendar';
import { CalendarReceiveUpdateCurrentMemberWorkingTimesReducer } from './types';
export const calendarReceiveUpdateCurrentMemberWorkingTimesReducer: CalendarReceiveUpdateCurrentMemberWorkingTimesReducer = (state, action) => {
  const {
    data
  } = action.payload;
  const currentMember = state.currentMember;
  if (calendarIsCurrentSpecialist(currentMember) && calendarIsCurrentSpecialistCalendar(currentMember?.calendar)) {
    const calendar = currentMember.calendar;
    return {
      ...state,
      currentMember: {
        ...currentMember,
        calendar: {
          ...calendar,
          workingTimes: data
        }
      }
    };
  }
  return state;
};