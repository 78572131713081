import { useMemo } from 'react';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { notificationsUiRemove } from '@slices/notifications-ui';
import { useNavigator } from '@hooks/routing';
import { useTypedDispatch } from '@hooks/store';
import { NotificationUiWidget, NotificationUiWidgetProps } from '@widgets/notifications/notification-ui';
import { ViewsIcon } from '@icons';
import { NewViewNotificationProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NewViewNotification: AppFCC<NewViewNotificationProps> = props => {
  const {
    notificationId,
    name,
    avatar
  } = props;
  const dispatch = useTypedDispatch();
  const navigator = useNavigator();
  const actionButton: NotificationUiWidgetProps['actionButton'] = useMemo(() => {
    return {
      icon: _jsx(ViewsIcon, {}),
      text: 'Просмотреть',
      onClick: () => {
        navigator.navigate(ROUTES.views.index.getDescriptor(undefined,
        //
        {
          resetScroll: true
        }));
        dispatch(notificationsUiRemove({
          id: notificationId
        }));
      }
    };
  }, [dispatch, navigator, notificationId]);
  return _jsx(NotificationUiWidget //
  , {
    notificationId: notificationId,
    title: "\u041D\u043E\u0432\u044B\u0439 \u043F\u0440\u043E\u0441\u043C\u043E\u0442\u0440",
    name: name,
    avatar: avatar,
    actionButton: actionButton
  });
};