import { POPUP, PUSH_PROMPT_IMPRESSIONS_MAX_NUMBER } from '@config/popups';
import { readPopupImpressionsNumber, writePopupImpressionsNumber } from '@storage/popups';
import { accountGetAccountId } from '@selectors/account';
import { NotificationsShowPushPrompt } from './types';
export const notificationsShowPushPrompt: NotificationsShowPushPrompt = payload => {
  return async (_dispatch, getState) => {
    const {
      storage,
      push
    } = payload;
    const state = getState();
    const currentMemberId = accountGetAccountId(state);
    if (!currentMemberId) {
      return;
    }
    const promptImpressionsNumber = readPopupImpressionsNumber(storage,
    //
    POPUP.PUSH_PROMPT, currentMemberId) ?? 0;
    if (promptImpressionsNumber >= PUSH_PROMPT_IMPRESSIONS_MAX_NUMBER) {
      return;
    }
    await push.showPrompt();
    writePopupImpressionsNumber(storage, POPUP.PUSH_PROMPT, currentMemberId, promptImpressionsNumber + 1);
  };
};