import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimeChatHasBeenReadEventPayloadData } from '@services/dto/realtime/realtime-chat-event/realtime-chat-has-been-read';
import { messagesSetReadByChatId } from '@slices/messages';
import { accountGetAccountId } from '@selectors/account';
import { RealtimeChatHasBeenRead } from './types';
export const realtimeChatHasBeenRead: RealtimeChatHasBeenRead = event => {
  return (dispatch, getState) => {
    const {
      payload
    } = event;
    const data = readJson<RealtimeChatHasBeenReadEventPayloadData>(payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      chatId
    } = data;
    const state = getState();
    const currentMemberId = accountGetAccountId(state);
    if (currentMemberId) {
      dispatch(messagesSetReadByChatId({
        chatId,
        currentMemberId
      }));
    }
  };
};