/**
 * @todo Move enum below to config/forms
 */
export enum LANDING_CLIENT_REGISTER_STEPS {
  START = 0,
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FORTH = 4,
}
export enum LANDING_CLIENT_CHAT_STEPS {
  START = 0,
  ROCKET = 1,
  NAME = 2,
  BIRTHDATE = 3,
  SEX = 4,
  CREDENTIALS = 5,
}