import { AppFCC } from '@types';
import { Overlay } from '@kit/overlay';
import { PopupContent } from './popup-content';
import { PopupScrollable } from './popup-scrollable';
import { PopupStyled } from './styled';
import { PopupProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const Popup: AppFCC<PopupProps> = props => {
  const {
    position,
    overlayBgColor,
    onClose,
    children
  } = props;
  return _jsxs(PopupStyled, {
    children: [_jsx(Overlay //
    , {
      overlayBgColor: overlayBgColor,
      onClick: onClose
    }), _jsx(PopupScrollable, {
      onClick: onClose,
      children: _jsx(PopupContent //
      , {
        position: position,
        children: children
      })
    })]
  });
};