import { AppFCC } from '@types';
import { StarFilledIcon, StarOutlineIcon } from '../icons-vector/components';
import { FavoriteIconProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const FavoriteIcon: AppFCC<FavoriteIconProps> = props => {
  const {
    className,
    isFavorite
  } = props;
  if (isFavorite) {
    return _jsx(StarFilledIcon, {
      className: className
    });
  }
  return _jsx(StarOutlineIcon, {
    className: className
  });
};