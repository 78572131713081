import { ENDPOINTS } from '@config/endpoints';
import { authedJsonFetch, AuthedJsonFetchResult } from '@services/base/authed-json-fetch';
import { AccountGetEmailIsConfirmedResponseData, AccountGetEmailIsConfirmedResponseDto } from '@services/dto/account/account-get-email-is-confirmed';
import { accountGetEmailIsConfirmedResponseFromDto } from '@mapping/account/account-get-email-is-confirmed';
type AccountGetEmailIsConfirmed = () => AuthedJsonFetchResult<AccountGetEmailIsConfirmedResponseData>;
export const accountGetEmailIsConfirmed: AccountGetEmailIsConfirmed = () => {
  const {
    promise,
    cancel
  } = authedJsonFetch<AccountGetEmailIsConfirmedResponseDto>(ENDPOINTS.account.emailConfirmed(),
  //
  {
    method: 'GET'
  });
  return {
    promise: promise.then(accountGetEmailIsConfirmedResponseFromDto),
    cancel
  };
};