import { IMediaCallHandlerConstructorArgs, IMediaCallHandlerOnConnectError, IMediaCallHandlerOnMediaError, IMediaCallHandlerOnParticipantReconnecting } from '@media-call-handler';
import Video, { createLocalAudioTrack, createLocalVideoTrack, Participant, RemoteTrack, Room, TwilioError } from 'twilio-video';
import { ERROR_CODE } from '@config/errors';
import { defineGeometryType, isDesktop } from '@helpers/geometry';
import { ChatId, MEDIA_CALL_DIRECTION, MEDIA_CALL_FACING_MODE, MEDIA_CALL_TYPE } from '@types';
import { IMediaCallHandler, IMediaCallHandlerConstructor, IMediaCallHandlerOnDisconnect, IMediaCallHandlerOnDisconnected, IMediaCallHandlerOnParticipantConnected, IMediaCallHandlerOnTrackStarted, IMediaCallHandlerOnTrackSubscribed, IMediaCallHandlerOnTrackUnsubscribed, MediaCallHandlerConnectArgs, MediaCallHandlerConnectOptions, MediaCallHandlerLocalAudioTrack, MediaCallHandlerLocalVideoTrack, MediaCallHandlerRoom } from './types';

/**
 * @todo
 * Don't use store and dispatch as dependencies.
 * MediaCallHandler should not know about redux store.
 */
export const MediaCallHandler: IMediaCallHandlerConstructor = class MediaCall implements IMediaCallHandler {
  private readonly _onParticipantConnected: undefined | IMediaCallHandlerOnParticipantConnected;
  private readonly _onParticipantReconnecting: undefined | IMediaCallHandlerOnParticipantReconnecting;
  private readonly _onDisconnect: undefined | IMediaCallHandlerOnDisconnect;
  private readonly _onDisconnected: undefined | IMediaCallHandlerOnDisconnected;
  private readonly _onMediaError: undefined | IMediaCallHandlerOnMediaError;
  private readonly _onConnectError: undefined | IMediaCallHandlerOnConnectError;
  private readonly _onTrackSubscribed: undefined | IMediaCallHandlerOnTrackSubscribed;
  private readonly _onTrackUnsubscribed: undefined | IMediaCallHandlerOnTrackUnsubscribed;
  private readonly _onTrackStarted: undefined | IMediaCallHandlerOnTrackStarted;
  private _token?: string;
  private _chatId?: ChatId;
  private _type?: MEDIA_CALL_TYPE;
  private _direction?: MEDIA_CALL_DIRECTION;
  private _room?: MediaCallHandlerRoom;
  private _localVideoTrack?: MediaCallHandlerLocalVideoTrack;
  private _localAudioTrack?: MediaCallHandlerLocalAudioTrack;
  public facingMode: undefined | MEDIA_CALL_FACING_MODE;
  constructor(args?: IMediaCallHandlerConstructorArgs) {
    const {
      onParticipantConnected,
      onParticipantReconnecting,
      onDisconnect,
      onDisconnected,
      onConnectError,
      onMediaError,
      onTrackSubscribed,
      onTrackUnsubscribed,
      onTrackStarted
    } = args ?? {};
    this._onParticipantConnected = onParticipantConnected;
    this._onParticipantReconnecting = onParticipantReconnecting;
    this._onDisconnect = onDisconnect;
    this._onDisconnected = onDisconnected;
    this._onConnectError = onConnectError;
    this._onMediaError = onMediaError;
    this._onTrackSubscribed = onTrackSubscribed;
    this._onTrackUnsubscribed = onTrackUnsubscribed;
    this._onTrackStarted = onTrackStarted;
  }
  private _participantConnected = (participant: Participant): void => {
    console.info('Participant "%s" connected', participant.identity);
    participant.on('trackSubscribed', this._trackSubscribed);
    participant.on('trackStarted', this._trackStarted);
    participant.on('trackUnsubscribed', this._trackUnsubscribed);
    participant.on('reconnecting', this._participantReconnecting);
    this._onParticipantConnected?.({
      chatId: this._chatId
    });
  };
  private _participantReconnecting = (participant: Participant) => {
    console.info('Participant "%s" reconnecting', participant.identity);
    this._onParticipantReconnecting?.({
      participant,
      chatId: this._chatId
    });
  };
  private _participantDisconnected = async (participant: Participant) => {
    console.info('Participant "%s" disconnected', participant.identity);
    await this._disconnect();
  };
  private _roomDisconnected = async (room: Room, error: undefined | TwilioError) => {
    console.info('Room "%s" disconnected', room.sid);
    if (error && error.code === ERROR_CODE.MEDIA_CONNECTION_ERROR) {
      await this._disconnect();
    }
    if (error && error.code !== ERROR_CODE.ROOM_IS_COMPLETED) {
      this._onDisconnected?.({
        error,
        chatId: this._chatId
      });
    }
  };
  private _trackSubscribed = (track: RemoteTrack): void => {
    console.info('Remote track subscribed: ', track);
    this._onTrackSubscribed?.({
      chatId: this._chatId,
      track
    });
  };
  private _trackUnsubscribed = (track: RemoteTrack): void => {
    console.info('Remote track unsubscribed: ', track);
    this._onTrackUnsubscribed?.({
      chatId: this._chatId,
      track
    });
  };
  private _trackStarted = (track: RemoteTrack): void => {
    console.info('Remote track started: ', track);
    this._onTrackStarted?.({
      chatId: this._chatId,
      track
    });
  };
  private _disconnect = async (): Promise<void> => {
    this._stopLocalVideoTracks();
    this._stopLocalAudioTracks();
    this._room?.disconnect();
    await this._onDisconnect?.({
      room: this._room,
      chatId: this._chatId
    });
    this._room = undefined;
    this._token = undefined;
    this._type = undefined;
    this._direction = undefined;
    this._chatId = undefined;
    this.facingMode = undefined;
  };
  private _stopLocalVideoTracks = () => {
    if (this._localVideoTrack) {
      this._localVideoTrack.stop();
      const localTrackPublication = this._room?.localParticipant //
      .unpublishTrack(this._localVideoTrack);
      // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
      this._room?.localParticipant.emit('trackUnpublished', localTrackPublication);
    }
    this._localVideoTrack = undefined;
  };
  private _stopLocalAudioTracks = () => {
    if (this._localAudioTrack) {
      this._localAudioTrack.stop();
      const localTrackPublication = this._room?.localParticipant //
      .unpublishTrack(this._localAudioTrack);
      // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
      this._room?.localParticipant.emit('trackUnpublished', localTrackPublication);
    }
    this._localAudioTrack = undefined;
  };
  private _getAvailableVideoCameras = async () => {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    return mediaDevices //
    .filter(mediaDevice => mediaDevice.kind === 'videoinput');
  };
  private _getAvailableMicrophones = async () => {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    return mediaDevices //
    .filter(mediaDevice => mediaDevice.kind === 'audioinput');
  };
  private _ensureHasCameras = async () => {
    const availableCameras = await this._getAvailableVideoCameras();
    return Boolean(availableCameras.length);
  };
  private _ensureHasMicrophones = async () => {
    const availableMicrophones = await this._getAvailableMicrophones();
    return Boolean(availableMicrophones.length);
  };
  private _handleMediaError = (error: Error) => {
    this._onMediaError?.({
      error,
      chatId: this._chatId
    });
  };
  public connect = async (args: MediaCallHandlerConnectArgs, options?: MediaCallHandlerConnectOptions): Promise<void> => {
    const {
      token,
      type,
      direction,
      roomName,
      chatId
    } = args;
    this._token = token;
    this._type = type;
    this._direction = direction;
    this._chatId = chatId;
    try {
      this._room = await Video.connect(this._token, {
        ...options,
        name: roomName,
        audio: false,
        video: false,
        networkQuality: {
          local: 1,
          remote: 1
        },
        bandwidthProfile: {
          video: {
            mode: 'grid',
            dominantSpeakerPriority: 'high'
          }
        },
        preferredVideoCodecs: [{
          codec: 'VP8',
          simulcast: true
        }],
        preferredAudioCodecs: ['opus']
      });
    } catch (error: unknown) {
      if (error instanceof TwilioError) {
        if (error.code === ERROR_CODE.MEDIA_CONNECTION_ERROR) {
          await this._disconnect();
        }
        this._onConnectError?.({
          error,
          chatId: this._chatId
        });
        throw error;
      } else if (error instanceof Error) {
        this._handleMediaError(error);
        throw error;
      } else {
        throw error;
      }
    }
    this._room?.on('participantConnected', this._participantConnected);
    this._room?.on('participantDisconnected', this._participantDisconnected);
    this._room?.on('disconnected', this._roomDisconnected);
    this._room?.participants.forEach(this._participantConnected);
  };
  public disconnect = async (): Promise<void> => {
    await this._disconnect();
  };
  public toggleVideoOn = async (): Promise<undefined | MediaCallHandlerLocalVideoTrack> => {
    const hasCameras = await this._ensureHasCameras();
    if (!hasCameras) {
      return;
    }
    try {
      if (!this._localVideoTrack) {
        const geometryType = defineGeometryType();
        this._localVideoTrack = await createLocalVideoTrack({
          advanced: [{
            facingMode: {
              exact: this.facingMode
            },
            ...(isDesktop(geometryType) ? {
              width: 1280,
              height: 720,
              frameRate: 24
            } : undefined)
          }]
        });
      }
      await this._room?.localParticipant //
      .publishTrack(this._localVideoTrack, {
        priority: 'low'
      });
      return this._localVideoTrack;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._handleMediaError(error);
        throw error;
      } else {
        throw error;
      }
    }
  };
  public toggleVideoOff = (): void => {
    this._stopLocalVideoTracks();
  };
  public toggleAudioOn = async (): Promise<undefined | MediaCallHandlerLocalAudioTrack> => {
    const hasMicrophones = await this._ensureHasMicrophones();
    if (!hasMicrophones) {
      return;
    }
    try {
      if (!this._localAudioTrack) {
        this._localAudioTrack = await createLocalAudioTrack();
      }
      await this._room?.localParticipant //
      .publishTrack(this._localAudioTrack, {
        priority: 'low'
      });
      return this._localAudioTrack;
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._handleMediaError(error);
        throw error;
      } else {
        throw error;
      }
    }
  };
  public toggleAudioOff = (): void => {
    this._stopLocalAudioTracks();
  };
};