import { isActiveCall } from '@helpers/media-call';
import { MediaCallDisableAcquiringAudioReducer } from './types';
export const mediaCallDisableAcquiringAudioReducer: MediaCallDisableAcquiringAudioReducer = state => {
  const activeCall = Object.values(state.mediaCallsById).find(isActiveCall);
  if (!activeCall) {
    return state;
  }
  return {
    ...state,
    mediaCallsById: {
      ...state.mediaCallsById,
      [activeCall.chatId]: {
        ...activeCall,
        _acquiringAudio: false
      }
    }
  };
};