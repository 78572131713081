import { ANALYTICS_ACTION } from '@config/logging';
import { IEventBus, IEventBusHandleResult } from '@logging/event-bus';
import { AnalyticsEvent, IAnalyticsEvent, IAnalyticsLPClickEvent } from '@logging/logging-events';
export const isLPClickEvent = (event: IAnalyticsEvent<unknown>): event is IAnalyticsLPClickEvent => {
  const {
    action
  } = event.payload;
  return action === ANALYTICS_ACTION.LP_CLICK;
};
export const sendLPClick = (eventBus: IEventBus): IEventBusHandleResult => {
  return eventBus.handle(new AnalyticsEvent<undefined>({
    action: ANALYTICS_ACTION.LP_CLICK,
    data: undefined
  }));
};