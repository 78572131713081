import NextRouter, { useRouter } from 'next/router';
import { NAVIGATION_MODE } from '@config/routing';
import { isServer } from '@helpers/misc';
import { decodeUrl } from '@helpers/routing';
import { Route, RouteDescriptor, RouteParams } from '../route';
import { ClientNavigatorArgs, ClientNavigatorOnNavigate, ClientNavigatorRouter, ClientNavigatorRouterQuery, IClientNavigator, IClientNavigatorConstructor } from './types';
export const ClientNavigator: IClientNavigatorConstructor = class ClientNavigator implements IClientNavigator {
  private readonly _router: ClientNavigatorRouter;
  private readonly _onNavigate?: ClientNavigatorOnNavigate;
  private _getRouter(): ClientNavigatorRouter {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return isServer() ? useRouter() : NextRouter;
  }
  constructor(args?: ClientNavigatorArgs) {
    this._router = args?.router ?? this._getRouter();
    this._onNavigate = args?.onNavigate;
    if (this._onNavigate && this._router.events) {
      this._router.events.on('routeChangeComplete', this._onNavigate);
    }
  }
  public getUrl(): string {
    return this._router.asPath;
  }
  public getPathname(): string {
    return decodeUrl(this._router.asPath).pathname;
  }
  public getQuery(): ClientNavigatorRouterQuery {
    return this._router.query;
  }
  public async navigate<P extends RouteParams>(routeDescriptor: RouteDescriptor<P>): Promise<void> {
    if (isServer()) {
      console.trace('Router is used on server side');
      return;
    }
    const {
      href,
      as
    } = new Route(routeDescriptor).getLink();
    if (routeDescriptor.mode === NAVIGATION_MODE.REPLACE) {
      await this._router.replace(href, as, {
        scroll: Boolean(routeDescriptor.resetScroll)
      });
    } else {
      await this._router.push(href, as, {
        scroll: Boolean(routeDescriptor.resetScroll)
      });
    }
  }
};