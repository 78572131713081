import { ClientsSetInvitationAllowedReducer } from './types';
export const clientsSetInvitationAllowedReducer: ClientsSetInvitationAllowedReducer = (state, action) => {
  const {
    clientId,
    invitationAllowed
  } = action.payload;
  return {
    ...state,
    clientsById: {
      ...state.clientsById,
      [clientId]: {
        ...state.clientsById[clientId],
        invitationAllowed
      }
    }
  };
};