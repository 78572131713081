export enum TAG {
  FAMILY = '#семья',
  SEX = '#секс',
  WORK = '#работа',
  STRESS = '#стресс',
  HEALTH = '#здоровье',
}
export type Tag = {
  text: TAG;
  isMain?: boolean;
};