import { readJson } from '@helpers/data';
import { RealtimeChatComplimentReceivedEventPayloadData } from '@services/dto/realtime/realtime-chat-event/realtime-chat-compliment-received';

// import { specialistsSetBalance } from '@actions/specialists';
// import { getSpecialistBalanceAmount } from '@selectors/specialists';
import { RealtimeChatComplimentReceived } from './types';

/**
 * @deprecated
 * The event intersects with BALANCE_CHANGED = 14 event.
 * Do not need to handle it currently.
 */
export const realtimeChatComplimentReceived: RealtimeChatComplimentReceived = event => {
  return () => {
    const data = readJson<RealtimeChatComplimentReceivedEventPayloadData>(event.payload);
    if (!data) {
      return;
    }

    // const { Amount: amount } = data;

    // const state = getState();
    // const balance = getSpecialistBalanceAmount(state);

    // dispatch(specialistsSetBalance({ amount: balance + amount }));
  };
};