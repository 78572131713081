import { AppFC } from '@types';
import { loadingScreenGetLastScreen } from '@selectors/loading-screen';
import { useTypedSelector } from '@hooks/store';
import { LoadingScreen } from '@kit/loading-screen';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LoadingScreenWidget: AppFC = () => {
  const loadingScreen = useTypedSelector(loadingScreenGetLastScreen);
  if (!loadingScreen) {
    return null;
  }
  return _jsx(LoadingScreen //
  , {
    overlayBgColor: loadingScreen.props?.overlayBgColor
  });
};