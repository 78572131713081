import { camelKeysRecursive, readJson } from '@helpers/data';
import { RealtimeChatPrepaymentBalanceChangedEventPayloadData } from '@services/dto/realtime/realtime-chat-event/realtime-chat-prepayment-balance-changed-event';
import { chatsSetPrepaymentBalance } from '@slices/chats';
import { RealtimeChatPrepaymentBalanceChanged } from './types';

/**
 * @deprecated
 * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/863
 */
export const realtimeChatPrepaymentBalanceChanged: RealtimeChatPrepaymentBalanceChanged = event => {
  return dispatch => {
    const data = readJson<RealtimeChatPrepaymentBalanceChangedEventPayloadData>(event.payload, camelKeysRecursive);
    if (!data) {
      return;
    }
    const {
      chatId,
      totalAmount,
      balanceAmount
    } = data;
    dispatch(chatsSetPrepaymentBalance({
      chatId,
      totalAmount,
      balanceAmount
    }));
  };
};