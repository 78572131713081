import { encode } from 'querystring';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { HTTP_CACHE_CONTROL, HTTP_HEADER_NAME, HTTP_STATUS_CODE } from '@config/net';
import { omitNullish } from '@helpers/data';
import { BaseNetError } from '@errors/base-net-error';
import { baseNetErrorBodyFromDto } from '@mapping/errors';
import { HttpFetchConfig, HttpFetchHeaders, HttpFetchResult } from './types';
export const httpOwnHeaders = {
  [HTTP_HEADER_NAME.CACHE_CONTROL]: HTTP_CACHE_CONTROL.NO_CACHE
};
export const httpFetch = <R, D = undefined, P = undefined, H extends HttpFetchHeaders = undefined>(url: string, config?: HttpFetchConfig<D, P, H>): HttpFetchResult<R> => {
  const source = axios.CancelToken.source();
  const {
    token: cancelToken
  } = source;
  const nextConfig: AxiosRequestConfig = {
    cancelToken,
    paramsSerializer: {
      serialize: params => encode(omitNullish(params))
    },
    validateStatus: status => {
      /**
       * Reject httpFetch promise on status 204 No Content to prevent
       * resolving of the promise without data.
       * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/838
       */
      return status >= 200 &&
      // default
      status < 300 &&
      // default
      status !== HTTP_STATUS_CODE.NO_CONTENT;
    },
    ...config,
    headers: {
      ...httpOwnHeaders,
      ...config?.headers
    },
    url,
    onUploadProgress: event => {
      if (!config?.onUploadProgress) {
        return;
      }
      if (!event.total) {
        return;
      }
      const progress = Math.round(event.loaded * 100 / event.total);
      config?.onUploadProgress?.(progress, event.event);
    }
  };
  return {
    promise: axios //
    .request<R>(nextConfig).catch((error: AxiosError<R, D>) => {
      if (axios.isCancel(error)) {
        console.warn(`Request canceled: ${error.message}`);
        throw error;
      }
      throw error;
    }).catch((error: AxiosError<R, D>) => {
      if (error.response) {
        const {
          response: {
            status,
            data
          },
          message
        } = error;
        throw new BaseNetError(status, message, baseNetErrorBodyFromDto(data));
      }
      throw error;
    }),
    cancel: source.cancel
  };
};