import { useMemo } from 'react';
import { QUERY_PARAM } from '@config/routing';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { notificationsUiRemove } from '@slices/notifications-ui';
import { useNavigator } from '@hooks/routing';
import { useTypedDispatch } from '@hooks/store';
import { NotificationUiWidget, NotificationUiWidgetProps } from '@widgets/notifications/notification-ui';
import { EmailIcon } from '@icons';
import { NewMessageNotificationProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NewMessageNotification: AppFCC<NewMessageNotificationProps> = props => {
  const {
    notificationId,
    message,
    name,
    avatar,
    chatId
  } = props;
  const dispatch = useTypedDispatch();
  const navigator = useNavigator();
  const actionButton: NotificationUiWidgetProps['actionButton'] = useMemo(() => {
    return {
      icon: _jsx(EmailIcon, {}),
      text: 'Прочитать',
      onClick: () => {
        navigator.navigate(ROUTES.chats.id.getDescriptor({
          [QUERY_PARAM.PATHNAME_CHAT_ID]: chatId
        }));
        dispatch(notificationsUiRemove({
          id: notificationId
        }));
      }
    };
  }, [chatId, dispatch, navigator, notificationId]);
  return _jsx(NotificationUiWidget //
  , {
    notificationId: notificationId,
    title: "\u041D\u043E\u0432\u043E\u0435 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435",
    message: message,
    name: name,
    avatar: avatar,
    actionButton: actionButton
  });
};