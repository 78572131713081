import { useMemo } from 'react';
import { NewCalendarNotification } from '@notifications/notifications/new-calendar';
import { NewInvitationNotification } from '@notifications/notifications/new-invitation';
import { NewMessageNotification } from '@notifications/notifications/new-message';
import { NewRefundNotification } from '@notifications/notifications/new-refund';
import { NewViewNotification } from '@notifications/notifications/new-view';
import { NOTIFICATION_TYPE } from '@config/notifications';
import { AppFC } from '@types';
import { notificationsUiGetList } from '@selectors/notifications-ui';
import { useTypedSelector } from '@hooks/store';
import { NotificationsUiContainerWidgetListStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const NotificationsUiContainerWidget: AppFC = () => {
  const notificationsList = useTypedSelector(notificationsUiGetList);
  const preparedList = useMemo(() => {
    return notificationsList.map(notification => {
      const notificationId = notification.id;
      let item;
      switch (notification.type) {
        case NOTIFICATION_TYPE.NEW_INVITATION:
          item = _jsx(NewInvitationNotification //
          , {
            notificationId: notificationId,
            ...notification
          }, notificationId);
          break;
        case NOTIFICATION_TYPE.NEW_VIEW:
          item = _jsx(NewViewNotification //
          , {
            notificationId: notificationId,
            ...notification
          }, notificationId);
          break;
        case NOTIFICATION_TYPE.NEW_CALENDAR:
          item = _jsx(NewCalendarNotification //
          , {
            notificationId: notificationId,
            ...notification
          }, notificationId);
          break;
        case NOTIFICATION_TYPE.NEW_REFUND:
          item = _jsx(NewRefundNotification //
          , {
            notificationId: notificationId,
            ...notification
          }, notificationId);
          break;
        case NOTIFICATION_TYPE.NEW_MESSAGE:
        default:
          item = _jsx(NewMessageNotification //
          , {
            notificationId: notificationId,
            ...notification
          }, notificationId);
      }
      return item;
    });
  }, [notificationsList]);
  return _jsx(NotificationsUiContainerWidgetListStyled, {
    children: preparedList
  });
};