import { Chat } from '@types';
import { ChatsReceiveGetByIdReducer } from './types';
export const chatsReceiveGetByIdReducer: ChatsReceiveGetByIdReducer = (state, action) => {
  const {
    chat
  } = action.payload;
  const {
    chatsById
  } = state;
  const prevChat = (chatsById[chat.chatId] as undefined | Chat);
  if (!prevChat) {
    return {
      ...state,
      chatsById: {
        ...chatsById,
        [chat.chatId]: chat
      }
    };
  }
  return {
    ...state,
    chatsById: {
      ...chatsById,
      [chat.chatId]: {
        ...chat,
        /**
         * lastMessage creationTime values are compared to define whether
         * update unreadedMessagesCount or not:
         * https://gitlab.com/freuders-development/freuders-frontend-web/-/issues/713
         */
        unreadedMessagesCount: new Date(prevChat.lastMessage?.creationTime ?? 0).getTime() >= new Date(chat.lastMessage?.creationTime ?? 0).getTime() ? prevChat.unreadedMessagesCount : chat.unreadedMessagesCount
      }
    }
  };
};