import { appCreateSlice } from '@store/rtk';
import { paymentsClearSettingsReducer } from '@slices/payments/payments-clear-settings';
import { paymentsReceiveDeleteBankCardReducer } from '@slices/payments/payments-receive-delete-bank-card';
import { paymentsReceiveGetPremiumIsSubscribedReducer } from '@slices/payments/payments-receive-get-premium-is-subscribed';
import { paymentsReceiveGetSettingsReducer } from '@slices/payments/payments-receive-get-settings';
import { paymentsReceivePostPremiumIsSubscribedReducer } from '@slices/payments/payments-receive-post-premium-is-subscribed';
import { PaymentsState } from './types';
export const paymentsDefaultState: PaymentsState = {
  paymentSettings: undefined,
  premiumIsSubscribed: undefined
};
const paymentsSlice = appCreateSlice({
  name: 'payments',
  initialState: paymentsDefaultState,
  reducers: {
    paymentsReceiveDeleteBankCard: paymentsReceiveDeleteBankCardReducer,
    paymentsReceiveGetPremiumIsSubscribed: paymentsReceiveGetPremiumIsSubscribedReducer,
    paymentsReceiveGetSettings: paymentsReceiveGetSettingsReducer,
    paymentsReceivePostPremiumIsSubscribed: paymentsReceivePostPremiumIsSubscribedReducer,
    paymentsClearSettings: paymentsClearSettingsReducer
  }
});
export const {
  actions: {
    paymentsReceiveDeleteBankCard,
    paymentsReceiveGetPremiumIsSubscribed,
    paymentsReceiveGetSettings,
    paymentsReceivePostPremiumIsSubscribed,
    paymentsClearSettings
  },
  reducer: paymentsReducer
} = paymentsSlice;