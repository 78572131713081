import { omit } from '@helpers/data';
import { enforceDateStringToISO } from '@helpers/date';
import { SpecialistsGetByIdResponseData, SpecialistsGetByIdResponseDto } from '@services/dto/specialists';
import { documentGetResponseFromDto } from '@mapping/documents';
import { mediaGetResponseFromDto } from '@mapping/media/media-get';
import { photoGetResponseFromDto } from '@mapping/photos';
export const specialistsGetByIdResponseFromDto = (dto: SpecialistsGetByIdResponseDto): SpecialistsGetByIdResponseData => {
  return omit({
    ...dto.data,
    birthDate: dto.data.birthDate ? enforceDateStringToISO(dto.data.birthDate) : undefined,
    lastActivityTime: dto.data.lastActivityTime ? enforceDateStringToISO(dto.data.lastActivityTime) : undefined,
    documents: dto.data.files.map(file => documentGetResponseFromDto({
      data: file
    })),
    media: [...(dto.data.media ? dto.data.media.map(file => mediaGetResponseFromDto({
      data: file
    })) : []), ...dto.data.photos.map(file => photoGetResponseFromDto({
      data: file
    }))]
  }, 'files', 'photos');
};