import { keyById, mapById } from '@helpers/data';
import { SubjectsReceiveGetReducer } from './types';
export const subjectsReceiveGetReducer: SubjectsReceiveGetReducer = (state, action) => {
  const {
    subjects
  } = action.payload;
  const sortedSubjects = subjects //
  .slice().sort((a, b) => a.sequence - b.sequence);
  return {
    ...state,
    subjectsById: keyById(sortedSubjects),
    subjectIds: mapById(sortedSubjects)
  };
};