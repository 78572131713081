import { PROVIDER } from '@types';
export enum ROLE_TYPE {
  CLIENT = 1,
  SPECIALIST = 2,
}
export enum CULTURE {
  RU = 'ru-RU',
}
export type AccountAssociatedSocialMedia = {
  loginProvider: PROVIDER;
};
export type AccountAssociatedSocialMedias = AccountAssociatedSocialMedia[];
export type AccountProtectedUserId = string;
export type AccountTimezoneId = string;
export type AccountTimezoneOffset = number;
export type AccountTimezone = {
  timezoneId: AccountTimezoneId;
};
export type AccountTimezones = AccountTimezone[];